import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class LoginLogic {

  constructor(context){
    this.baseContext = context;
  }

  handleEmailChange = (event)=>{
    this.baseContext.setState({emailValue: event.target.value});
  }

  handlePasswordChange = (event)=>{
    this.baseContext.setState({passwordValue: event.target.value});
  }

  openResetPassword = ()=>{
    this.baseContext.setState({resetPasswordOpen:true});
  }

  closeResetPassword = ()=>{
    this.baseContext.setState({resetPasswordOpen:false});
  }

  attempPasswordReset = async()=>{

    if(this.baseContext.state.resetPasswordEmail == ""){
      this.baseContext.showAlert("Your email cannot be empty!");
      return;
    }

    await this.baseContext.setState({loading:true, resetPasswordOpen:false});

    var auth = firebase.auth();
    let email = this.baseContext.state.resetPasswordEmail;

    let emailSent = await auth.sendPasswordResetEmail(email).then(()=>{
        return{
          success:true
        }
      }).catch((error)=>{
        return{
          success:false,
          message:error.message
        }
      });

      if(emailSent){
        this.baseContext.showAlert("An email has been sent to reset your password!");
        await this.baseContext.setState({loading:false});
      }else{
        this.baseContext.showAlert(emailSent.message);
        await this.baseContext.setState({loading:false});
      }




  }

  validateAuth = ()=>{
    firebase.auth().onAuthStateChanged((user)=>{

      if (user && !(this.baseContext.state.loading)) {
        this.baseContext.props.history.push('/dashboard');

      }else{
        this.baseContext.setState({authenticating:false});
      }

    });

  }

  attemptLogin = ()=>{
    if(!this.baseContext.state.loading){

      this.baseContext.setState({loading: true, error:''});
      let email = this.baseContext.state.emailValue;
      let password = this.baseContext.state.passwordValue;

      firebase.auth().signInWithEmailAndPassword(email, password)
      .then((result)=> {
        this.baseContext.setState({
          loading:false,
          error:"",
        });


      const currentUserId = firebase.auth().currentUser.uid;
      this.baseContext.props.history.push('/dashboard');

    }).catch((error)=> {
      this.baseContext.setState({
        loading:false,
        error:error.message,
      });
    });

    }
  }



}

export default LoginLogic;
