import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class CoachLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Open Panel to create course
  openNewCoursePanel = ()=>{
    this.baseContext.setState({newCoursePanelOpen: true});
  }

  //Close new course panel (Not created)
  closeNewCoursePanel = ()=>{
    this.baseContext.setState({newCoursePanelOpen: false});
  }

  //Open a course
  openCourse = (courseIndex)=>{
    this.baseContext.setState({courseOpen:true, openCourseIndex:courseIndex, loadingModules:true});

    let modules = [];

    firebase.firestore().collection("resources").doc(this.baseContext.state.coursesList[courseIndex].id).collection("modules").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          modules.push({name:doc.data().name, files: doc.data().files, description:doc.data().description, id:doc.id, lessons:doc.data().lessons});
        });

        this.baseContext.setState({
          chosenCourseModules: modules,
          loadingModules:false
        });



    });
  }

  //Opena  Module
  openModule = (moduleIndex)=>{
    this.baseContext.setState({moduleOpen:true, openModuleIndex:moduleIndex});
  }

  //Close A Module
  closeModule = ()=>{
    this.baseContext.setState({moduleOpen:false, openModuleIndex:null});
  }

  //Open a file
  openLink = (url)=>{
    window.open(
        url,
        '_blank'
      );
  }

  //Close a course
  closeCourse = ()=>{
    this.baseContext.setState({courseOpen:false, openCourseIndex:null});
  }

  //Load Coach's courses
  loadCourses = async()=>{
    let courses = [];
    let courseIds = [];
    let count = 0;

    const currentUserId = firebase.auth().currentUser.uid;
    const currentUserCompanyId = this.baseContext.state.accountStatus.companyId;

    await firebase.firestore().collection("resources").where("userIds","array-contains", currentUserId).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if(doc.data().live && !courseIds.includes(doc.id)){
          courses.push({name:doc.data().name, public:doc.data().public,files: doc.data().files, live:doc.data().live, id:doc.id, index:count, isCoachingUser:doc.data().isCoachingUser, isCheckinUser:doc.data().isCheckinUser,email:doc.data().email, authority:doc.data().authority});
          courseIds.push(doc.id);
          count++;
        }

        });

    });

    console.log('COMPANYID', currentUserCompanyId);

    await firebase.firestore().collection("resources").where("organisationIds","array-contains", currentUserCompanyId).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if(doc.data().live && !courseIds.includes(doc.id)){
          courses.push({name:doc.data().name, public:doc.data().public,files: doc.data().files, live:doc.data().live, id:doc.id, index:count, isCoachingUser:doc.data().isCoachingUser, isCheckinUser:doc.data().isCheckinUser,email:doc.data().email, authority:doc.data().authority});
          courseIds.push(doc.id);
          count++;
        }

        });

    });

    this.baseContext.setState({
      coursesList: courses,
    });



  }



}

export default CoachLogic;
