import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { withAlert } from 'react-alert';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import whitePlusIcon from '../../../assets/white-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import defaultProfileImage from '../../../assets/profile-picture.png';
import creditCardIcon from '../../../assets/icons/credit-card.png';
import tickIcon from '../../../assets/tick.png';
import stopIcon from '../../../assets/icons/cancel-subscription.png';

import 'react-big-calendar/lib/css/react-big-calendar.css';


const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "rgb(40, 87, 222)",
      color: "rgb(40, 87, 222)",
      fontSize: "16px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#787878"
      }
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};

let CardSection = ()=>{
  return <CardElement options={CARD_ELEMENT_OPTIONS} />;
}

class CheckoutForm extends React.Component {
  handleSubmit = async event => {
    event.preventDefault();

    if(this.props.type == 'planPayment'){
      const elements = this.props.elements;
      const stripe = this.props.stripe;

      if (!stripe || !elements) {
        return;
      }


      const card = elements.getElement(CardElement);
      const result = await stripe.createPaymentMethod({
            type: 'card',
            card: card
          })

          if (result.error) {
            this.showAlert(result.error.message);
          } else {
            this.props.context.logic.payForPlan(result.paymentMethod.id);
          }

    }else{
      const elements = this.props.elements;
      const stripe = this.props.stripe;

      if (!stripe || !elements) {
        return;
      }


      const card = elements.getElement(CardElement);
      const result = await stripe.createPaymentMethod({
            type: 'card',
            card: card
          })

          if (result.error) {
            this.showAlert(result.error.message);
          } else {
            this.props.context.logic.createOrganisationPayment(result.paymentMethod.id);
          }

    }


      }


  render() {
    return (
      <div className="payment-form">
        <div className="product-info">
          <h3 className="product-title">Inkel Health {(this.props.onceOff)?'':'Company'} Plan</h3>
          <h4 className="product-price">€{this.props.price} {(this.props.onceOff)?'':'/month'}</h4>
        </div>
        <form onSubmit={this.handleSubmit}>
          <CardSection />
          <button className="btn-pay">Buy Now</button>
        </form>
      </div>
    );
  }

}

class ClientSubscriptions extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      accountStatus:props.accountStatus,
      myName:props.accountStatus.name,
      myCompanyName:props.accountStatus.companyName,
      profileImage:props.accountStatus.profileURL
    };

    this.context = props.context;
    this.stripePromise = loadStripe('pk_live_51H70XpEfWvOoal5c8H5IQT5ZhudVi7KnglHRJMnawBSh6mLejhGIyiwQwVlR7x7sxipjCrSXeHAIn9vVbXhOn5zo00FF3W0tas');
    this.mobileSideBar = props.mobileSideBar;
    this.profileImageInput = React.createRef();
    this.logic = new Logic(this);
    console.log('loads');
  }

  componentDidMount(){
    this.logic.loadPlanData();
  }

  renderAddAvailabilityPanel = ()=>{
    if(!this.state.addAvailabilityPanelOpen) return;

    return(
      <div className="add-availability-panel">
        <img src={xButton} className="add-availability-close-button" onClick={this.logic.closeAddAvailabilityPanel} />
        <div className="add-availability-form">
          <div className="add-availability-form-header">Set your Session Availability</div>
          <div className="add-availability-timezone">Your Timezone: {this.state.timeZone}</div>
          <div className="add-availability-chosen-date">Chosen Date: {this.state.chosenDisplayDate}</div>
        </div>
      </div>
    )
  }


  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else if(this.state.accountStatus.accountType == 'company' && this.state.accountStatus.authority == 'admin'){
      return(
        <div className="client-settings-home">
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Subscriptions.MySubscriptions} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="client-subscriptions-container">

            {this.renderPaymentPanel()}


            <div className="subscriptions-main-manager">
              <div className="subscriptions-main-manager-header">{Language.English.Subscriptions.SubscriptionsHeader}</div>
              {this.renderCompanySubscriptionPanel()}
            </div>

          </div>
        </div>
      )
    }else{

      const localizer = momentLocalizer(moment);

      return(
        <div className="client-settings-home">
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Subscriptions.MySubscriptions} userName={this.props.userName} profileImage={this.props.profileImage}/>

          {this.renderPlanPaymentPanel()}

          <div className="client-subscriptions-container">

            <div className="plans-manager">
              <div className="plans-manager-header">{Language.English.Subscriptions.PlansHeader}</div>
              {this.renderPlansManagerContent()}
            </div>

          </div>
        </div>
      );

    }

  }

  renderPlanPaymentPanel = ()=>{
    if(!this.state.planPaymentPanelOpen) return;

    return(
      <div className="payment-method-panel">
        <img className="close-button" src={xButton} onClick={this.logic.closePlanPaymentPanel}/>

        <div className="payment-method-form">
          <Elements stripe={this.stripePromise}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} type={'planPayment'} onceOff={true} elements={elements} context={this} price={this.state.planPrice}/>
              )}
            </ElementsConsumer>
          </Elements>
        </div>
      </div>
    )
  }

  renderPaymentPanel = ()=>{
    if(!this.state.paymentPanelOpen) return;

    return(
      <div className="payment-method-panel">
        <img className="close-button" src={xButton} onClick={this.logic.closeCreatePaymentPanel}/>

        <div className="payment-method-form">
          <Elements stripe={this.stripePromise}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} context={this} price={this.logic.calculateTotalPrice([this.state.accountStatus.coachingServicesPrice,this.state.accountStatus.checkinServicesPrice,this.state.accountStatus.basicUserPrice, this.state.accountStatus.contentServicesPrice], [this.state.accountStatus.numberOfCoachingServices,this.state.accountStatus.numberOfCheckinServices,this.state.accountStatus.numberOfBasicUsers, 1])}/>
              )}
            </ElementsConsumer>
          </Elements>
        </div>
      </div>
    )
  }

  renderCompanySubscriptionPanel = ()=>{

    if(!this.state.accountStatus.stripeId){
      return(
        <div className="plans-manager-content">
          {Language.English.Subscriptions.NoCompanySubscription}

          <br/>

          {this.renderCompanyPlanTable()}

          <div className="add-payment-button" onClick={this.logic.openCreatePaymentPanel}>
            <img src={creditCardIcon} className="add-payment-icon" />
            <div className="add-payment-text">
              {Language.English.Subscriptions.CreatePayment}
            </div>
          </div>

        </div>
      )
    }else if(!this.state.accountStatus.companyPaid){
      return(
        <div className="plans-manager-content">
          {Language.English.Subscriptions.NoCompanySubscription}

          <br/>

          {this.renderCompanyPlanTable()}



          <div className="add-payment-button" onClick={this.logic.requestSubscription}>
            <img src={creditCardIcon} className="add-payment-icon" />
            <div className="add-payment-text">
              {Language.English.Subscriptions.AddPayment}
            </div>
          </div>

        </div>
      )
    }else{
      return(
        <div className="plans-manager-main-content">
          <div classNam="tick-container">
            <img className="plans-manager-tick-icon" src={tickIcon} />
          </div>
          {Language.English.Subscriptions.UpToDatePayment}

          {this.renderCompanyPlanTable()}

          <div className="add-payment-button" onClick={this.logic.openUpdateOrganizationPaymentPanel}>
            <img src={creditCardIcon} className="add-payment-icon" />
            <div className="add-payment-text">
              {Language.English.Subscriptions.AddPayment}
            </div>
          </div>

          <div className="cancel-payment-button" onClick={this.logic.cancelOrganizationSubscription}>
            <img src={stopIcon} className="add-payment-icon" />
            <div className="add-payment-text">
              {Language.English.Subscriptions.CancelSubscription}
            </div>
          </div>
        </div>
      )

    }
  }

  renderCompanyPlanTable = ()=>{
    return(
      <table className="company-main-plan-x-table">

        <tr>
          <td></td>
          <td>Number of Users</td>
          <td>Monthly User Price</td>
          <td>Total</td>
        </tr>

        <tr>
          <td>Basic Users</td>
          <td>{this.state.accountStatus.numberOfBasicUsers}</td>
          <td>€{this.state.accountStatus.basicUserPrice}</td>
          <td>€{parseInt(this.state.accountStatus.basicUserPrice)*parseInt(this.state.accountStatus.numberOfBasicUsers)}</td>
        </tr>

        <tr>
          <td>Users with Check-ins</td>
          <td>{this.state.accountStatus.numberOfCheckinServices}</td>
          <td>€{this.state.accountStatus.checkinServicesPrice}</td>
          <td>€{parseInt(this.state.accountStatus.checkinServicesPrice)*parseInt(this.state.accountStatus.numberOfCheckinServices)}</td>
        </tr>

        <tr>
          <td>Users requiring coaching</td>
          <td>{this.state.accountStatus.numberOfCoachingServices}</td>
          <td>€{this.state.accountStatus.coachingServicesPrice}</td>
          <td>€{parseInt(this.state.accountStatus.coachingServicesPrice)*parseInt(this.state.accountStatus.numberOfCoachingServices)}</td>
        </tr>

        <tr>
          <td>Content Price</td>
          <td></td>
          <td></td>
          <td>€{parseInt(this.state.accountStatus.contentServicesPrice)}</td>
        </tr>

        <tr>
          <td><b>Monthly Total:</b></td>
          <td> - </td>
          <td> - </td>
          <td>€{this.logic.calculateTotalPrice([this.state.accountStatus.coachingServicesPrice,this.state.accountStatus.checkinServicesPrice,this.state.accountStatus.basicUserPrice, this.state.accountStatus.contentServicesPrice], [this.state.accountStatus.numberOfCoachingServices,this.state.accountStatus.numberOfCheckinServices,this.state.accountStatus.numberOfBasicUsers, 1])}</td>
        </tr>

      </table>
    )
  }


  renderPlansManagerContent = ()=>{
    let planPaid = this.state.planPaid;
    let planOutdated = (this.state.endTimeUTC && this.logic.isOutdated(this.state.endTimeUTC));
    let planRequested = this.state.planRequested;
    let processedByCoach = this.state.processedByCoach;
    let processedByInkelAdmin = this.state.processedByInkelAdmin;
    let planPrice = this.state.planPrice;

    if(!this.state.accountStatus.coach){
      return(
        <div className="plans-manager-content">
          {Language.English.Subscriptions.NoCoach}
        </div>
      )
    }

    if(!planRequested || planOutdated){
      return(
        <div className="plans-manager-content">
          {Language.English.Subscriptions.NoPlan}

          <div className="request-plan-button" onClick={this.logic.requestPlan}>
            {Language.English.Subscriptions.RequestPlan}
          </div>

        </div>
      )
    }

    if(planRequested && !processedByCoach && !processedByInkelAdmin){
      return(
        <div className="plans-manager-content">
          {Language.English.Subscriptions.PlanRequested}
        </div>
      )
    }

    if(!planPaid){
      return(
        <div className="plans-manager-content">
          {Language.English.Subscriptions.RequestPlanPayment}
          <br/><br/>
          €{planPrice}

          <br/><br/>

          Plan Description: {this.state.planDescription}

          <br/>

          <div className="add-payment-button" onClick={this.logic.openPlanPaymentPanel}>
            <img src={creditCardIcon} className="add-payment-icon" />
            <div className="add-payment-text">
              {Language.English.InkelAdmin.PayNow}
            </div>
          </div>

          {" "}

          <div className="add-payment-button" onClick={this.logic.requestPlan}>
            <img src={xButton} className="add-payment-icon" />
            <div className="add-payment-text">
              {Language.English.Subscriptions.RequestNewPlan}
            </div>
          </div>




        </div>
      )
    }

    return(
      <div className="plans-manager-content">
        <div style={{textAlign:'center'}}>
          <img className="plans-tick-icon" src={tickIcon} />
        </div>
        {Language.English.Subscriptions.PlanUpToDate}
      </div>
    )

  }

  renderSubscriptionManagerContent = ()=>{
    let subscriptionPaid = this.state.accountStatus.subscriptionPaid;
    let subscriptionSet = this.state.accountStatus.subscriptionSet;
    let subscriptionRequested = this.state.accountStatus.subscriptionRequested;

    if(subscriptionRequested && !subscriptionSet){
      return(
        <div className="plans-manager-content">
          {Language.English.Subscriptions.SubscriptionRequested}
        </div>
      )
    }

    if(!subscriptionSet){
      return(
        <div className="plans-manager-content">
          {Language.English.Subscriptions.NoSubscription}

          <div className="request-plan-button" onClick={this.logic.requestSubscription}>
            {Language.English.Subscriptions.RequestSubscription}
          </div>

        </div>
      )
    }

    if(!subscriptionPaid){
      return(
        <div className="plans-manager-content">
          {Language.English.Subscriptions.RequestPaymentSubscription}
        </div>
      )
    }



  }






}

export default withAlert()(ClientSubscriptions);
