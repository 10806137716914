import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { withAlert } from 'react-alert';


import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import whitePlusIcon from '../../../assets/white-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import sendIcon from '../../../assets/icons/send.png';

import 'react-big-calendar/lib/css/react-big-calendar.css';

class ClientMessages extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }


  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      coachId:props.accountStatus.coach,
      messages:[],
      message:"",
      accountStatus:props.accountStatus,
    };

    this.context = props.context;
    this.headerRef = React.createRef();
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);
    this.logic.loadCoachName();
    this.logic.loadMessages(props.accountStatus.coach);
  }



  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{

      const localizer = momentLocalizer(moment);

      if(this.headerRef.current) this.headerRef.current.name = this.state.coachName;

      return(
        <div className="client-messages-home">
          <DashboardHeader ref={this.headerRef} context={this} mobileSideBar={this.mobileSideBar} name={this.state.coachName} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="client-messages-container">
            <div className="client-messages" id="client-messages">
              {
                this.state.messages.map((message, index)=>{
                  if(message.toId == firebase.auth().currentUser.uid){
                    return(
                      <div className="message-received-row">
                        <div className="message-received-text">
                          {message.message}
                        </div>
                        <div className="message-timestamp">{this.logic.convertToLocalDateTime(message.timestamp)}</div>
                      </div>
                    )
                  }else if(message.fromId == firebase.auth().currentUser.uid){
                    return(
                      <div className="message-sent-row">
                        <div className="message-timestamp">{this.logic.convertToLocalDateTime(message.timestamp)}</div>
                        <div className="message-sent-text">
                          {message.message}
                        </div>
                      </div>
                    )
                  }


                })
              }
            </div>

            <div className="send-message-bar">
              <form onSubmit={this.logic.sendMessage}>
                <input className="send-message-textbox" placeholder={Language.English.Messages.Message} value={this.state.message} onChange={(event)=>{this.setState({message:event.target.value})}} />
                <button className="send-message-button" onClick={this.logic.sendMessage}>
                  Send
                  <img src={sendIcon} className="send-message-icon" />
                </button>
              </form>
            </div>

          </div>
        </div>
      );
    }
  }




}

export default withAlert()(ClientMessages);
