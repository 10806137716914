import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class MessagesLogic {

  constructor(context){
    this.baseContext = context;
  }

  loadUserDetails = ()=>{

    var projects = [];

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("users").doc(currentUserId).get().then((doc)=>{
      let name = doc.data().name;
      let type = doc.data().type;
      let accountType = doc.data().accountType;
      let profileURL = doc.data().profileURL;
      let availability = doc.data().availability;

      this.baseContext.setState({
        name: name,
        type:type,
        profileURL:profileURL,
        isLoadingUserData: false,
        accountType:accountType,
        availability:availability,
        accountStatus:{
          type:type,
          accountType:accountType,
          hasFilledSurvey:doc.data().surveyComplete,
          hasChosenCoach:doc.data().coachChosen,
          hasAssignedCoach:doc.data().coachAssigned,
          coach:doc.data().coach,
          authority:doc.data().authority,
          isBasicUser:doc.data().isBasicUser,
          name:name,
          isCoachingUser:doc.data().isCoachingUser,
          enabled:doc.data().enabled
        }
      });

    }).catch((error)=>{
      this.baseContext.showAlert("Error getting your details!");
    });

    firebase.firestore().collection("users").doc(currentUserId).collection("resources").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          projects.push({name:doc.data().name});
        });

        this.baseContext.setState({
          projectsList: projects,
          isLoadingProjects: false,
        });


    });


  }


  validateAuth = ()=>{

    firebase.auth().onAuthStateChanged((user)=>{

      if (user && !(this.baseContext.state.loading)) {
        this.baseContext.setState({authenticating:false});
        this.loadUserDetails();
      }else{
        document.location = "/";
      }

    });

  }






}

export default MessagesLogic;
