import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';
import "react-datepicker/dist/react-datepicker.css";

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import DatePicker from "react-datepicker";
import { Chart } from 'react-charts';
import { withAlert } from 'react-alert';
import DonutChart from 'react-donut-chart';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { VictoryChart, VictoryBar, VictoryTheme } from "victory";


//Assets
import defaultProfileImage from '../../../assets/profile-picture.png';
import xButton from '../../../assets/x-button.png';
import tick from '../../../assets/tick.png';
import switchCoachIcon from '../../../assets/icons/switch.svg';
import coachPlanIcon from '../../../assets/icons/plan.svg';
import subscriptionIcon from '../../../assets/icons/subscription.svg';
import checkinIcon from '../../../assets/icons/checkin-triangle.png';
import contentIcon from '../../../assets/icons/content-triangle.png';
import coachingIcon from '../../../assets/icons/coaching-triangle.png';
import diaryWidgetTriangle from '../../../assets/icons/diary-widget-triangle.png';

import achievement from '../../../assets/icons/trophy.png';
import meaning from '../../../assets/icons/meaning.png';
import engagement from '../../../assets/icons/engagement.png';
import happiness from '../../../assets/icons/emoticon-happy.png';
import physical from  '../../../assets/icons/heart-pulse.png';
import relationships from '../../../assets/icons/handshake.png';


//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';


// Animations
import Fade from 'react-reveal/Fade';

class ClientDashboard extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:true,
      hours:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
      minutes:['00','15','30','45'],
      excercise:'',
      dayIndex:0,
      loadingSurveyData:true,

      chosenHours:'00',
      chosenMinutes:'00',

      accountStatus:props.accountStatus,

      mainData:[],

      axes:[
        { primary: true, position: 'bottom', type: 'ordinal', show:true },
        { position: 'left', type: 'linear', stacked: false,hardMin:0, hardMax:10 }
      ],

      colorPalette:[
        "#00DEDC",
        "#fc0050",
        "#9c00f0",
        "#00de51",
        '#edd900',
        '#4b29e3'
      ],

      coaches:[]





    }

    this.accountStatus = props.accountStatus;
    this.accountType = props.accountType;

    this.logic = new Logic(this);
    this.mobileSideBar = props.mobileSideBar;
  }

  componentDidMount = async()=>{
    this.logic.loadContent();
    this.logic.loadSurveyData();

    const messaging = firebase.messaging();


    if(!firebase.apps.length){ firebase.initializeApp(ApiKeys.FirebaseConfig); }


        messaging.requestPermission().then(()=>{
          return messaging.getToken();
        }).then((token)=>{
          this.logic.saveToken(token);
          console.log(token);
        }).catch((error)=>{
          console.log('Error:'+error);
        })


  }

  series = () => {
      return( {
        type: 'bar'
      }
    )

    }

  render(){
    return(
      <div style={{flexDirection:'row',display:'flex',flex:1}}>
        {this.renderDashboard()}
        {this.renderCoachView()}
      </div>
    );
  }



  renderDashboard = ()=>{

    if(this.state.loading || this.state.loadingSurveyData){
      return(
        <div className="client-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={this.state.loading}
          />
        </div>
      );
    }else{
      return(
        <div className="client-dashboard-home">
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Dashboard.HomeHeader} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="client-dashboard-content-main">
            {this.renderClientDashboardContent()}
            {this.renderNewSessionPanel()}
            {this.renderNewCheckinPanel()}
          </div>
        </div>
      );
    }
  }

  renderPulseWidget = ()=>{
    if(this.state.accountStatus.pulseSurveyAvailable){
      return(
        <a href='/surveys' className="pulse-survey-widget">
          <div className="pulse-survey-widget-content">
            <img className="pulse-survey-widget-icon" src={diaryWidgetTriangle} />
            <div>{Language.English.Dashboard.PulseSurveyNotification}</div>
          </div>
        </a>
      )
    }

    return(
      <a href='/diary' className="diary-widget">
          <div className="diary-widget-content">
            <img className="pulse-survey-widget-icon" src={diaryWidgetTriangle} />
            <div>{Language.English.Dashboard.MyDiaryWidget}</div>
          </div>
      </a>
    )
  }

  getSeriesStyle = (series) => {
    // Based off my chart bars
    const colorPalette = {
      Engagement: "#00DEDC",
      Physical:"#fc0050",
      Relationships:"#9c00f0",
      Achievement:"#00de51",
      Meaning:'#edd900',
      Happiness:'#4b29e3',
      'Overall Wellbeing':'#f00000'
    };


    return {
      fill: colorPalette[series.label],
      stroke: colorPalette[series.label],
      borderTopLeftRadius:'20px',
      borderTopRightRadius:'20px',
      cornerRadius:20
    };
  }

  renderInterventionIcon = ()=>{
    switch(this.state.excerciseName){
      case('Accomplishment'):
        return achievement;
      break;

      case('Relationships'):
        return relationships;
      break;

      case('Engagement'):
        return engagement;
      break;

      case('Health'):
        return physical;
      break;

      case('Meaning'):
        return meaning;
      break;

      case('PositiveEmotions'):
        return happiness;
      break;
    }
  }

  renderWellbeingScore = ()=>{

    if(this.state.accountStatus.pulseSurveyAvailable){
      return(
        <a href='/surveys' className="client-home-nav-button">
          <div className="pulse-survey-widget-content">
            <img className="pulse-survey-widget-icon" src={diaryWidgetTriangle} />
            <div className="pulse-survey-widget-text" style={{color:'#000000'}}>{Language.English.Dashboard.PulseSurveyNotification}</div>
          </div>
        </a>
      )
    }else{
      return(
        <div className="client-home-hooga-button">

          <div className="recent-overall-score-content">

            <div className="recent-overall-score-header">
              {Language.English.Dashboard.MyWellbeing}<br/>
              {this.state.recentDate || '(No Surveys Taken)'}
            </div>

            <div className="recent-overall-score-text">
              {this.state.recentWellbeingScore || '0'}
            </div>

          </div>

        </div>
      )
    }


  }

  renderHoogaCarousel = ()=>{

    let vh = window.screen.height / 100;

    return(
      <div className="client-home-nav-button">
         <Carousel className="chart-carousel" interval={5000} autoPlay={true} infiniteLoop={true} swipeable={true} stopOnHover={true} showStatus={false} showArrows={true}>

          <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
            <div className="chart-widget-header">Ability Pulse</div>
           <DonutChart
             data={[{
                 label: '',
                 value: Math.round(this.state.pulseAbilityScore*7) / 100  || 0
             },
             {
                 label: '',
                 value: (this.state.pulseAbilityScore)? Math.round(7 - (this.state.pulseAbilityScore*7) / 100):0,
                 isEmpty: true
             }]}
             legend={false}
             colors={['#0075d5']}
             strokeColor={'#0075d5'}
             height={30*vh}
             width={30*vh}
             className="donut-chart"
             onMouseEnter={()=>{}}
             onClick={()=>{}}
             clickToggle={false}
             formatValues={(values,total)=>`${(values)+'/7'}`}
             />
            </div>

            <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
              <div className="chart-widget-header">Harmony Pulse</div>
             <DonutChart
               data={[{
                   label: '',
                   value: Math.round(this.state.pulseHarmonyScore*7) / 100  || 0
               },
               {
                   label: '',
                   value: (this.state.pulseHarmonyScore)? Math.round(7 - (this.state.pulseHarmonyScore*7) / 100):0,
                   isEmpty: true
               }]}
               legend={false}
               colors={['#0075d5']}
               strokeColor={'#0075d5'}
               height={30*vh}
               width={30*vh}
               className="donut-chart"
               onMouseEnter={()=>{}}
               onClick={()=>{}}
               clickToggle={false}
               formatValues={(values,total)=>`${(values)+'/7'}`}
               />
            </div>

            <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
              <div className="chart-widget-header">Happiness Pulse</div>
             <DonutChart
               data={[{
                   label: '',
                   value: Math.round(this.state.pulseHappinessScore*7) / 100  || 0
               },
               {
                   label: '',
                   value: (this.state.pulseHappinessScore)? Math.round(7 - (this.state.pulseHappinessScore*7) / 100):0,
                   isEmpty: true
               }]}
               legend={false}
               colors={['#0075d5']}
               strokeColor={'#0075d5'}
               height={30*vh}
               width={30*vh}
               className="donut-chart"
               onMouseEnter={()=>{}}
               onClick={()=>{}}
               clickToggle={false}
               formatValues={(values,total)=>`${(values)+'/7'}`}
               />
              </div>

              <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
                <div className="chart-widget-header">Strengths Pulse</div>
               <DonutChart
                 data={[{
                     value: Math.round(this.state.pulseStrengthsScore*7) / 100  || 0
                 },
                 {
                     label: '',
                     value: (this.state.pulseStrengthsScore)? Math.round(7 - (this.state.pulseStrengthsScore *7) / 100):0,
                     isEmpty: true
                 }]}
                 legend={false}
                 colors={['#0075d5']}
                 strokeColor={'#0075d5'}
                 height={30*vh}
                 width={30*vh}
                 className="donut-chart"
                 onMouseEnter={()=>{}}
                 onClick={()=>{}}
                 clickToggle={false}
                 formatValues={(values,total)=>`${(values)+'/7'}`}
                 />
                </div>

                <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
                  <div className="chart-widget-header">Vibe Pulse</div>
                 <DonutChart
                   data={[{
                       label: '',
                       value: Math.round((this.state.pulseVibeScore)*7) / 100 || 0
                   },
                   {
                       label: '',
                       value: (this.state.pulseVibeScore)? Math.round(7 - (this.state.pulseVibeScore *7) / 100):0,
                       isEmpty: true
                   }]}
                   legend={false}
                   colors={['#0075d5']}
                   strokeColor={'#0075d5'}
                   height={30*vh}
                   width={30*vh}
                   className="donut-chart"
                   onMouseEnter={()=>{}}
                   onClick={()=>{}}
                   clickToggle={false}
                   formatValues={(values,total)=>`${(values)+'/7'}`}
                   />
                  </div>

                  <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
                    <div className="chart-widget-header">Tribe Pulse</div>
                   <DonutChart
                     data={[{
                         label: '',
                         value: Math.round(this.state.pulseTribeScore*7) / 100 || 0
                     },
                     {
                         label: '',
                         value: (this.state.pulseTribeScore)? Math.round(7 - (this.state.pulseTribeScore *7) / 100):0,
                         isEmpty: true
                     }]}
                     legend={false}
                     colors={['#0075d5']}
                     strokeColor={'#0075d5'}
                     height={30*vh}
                     width={30*vh}
                     className="donut-chart"
                     onMouseEnter={()=>{}}
                     onClick={()=>{}}
                     clickToggle={false}
                     formatValues={(values,total)=>`${(values)+'/7'}`}
                     />
                  </div>
         </Carousel>
      </div>
    )
  }

  renderPulseCarousel = ()=>{

    let vh = window.screen.height / 100;
    let vw = window.screen.width / 100;

    return(
      <div className="client-home-nav-button">
        <Carousel className="chart-carousel" interval={5000} autoPlay={true} infiniteLoop={true} swipeable={true} stopOnHover={true} showStatus={false} showArrows={true}>

        <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
          <div className="chart-widget-header">Physical</div>
          <DonutChart
            data={[{
                label: '',
                value: (parseInt(this.state.recentPhysicalScore) * 10) || 0
            },
            {
                label: '',
                value: (this.state.recentPhysicalScore)? 100 - (parseInt(this.state.recentPhysicalScore)* 10):0,
                isEmpty: true
            }]}
            legend={false}
            colors={['#00ccd7']}
            strokeColor={'#00ccd7'}
            height={30*vh}
            width={30*vh}
            className="donut-chart"
            onMouseEnter={()=>{}}
            onClick={()=>{}}
            clickToggle={false}
            formatValues={(values,total)=>`${(values / 10)}`}
            />
          </div>

          <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
            <div className="chart-widget-header">Purpose</div>
            <DonutChart
              data={[{
                  label: '',
                  value: (parseInt(this.state.recentMeaningScore) * 10) || 0
              },
              {
                  label: '',
                  value: (this.state.recentMeaningScore)? 100 - (parseInt(this.state.recentMeaningScore)* 10):0,
                  isEmpty: true
              }]}
              legend={false}
              colors={['#00ccd7']}
              strokeColor={'#00ccd7'}
              height={30*vh}
              width={30*vh}
              className="donut-chart"
              onMouseEnter={()=>{}}
              onClick={()=>{}}
              clickToggle={false}
              formatValues={(values,total)=>`${(values / 10)}`}
              />
            </div>

            <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
              <div className="chart-widget-header">Happiness</div>
              <DonutChart
                data={[{
                    label: '',
                    value: (parseInt(this.state.recentHappinessScore) * 10) || 0
                },
                {
                    label: '',
                    value: (this.state.recentHappinessScore)? 100 - (parseInt(this.state.recentHappinessScore)* 10):0,
                    isEmpty: true
                }]}
                legend={false}
                colors={['#00ccd7']}
                strokeColor={'#00ccd7'}
                height={30*vh}
                width={30*vh}
                className="donut-chart"
                onMouseEnter={()=>{}}
                onClick={()=>{}}
                clickToggle={false}
                formatValues={(values,total)=>`${(values / 10)}`}
                />
              </div>

              <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
                <div className="chart-widget-header">Relationships</div>
                <DonutChart
                  data={[{
                      label: '',
                      value: (parseInt(this.state.recentRelationshipScore) * 10) || 0
                  },
                  {
                      label: '',
                      value: (this.state.recentRelationshipScore)? 100 - (parseInt(this.state.recentRelationshipScore)* 10):0,
                      isEmpty: true
                  }]}
                  legend={false}
                  colors={['#00ccd7']}
                  strokeColor={'#00ccd7'}
                  height={30*vh}
                  width={30*vh}
                  className="donut-chart"
                  onMouseEnter={()=>{}}
                  onClick={()=>{}}
                  clickToggle={false}
                  formatValues={(values,total)=>`${(values / 10)}`}
                  />
                </div>

                <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
                <div className="chart-widget-header">Goals</div>
                  <DonutChart
                    data={[{
                        value: (parseInt(this.state.recentAchievementScore) * 10) || 0
                    },
                    {
                        label: '',
                        value: (this.state.recentAchievementScore)? 100 - (parseInt(this.state.recentAchievementScore)* 10):0,
                        isEmpty: true
                    }]}
                    legend={false}
                    colors={['#00ccd7']}
                    strokeColor={'#00ccd7'}
                    height={30*vh}
                    width={30*vh}
                    className="donut-chart"
                    onMouseEnter={()=>{}}
                    onClick={()=>{}}
                    clickToggle={false}
                    formatValues={(values,total)=>`${(values / 10)}`}
                    />
                  </div>

                  <div style={{backgroundColor:'white', paddingBottom:'6vh'}}>
                    <div className="chart-widget-header">Flow</div>
                    <DonutChart
                      data={[{
                          label: '',
                          value: (parseInt(this.state.recentEngagementScore) * 10) || 0
                      },
                      {
                          label: '',
                          value: (this.state.recentEngagementScore)? 100 - (parseInt(this.state.recentEngagementScore)* 10):0,
                          isEmpty: true
                      }]}
                      legend={false}
                      colors={['#00ccd7']}
                      strokeColor={'#00ccd7'}
                      height={30*vh}
                      width={30*vh}
                      className="donut-chart"
                      onMouseEnter={()=>{}}
                      onClick={()=>{}}
                      clickToggle={false}
                      formatValues={(values,total)=>`${(values / 10)}`}
                      />
                  </div>
        </Carousel>
      </div>
    )
  }

  renderClientDashboardContent = ()=>{

    if(this.state.accountStatus.coachConfirmed || this.state.accountStatus.coach || ((!this.state.accountStatus.isCoachingUser) && (this.state.accountStatus.accountType == 'company' || this.state.accountStatus.authority == 'employee') )){

      let vh = window.screen.height / 100;
      let vw = window.screen.width / 100;

      let hoogaChartWidth = ((100 * vw) > 768)? (55*vw):480;

      const getSecondary = (datum)=>{
        return datum.value;
      };

      return(

        <div className="charts-container">

          <div className="nav-buttons-row">

            <div className="client-home-nav-buttons">

                {this.renderWellbeingScore()}
                {this.renderHoogaCarousel()}
                {this.renderPulseCarousel()}

            </div>

          </div>

          <div className="client-home-hooga-row">

            <div className="client-home-hooga-intervention">

              <div style={{backgroundColor:this.state.colorPalette[this.state.dayIndex]}} className="intervention-excercise">

                <div className="intervention-content">

                  <img className="intervention-icon" src={this.renderInterventionIcon()} />

                  <div className="recent-overall-score-header">
                    {this.state.excercise.header}
                  </div>

                </div>

              </div>

              <div className="intervention-activity">
                <div className="intervention-activity-header">
                  {this.state.excercise.activities[0].title}
                </div>
                {this.state.excercise.activities[0].content}
              </div>

              <div className="intervention-activity">
                <div className="intervention-activity-header">
                  {this.state.excercise.activities[1].title}
                </div>
                {this.state.excercise.activities[1].content}
              </div>
            </div>

            <div className="client-home-hooga-chart">

            <div className="chart-header">{Language.English.Dashboard.HoogaScores}</div>
              <div
                  style={{
                    width: '85%',
                    height: '80%',
                    position:'relative',
                    left:'6%',
                  }}
                >
                <VictoryChart
                  domainPadding={{ x: 10 }}
                  width={hoogaChartWidth}
                  height={50*vh}
                  labels={() => "click me!"}
                  >
                  <VictoryBar
                    barRatio={0.2}
                    style={{
                      data: { fill: "#0078EB", fontSize: 20  },
                      labels: { fill: "white", fontSize: 20 },
                      tickLabels: {fontSize: 15, padding: 5}
                    }}
                    data={this.state.mainData}
                    x={'label'}
                    y={'data'}
                    domain={{y:[0, 10]}}
                    animate={{
                      duration: 2000,
                      onLoad: { duration: 1000 }
                    }}
                    labels={() => "click me!"}
                  />
                  </VictoryChart>
                </div>

            </div>

          </div>



          <div className="client-booking-buttons-row">
                {this.renderCoachingBookingButton()}
                {this.renderCheckinBookingButton()}
          </div>


        </div>
      )

    }else if(!this.logic.coachChosen()){
      return(
        <div className="coaches-container">
          <div className="coach-options-header">{Language.English.Dashboard.ChooseCoach}</div><br/>
          {
            this.state.coaches.map((coach, index)=>{
              return(
                <div className="coach-container">
                  <img className="coach-container-profile-image" src={coach.profileURL || defaultProfileImage} />
                  <div className="coach-container-profile-name">{coach.name}</div>
                  <div onClick={()=>{this.logic.openChooseCoachPanel(coach.name, coach.id, coach.description, coach.profileURL)}} className="choose-coach-button">More</div>
                </div>
              )
            })
          }
        </div>
      )
    }else if(this.logic.sessionPlannedInPast()){
      return(
        <div className="booking-confirmation-container">

          <img src={tick} className="booking-confirmation-tick" />
          <div className="booking-confirmation-text">
            {Language.English.Dashboard.SessionComplete}
          </div>

          <div className="post-session-option" id="keep-coach-option" onClick={this.logic.keepCoach}>
            <img src={coachPlanIcon} className="post-session-option-icon" />
            <div className="post-session-option-text">
              {Language.English.Dashboard.KeepCoachOption}
            </div>
          </div>

          <div className="post-session-option" id="new-coach-option" onClick={this.logic.chooseNewCoach}>
            <img src={switchCoachIcon} className="post-session-option-icon" />
            <div className="post-session-option-text">
              {Language.English.Dashboard.NewCoachOption}
            </div>
          </div>

        </div>
      )
    }
  }

  //Render Buttons based on user status

  renderCoachingBookingButton = ()=>{
    if((this.state.accountStatus.isCoachingUser || this.state.accountStatus.accountType == 'individual')){
      return(
        <div className="main-client-home-booking-button" onClick={this.logic.openNewSessionPanel}>
          <div className="client-home-nav-content">
            <img className="client-home-icon" src={checkinIcon} />
            <div className="client-home-nav-text">{Language.English.Dashboard.BookCoachingSession}</div>
          </div>
        </div>
      )
    }

    return(
      <a href="/surveys" className="main-client-home-booking-button">
          <div className="client-home-nav-content">
            <img className="client-home-icon" src={checkinIcon} />
            <div className="client-home-nav-text">{Language.English.Dashboard.MySurveys}</div>
          </div>
      </a>
    )

  }

  renderCheckinBookingButton = ()=>{
    if((this.state.accountStatus.isCheckinUser)){
      return(
        <div className="main-client-home-booking-button" onClick={this.logic.openNewCheckinPanel}>
          <div className="client-home-nav-content">
            <img className="client-home-icon" src={coachingIcon} />
            <div className="client-home-nav-text">{Language.English.Dashboard.BookCheckinSession}</div>
          </div>
        </div>
      )
    }

    return(
      <a href="/calendar" className="main-client-home-booking-button">
          <div className="client-home-nav-content">
            <img className="client-home-icon" src={coachingIcon} />
            <div className="client-home-nav-text">{Language.English.Dashboard.MyCalendar}</div>
          </div>
      </a>
    )
  }



  //Render or recommend subscription if not subscribed

  renderSubscriptionRecommendation = ()=>{

    let hasSubscription = this.state.accountStatus.subscriptionPaid;
    let hasPlan = this.state.accountStatus.planPaid;

    if(!hasSubscription || !hasPlan){

      return(
        <div className="subscription-recommendation">
          {Language.English.Dashboard.SubscriptionRecommendation}
        </div>
      )

    }


  }

  renderNewCheckinPanel = ()=>{
    if(!this.state.newCheckinPanelOpen) return;

    return(
      <div className="view-coach-panel">
        <img src={xButton} className="view-coach-close-button" onClick={this.logic.closeNewCheckinPanel} />
        <div className="view-coach-panel-content">
          <div className="choose-coach-date-header">
          Please choose your preferred date & time:
          (Note: You must book more than 5 days in advance of your session. Most coaches are available Monday to Friday)
          </div>

          <DatePicker onChange={(date)=>{this.logic.changeChosenCheckinDate(date)}} selected={this.state.chosenCheckinDate} className="client-input-date" dateFormat="dd/MM/yyyy" calendarClassName="rasta-stripes" />

          <div className="client-input-time-container">
            <div className="choose-coach-date-header">
            Please choose a preferred time.
            Note: Times are in 24 hour format and you should choose a time based off your current local timezone.
            </div><br/>
            <select  value={this.state.chosenCheckinHours} onChange={(event)=>{this.setState({chosenCheckinHours:event.target.value})}} className="client-input-time-hours">
              {
                this.state.hours.map((option)=> <option key={option} value={option}>{option}</option>)
              }
            </select>
             :
             <select  value={this.state.chosenCheckinMinutes} onChange={(event)=>{this.setState({chosenCheckinMinutes:event.target.value})}} className="client-input-time-minutes">
               {
                 this.state.minutes.map((option)=> <option key={option} value={option}>{option}</option>)
               }
             </select>

             <br/>

             <div className="choose-time-submit-button" onClick={this.logic.createCheckinBookingRequest}>Submit</div>
          </div>

        </div>
      </div>
    )
  }


  renderNewSessionPanel = ()=>{
    if(!this.state.newSessionPanelOpen) return;

    return(
      <div className="view-coach-panel">
        <img src={xButton} className="view-coach-close-button" onClick={this.logic.closeNewSessionPanel} />
        <div className="view-coach-panel-content">
          <div className="choose-coach-date-header">
          Please choose your preferred date & time:
          (Note: You must book more than 5 days in advance of your session. Most coaches are available Monday to Friday)
          </div>

          <DatePicker onChange={(date)=>{this.logic.changeChosenSessionDate(date)}} selected={this.state.chosenSessionDate} className="client-input-date" dateFormat="dd/MM/yyyy" calendarClassName="rasta-stripes" />

          <div className="client-input-time-container">
            <div className="choose-coach-date-header">
            Please choose a preferred time.
            Note: Times are in 24 hour format and you should choose a time based off your current local timezone.
            </div><br/>
            <select  value={this.state.chosenCheckinHours} onChange={(event)=>{this.setState({chosenCheckinHours:event.target.value})}} className="client-input-time-hours">
              {
                this.state.hours.map((option)=> <option key={option} value={option}>{option}</option>)
              }
            </select>
             :
             <select  value={this.state.chosenCheckinMinutes} onChange={(event)=>{this.setState({chosenCheckinMinutes:event.target.value})}} className="client-input-time-minutes">
               {
                 this.state.minutes.map((option)=> <option key={option} value={option}>{option}</option>)
               }
             </select>

             <br/>

             <div className="choose-time-submit-button" onClick={this.logic.createSessionBookingRequest}>Submit</div>
          </div>

        </div>
      </div>
    )
  }

  renderCoachView = ()=>{
    if(!this.state.chooseCoachPanelOpen) return;

    if(!this.state.chooseSessionDateOpen){
      return(
        <div className="view-coach-panel">
          <img src={xButton} className="view-coach-close-button" onClick={this.logic.closeChooseCoachPanel} />
          <div className="view-coach-panel-content">
            <img src={this.state.chosenCoachProfileURL || defaultProfileImage} className="view-coach-panel-image" />
            <div className="view-coach-panel-name">{this.state.chosenCoachName}</div>
            <div className="view-coach-panel-description">{this.state.chosenCoachDescription}</div>
            <div className="view-coach-panel-choose-button" onClick={this.logic.openChooseSessionDate}>{Language.English.Dashboard.ChooseCoachButton}</div>
          </div>
        </div>
      )
    }else{
      return(
        <div className="view-coach-panel">
          <img src={xButton} className="view-coach-close-button" onClick={this.logic.closeChooseCoachPanel} />
          <div className="view-coach-panel-content">
            <div className="choose-coach-date-header">
            Please choose your preferred date & time:
            (Note: You must book more than 5 days in advance of your session. Most coaches are available Monday to Friday)
            </div>

            <DatePicker onChange={(date)=>{this.logic.changeChosenDate(date)}} selected={this.state.chosenDate} className="client-input-date" dateFormat="dd/MM/yyyy" calendarClassName="rasta-stripes" />

            <div className="client-input-time-container">
              <div className="choose-coach-date-header">
              Please choose a preferred time.
              Note: Times are in 24 hour format and you should choose a time based off your current local timezone.
              </div><br/>
              <select  value={this.state.chosenHours} onChange={(event)=>{this.setState({chosenHours:event.target.value})}} className="client-input-time-hours">
                {
                  this.state.hours.map((option)=> <option key={option} value={option}>{option}</option>)
                }
              </select>
               :
               <select  value={this.state.chosenMinutes} onChange={(event)=>{this.setState({chosenMinutes:event.target.value})}} className="client-input-time-minutes">
                 {
                   this.state.minutes.map((option)=> <option key={option} value={option}>{option}</option>)
                 }
               </select>

               <br/>

               <div className="choose-time-submit-button" onClick={this.logic.createBookingRequest}>Submit</div>
            </div>

          </div>
        </div>
      )
    }

  }



}

export default withAlert()(ClientDashboard);
