import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import { withAlert } from 'react-alert'

//Services & Local Logic
import Language from '../../services/Language';
import Logic from './logic';

// Assets
import inkelLogo from '../../assets/logo.svg';
import inkelLogoDark from '../../assets/logo-dark.svg';
import loginIcon from '../../assets/lock-icon.png';
import yellowCurve from '../../assets/yellow-curve.png';
import xButton from '../../assets/x-button.png';

// Animations
import Fade from 'react-reveal/Fade';

class Login extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {attemptingLogin:false,authenticating:true,loading:false,emailValue:'',passwordValue:'', resetPasswordEmail:''};

    if(!firebase.apps.length){ firebase.initializeApp(ApiKeys.FirebaseConfig); }

    
    this.logic = new Logic(this);

    this.logic.validateAuth();

  }

  render(){
    return(
      <div>
        {this.renderPage()}
      </div>
    );
  }


  renderPage = ()=>{
    if(this.state.attemptingLogin || this.state.authenticating){
      return(
        <div className="page-loading">
          <ClipLoader
            size={160}
            color={"#0056e0"}
            loading={true}
          />
        </div>
      );
    }else{
      return(
      <div>

          <div className="login-welcome-image-container">
            <img src={inkelLogo} className="login-inkel-logo" />

            <div className="login-welcome-content">
              <div className="login-welcome-header">
                {Language.English.Login.LoginWelcomeHeader}
              </div>

              <div className="login-welcome-text">
                {Language.English.Login.LoginWelcomeText}
              </div>
            </div>

          </div>

          <img src={inkelLogoDark} className="login-mobile-inkel-logo" />

        <Fade delay={400}>

          <div className="login-panel-container">

              <div className="login-panel">

                <div className="login-header">{Language.English.Login.LoginHeader}</div>

                <div className="login-content">
                  {this.renderLoginPanel()}
                </div>

              </div>

          </div>

        </Fade>

        <img src={yellowCurve} className="yellow-curve-image" />

        {this.renderResetPassword()}

      </div>

      );
    }
  }

  renderResetPassword = ()=>{
    if(!this.state.resetPasswordOpen) return;

    return(
      <div className="new-course-panel">
        <img src={xButton} className="new-course-close-button" onClick={this.logic.closeResetPassword}/>
        <div className="new-course-form">
          <input className="new-course-name-input" type="text" placeholder="Email" value={this.state.resetPasswordEmail} onChange={(event)=>{this.setState({resetPasswordEmail:event.target.value})}}/>
          <div className="new-course-submit-button" onClick={this.logic.attempPasswordReset}>{Language.English.Login.ResetPasswordButton}</div>
        </div>
      </div>
    )
  }

  renderLoginPanel = ()=>{

    if(this.state.loading){
      return(
        <div className="loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={this.state.loading}
          />
        </div>
      );
    }else{
      return(
        <div>
          <div className="error-message">{this.state.error}</div>

          <input type="email" id="email-input" className="email-input" placeholder={Language.English.Login.emailPlaceholder} value={this.state.emailValue} onChange={this.logic.handleEmailChange}></input>
          <input type="password" id="password-input" className="email-input" placeholder={Language.English.Login.passwordPlaceholder} value={this.state.passwordValue} onChange={this.logic.handlePasswordChange}></input>

          <br/>

          <span className="login-button" onClick={this.logic.attemptLogin}>
            <img className="login-button-icon" src={loginIcon} />
            <div className="login-button-text">{Language.English.Login.LoginButtonText}</div>
          </span>



          <a href="/register">
            <div className="signup-link">{Language.English.Login.SignupLink}</div>
          </a>

          <div className="forgot-password-link" onClick={this.logic.openResetPassword}>{Language.English.Login.ResetPassword}</div>

        </div>
      );
    }
  }


}

export default withAlert()(Login);
