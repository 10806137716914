import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { withAlert } from 'react-alert';
import DonutChart from 'react-donut-chart';


import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import binIcon from '../../../assets/icons/bin.png';
import plusTriangle from '../../../assets/icons/plus-triangle.png';
import sendIcon from '../../../assets/icons/send.png';
import greyEyeIcon from '../../../assets/icons/grey-eye.png';

class CoachDashboard extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }


  constructor(props){
    super(props);

    this.state = {
      accountStatus:props.accountStatus,
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      loadingMyClients:true,
      newCoursePanelOpen:false,
      newCourseName:"",
      coursesList:[],
      chosenCourseModulesList:[],
      editPanelLoading:true,
      newLessonFiles:[],
      chosenModuleLessons:[],
      myClients:[],
      chosenCourseUsers:[],
      chosenCourseUserIds:[],
      newLessonName:"",
      newLessonDescription:"",
      usersArray:[],
      organisations:[],
      userSearch:""
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);
    this.logic.loadCourses();
    this.logic.loadUsers();
    this.logic.loadOrganisations();

    this.filesInput = React.createRef();
    this.EditLessonFilesInput = React.createRef();
  }

  componentDidMount = async()=>{

        if(!firebase.apps.length){ firebase.initializeApp(ApiKeys.FirebaseConfig); }
        const messaging = firebase.messaging();

        messaging.requestPermission().then(()=>{
          return messaging.getToken();
        }).then((token)=>{
          this.logic.saveToken(token);
        }).catch((error)=>{
          console.log('Error:'+error);
        })

  }

  renderPulseSurveyButton = ()=>{
    if(this.state.chosenClient.pulseSurveyAvailable){
      return(
        <div className="survey-sent-text">
          {Language.English.Dashboard.pulseSurveySent}
        </div>
      )
    }

    return(
      <div className="edit-course-button" onClick={()=>{this.logic.sendPulseSurvey(this.state.chosenClient, this.state.chosenClientIndex)}}>
        {Language.English.Dashboard.SendPulseSurvey}
        <img src={sendIcon} className="send-survey-icon" />
      </div>
    )

  }

  renderCoreSurveyButton = ()=>{
    if(this.state.chosenClient.coreSurveyAvailable){
      return(
        <div className="survey-sent-text">
          {Language.English.Dashboard.coreSurveySent}
        </div>
      )
    }

    return(
      <div className="edit-course-button" onClick={()=>{this.logic.sendCoreSurvey(this.state.chosenClient, this.state.chosenClientIndex)}}>
        {Language.English.Dashboard.SendCoreSurvey}
        <img src={sendIcon} className="send-survey-icon" />
      </div>
    )

  }

  renderHopeSurveyButton = ()=>{
    if(this.state.chosenClient.hopeSurveyAvailable){
      return(
        <div className="survey-sent-text">
          {Language.English.Dashboard.hopeSurveySent}
        </div>
      )
    }

    return(
      <div className="edit-course-button" onClick={()=>{this.logic.sendHopeSurvey(this.state.chosenClient, this.state.chosenClientIndex)}}>
        {Language.English.Dashboard.SendHopeSurvey}
        <img src={sendIcon} className="send-survey-icon" />
      </div>
    )

  }

  renderClientsLoadingContainer = ()=>{
    return(
      <div className="coach-dashboard-client-loader-holder">
        <ClipLoader
          size={100}
          color={"#005c9e"}
          loading={true}
          className="coach-dashboard-loader-icon"
        />
      </div>
    );
  }


  render(){
    if(this.state.loading || this.state.loadingMyUsers){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{
      return(
        <div className="coach-dashboard-home">
          {this.renderNewCoursePanel()}
          {this.renderEditEventPanel()}
          {this.renderSurveyResultsPanel()}
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Dashboard.HomeHeader} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="coach-dashboard-content">

            <div className="coach-dashboard-home-courses-panel">
              <div className="coach-courses-panel-header">
                <div className="coach-courses-panel-text-container">
                  <div className="courses-panel-text">
                    {Language.English.Dashboard.MyCoursesHeader}
                  </div>
                </div>
                <div className="courses-panel-button-container">
                  <div className="courses-panel-new-button" onClick={this.logic.openNewCoursePanel}>
                    <img src={plusIcon} className="courses-button-icon" />
                    {Language.English.Dashboard.NewButton}
                  </div>
                </div>
              </div>
              <div className="courses-panel-content">
                {
                  this.state.coursesList.map((course, index)=>{
                    return(
                      <div className="course-display">
                        <div className="course-display-left">
                          <div className="course-display-name">{course.name}</div>
                        </div>

                        <div className="course-display-right">
                          <div className="edit-course-button" onClick={()=>{this.logic.editEvent(course)}}>
                            <img src={editIcon} className="edit-course-icon" />
                            {Language.English.Dashboard.Edit}
                          </div>

                          <div className="course-delete-button" onClick={()=>{this.logic.deleteCourse(course,index)}}>
                            <img src={binIcon} className="course-bin-icon" />
                          </div>

                        </div>
                      </div>
                    )
                  })
                }
              </div>

            </div>

            <div className="coach-dashboard-home-courses-panel">

            <div className="coach-courses-panel-header">
              <div className="coach-courses-panel-text-container">
                <div className="courses-panel-text">
                  {Language.English.Dashboard.MyUsersHeader}
                </div>
              </div>
            </div>

            <input type="text" placeholder="Search clients..." className="search-input" value={this.state.userSearch} onChange={(event)=>{this.setState({userSearch:event.target.value})}} />

            <div className="courses-panel-content">
              {
                (this.state.loadingMyClients)? this.renderClientsLoadingContainer(): this.state.myClients.map((client, index)=>{

                  if(this.state.userSearch.length > 0 && client.name.toUpperCase().search(this.state.userSearch.toUpperCase()) == -1) return;
                  
                  return(
                    <div className="course-display">
                      <div className="clients-display-left">
                        <div className="client-display-name">{client.name}</div>
                      </div>

                      <div className="clients-display-right">
                        <div className="view-client-surveys-button" onClick={()=>{this.logic.openSurveyResults(client,index)}}>
                          <img className="view-client-icon" src={greyEyeIcon} />
                          {Language.English.Dashboard.OpenResultsButton}
                        </div>

                      </div>
                    </div>
                  )
                })
              }
            </div>

            </div>

          </div>
        </div>
      );
    }
  }

  renderSurveyResultsPanel = ()=>{


    if(this.state.surveyResultsOpen){

      let vh = window.screen.height / 100;

      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeSurveyResults}/>
          <div className="client-data-form">
            <div className="client-results-header">{this.state.surveyResultsClientName}</div>

            {this.renderPulseSurveyButton()}
            {this.renderCoreSurveyButton()}
            {this.renderHopeSurveyButton()}

            <div className="client-results-scores">


            <DonutChart
              data={[{
                  label: 'Ability Pulse',
                  value: (parseInt(this.state.pulseAbilityScore)) || 0
              },
              {
                  label: '',
                  value: (this.state.pulseAbilityScore)? 100 - (parseInt(this.state.pulseAbilityScore)):0,
                  isEmpty: true
              }]}
              legend={false}
              colors={['#0075d5']}
              strokeColor={'#0075d5'}
              height={30*vh}
              width={30*vh}
              className="client-pulse-donut-chart"
              onMouseEnter={()=>{}}
              onClick={()=>{}}
              clickToggle={false}
              />

              <DonutChart
                data={[{
                    label: 'Harmony Pulse',
                    value: (parseInt(this.state.pulseHarmonyScore)) || 0
                },
                {
                    label: '',
                    value: (this.state.pulseHarmonyScore)? 100 - (parseInt(this.state.pulseHarmonyScore)):0,
                    isEmpty: true
                }]}
                legend={false}
                colors={['#0075d5']}
                strokeColor={'#0075d5'}
                height={30*vh}
                width={30*vh}
                className="client-pulse-donut-chart"
                onMouseEnter={()=>{}}
                onClick={()=>{}}
                clickToggle={false}
                />

                <DonutChart
                  data={[{
                      label: 'Happiness Pulse',
                      value: (parseInt(this.state.pulseHappinessScore)) || 0
                  },
                  {
                      label: '',
                      value: (this.state.pulseHappinessScore)? 100 - (parseInt(this.state.pulseHappinessScore)):0,
                      isEmpty: true
                  }]}
                  legend={false}
                  colors={['#0075d5']}
                  strokeColor={'#0075d5'}
                  height={30*vh}
                  width={30*vh}
                  className="client-pulse-donut-chart"
                  onMouseEnter={()=>{}}
                  onClick={()=>{}}
                  clickToggle={false}
                  />

                  <DonutChart
                    data={[{
                        label: 'Strengths Pulse',
                        value: (parseInt(this.state.pulseStrengthsScore)) || 0
                    },
                    {
                        label: '',
                        value: (this.state.pulseStrengthsScore)? 100 - (parseInt(this.state.pulseStrengthsScore)):0,
                        isEmpty: true
                    }]}
                    legend={false}
                    colors={['#0075d5']}
                    strokeColor={'#0075d5'}
                    height={30*vh}
                    width={30*vh}
                    className="client-pulse-donut-chart"
                    onMouseEnter={()=>{}}
                    onClick={()=>{}}
                    clickToggle={false}
                    />

                    <DonutChart
                      data={[{
                          label: 'Vibe Pulse',
                          value: (parseInt(this.state.pulseVibeScore)) || 0
                      },
                      {
                          label: '',
                          value: (this.state.pulseVibeScore)? 100 - (parseInt(this.state.pulseVibeScore)):0,
                          isEmpty: true
                      }]}
                      legend={false}
                      colors={['#0075d5']}
                      strokeColor={'#0075d5'}
                      height={30*vh}
                      width={30*vh}
                      className="client-pulse-donut-chart"
                      onMouseEnter={()=>{}}
                      onClick={()=>{}}
                      clickToggle={false}
                      />

                      <DonutChart
                        data={[{
                            label: 'Tribe Pulse',
                            value: (parseInt(this.state.pulseTribeScore)) || 0
                        },
                        {
                            label: '',
                            value: (this.state.pulseTribeScore)? 100 - (parseInt(this.state.pulseTribeScore)):0,
                            isEmpty: true
                        }]}
                        legend={false}
                        colors={['#0075d5']}
                        strokeColor={'#0075d5'}
                        height={30*vh}
                        width={30*vh}
                        className="client-pulse-donut-chart"
                        onMouseEnter={()=>{}}
                        onClick={()=>{}}
                        clickToggle={false}
                        />

            </div>

            <div className="diary-count-container">
              Total Diary Entries: {this.state.chosenClient.diaryCount}
            </div>

            <div className="diary-count-container">
              Total Goal Tracking Entries: {this.state.chosenClient.goalTrackerCount} Most Recent Score: {this.state.chosenClient.recentGoalTrackingScore}
            </div>

            <div className="hope-scores-container">
              Last Hope Survey: {this.logic.lastHopeSurveyDate(0)}<br/>
              Pathways Subscale Score: {this.logic.calculatePathwaysSubscaleScore(0)}/32 <br/>
              Agency Subscale Score: {this.logic.calculateAgencySubscaleScore(0)}/32 <br/>
              Total Hope Score: {this.logic.calculateTotalHopeScore(0)}/64
            </div>

            <div className="hope-scores-container">
              Previous Hope Survey: {this.logic.lastHopeSurveyDate(1)}<br/>
              Previous Pathways Subscale Score: {this.logic.calculatePathwaysSubscaleScore(1)}/32 <br/>
              Previous Agency Subscale Score: {this.logic.calculateAgencySubscaleScore(1)}/32 <br/>
              Previous Total Hope Score: {this.logic.calculateTotalHopeScore(1)}/64
            </div>
            <DonutChart
              data={[{
                  label: 'Physical',
                  value: (parseInt(this.state.recentPhysicalScore) * 10) || 0
              },
              {
                  label: '',
                  value: (this.state.recentPhysicalScore)? 100 - (parseInt(this.state.recentPhysicalScore)* 10):0,
                  isEmpty: true
              }]}
              legend={false}
              colors={['#00ccd7']}
              strokeColor={'#00ccd7'}
              height={30*vh}
              width={30*vh}
              className="client-pulse-donut-chart"
              onMouseEnter={()=>{}}
              onClick={()=>{}}
              clickToggle={false}
              formatValues={(values,total)=>`${(values / 10)}`}
              />

              <DonutChart
                data={[{
                    label: 'Purpose',
                    value: (parseInt(this.state.recentMeaningScore) * 10) || 0
                },
                {
                    label: '',
                    value: (this.state.recentMeaningScore)? 100 - (parseInt(this.state.recentMeaningScore)* 10):0,
                    isEmpty: true
                }]}
                legend={false}
                colors={['#00ccd7']}
                strokeColor={'#00ccd7'}
                height={30*vh}
                width={30*vh}
                className="client-pulse-donut-chart"
                onMouseEnter={()=>{}}
                onClick={()=>{}}
                clickToggle={false}
                formatValues={(values,total)=>`${(values / 10)}`}
                />

                <DonutChart
                  data={[{
                      label: 'Happiness',
                      value: (parseInt(this.state.recentHappinessScore) * 10) || 0
                  },
                  {
                      label: '',
                      value: (this.state.recentHappinessScore)? 100 - (parseInt(this.state.recentHappinessScore)* 10):0,
                      isEmpty: true
                  }]}
                  legend={false}
                  colors={['#00ccd7']}
                  strokeColor={'#00ccd7'}
                  height={30*vh}
                  width={30*vh}
                  className="client-pulse-donut-chart"
                  onMouseEnter={()=>{}}
                  onClick={()=>{}}
                  clickToggle={false}
                  formatValues={(values,total)=>`${(values / 10)}`}
                  />

                  <DonutChart
                    data={[{
                        label: 'Relationships',
                        value: (parseInt(this.state.recentRelationshipScore) * 10) || 0
                    },
                    {
                        label: '',
                        value: (this.state.recentRelationshipScore)? 100 - (parseInt(this.state.recentRelationshipScore)* 10):0,
                        isEmpty: true
                    }]}
                    legend={false}
                    colors={['#00ccd7']}
                    strokeColor={'#00ccd7'}
                    height={30*vh}
                    width={30*vh}
                    className="client-pulse-donut-chart"
                    onMouseEnter={()=>{}}
                    onClick={()=>{}}
                    clickToggle={false}
                    formatValues={(values,total)=>`${(values / 10)}`}
                    />

                    <DonutChart
                      data={[{
                          label: 'Goals',
                          value: (parseInt(this.state.recentAchievementScore) * 10) || 0
                      },
                      {
                          label: '',
                          value: (this.state.recentAchievementScore)? 100 - (parseInt(this.state.recentAchievementScore)* 10):0,
                          isEmpty: true
                      }]}
                      legend={false}
                      colors={['#00ccd7']}
                      strokeColor={'#00ccd7'}
                      height={30*vh}
                      width={30*vh}
                      className="client-pulse-donut-chart"
                      onMouseEnter={()=>{}}
                      onClick={()=>{}}
                      clickToggle={false}
                      formatValues={(values,total)=>`${(values / 10)}`}
                      />

                      <DonutChart
                        data={[{
                            label: 'Flow',
                            value: (parseInt(this.state.recentEngagementScore) * 10) || 0
                        },
                        {
                            label: '',
                            value: (this.state.recentEngagementScore)? 100 - (parseInt(this.state.recentEngagementScore)* 10):0,
                            isEmpty: true
                        }]}
                        legend={false}
                        colors={['#00ccd7']}
                        strokeColor={'#00ccd7'}
                        height={30*vh}
                        width={30*vh}
                        className="client-pulse-donut-chart"
                        onMouseEnter={()=>{}}
                        onClick={()=>{}}
                        clickToggle={false}
                        formatValues={(values,total)=>`${(values / 10)}`}
                        />
          </div>
        </div>
      )
    }
  }

  renderNewCoursePanel = ()=>{
    if(this.state.newCoursePanelOpen){

      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeNewCoursePanel}/>
          <div className="new-course-form">
            <input className="new-course-name-input" type="text" placeholder="New Course Name" value={this.state.newCourseName} onChange={(event)=>{this.setState({newCourseName:event.target.value})}}/>
            <br/>
            <div className="new-course-main-submit-button" onClick={this.logic.createCourse}>
              <img className="create-course-icon" src={plusTriangle} />
              {Language.English.Dashboard.AddButton}
            </div>
          </div>
        </div>
      )

    }
  }

  renderEditEventPanel = ()=>{
    if(this.state.editEventPanelOpen){

      return(
        <div className="new-coach-course-panel">
          <img src={xButton} className="new-coach-course-close-button" onClick={this.logic.closeEditEventPanel}/>
          <div className="new-coach-course-form">
            <div className="new-coach-course-header">
              {this.state.chosenCourseName}
                <Toggle
                  id='live-status'
                  defaultChecked={this.state.chosenCourseIsLive}
                  onChange={this.logic.liveToggleChange}
                  className="live-toggle" />
                <label className="live-toggle-label" htmlFor='live-status'>{Language.English.Dashboard.Live}</label>


            </div>
            <div className="edit-course-content">
              {this.renderEditCourseContent()}
            </div>
          </div>
        </div>
      )

    }
  }

  renderEditCourseContent = ()=>{
    if(this.state.editPanelLoading){ //Loading Resources
      return(
        <div className="edit-panel-loader">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
          />
        </div>
      )
    }else if(this.state.addModulePanelOpen){ //Adding New Module
      return(
        <div className="new-module-form">
        <input className="new-course-name-input" type="text" placeholder="New Module Name" value={this.state.newModuleName} onChange={(event)=>{this.setState({newModuleName:event.target.value})}}/>
        <br/>
        <div className="new-module-cancel-button" onClick={this.logic.closeAddNewModulePanel}>{Language.English.Dashboard.CancelButton}</div>
        <div className="new-module-submit-button" onClick={this.logic.addEventModule}>{Language.English.Dashboard.AddButton}</div>
        </div>
      )
    }else if(this.state.editLessonPanelOpen){ //Editting an existing lesson

      return(
        <div>
          <div className="coach-course-modules-header">
            <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeEditLessonPanel} />
            {Language.English.Dashboard.EditLessonHeader}
          </div>

          <div className="new-lesson-form">
          <input className="new-lesson-name-input" type="text" placeholder="Lesson Name" value={this.state.EditLessonName} onChange={(event)=>{this.setState({EditLessonName:event.target.value})}}/>
          <br/>
          <textarea className="new-lesson-description-main-input" placeholder="Lesson Description" value={this.state.EditLessonDescription} onChange={(event)=>{this.setState({EditLessonDescription:event.target.value})}} />
          <br/>
          <div className="files-list">
          {Language.English.Dashboard.ExistingFilesHeader}:<br/>
            {
              this.state.EditLessonExistingFiles.map((file,index)=>{
                return(
                  <div div className="file-name-display">
                    {index+1}. {file.name}
                    <img src={xButton} className="cancel-lesson-file" onClick={()=>{this.logic.removeExistingFile(index)}}/>
                  </div>
                )
              })
            }
          </div>
          <br/>
          <div className="files-list">
          {Language.English.Dashboard.NewFilesHeader}:<br/>
            {
              this.state.EditLessonNewFiles.map((file,index)=>{
                return(
                  <div div className="file-name-display">
                    {index+1}. {file.name}
                    <img src={xButton} className="cancel-lesson-file" onClick={()=>{this.logic.removeEditLessonFile(index)}}/>
                  </div>
                )
              })
            }
          </div>
          <br/>
          <input type="file" onChange={this.logic.editLessonAddNewFile} ref={this.EditLessonFilesInput} className="file-input" /><br/>
          <div className="new-module-submit-button" onClick={this.logic.saveLessonUpdate}>{Language.English.Dashboard.SaveButton}</div>
          </div>


        </div>
      )

    }else if(this.state.newLessonPanelOpen){ //Adding a Lesson
      return(
        <div>
          <div className="coach-course-modules-header">
            <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeAddLessonPanel} />
            {Language.English.Dashboard.NewLessonHeader}
          </div>

          <div className="new-lesson-form">
          <input className="new-lesson-name-input" type="text" placeholder="New Lesson Name" value={this.state.newLessonName} onChange={(event)=>{this.setState({newLessonName:event.target.value})}}/>
          <br/>
          <textarea className="new-lesson-description-main-input" placeholder="New Lesson Description" value={this.state.newLessonDescription} onChange={(event)=>{this.setState({newLessonDescription:event.target.value})}} />
          <br/>
          <div className="files-list">
          Files:<br/>
            {
              this.state.newLessonFiles.map((file,index)=>{
                return(
                  <div div className="file-name-display">
                    {index+1}. {file.name}
                    <img src={xButton} className="cancel-lesson-file" onClick={()=>{this.logic.removeNewFile(index)}}/>
                  </div>
                )
              })
            }
          </div>
          <br/>
          <input type="file" onChange={this.logic.addNewFile} ref={this.filesInput} className="file-input" /><br/>
          <div className="new-module-submit-button" onClick={this.logic.createLesson}>{Language.English.Dashboard.AddButton}</div>
          </div>


        </div>
      )
    }else if(this.state.lessonsPanelOpen){ //Viewing A Module's Lessons
      return(
        <div>
          <div className="coach-course-modules-header">
            <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeModuleLessons} />
            {Language.English.Dashboard.LessonsHeader}
            <div className="add-modules-button-container">
              <div className="add-modules-button" onClick={this.logic.openAddLessonPanel}>
                <img src={greyPlusIcon} className="modules-button-icon" />
                {Language.English.Dashboard.AddLessons}
              </div>

            </div>
           </div>

           <div className="module-name-edit-row">

             <input className="module-edit-name-input" type="text" placeholder="Module Name" value={this.state.chosenModuleName} onChange={(event)=>{this.setState({chosenModuleName:event.target.value})}}/>
             <br/>
             <div className="webinar-edit-button" onClick={()=>{this.logic.saveModuleName()}}>
               {Language.English.Dashboard.SaveModuleName}
             </div>

           </div>

           {
             this.state.chosenModuleLessons.map((lesson,index)=>{
               return(
                 <div className="lesson-display-row" >
                   {index+1}. {lesson.name}

                   <div className="module-buttons-container">

                     <div className="webinar-edit-button"  onClick={()=>{this.logic.openEditLessonPanel(index,lesson.name,lesson.description,lesson.files)}}>
                       <img src={editIcon} className="edit-course-icon"/>
                       {Language.English.Dashboard.Edit}
                     </div>

                     <div className="webinar-delete-button" onClick={()=>{this.logic.deleteLesson(index)}}>
                       <img src={binIcon} className="bin-icon" />
                       {Language.English.InkelAdmin.Delete}
                     </div>
                   </div>
                 </div>
               )
             })
           }
        </div>
      )
    }else if(this.state.organisationsPanelOpen){

      return(
        <div>
          <div className="coach-course-modules-header">
            <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeOrganisationsPanel} />
            {Language.English.Dashboard.AddOrganisations}
            <div className="add-modules-button-container">
            </div>

            </div>

            <div className="course-users-container">

            <div className="new-course-user-container">

              <select className="coaching-users-dropdown" value={this.state.chosenNewCourseOrganisationId} onChange={(event)=>{this.setState({chosenNewCourseOrganisationId:event.target.value})}}>


                {
                  this.state.organisations.map((organisation, index)=>{

                    if(!this.state.chosenCourseOrganisationIds.includes(organisation.id)){
                      return(
                        <option key={organisation.id} value={organisation.id}>{organisation.companyName}</option>
                      )
                    }

                  })
                }
              </select>

              <div className="new-course-submit-button" onClick={()=>{this.logic.addOrganisationToCourse()}}>{Language.English.Dashboard.AddOrganisation}</div>

            </div>

            <br/>

            {
              this.state.organisations.map((organisation,index)=>{
                if(this.state.chosenCourseOrganisationIds.includes(organisation.id)){
                  return(
                    <div className="organisation-display">
                      <div className="course-user-display-left">
                        <div className="course-user-text">{organisation.companyName}</div>
                      </div>

                      <div className="course-display-right">
                        <div className="remove-user-button" onClick={()=>{this.logic.removeOrganisationFromCourse(organisation.id)}}>
                          <img src={binIcon} className="remove-user-icon" />
                          {Language.English.CompanyAdmin.RemoveUser}
                        </div>
                      </div>
                    </div>
                  )
                }
              })
            }


            </div>


        </div>
      )


    }else if(this.state.addUsersPanelOpen){ //Display Adding New Users Module


      return(
        <div>
          <div className="coach-course-modules-header">
            <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeAddUsersPanel} />
            {Language.English.Dashboard.AddUsers}
            <div className="add-modules-button-container">
            </div>

            </div>

            <div className="course-users-container">

            <div className="new-course-user-container">

            <select className="coaching-users-dropdown" value={this.state.chosenNewCourseUserId} onChange={(event)=>{this.setState({chosenNewCourseUserId:event.target.value})}}>


              {
                this.state.myUsers.map((user, index)=>{

                  if(!this.state.chosenCourseUserIds.includes(user.id)){
                    return(
                      <option key={user.email} value={user.id}>{user.name}</option>
                    )
                  }

                })
              }
            </select>

            <div className="new-course-submit-button" onClick={()=>{this.logic.addUserToCourse()}}>{Language.English.CompanyAdmin.AddUser}</div>

            </div>

            <br/>

            {
              this.state.myUsers.map((user,index)=>{
                if(this.state.chosenCourseUserIds.includes(user.id)){
                  return(
                    <div className="course-user-display">
                      <div className="course-user-display-left">
                        <div className="course-user-text">{user.name}</div>
                      </div>

                      <div className="course-display-right">
                        <div className="remove-user-button" onClick={()=>{this.logic.removeUserFromCourse(user.id)}}>
                          <img src={binIcon} className="remove-user-icon" />
                          {Language.English.CompanyAdmin.RemoveUser}
                        </div>
                      </div>
                    </div>
                  )
                }
              })
            }


            </div>


        </div>
      )

    }else if(this.state.modulesPanelOpen){


      return(
        <div>
          <div className="coach-course-modules-header">
            <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeModulesPanel} />
            {Language.English.Dashboard.ModulesHeader}

            <div className="add-modules-button-container">
              <div className="add-modules-button" onClick={this.logic.openAddNewModulePanel}>
                <img src={greyPlusIcon} className="modules-button-icon" />
                {Language.English.Dashboard.AddModules}
              </div>


            </div>
          </div>

          <div className="modules-content">

            {
              this.state.chosenCourseModulesList.map((module,index)=>{
                return(
                  <div className="module-display-row" >
                    {index+1}. {module.name}



                    <div className="module-buttons-container">

                      <div className="webinar-edit-button" onClick={()=>{this.logic.openModuleLessons(module.id, module.name, index,module.lessons || [])}}>
                        <img src={editIcon} className="edit-course-icon" />
                        {Language.English.Dashboard.Edit}
                      </div>

                      <div className="webinar-delete-button" onClick={()=>{this.logic.deleteModule(index)}}>
                        <img src={binIcon} className="bin-icon" />
                        {Language.English.InkelAdmin.Delete}
                      </div>
                    </div>

                  </div>
                )
              })
            }
          </div>
        </div>
      )



    }else{ //Default: Display a Course's Details

      return(
        <div>

        <div className="coach-course-modules-header">
          <div className="add-modules-button-container">
            <div className="add-modules-button" onClick={this.logic.openModulesPanel}>
              <img src={greyPlusIcon} className="modules-button-icon" />
              {Language.English.Dashboard.MyModules}
            </div>

            <div className="add-course-users-button" onClick={this.logic.openAddUsersPanel}>
              <img src={greyPlusIcon} className="modules-button-icon" />
              {Language.English.Dashboard.AddUsers}
            </div>

            <div className="add-course-users-button" onClick={this.logic.openOrganisationsPanel}>
              <img src={greyPlusIcon} className="modules-button-icon" />
              {Language.English.Dashboard.AddOrganisations}
            </div>

          </div>
        </div>

        <div className="module-name-edit-row">

          <input className="module-edit-name-input" type="text" placeholder="Course Name" value={this.state.chosenCourseName} onChange={(event)=>{this.setState({chosenCourseName:event.target.value})}}/>
          <br/><br/>
          <textarea className="module-edit-description-input" placeholder="Course Description" value={this.state.chosenCourseDescription} onChange={(event)=>{this.setState({chosenCourseDescription:event.target.value})}}/>
          <br/><br/>
          <div className="webinar-edit-button" onClick={()=>{this.logic.saveCourseDetails()}}>
            {Language.English.Dashboard.SaveModuleName}
          </div>

        </div>



        </div>
      )

    }
  }

}

export default withAlert()(CoachDashboard);
