import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

//Import Screens
import Login from './screens/Login/index';
import Register from './screens/Register/index';
import Dashboard from './screens/Home/index';
import Calendar from './screens/Calendar/index';
import Requests from './screens/Requests/index';
import PlanRequests from './screens/PlanRequests/index';
import Messages from './screens/Messages/index';
import Settings from './screens/Settings/index';
import Subscriptions from './screens/Subscriptions/index';
import Surveys from './screens/Surveys/index';
import InkelAdmin from './screens/inkelAdmin/index';
import CompanyAdmin from './screens/CompanyAdmin/index';
import Content from './screens/Content/index';
import CompanyPlans from './screens/CompanyPlans/index';
import Diary from './screens/Diary/index';
import OrganisationSurveys from './screens/OrganisationSurveys/index';
import PublicSurveys from './screens/PublicSurveys';

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE
}

const Root = () => (
  <AlertProvider template={AlertTemplate} {...options}>
  <Router>
    <div>
      <Switch>
        <Route path={`${process.env.PUBLIC_URL}/`} exact component={Login} />
        <Route path={`${process.env.PUBLIC_URL}/register`}  component={Register} />
        <Route path={`${process.env.PUBLIC_URL}/calendar`}  component={Calendar} />
        <Route path={`${process.env.PUBLIC_URL}/requests`}  component={Requests} />
        <Route path={`${process.env.PUBLIC_URL}/messages`}  component={Messages} />
        <Route path={`${process.env.PUBLIC_URL}/settings`}  component={Settings} />
        <Route path={`${process.env.PUBLIC_URL}/subscriptions`}  component={Subscriptions} />
        <Route path={`${process.env.PUBLIC_URL}/plan-requests`}  component={PlanRequests} />
        <Route path={`${process.env.PUBLIC_URL}/surveys`}  component={Surveys} />
        <Route path={`${process.env.PUBLIC_URL}/dashboard`}  component={Dashboard} />
        <Route path={`${process.env.PUBLIC_URL}/inkel-admin`}  component={InkelAdmin} />
        <Route path={`${process.env.PUBLIC_URL}/company-admin`}  component={CompanyAdmin} />
        <Route path={`${process.env.PUBLIC_URL}/organisation-surveys`}  component={OrganisationSurveys} />
        <Route path={`${process.env.PUBLIC_URL}/organisation-plans`}  component={CompanyPlans} />
        <Route path={`${process.env.PUBLIC_URL}/content`} component={Content} />
        <Route path={`${process.env.PUBLIC_URL}/diary`} component={Diary} />
        <Route path={`${process.env.PUBLIC_URL}/public-surveys`}  component={PublicSurveys} />
      </Switch>
    </div>
  </Router>
  </AlertProvider>
)


ReactDOM.render(<Root />, document.getElementById('root'));
