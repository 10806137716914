import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { withAlert } from 'react-alert';

import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import whitePlusIcon from '../../../assets/white-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import defaultProfileImage from '../../../assets/profile-picture.png';
import lockTriangle from '../../../assets/icons/lock-triangle.png';

import 'react-big-calendar/lib/css/react-big-calendar.css';

class ClientSettings extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      loadingNotificationSettings:true,
      accountStatus:props.accountStatus,
      myName:props.accountStatus.name,
      myCompanyName:props.accountStatus.companyName,
      myCompanyAddress:props.accountStatus.companyAddress,
      profileImage:props.accountStatus.profileURL,
      userEmail:props.accountStatus.userEmail,
      myCompanyAddress1:props.accountStatus.companyAddress1,
      myCompanyAddress2:props.accountStatus.companyAddress2,
      myCompanyCounty:props.accountStatus.companyCounty,
      myCompanyCountry:props.accountStatus.companyCountry,
      myCompanyPhone:props.accountStatus.companyPhone,
      myCompanyTownCity:props.accountStatus.companyTownCity,
      myCompanyPostcode:props.accountStatus.companyPostcode,
      notificationHours:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.profileImageInput = React.createRef();
    this.logic = new Logic(this);

    this.logic.loadNotificationSettings();
  }

  renderAddAvailabilityPanel = ()=>{
    if(!this.state.addAvailabilityPanelOpen) return;

    return(
      <div className="add-availability-panel">
        <img src={xButton} className="add-availability-close-button" onClick={this.logic.closeAddAvailabilityPanel} />
        <div className="add-availability-form">
          <div className="add-availability-form-header">Set your Session Availability</div>
          <div className="add-availability-timezone">Your Timezone: {this.state.timeZone}</div>
          <div className="add-availability-chosen-date">Chosen Date: {this.state.chosenDisplayDate}</div>
        </div>
      </div>
    )
  }


  render(){
    if(this.state.loading || this.state.loadingNotificationSettings){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{

      const localizer = momentLocalizer(moment);

      return(
        <div className="client-settings-home">
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Settings.MySettings} userName={this.props.userName} profileImage={this.props.profileImage}/>
          {this.renderChangeEmailPanel()}
          {this.renderChangePasswordPanel()}
          <div className="client-settings-container">
            <form onSubmit={this.logic.saveSettings}>
              <div className="settings-header">{Language.English.Settings.PersonalDetails}</div>
              <div className="setting-label">{Language.English.Settings.MyName}</div>
              <input type="text" className="setting-input-text" value={this.state.myName} onChange={(event)=>{this.setState({myName:event.target.value})}}/>
              {this.renderCompanyDataInput()}
              <br/>
              <button className="save-settings-button">{Language.English.Settings.Save}</button>

              <div className="open-change-email-button" onClick={this.logic.openChangeEmailPanel}>{Language.English.Settings.ChangeEmail}</div>
              <div className="open-change-password-button" onClick={this.logic.openChangePasswordPanel}>{Language.English.Settings.ChangePassword}</div>
            </form>
          </div>

          <div className="client-settings-container">
            <form onSubmit={this.logic.saveNotificationSettings}>
              <div className="settings-header">{Language.English.Settings.NotificationsHeader}</div>
              <div className="setting-label">{Language.English.Settings.MyDays}</div>

              <br/>

              <input type="checkbox" className="day-checkbox" id="checkbox" name="day-checkbox" checked={this.state.mondayChecked} onChange={()=>{this.setState({mondayChecked:!this.state.mondayChecked})}}></input>
              <label className="day-checkbox-label"  for="day-checkbox">{Language.English.Weekdays.Monday}</label>

              <input type="checkbox" className="day-checkbox" id="checkbox" name="day-checkbox" checked={this.state.tuesdayChecked} onChange={()=>{this.setState({tuesdayChecked:!this.state.tuesdayChecked})}}></input>
              <label className="day-checkbox-label"  for="day-checkbox">{Language.English.Weekdays.Tuesday}</label>

              <input type="checkbox" className="day-checkbox" id="checkbox" name="day-checkbox" checked={this.state.wednesdayChecked} onChange={()=>{this.setState({wednesdayChecked:!this.state.wednesdayChecked})}}></input>
              <label className="day-checkbox-label"  for="day-checkbox">{Language.English.Weekdays.Wednesday}</label>

              <input type="checkbox" className="day-checkbox" id="checkbox" name="day-checkbox" checked={this.state.thursdayChecked} onChange={()=>{this.setState({thursdayChecked:!this.state.thursdayChecked})}}></input>
              <label className="day-checkbox-label"  for="day-checkbox">{Language.English.Weekdays.Thursday}</label>

              <input type="checkbox" className="day-checkbox" id="checkbox" name="day-checkbox" checked={this.state.fridayChecked} onChange={()=>{this.setState({fridayChecked:!this.state.fridayChecked})}}></input>
              <label className="day-checkbox-label"  for="day-checkbox">{Language.English.Weekdays.Friday}</label>

              <input type="checkbox" className="day-checkbox" id="checkbox" name="day-checkbox" checked={this.state.saturdayChecked} onChange={()=>{this.setState({saturdayChecked:!this.state.saturdayChecked})}}></input>
              <label className="day-checkbox-label"  for="day-checkbox">{Language.English.Weekdays.Saturday}</label>

              <input type="checkbox" className="day-checkbox" id="checkbox" name="day-checkbox" checked={this.state.sundayChecked} onChange={()=>{this.setState({sundayChecked:!this.state.sundayChecked})}}></input>
              <label className="day-checkbox-label"  for="day-checkbox">{Language.English.Weekdays.Sunday}</label>

              <br/><br/>

              <input type="checkbox" className="notification-checkbox" id="checkbox" name="notification-checkbox" checked={this.state.notificationsChecked} onChange={()=>{this.setState({notificationsChecked:!this.state.notificationsChecked})}}></input>
              <label className="notification-checkbox-label"  for="notification-checkbox">{Language.English.Settings.Notifications}</label>

              <br/><br/>

              <input type="checkbox" className="notification-checkbox" id="checkbox" name="notification-checkbox" checked={this.state.emailsChecked} onChange={()=>{this.setState({emailsChecked:!this.state.emailsChecked})}}></input>
              <label className="notification-checkbox-label"  for="notification-checkbox">{Language.English.Settings.Emails}</label>

              <div className="setting-label">{Language.English.Settings.NotificationTime}</div>

              <select  value={this.state.notificationTime} onChange={(event)=>{this.setState({notificationTime:event.target.value})}} className="notification-hours-dropdown">
                {
                  this.state.notificationHours.map((option)=> <option key={option} value={option}>{option}</option>)
                }
              </select>
              <div className="notification-minutes"> : 00</div>

              <br/>
              <button className="save-settings-button" onClick={this.logic.saveNotificationSettings}>{Language.English.Settings.Save}</button>
            </form>
          </div>

          <div className="client-profile-picture-container">
            <div className="settings-header">{Language.English.Settings.ProfilePicture}</div>
            <div className="settings-profile-notice">
              Note: Your profile picture will automatically update when you choose a file.
            </div>

            <div className="profile-picture-row">
              <img src={this.state.profileImage || defaultProfileImage} className="settings-profile-image" />
              <input type="file" accept="image/*" ref={this.profileImageInput} className="choose-profile-image-input" onChange={this.logic.saveProfileImage} />
            </div>

          </div>


        </div>
      );

    }

  }

  renderChangePasswordPanel = ()=>{
    if(!this.state.passwordPanelOpen) return;

    if(this.state.passwordLoading){
      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeChangePasswordPanel}/>

          <div className="edit-course-form">
            <div className="edit-panel-loader">
              <ClipLoader
                size={100}
                color={"#005c9e"}
                loading={true}
              />
            </div>
          </div>
        </div>
      )
    }

    return(
      <div className="new-course-panel">
        <img src={xButton} className="new-course-close-button" onClick={this.logic.closeChangePasswordPanel}/>

        <div className="edit-course-form">

          <div className="change-email-header">{Language.English.Settings.ChangePasswordHeader}</div>


          <div className="change-email-form">
            <div className="change-credentials-header">{Language.English.Settings.OldPassword}</div><br/>
            <input className="change-credentials-input" type="password" placeholder="" value={this.state.oldPassword} onChange={(event)=>{this.setState({oldPassword:event.target.value})}}/>

            <div className="change-credentials-header">{Language.English.Settings.NewPassword}</div><br/>
            <input className="change-credentials-input" type="password" placeholder="" value={this.state.updatedPassword} onChange={(event)=>{this.setState({updatedPassword:event.target.value})}}/>

            <br/>

            <div className="new-credentials-submit-button" onClick={this.logic.updatePassword}>
              <img className="save-button-lock-icon" src={lockTriangle} />
              {Language.English.Settings.Save}
            </div>

            <br/>

            <div></div>

          </div>

        </div>
      </div>
    )
  }

  renderChangeEmailPanel = ()=>{
    if(!this.state.emailPanelOpen) return;

    if(this.state.emailLoading){
      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeChangeEmailPanel}/>

          <div className="edit-course-form">
            <div className="edit-panel-loader">
              <ClipLoader
                size={100}
                color={"#005c9e"}
                loading={true}
              />
            </div>
          </div>
        </div>
      )
    }

    return(
      <div className="new-course-panel">
        <img src={xButton} className="new-course-close-button" onClick={this.logic.closeChangeEmailPanel}/>

        <div className="edit-course-form">

          <div className="change-email-header">{Language.English.Settings.ChangeEmailHeader}</div>


          <div className="change-email-form">
            <div className="change-credentials-header">{Language.English.Settings.MyEmail}</div><br/>
            <input className="change-credentials-input" type="text" placeholder="" value={this.state.userEmail} onChange={(event)=>{this.setState({userEmail:event.target.value})}}/>

            <div className="change-credentials-header">{Language.English.Settings.MyPassword}</div><br/>
            <input className="change-credentials-input" type="password" placeholder="" value={this.state.emailUpdatePassword} onChange={(event)=>{this.setState({emailUpdatePassword:event.target.value})}}/>

            <br/>

            <div className="new-credentials-submit-button" onClick={this.logic.updateEmail}>
              <img className="save-button-lock-icon" src={lockTriangle} />
              {Language.English.Settings.Save}
            </div>

            <br/>

            <div></div>

          </div>

        </div>
      </div>
    )
  }

  renderCompanyDataInput = ()=>{
    if(this.state.accountStatus.authority != 'admin')return;

    return(
      <div>
        <div className="setting-label">My Company Name:</div>
        <input type="text" className="setting-input-text" value={this.state.myCompanyName} onChange={(event)=>{this.setState({myCompanyName:event.target.value})}} />


        <div className="setting-label">My Company Address Line 1:</div>
        <input type="text" className="setting-input-text" value={this.state.myCompanyAddress1} onChange={(event)=>{this.setState({myCompanyAddress1:event.target.value})}} />

        <div className="setting-label">My Company Address Line 2:</div>
        <input type="text" className="setting-input-text" value={this.state.myCompanyAddress2} onChange={(event)=>{this.setState({myCompanyAddress2:event.target.value})}} />

        <div className="setting-label">My Company Town/City:</div>
        <input type="text" className="setting-input-text" value={this.state.myCompanyTownCity} onChange={(event)=>{this.setState({myCompanyTownCity:event.target.value})}} />


        <div className="setting-label">My Company Postcode:</div>
        <input type="text" className="setting-input-text" value={this.state.myCompanyPostcode} onChange={(event)=>{this.setState({myCompanyPostcode:event.target.value})}} />


        <div className="setting-label">My Company County:</div>
        <input type="text" className="setting-input-text" value={this.state.myCompanyCounty} onChange={(event)=>{this.setState({myCompanyCounty:event.target.value})}} />

        <div className="setting-label">My Company Country:</div>
        <input type="text" className="setting-input-text" value={this.state.myCompanyCountry} onChange={(event)=>{this.setState({myCompanyCountry:event.target.value})}} />

        <div className="setting-label">My Company Phone:</div>
        <input type="text" className="setting-input-text" value={this.state.myCompanyPhone} onChange={(event)=>{this.setState({myCompanyPhone:event.target.value})}} />




      </div>
    )

  }





}

export default withAlert()(ClientSettings);
