import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class CoachLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Last Hope Survey Date
  lastHopeSurveyDate = (index)=>{

    if(index == 0){

    if(!this.baseContext.state.chosenClientHopeScore) return 'N/A';

    let lastDate = this.baseContext.state.chosenClientHopeScore.timestampUTC;
    let localLastDate = lastDate + (new Date().getTimezoneOffset()*60*1000);

    let localDate = new Date(localLastDate);

    let dateString = localDate.getDate()+'/'+(localDate.getMonth()+1)+'/'+localDate.getFullYear();
    let timeString = localDate.getHours()+':'+((localDate.getMinutes().toString().length == 1)? ('0'+localDate.getMinutes()):localDate.getMinutes());

    let dateTimeString = dateString+' '+timeString;

    return dateTimeString

    }

    if(!this.baseContext.state.chosenClientPreviousHopeScore) return 'N/A';

    let lastDate = this.baseContext.state.chosenClientPreviousHopeScore.timestampUTC;
    let localLastDate = lastDate + (new Date().getTimezoneOffset()*60*1000);

    let localDate = new Date(localLastDate);

    let dateString = localDate.getDate()+'/'+(localDate.getMonth()+1)+'/'+localDate.getFullYear();
    let timeString = localDate.getHours()+':'+((localDate.getMinutes().toString().length == 1)? ('0'+localDate.getMinutes()):localDate.getMinutes());

    let dateTimeString = dateString+' '+timeString;

    return dateTimeString


  }

  //Pathways Subscale Score
  calculatePathwaysSubscaleScore = (index)=>{

    if(index == 0){
      if(!this.baseContext.state.chosenClientHopeScore) return 'N/A';

      let data = this.baseContext.state.chosenClientHopeScore.data;

      return (data[0]+data[3]+data[5]+data[7]);
    }

    if(!this.baseContext.state.chosenClientPreviousHopeScore) return 'N/A';

    let data = this.baseContext.state.chosenClientPreviousHopeScore.data;

    return (data[0]+data[3]+data[5]+data[7]);
  }

  //AgencySubscale Score
  calculateAgencySubscaleScore = (index)=>{

    if(index == 0){
      if(!this.baseContext.state.chosenClientHopeScore) return 'N/A';

      let data = this.baseContext.state.chosenClientHopeScore.data;

      return (data[1]+data[8]+data[9]+data[11]);
    }

    if(!this.baseContext.state.chosenClientPreviousHopeScore) return 'N/A';

    let data = this.baseContext.state.chosenClientPreviousHopeScore.data;

    return (data[1]+data[8]+data[9]+data[11]);
  }

  //Total Hope Score
  calculateTotalHopeScore = (index)=>{
    if((index == 0 &&  !this.baseContext.state.chosenClientHopeScore) || (index == 1 && !this.baseContext.state.chosenClientPreviousHopeScore)) return 'N/A';

    return this.calculatePathwaysSubscaleScore(index) + this.calculateAgencySubscaleScore(index);
  }

  //Open Panel to create course
  openNewCoursePanel = ()=>{
    this.baseContext.setState({newCoursePanelOpen: true});
  }

  //Close new course panel (Not created)
  closeNewCoursePanel = ()=>{
    this.baseContext.setState({newCoursePanelOpen: false, newCourseName:''});
  }

  //View Modules in a Course
  openModulesPanel = ()=>{
    this.baseContext.setState({modulesPanelOpen:true});
  }

  closeModulesPanel = ()=>{
    this.baseContext.setState({modulesPanelOpen:false});
  }

  //View Organisations in a Course
  openOrganisationsPanel = ()=>{
    let organisations = this.baseContext.state.organisations;
    let chosenOrganisations = this.baseContext.state.chosenCourseOrganisationIds;
    let unchosenOrganisations = [];

    for(let index = 0;index < organisations.length;index++){
      if(!chosenOrganisations.includes(organisations[index].id)){
        unchosenOrganisations.push(organisations[index]);
      }
    }

    let chosenOrganisationId = (unchosenOrganisations[0])?unchosenOrganisations[0].id : null
    this.baseContext.setState({organisationsPanelOpen:true, chosenNewCourseOrganisationId:chosenOrganisationId});
  }

  closeOrganisationsPanel = ()=>{
    this.baseContext.setState({organisationsPanelOpen:false});
  }

  //Open surveys results from client
  openSurveyResults = async(client,index)=>{

    await this.baseContext.setState({loading:true});

    await firebase.firestore().collection("users").doc(client.id).collection('surveyData').doc('UpZyaeSe0Jb0HCmcWjU4').get().then(async(doc)=>{

      let hoogaInterventions = await firebase.firestore().collection("hooga-pillars").doc('interventions').get().then(async(doc)=>{

        if(!doc.data()) return {};

        return doc.data().content;


      });

      if(!doc.data() || !doc.data().results){

        let scoresArray = [
          {name:'Accomplishment',data:hoogaInterventions['Accomplishment'],score:0},
          {name:'Relationships',data:hoogaInterventions['Relationships'],score:0},
          {name:'Engagement',data:hoogaInterventions['Engagement'],score:0},
          {name:'Health',data:hoogaInterventions['Health'],score:0},
          {name:'Meaning',data:hoogaInterventions['Meaning'],score:0},
          {name:'PositiveEmotions',data:hoogaInterventions['PositiveEmotions'],score:0}
        ];

        let interventionRandomIndex = (Math.floor(Math.random() * 6));
        let intervention = scoresArray[interventionRandomIndex];

        let interventionIndex = Math.floor(Math.random() * intervention.data.length);
        let excercise = intervention.data[interventionIndex];
        let excerciseName = intervention.name;

        await this.baseContext.setState({
          engagementData:[],
          productivityData:[],
          physicalData:[],
          achievementData:[],
          loadingSurveyData:false,
          excercise:excercise,
          excerciseName:excerciseName,
          dayIndex:interventionRandomIndex
        });

        return;
      }


      let results = doc.data().results || [];

      results.sort((a, b) => (a.dateTimeUTC > b.dateTimeUTC) ? 1 : (a.dateTimeUTC === b.dateTimeUTC) ? ((a.size > b.size) ? 1 : -1) : -1 );
      results.reverse();


      let engagementDataArray = [];
      let relationshipsDataArray = [];
      let physicalDataArray = [];
      let achievementDataArray = [];
      let meaningDataArray = [];
      let happinessDataArray = [];
      let overallDataArray = [];

      let timestampsArray = [];

      for(let resultIndex = 0; (resultIndex < results.length)&&(resultIndex < 1); resultIndex++){
        let engagementMean = (parseInt(results[resultIndex]['answers'][1]) + parseInt(results[resultIndex]['answers'][9]) + parseInt(results[resultIndex]['answers'][22])) / 3;
        let relationshipsMean = (parseInt(results[resultIndex]['answers'][7]) + parseInt(results[resultIndex]['answers'][15]) + parseInt(results[resultIndex]['answers'][17])) / 3;
        let physicalMean = (parseInt(results[resultIndex]['answers'][5]) + parseInt(results[resultIndex]['answers'][10]) + parseInt(results[resultIndex]['answers'][14])) / 3;
        let achievementMean = (parseInt(results[resultIndex]['answers'][0]) + parseInt(results[resultIndex]['answers'][4]) + parseInt(results[resultIndex]['answers'][21])) / 3;
        let meaningMean = (parseInt(results[resultIndex]['answers'][6]) + parseInt(results[resultIndex]['answers'][8]) + parseInt(results[resultIndex]['answers'][16])) / 3;
        let happinessMean = parseInt(results[resultIndex]['answers'][19]);

        let overallMean = this.calculateTotalAverage(results[resultIndex]['answers']);

        engagementDataArray.push([2 - resultIndex, engagementMean]);
        relationshipsDataArray.push([2 - resultIndex, relationshipsMean]);
        physicalDataArray.push([2 - resultIndex, physicalMean]);
        achievementDataArray.push([2 - resultIndex, achievementMean]);
        meaningDataArray.push([2 - resultIndex, meaningMean]);
        happinessDataArray.push([2 - resultIndex, happinessMean]);

        overallDataArray.push([2 - resultIndex, overallMean]);

        timestampsArray.push(results[resultIndex]['dateTimeUTC']);



      }

      engagementDataArray.reverse();
      relationshipsDataArray.reverse();
      physicalDataArray.reverse();
      achievementDataArray.reverse();
      meaningDataArray.reverse();
      happinessDataArray.reverse();
      overallDataArray.reverse();
      timestampsArray.reverse();

      let mostRecentOverallScore = overallDataArray[overallDataArray.length - 1][1];

      let mostRecentEngagementScore = engagementDataArray[engagementDataArray.length - 1][1];
      let mostRecentRelationshipScore = relationshipsDataArray[relationshipsDataArray.length - 1][1];
      let mostRecentPhysicalScore = physicalDataArray[physicalDataArray.length - 1][1];
      let mostRecentAchievementScore = achievementDataArray[achievementDataArray.length - 1][1];
      let mostRecentMeaningScore = meaningDataArray[meaningDataArray.length - 1][1];
      let mostRecentHappinessScore = happinessDataArray[happinessDataArray.length - 1][1];

      let mostRecentDate = new Date(new Date(timestampsArray[timestampsArray.length - 1]).getTime() + (new Date().getTimezoneOffset()*60*1000));

      let mostRecentDateFormatted = mostRecentDate.getDate()+'/'+(mostRecentDate.getMonth()+1)+'/'+mostRecentDate.getFullYear();

      let currentDayInt = (new Date().getDay() == 6)?(0):(new Date().getDay()+1);
      let scoresArray = [
        {name:'Accomplishment',data:hoogaInterventions['Accomplishment'],score:mostRecentAchievementScore},
        {name:'Relationships',data:hoogaInterventions['Relationships'],score:mostRecentRelationshipScore},
        {name:'Engagement',data:hoogaInterventions['Engagement'],score:mostRecentEngagementScore},
        {name:'Health',data:hoogaInterventions['Health'],score:mostRecentPhysicalScore},
        {name:'Meaning',data:hoogaInterventions['Meaning'],score:mostRecentMeaningScore},
        {name:'PositiveEmotions',data:hoogaInterventions['PositiveEmotions'],score:mostRecentHappinessScore}
      ];

      scoresArray.sort((a, b) => (a.score > b.score) ? 1 : (a.score === b.score) ? 0 : -1 );

      let dayIndex = (currentDayInt == 6)? (Math.floor(Math.random() * 6)):(currentDayInt);
      let intervention = scoresArray[dayIndex];

      let interventionIndex = Math.floor(Math.random() * intervention.data.length);
      let excercise = intervention.data[interventionIndex];
      let excerciseName = intervention.name;

      console.log(excercise);




      let engagementData = {
        label:'Flow',
        data:engagementDataArray[0][1]
      }

      let relationshipsData = {
        label:'Relationships',
        data:relationshipsDataArray[0][1]
      }

      let physicalData = {
        label:'Physical',
        data:physicalDataArray[0][1]
      }

      let achievementData = {
        label:'Goals',
        data:achievementDataArray[0][1]
      }

      let meaningData = {
        label:'Purpose',
        data:meaningDataArray[0][1]
      }

      let happinessData = {
        label:'Happiness',
        data:happinessDataArray[0][1]
      }

      let overallData = {
        label:'Overall Wellbeing',
        data:overallDataArray[0]
      }

      console.log(happinessDataArray[0][1]);



      let mainData = [engagementData, relationshipsData, physicalData, achievementData, meaningData, happinessData]

      console.log(100 - Math.round(parseFloat(mostRecentPhysicalScore)).toString());

      this.baseContext.setState({
        mainData: mainData,
        recentWellbeingScore:Math.round(parseFloat(mostRecentOverallScore)).toString(),
        recentEngagementScore:Math.round(parseFloat(mostRecentEngagementScore)).toString(),
        recentHappinessScore:Math.round(parseFloat(mostRecentHappinessScore)).toString(),
        recentPhysicalScore:Math.round(parseFloat(mostRecentPhysicalScore)).toString(),
        recentAchievementScore:Math.round(parseFloat(mostRecentAchievementScore)).toString(),
        recentMeaningScore:Math.round(parseFloat(mostRecentMeaningScore)).toString(),
        recentRelationshipScore:Math.round(parseFloat(mostRecentRelationshipScore)).toString(),
        recentDate:mostRecentDateFormatted,
        excercise:excercise,
        excerciseName:excerciseName,
        dayIndex:dayIndex,
        loadingSurveyData:false,
      });

    }).catch((error)=>{
      console.log('ERROR'+error.message);
    })

    let pulseSurveyData = client.pulseSurveyResults || [];


    let hopeSurveyValues = (Array.isArray(client.hopeSurveyValues))? client.hopeSurveyValues[client.hopeSurveyValues.length - 1]:null;
    let previousHopeSurveyValues = (Array.isArray(client.hopeSurveyValues) && client.hopeSurveyValues.length > 1)? client.hopeSurveyValues[client.hopeSurveyValues.length - 2]:null;



    if(pulseSurveyData.length > 0){
      let recentPulseData = pulseSurveyData[pulseSurveyData.length - 1].answers;


      let pulseAbilityScore = this.calculateTotalScore(recentPulseData['Ability Pulse']);
      let pulseVibeScore = this.calculateTotalScore(recentPulseData['Vibe Pulse']);
      let pulseTribeScore = this.calculateTotalScore(recentPulseData['Tribe Pulse']);
      let pulseHappinessScore = this.calculateTotalScore(recentPulseData['Happiness Pulse']);
      let pulseHarmonyScore = this.calculateTotalScore(recentPulseData['Harmony Pulse']);
      let pulseStrengthsScore = this.calculateTotalScore(recentPulseData['Strengths Pulse']);

      this.baseContext.setState({
        surveyResultsOpen:true,
        surveyResults:client.pulseSurveyResults || [],
        surveyResultsClientName:client.name,
        pulseAbilityScore:pulseAbilityScore,
        pulseVibeScore:pulseVibeScore,
        pulseTribeScore:pulseTribeScore,
        pulseHappinessScore:pulseHappinessScore,
        pulseHarmonyScore:pulseHarmonyScore,
        pulseStrengthsScore:pulseStrengthsScore,
        chosenClientHopeScore:hopeSurveyValues,
        chosenClientPreviousHopeScore:previousHopeSurveyValues,
        chosenClient:client,
        chosenClientIndex:index,
        loading:false
      });

    }else{
      this.baseContext.setState({
        surveyResultsOpen:true,
        surveyResults:client.pulseSurveyResults || [],
        surveyResultsClientName:client.name,
        pulseAbilityScore:null,
        pulseVibeScore:null,
        pulseTribeScore:null,
        pulseHappinessScore:null,
        pulseHarmonyScore:null,
        pulseStrengthsScore:null,
        chosenClientHopeScore:hopeSurveyValues,
        chosenClientPreviousHopeScore:previousHopeSurveyValues,
        chosenClient:client,
        chosenClientIndex:index,
        loading:false
      });
    }



  }

  calculateTotalAverage = (array)=>{

    if(!array) return;

    let total = 0;

    for(let index = 0;index < array.length;index++){
      total += parseInt(array[index]);
    }

    return ((total / array.length));
  }

  calculateTotalScore = (array)=>{

    if(!array) return;

    let total = 0;

    for(let index = 0;index < array.length;index++){
      total += parseInt(array[index]);
    }

    console.log('total'+(total / (7*array.length)));
    return ((total / (7*array.length))*100);
  }

  //Close Survey Results
  closeSurveyResults = ()=>{
    this.baseContext.setState({
      surveyResultsOpen:false,
      surveyResults:[],
      surveyResultsClientName:null
    });
  }

  //Save Token for Notifications
  saveToken = async(token)=>{
    const currentUserId = firebase.auth().currentUser.uid;

    let notificationTokens = this.baseContext.state.accountStatus.notificationTokens;

    if(!notificationTokens.includes(token)){
      notificationTokens.push(token);
      firebase.firestore().collection("users").doc(currentUserId).set({notificationTokens:notificationTokens},{merge:true});
    }

  }

  loadOrganisations = async()=>{
    if(this.baseContext.state.accountStatus.authority != 'inkel-admin') return;

    const currentUserId = firebase.auth().currentUser.uid;

    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });


    functions.httpsCallable('getOrganisations')({
      token:token
    }).then(async(result)=>{

      if(result.data.success){

        let defaultOrganisationId = null;

        if(result.data.usersArray.length > 0){
          defaultOrganisationId = result.data.usersArray[0].id;
        }

        await this.baseContext.setState({organisations:result.data.usersArray, chosenNewCourseOrganisationId: defaultOrganisationId});
      }else{
        await this.baseContext.setState({organisations:[]});
        this.baseContext.showAlert(result.data.errorMessage);
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });



  }

  loadUsers = async()=>{

    await this.baseContext.setState({loadingMyClients:true});

    const currentUserId = firebase.auth().currentUser.uid;

    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });


    functions.httpsCallable('getUsers')({
      token:token
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loadingMyClients:false, myClients:result.data.usersArray});
      }else{
        await this.baseContext.setState({loadingMyClients:false,  myClients:[]});
        this.baseContext.showAlert(result.data.errorMessage);
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });
  }

  //Send a Pulse Survey
  sendPulseSurvey = async(client, index)=>{
    let updatedClients = this.baseContext.state.myClients;
    updatedClients[index].pulseSurveyAvailable = true;
    this.baseContext.state.chosenClient.pulseSurveyAvailable = true;
    await this.baseContext.setState({myClients:updatedClients});

    const currentUserId = firebase.auth().currentUser.uid;

    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    functions.httpsCallable('sendPulseSurvey')({
      token:token,
      clientId:client.id
    }).then(async(result)=>{

      if(result.data.success){
        this.baseContext.showAlert('Survey Sent!');
      }else{
        this.baseContext.showAlert('Error sending survey!');
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
    });
  }

  //Send a Core Survey
  sendCoreSurvey = async(client, index)=>{
    let updatedClients = this.baseContext.state.myClients;
    updatedClients[index].coreSurveyAvailable = true;
    this.baseContext.state.chosenClient.coreSurveyAvailable = true;
    await this.baseContext.setState({myClients:updatedClients});

    const currentUserId = firebase.auth().currentUser.uid;

    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    functions.httpsCallable('sendCoreSurvey')({
      token:token,
      clientId:client.id
    }).then(async(result)=>{

      if(result.data.success){
        this.baseContext.showAlert('Survey Sent!');
      }else{
        this.baseContext.showAlert('Error sending survey!');
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
    });
  }

  //Send a Hope Survey
  sendHopeSurvey = async(client, index)=>{
    let updatedClients = this.baseContext.state.myClients;
    updatedClients[index].hopeSurveyAvailable = true;
    this.baseContext.state.chosenClient.hopeSurveyAvailable = true;
    await this.baseContext.setState({myClients:updatedClients});

    const currentUserId = firebase.auth().currentUser.uid;

    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    functions.httpsCallable('sendHopeSurvey')({
      token:token,
      clientId:client.id
    }).then(async(result)=>{

      if(result.data.success){
        this.baseContext.showAlert('Survey Sent!');
      }else{
        this.baseContext.showAlert('Error sending survey!');
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
    });
  }

  //Delete a course
  deleteCourse = async(course, index)=>{

    await firebase.firestore().collection("resources").doc(course.id).delete();

    let courses = [];

    for(let courseIndex = 0; courseIndex < this.baseContext.state.coursesList.length; courseIndex++){
      if(this.baseContext.state.coursesList[courseIndex].id != course.id){
        courses.push(this.baseContext.state.coursesList[courseIndex]);
      }
    }

    this.baseContext.showAlert("Successfully deleted course!");

    this.baseContext.setState({coursesList:courses});

  }

  //Delete a Module
  deleteModule = async(moduleIndex)=>{

    await this.baseContext.setState({loading:true});
    let chosenModuleId = this.baseContext.state.chosenCourseModulesList[moduleIndex].id;

    let newModules = [];

    for(let index = 0;index < this.baseContext.state.chosenCourseModulesList.length;index++){
      if(this.baseContext.state.chosenCourseModulesList[index].id != chosenModuleId){
        newModules.push(this.baseContext.state.chosenCourseModulesList[index]);
      }
    }

    await firebase.firestore().collection("resources").doc(this.baseContext.state.chosenCourseId).collection("modules").doc(chosenModuleId).delete();

    this.baseContext.showAlert("Document Deleted!");

    this.baseContext.setState({
      loading:false,
      chosenCourseModulesList:newModules
    })

  }

  //Load Coach's courses
  loadCourses = async()=>{
    let courses = [];
    let count = 0;

    const currentUserId = firebase.auth().currentUser.uid;



    await this.baseContext.setState({loadingMyUsers:true});


    firebase.firestore().collection("users").where("coach","==",currentUserId).get().then((querySnapshot) => {
        let count = 0;
        let users= [];
        let userIds=[];
      querySnapshot.forEach((doc) => {
          users.push({name:doc.data().name, email:doc.data().email, id:doc.id, index:count});
          userIds.push(doc.id);
          count++;
        });

        let chosenNewCourseUserId = null;

        if(users.length > 0){
          chosenNewCourseUserId = users[0].id;
        }

        this.baseContext.setState({
          myUsers: users,
          chosenNewCourseUserId:chosenNewCourseUserId,
          loadingMyUsers:false
        });



    }).catch((error)=>{
      console.log('ERROR'+error.message);
    })



    firebase.firestore().collection("resources").where("creatorId","==",currentUserId).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          courses.push({name:doc.data().name, organisationIds:(doc.data().organisationIds || []),userIds:(doc.data().userIds || []),public:doc.data().public,files: doc.data().files, live:doc.data().live, id:doc.id, index:count});
          count++;
        });

        this.baseContext.setState({
          coursesList: courses,
        });



    }).catch((error)=>{
      console.log('ERROR'+error.message);
    })

  }

  //Adds a User to a course & saves it

  addUserToCourse = ()=>{
    let chosenUserId = this.baseContext.state.chosenNewCourseUserId;
    let chosenCourseUsers = this.baseContext.state.chosenCourseUserIds;
    let chosenCourseId = this.baseContext.state.chosenCourseId;

    if(chosenCourseUsers.includes(chosenUserId) || this.baseContext.state.myUsers.length == 0) {

      this.baseContext.showAlert("Already Part of this course");
      return;

    }

    chosenCourseUsers.push(chosenUserId);


    this.baseContext.setState({loading:true});

    firebase.firestore().collection("resources").doc(chosenCourseId).set({userIds:chosenCourseUsers},{merge:true}).then((result)=>{
      this.baseContext.setState({loading:false, chosenCourseUserIds:chosenCourseUsers});
    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
      this.baseContext.setState({loading:false});
    })

  }

  //Remove a user from a course

  removeUserFromCourse = (userId)=>{
    let chosenUserId = userId;
    let chosenCourseUsers = this.baseContext.state.chosenCourseUserIds;
    let chosenCourseId = this.baseContext.state.chosenCourseId;

    let updatedChosenCourseUsers = [];

    for(let index = 0; index < chosenCourseUsers.length;index++){
      if(chosenCourseUsers[index] != chosenUserId){
        updatedChosenCourseUsers.push(chosenCourseUsers[index]);
      }
    }


    this.baseContext.setState({loading:true});

    firebase.firestore().collection("resources").doc(chosenCourseId).set({userIds:updatedChosenCourseUsers},{merge:true}).then((result)=>{
      this.baseContext.setState({loading:false, chosenCourseUserIds:updatedChosenCourseUsers});
    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
      this.baseContext.setState({loading:false});
    })
  }

  //Add Organisation to a Course

  addOrganisationToCourse = ()=>{
    let chosenOrganisationId = this.baseContext.state.chosenNewCourseOrganisationId;
    let chosenCourseOrganisations = this.baseContext.state.chosenCourseOrganisationIds;
    let chosenCourseId = this.baseContext.state.chosenCourseId;

    if(chosenCourseOrganisations.includes(chosenOrganisationId) || this.baseContext.state.organisations.length == 0) {

      this.baseContext.showAlert("Already Part of this course");
      return;

    }else if(chosenOrganisationId == null){
      this.baseContext.showAlert("No Chosen Organisation!");
      return;
    }

    chosenCourseOrganisations.push(chosenOrganisationId);


    this.baseContext.setState({loading:true});

    firebase.firestore().collection("resources").doc(chosenCourseId).set({organisationIds:chosenCourseOrganisations},{merge:true}).then((result)=>{
      this.baseContext.setState({loading:false, chosenCourseOrganisationIds:chosenCourseOrganisations});
    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
      this.baseContext.setState({loading:false});
    })

  }

  //Remove a user from a course

  removeUserFromCourse = (userId)=>{
    let chosenUserId = userId;
    let chosenCourseUsers = this.baseContext.state.chosenCourseUserIds;
    let chosenCourseId = this.baseContext.state.chosenCourseId;

    let updatedChosenCourseUsers = [];

    for(let index = 0; index < chosenCourseUsers.length;index++){
      if(chosenCourseUsers[index] != chosenUserId){
        updatedChosenCourseUsers.push(chosenCourseUsers[index]);
      }
    }


    this.baseContext.setState({loading:true});

    firebase.firestore().collection("resources").doc(chosenCourseId).set({userIds:updatedChosenCourseUsers},{merge:true}).then((result)=>{
      this.baseContext.setState({loading:false, chosenCourseUserIds:updatedChosenCourseUsers});
    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
      this.baseContext.setState({loading:false});
    })
  }


  // Remove an organisation from a course
  removeOrganisationFromCourse = (organisationId)=>{
    let chosenUserId = organisationId;
    let chosenCourseOrganisations = this.baseContext.state.chosenCourseOrganisationIds;
    let chosenCourseId = this.baseContext.state.chosenCourseId;

    let updatedChosenCourseOrganisations = [];

    for(let index = 0; index < chosenCourseOrganisations.length;index++){
      if(chosenCourseOrganisations[index] != chosenUserId){
        updatedChosenCourseOrganisations.push(chosenCourseOrganisations[index]);
      }
    }


    this.baseContext.setState({loading:true});

    firebase.firestore().collection("resources").doc(chosenCourseId).set({organisationIds:updatedChosenCourseOrganisations},{merge:true}).then((result)=>{
      this.baseContext.setState({loading:false, chosenCourseOrganisationIds:updatedChosenCourseOrganisations});
    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
      this.baseContext.setState({loading:false});
    })
  }



  //Opens Event/Course Panel for editting
  editEvent = async(course)=>{
    await this.baseContext.setState({
      editEventPanelOpen:true,
      chosenCourseName:course.name,
      chosenCourseId:course.id,
      editPanelLoading:true,
      chosenCourseIsLive:course.live,
      chosenCourseIsPublic:course.public,
      chosenCourseUserIds:course.userIds || [],
      chosenCourseOrganisationIds:course.organisationIds || [],
      chosenCourseIndex:course.index,
      chosenCourseDescription:course.description || '',
      editLessonPanelOpen:false,});

    let modules = [];

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("resources").doc(course.id).collection("modules").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          modules.push({name:doc.data().name, files: doc.data().files, description:doc.data().description, id:doc.id, lessons:doc.data().lessons});
        });

        this.baseContext.setState({
          chosenCourseModulesList: modules,
          editPanelLoading:false
        });



    }).catch((error)=>{
      console.log(error.message);
    })

  }

  //Add module to course
  addEventModule = async()=>{
    if(this.baseContext.state.newModuleName == ""){
      this.baseContext.showAlert("Your Module Name Cannot be Empty!");
      return;
    }

    const currentUserId = firebase.auth().currentUser.uid;

    await this.baseContext.setState({loading:true, addModulePanelOpen:false});

    let currentCourseId = this.baseContext.state.chosenCourseId;

    firebase.firestore().collection("resources").doc(currentCourseId).collection("modules").add({
      name: this.baseContext.state.newModuleName,
      lessons:[]
    }).then((docRef)=>{
      this.baseContext.showAlert("Module Created!");
      let oldModulesList = this.baseContext.state.chosenCourseModulesList;
      let newModule = {name: this.baseContext.state.newModuleName};
      newModule['id'] = docRef.id;

      oldModulesList.push(newModule);

      this.baseContext.setState({loading:false, chosenCourseModulesList: oldModulesList});
      this.loadCourses();
    }).catch((error)=>{
      this.baseContext.showAlert("Error: "+ error.message);
      this.baseContext.setState({loading:false});
    });

  }

  //Save Edited Course Name
  saveCourseDetails = async()=>{
    if(this.baseContext.state.chosenCourseName == ''){
      this.baseContext.showAlert('Please enter a name for the Course!');
    }

    await this.baseContext.setState({editPanelLoading:true});

    let currentCourseId = this.baseContext.state.chosenCourseId;

    firebase.firestore().collection("resources").doc(currentCourseId).set({
      name: this.baseContext.state.chosenCourseName,
      description: this.baseContext.state.chosenCourseDescription
    },{merge:true}).then((result)=>{
      this.baseContext.showAlert("Course details saved successfully.");

      let courses = this.baseContext.state.coursesList;
      courses[this.baseContext.state.chosenCourseIndex].name = this.baseContext.state.chosenCourseName;

      this.baseContext.setState({editPanelLoading:false, coursesList:courses});

    }).catch((error)=>{
      this.baseContext.showAlert("Error saving your course details: "+error);
      this.baseContext.setState({editPanelLoading:false});
    })


  }

  //Save Edited Module Name
  saveModuleName = async()=>{
    if(this.baseContext.state.chosenModuleName == ''){
      this.baseContext.showAlert('Please enter a name for the Module!');
    }

    await this.baseContext.setState({editPanelLoading:true});

    let currentCourseId = this.baseContext.state.chosenCourseId;
    let chosenModuleId = this.baseContext.state.chosenModuleId;

    firebase.firestore().collection("resources").doc(currentCourseId).collection("modules").doc(chosenModuleId).set({
      name: this.baseContext.state.chosenModuleName
    },{merge:true}).then((result)=>{
      this.baseContext.showAlert("Module Name saved successfully.");

      let chosenModuleIndex = this.baseContext.state.chosenModuleIndex;
      let chosenModules = this.baseContext.state.chosenCourseModulesList;
      chosenModules[chosenModuleIndex].name = this.baseContext.state.chosenModuleName;
      this.baseContext.setState({editPanelLoading:false, chosenCourseModulesList:chosenModules});

    }).catch((error)=>{
      this.baseContext.showAlert("Error saving your module name: "+error);
      this.baseContext.setState({editPanelLoading:false});
    })


  }

  //Opens Lessons for a chosen Module onClick
  openModuleLessons = async(moduleId, moduleName, index,lessons)=>{
   await this.baseContext.setState({chosenModuleId:moduleId,chosenModuleName:moduleName, chosenModuleIndex:index,chosenModuleLessons:lessons, lessonsPanelOpen:true});
  }

  //Delete a lesson
  deleteLesson = (index)=>{
    let chosenModuleLessons = this.baseContext.state.chosenModuleLessons;
    let updatedLessons = [];

    for(let lessonIndex = 0; lessonIndex < chosenModuleLessons.length; lessonIndex++){

      if(lessonIndex != index){
        updatedLessons.push(chosenModuleLessons[index]);
      }

    }

    let currentCourseId = this.baseContext.state.chosenCourseId;
    let chosenModuleId = this.baseContext.state.chosenModuleId;

    firebase.firestore().collection("resources").doc(currentCourseId).collection("modules").doc(chosenModuleId).set({
      lessons: updatedLessons
    },{merge:true}).then((result)=>{
      this.baseContext.showAlert("Lesson deleted successfully.");
      this.baseContext.setState({editPanelLoading:false, chosenModuleLessons: updatedLessons});
    }).catch((error)=>{
      this.baseContext.showAlert("Error deleting your lesson: "+error);
      this.baseContext.setState({editPanelLoading:false});
    })




  }

  //Closes the chosen Module's lessons
  closeModuleLessons = async()=>{
    await this.baseContext.setState({lessonsPanelOpen:false});
  }

  //Opens the Panel for creating a Lesson
  openAddLessonPanel = async()=>{
    await this.baseContext.setState({newLessonPanelOpen:true, newLessonName:'',newLessonFiles:[], newLessonDescription:""});
  }

  //Closes the Panel for creating a Lesson
  closeAddLessonPanel = async()=>{
    await this.baseContext.setState({newLessonPanelOpen:false, newLessonName:"", newLessonFiles:[], newLessonDescription:""});
  }

  //Closes the Panel for editting a Lesson
  closeEditLessonPanel = async()=>{
    await this.baseContext.setState({editLessonPanelOpen:false, EditLessonName:"", EditLessonFiles:[], EditLessonDescription:""});
  }

  //Opens a Lesson for Editting
  openEditLessonPanel = async(lessonIndex, lessonName, lessonDescription, lessonFiles)=>{
   await this.baseContext.setState({EditLessonIndex:lessonIndex, EditLessonName:lessonName, EditLessonDescription:lessonDescription, EditLessonExistingFiles:lessonFiles, EditLessonNewFiles:[], editLessonPanelOpen:true});
  }

  //Opens Adding New Users Panel
  openAddUsersPanel = ()=>{
    this.baseContext.setState({
      addUsersPanelOpen:true
    })
  }

  //Closes Adding New Users Panel
  closeAddUsersPanel = ()=>{
    this.baseContext.setState({
      addUsersPanelOpen:false
    })
  }

  //Remove New File from Lesson
  removeEditLessonFile = async(index)=>{
    let filesArray = this.baseContext.state.EditLessonNewFiles;
    filesArray.splice(index, 1);
    await this.baseContext.setState({EditLessonNewFiles: filesArray});
  }

  //Remove Existing File While Editting Lesson
  removeExistingFile = async(index)=>{
    let filesArray = this.baseContext.state.EditLessonExistingFiles;
    filesArray.splice(index, 1);
    await this.baseContext.setState({EditLessonNewFiles: filesArray});
  }

  //Add New File To the Existing Lesson
  editLessonAddNewFile = async()=>{
    let filesArray = this.baseContext.state.EditLessonNewFiles;
    let newFile = this.baseContext.EditLessonFilesInput.current.files[0];
    filesArray.push(newFile);

    await this.baseContext.setState({EditLessonNewFiles: filesArray});
  }

  //Save Editted Lesson
  saveLessonUpdate = async()=>{
    let lessonIndex = this.baseContext.state.EditLessonIndex;
    let lessonName = this.baseContext.state.EditLessonName;
    let lessonDescription = this.baseContext.state.EditLessonDescription;
    let lessonExistingFiles = this.baseContext.state.EditLessonExistingFiles;
    let lessonNewFiles = this.baseContext.state.EditLessonNewFiles;

    if(lessonName == ""){
      this.baseContext.showAlert("The lesson name cannot be empty!");
      return;
    }else if(lessonDescription == ""){
      this.baseContext.showAlert("The lesson description cannot be empty!");
      return;
    }

    await this.baseContext.setState({editPanelLoading:true, editLessonPanelOpen:false});

    let newFilesArray = lessonExistingFiles;
    let storageRef = firebase.storage().ref();
    let currentUserId = firebase.auth().currentUser.uid;
    let currentCourseId = this.baseContext.state.chosenCourseId;
    let chosenModuleId = this.baseContext.state.chosenModuleId;

    for(let index = 0; index < lessonNewFiles.length; index++){

      let newFileRef = storageRef.child('lessonFiles/'+currentUserId+"/"+currentCourseId+"/"+lessonNewFiles[index].name);

      await newFileRef.put(lessonNewFiles[index]).then(async(snapshot)=>{

        await newFileRef.getDownloadURL().then((url)=>{
          let fileObject = {
            name: lessonNewFiles[index].name,
            url: url
          }

          newFilesArray.push(fileObject);
        })

      });


    }

    let existingLessons = this.baseContext.state.chosenModuleLessons;
    let updatedLesson = {
      name:lessonName,
      description:lessonDescription,
      files:newFilesArray
    }
    existingLessons[lessonIndex] = updatedLesson;


    firebase.firestore().collection("resources").doc(currentCourseId).collection("modules").doc(chosenModuleId).set({
      lessons: existingLessons
    },{merge:true}).then((result)=>{
      this.baseContext.showAlert("Lesson saved successfully.");
      this.baseContext.setState({editPanelLoading:false, chosenModuleLessons: existingLessons});
    }).catch((err)=>{
      this.baseContext.showAlert("Error saving your lesson.");
      this.baseContext.setState({editPanelLoading:false});
    })


  }

  //Connection to DB to set course to live or not onChange
  liveToggleChange = async()=>{

    await this.baseContext.setState({chosenCourseIsLive: !this.baseContext.state.chosenCourseIsLive});

    let currentCourseId = this.baseContext.state.chosenCourseId;

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("resources").doc(currentCourseId).set({
      live: this.baseContext.state.chosenCourseIsLive
    },{merge:true}).then((result)=>{

      let coursesList = this.baseContext.state.coursesList;

      coursesList[this.baseContext.state.chosenCourseIndex].live = this.baseContext.state.chosenCourseIsLive;

      this.baseContext.setState({coursesList:coursesList});
    });

  }

  //Connection to DB to set course to public or private onChange
  publicToggleChange = async()=>{

    await this.baseContext.setState({chosenCourseIsPublic: !this.baseContext.state.chosenCourseIsPublic});

    let currentCourseId = this.baseContext.state.chosenCourseId;

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("resources").doc(currentCourseId).set({
      public: this.baseContext.state.chosenCourseIsPublic
    },{merge:true}).then((result)=>{

      let coursesList = this.baseContext.state.coursesList;

      coursesList[this.baseContext.state.chosenCourseIndex].public = this.baseContext.state.chosenCourseIsPublic;

      this.baseContext.setState({coursesList:coursesList});
    });

  }


  //Close Event Panel, details saved automatically
  closeEditEventPanel = async()=>{
    await this.baseContext.setState({editEventPanelOpen:false, lessonsPanelOpen:false, organisationsPanelOpen:false, addModulePanelOpen:false, newLessonPanelOpen:false});
  }


  //Opens Pane for adding a new module for a chosen course
  openAddNewModulePanel = async()=>{
    await this.baseContext.setState({addModulePanelOpen:true, newModuleName:''});
  }

  //Closes Pane for adding a new module for a chosen course
  closeAddNewModulePanel = async()=>{
    await this.baseContext.setState({addModulePanelOpen:false});
  }

  //Add New File to new lesson
  addNewFile = async()=>{
    let filesArray = this.baseContext.state.newLessonFiles;
    let newFile = this.baseContext.filesInput.current.files[0];
    filesArray.push(newFile);

    await this.baseContext.setState({newLessonFiles: filesArray});

  }

  //Remove Added File
  removeNewFile = async(index)=>{
    let filesArray = this.baseContext.state.newLessonFiles;
    filesArray.splice(index, 1);
    await this.baseContext.setState({newLessonFiles: filesArray});
  }

  //Save files to storage & create lesson
  createLesson = async()=>{


    let lessonName = this.baseContext.state.newLessonName;
    let lessonDescription = this.baseContext.state.newLessonDescription;
    let lessonFiles = this.baseContext.state.newLessonFiles;

    if(lessonName == ""){
      this.baseContext.showAlert("The lesson name cannot be empty!");
      return;
    }else if(lessonDescription == ""){
      this.baseContext.showAlert("The lesson description cannot be empty!");
      return;
    }

    await this.baseContext.setState({editPanelLoading:true, newLessonPanelOpen:false});

    let newFilesArray = [];
    let storageRef = firebase.storage().ref();
    let currentUserId = firebase.auth().currentUser.uid;
    let currentCourseId = this.baseContext.state.chosenCourseId;
    let chosenModuleId = this.baseContext.state.chosenModuleId;

    for(let index = 0; index < lessonFiles.length; index++){

      let newFileRef = storageRef.child('lessonFiles/'+currentUserId+"/"+currentCourseId+"/"+lessonFiles[index].name);

      await newFileRef.put(lessonFiles[index]).then(async(snapshot)=>{

        await newFileRef.getDownloadURL().then((url)=>{
          let fileObject = {
            name: lessonFiles[index].name,
            url: url
          }

          newFilesArray.push(fileObject);
        })

      });


    }

    let existingLessons = ((this.baseContext.state.chosenModuleLessons != null && this.baseContext.state.chosenModuleLessons != undefined)? this.baseContext.state.chosenModuleLessons : []);
    let newLesson = {
      name:lessonName,
      description:lessonDescription,
      files:newFilesArray
    }

    existingLessons.push(newLesson);



    firebase.firestore().collection("resources").doc(currentCourseId).collection("modules").doc(chosenModuleId).set({
      lessons: existingLessons
    },{merge:true}).then((result)=>{
      this.baseContext.showAlert("Lesson saved successfully.");
      this.baseContext.setState({editPanelLoading:false, chosenModuleLessons: existingLessons});
    }).catch((err)=>{
      this.baseContext.showAlert("Error saving your lesson.");
      this.baseContext.setState({editPanelLoading:false});
    })

  }


  //Create a new course (Default: Not Live & Invite Only)
  createCourse = async()=>{

    if(this.baseContext.state.newCourseName == ""){
      this.baseContext.showAlert("Your Course Name Cannot be Empty!");
      return;
    }

    const currentUserId = firebase.auth().currentUser.uid;

    await this.baseContext.setState({loading:true, newCoursePanelOpen:false});

    firebase.firestore().collection("resources").add({
      live:false,
      public:false,
      name:this.baseContext.state.newCourseName,
      creatorId:currentUserId

    }).then((docRef)=>{
      this.baseContext.showAlert("Document Created!");
      this.baseContext.setState({loading:false});
      this.loadCourses();
    }).catch((error)=>{
      this.baseContext.showAlert("Error: "+ error.message);
      this.baseContext.setState({loading:false});
    });



  }

}

export default CoachLogic;
