import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

class SubscriptionsLogic {

  constructor(context){
    this.baseContext = context;
  }

  loadUserDetails = ()=>{

    var projects = [];

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("users").doc(currentUserId).get().then(async(doc)=>{
      let name = doc.data().name;
      let type = doc.data().type;
      let accountType = doc.data().accountType;
      let profileURL = doc.data().profileURL;
      let availability = doc.data().availability;

      if(type != 'client') document.location = '/';

      let myPlans = await firebase.firestore().collection("requests").doc(currentUserId).get().then((doc)=>{
          return{
            planSet:doc.data().planSet,
            planRequested:doc.data().planRequested,
            planPaid:doc.data().planPaid,
            subscriptionSet:doc.data().subscriptionSet,
            subscriptionRequested:doc.data().subscriptionRequested,
            subscriptionPaid:doc.data().subscriptionPaid
          }
      }).catch((error)=>{
        return{
          planSet:false,
          planRequested:false,
          planPaid:false,
          subscriptionSet:false,
          subscriptionRequested:false,
          subscriptionPaid:false
        }
      })

      let hasPaid = false;


      if(doc.data().subscriptionId){

        let authToken = await firebase.auth().currentUser.getIdToken(true).then((idToken)=>{
          return {
            id:idToken,
            success:true
          }
        }).catch((error)=>{
          return {
            error:error.message,
            success:false
          }
        });

        if(!authToken.success){
          alert("There was an error with your login details!");
          await this.baseContext.setState({loading:false, paymentPanelOpen:true});
          return;
        }



        var functions = firebase.app().functions('europe-west1');

        const currentUser = firebase.auth().currentUser;

        const subscription = await functions.httpsCallable('getUserSubscription')({
          token:authToken.id,
          subscriptionToken: doc.data().subscriptionId
        }).then(async(result)=>{

          if(result.data.success){
            return {
              success:true,
              hasPaid:result.data.hasPaid || false
            }
          }else{
            return {
              success:true,
              hasPaid:false
            }
          }

        }).catch(async(error)=>{
          alert(error.message);
          await this.baseContext.setState({loading:false});
        });

        hasPaid = subscription.hasPaid;


      }


      this.baseContext.setState({
        name: name,
        type:type,
        profileURL:profileURL,
        isLoadingUserData: false,
        accountType:accountType,
        availability:availability,
        accountStatus:{
          name:name,
          type:type,
          accountType:accountType,
          profileURL:profileURL,
          hasFilledSurvey:doc.data().surveyComplete,
          hasChosenCoach:doc.data().coachChosen,
          hasAssignedCoach:doc.data().coachAssigned,
          coach:doc.data().coach,
          authority:doc.data().authority,
          subscriptionPaid:doc.data().subscriptionPaid,
          planPaid:doc.data().planPaid,
          subscriptionId:doc.data().subscriptionId,
          planId:doc.data().planId,
          planSet:myPlans.planSet,
          planRequested:myPlans.planRequested,
          planPaid:myPlans.planPaid,
          subscriptionSet:myPlans.subscriptionSet,
          subscriptionRequested:myPlans.subscriptionRequested,
          subscriptionPaid:myPlans.subscriptionPaid,
          authority:doc.data().authority,
          companyPaid:hasPaid,
          stripeId:doc.data().stripeId,
          numberOfBasicUsers:doc.data().numberOfBasicUsers,
          numberOfCheckinServices:doc.data().numberOfCheckinServices,
          numberOfCoachingServices:doc.data().numberOfCoachingServices,
          basicUserPrice:doc.data().basicUserPrice,
          checkinServicesPrice:doc.data().checkinServicesPrice,
          coachingServicesPrice:doc.data().coachingServicesPrice,
          isCoachingUser:doc.data().isCoachingUser,
        }
      });

    }).catch((error)=>{
      alert(error.message);
    });

    firebase.firestore().collection("users").doc(currentUserId).collection("resources").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          projects.push({name:doc.data().name});
        });

        this.baseContext.setState({
          projectsList: projects,
          isLoadingProjects: false,
        });


    });


  }


  validateAuth = ()=>{

    firebase.auth().onAuthStateChanged((user)=>{

      if (user && !(this.baseContext.state.loading)) {
        this.baseContext.setState({authenticating:false});
        this.loadUserDetails();
      }else{
        document.location = "/";
      }

    });

  }






}

export default SubscriptionsLogic;
