import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";

//Services & Local Logic
import Language from '../../services/Language';
import Logic from './logic';
import SideBar from '../../components/SideBar';
import MobileSideBar from '../../components/SideBarMobile';

//Dashboards
import ClientCalendar from './ClientCalendar';
import CoachCalendar from './CoachCalendar';

// Assets
import inkelLogo from '../../assets/logo.svg';
import inkelLogoDark from '../../assets/logo-dark.svg';
import loginIcon from '../../assets/lock-icon.png';
import yellowCurve from '../../assets/yellow-curve.png';
import sadFaceIcon from '../../assets/icons/sad-face-icon.png';

// Animations
import Fade from 'react-reveal/Fade';

class Calendar extends React.Component{

  constructor(props){
    super(props);

    this.state = {attemptingLogin:false,authenticating:true,isLoadingUserData:true,isLoadingProjects:true,emailValue:'',passwordValue:''};

    if(!firebase.apps.length){ firebase.initializeApp(ApiKeys.FirebaseConfig); }

    this.logic = new Logic(this);
    this.mobileSideBar = React.createRef();

  }

  componentDidMount = async()=>{
    this.logic.validateAuth();
  }

  render(){
    return(
      <div>
        {this.renderDashboard()}
      </div>
    );
  }


  renderDashboard = ()=>{

    if(this.state.authenticating || this.state.isLoadingProjects || this.state.isLoadingUserData){
      return(
        <div className="dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={this.state.loading}
          />
        </div>
      );
    }else{
      return(
        <div className="dashboard-home-container">
          <MobileSideBar context={this} ref={this.mobileSideBar} accountStatus={this.state.accountStatus} name="name1" openScreen={'calendar'} />
          <SideBar context={this} accountStatus={this.state.accountStatus} openScreen={'calendar'} />

          <div className="dashboard-content-container">
            {this.renderDashboardContent()}
          </div>
        </div>
      );
    }
  }

  renderDashboardContent = ()=>{
    if(this.state.accountStatus.accountType == 'company' && !this.state.accountStatus.enabled){


            return(
              <div className="not-basic-user">
                <img src={sadFaceIcon} className="not-basic-user-icon" />
                {Language.English.Dashboard.NotEnabled}
              </div>
            )

    }else if(this.state.type == 'client' && this.state.accountStatus.authority == 'admin' && !this.state.accountStatus.isBasicUser){
      return(
        <div className="not-basic-user">
          <img src={sadFaceIcon} className="not-basic-user-icon" />
          {Language.English.Dashboard.NotBasicUser}
        </div>
      )
    }else if(this.state.type == "client"){
      return(
        <ClientCalendar context={this} mobileSideBar={this.mobileSideBar} accountType={this.state.accountType} userName={this.state.name} profileImage={this.state.profileURL} accountStatus={this.state.accountStatus}/>
      )
    }else{
      return(
        <CoachCalendar context={this} mobileSideBar={this.mobileSideBar} userName={this.state.name} profileImage={this.state.profileURL} accountStatus={this.state.accountStatus}/>
      )
    }
  }


}

export default Calendar;
