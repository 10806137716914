import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { withAlert } from 'react-alert';

class CoachLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Open Panel to create course
  openNewCoursePanel = ()=>{
    this.baseContext.setState({newCoursePanelOpen: true});
  }

  //Close new course panel (Not created)
  closeNewCoursePanel = ()=>{
    this.baseContext.setState({newCoursePanelOpen: false});
  }

  openSetPricePanel = (request, index)=>{
    this.baseContext.setState({setPricePanelOpen:true, chosenRequest:request});
  }

  closeSetPricePanel = ()=>{
    this.baseContext.setState({setPricePanelOpen:false});
  }

  //Download Hooga CSV File
  downloadCsvFile = ()=>{
    let data = this.baseContext.state.surveyData;

    const rows = [['Name','Engagement','Relationships','Physical','Achievement','Meaning','Happiness','Overall']];

    for(let index = 0;index < data.length;index++){
      let row = data[index];
      rows.push([row.name,row.engagementMean,row.relationshipsMean,row.physicalMean,row.achievementMean,row.meaningMean,row.happinessMean,row.overallMean]);
    }

    let csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "hooga_data.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  checkIfClientsPaid = async()=>{

    await this.baseContext.setState({loadingPaymentDetails:false});

    return;

    await this.baseContext.setState({loadingPaymentDetails:true});

    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    let organisations = this.baseContext.state.coursesList;
    let clientStripeIds = [];

    for(let organisationIndex = 0; organisationIndex < organisations.length; organisationIndex++){
      clientStripeIds.push(organisations[organisationIndex].stripeId);
    }


    await functions.httpsCallable('checkClientPayments')({
      token:token,
      clientStripeIds:clientStripeIds,
    }).then((result)=>{
      if(result.data.success){
          this.baseContext.setState({
            paymentStatuses:result.data.paymentStatuses,
            loadingPaymentDetails:false
          })
      }else{
        this.baseContext.showAlert('Error:Loading Payment Details');
        this.baseContext.setState({
          loadingPaymentDetails:false
        })
      }
    }).catch((error)=>{
      this.baseContext.showAlert('Error Loading Payment Details: '+error.message);
      this.baseContext.setState({
        loadingPaymentDetails:false
      })
    })
  }



  //Load Coaches
  loadCoaches = async()=>{
    let coaches = [];
    let requests = [];
    let count = 0;

    const currentUserId = firebase.auth().currentUser.uid;

    await this.baseContext.setState({loading:true});

    await firebase.firestore().collection("planRequests").where("processedByInkelAdmin","==",false).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {

          if(doc.data().processedByCoach){
            let docData = doc.data();
            docData['id'] = doc.id;
            requests.push(docData);
          }

        });

        this.baseContext.setState({
          planRequests:requests,
          loading:false
        });


    }).catch((error)=>{
      console.log(error.message);
    })



    firebase.firestore().collection("users").where("type","==","coach").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          let docData = doc.data();
          docData['id'] = doc.id;
          coaches.push(docData);
          count++;
        });

        this.baseContext.setState({
          coachesList: coaches,
        });

    }).catch((error)=>{
      console.log(error.message);
    })


  }

  setRequestPrice = ()=>{
    if((!this.baseContext.state.planRequestPrice) || (this.baseContext.state.planRequestPrice == '')){
      this.baseContext.showAlert("Please enter a price (in Euro) of the plan for your client");
      return;
    }

    firebase.firestore().collection("planRequests").doc(this.baseContext.state.chosenRequest.id).set({
      planPrice:this.baseContext.state.planRequestPrice,
      processedByInkelAdmin:true
    },{merge:true}).then(async()=>{
      await this.baseContext.setState({createBookingLoading:false});


      const currentUserId = firebase.auth().currentUser.uid;

      let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
          return idToken;
      });


      var functions = firebase.app().functions('europe-west1');

      await functions.httpsCallable('sendAdminRequestConfirmationEmail')({
        token:token,
        receiverId:this.baseContext.state.chosenRequest.id,
      })

      this.baseContext.showAlert("Successfully Set The Plan Price!");


      window.location.reload();
    }).catch(async(error)=>{
      await this.baseContext.setState({createBookingLoading:false});
      this.closeCreateBookingPanel();
      this.baseContext.showAlert("Error: "+error.message);
    })
  }

  //Load Inkel Organisations
  loadOrganisations = ()=>{
    let organisations = [];
    let count = 0;

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("users").where("accountType","==","company").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {

        if(doc.data().authority == 'admin'){
          let docData = doc.data();
          docData['id'] = doc.id;
          organisations.push(docData);
          count++;
        }

        });

        this.baseContext.setState({
          coursesList: organisations,
        });



    });

  }

  //Save Pricing Details
  savePricingDetails = async()=>{

    await this.baseContext.setState({
      editCoachPanelOpen:false,
      loading:true
    })

    if(this.baseContext.state.chosenCoachCheckinPrice == "" || this.baseContext.state.chosenCoachMonthlyPrice == "" || this.baseContext.state.chosenCoachSessionPrice == ""){
      this.baseContext.showAlert("Prices cannot be empty");
      await this.baseContext.setState({
        editCoachPanelOpen:true,
        loading:false
      })
      return;
    }

    firebase.firestore().collection("users").doc(this.baseContext.state.chosenCoachId).set({
      session_price: this.baseContext.state.chosenCoachSessionPrice,
      monthly_price: this.baseContext.state.chosenCoachMonthlyPrice,
      monthly_checkin_price: this.baseContext.state.chosenCoachCheckinPrice
    }, {merge:true}).then((result)=>{
      this.baseContext.showAlert("Pricing Updated!");

      let coachesList = this.baseContext.state.coachesList;
      let coachIndex = this.baseContext.state.chosenCoachIndex;

      coachesList[coachIndex]['session_price'] = this.baseContext.state.chosenCoachSessionPrice;
      coachesList[coachIndex]['monthly_price'] = this.baseContext.state.chosenCoachMonthlyPrice;
      coachesList[coachIndex]['monthly_checkin_price'] = this.baseContext.state.chosenCoachCheckinPrice;

      this.baseContext.setState({
        editCoachPanelOpen:false,
        loading:false,
        coachesList:coachesList
      })

    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
      this.baseContext.setState({
        editCoachPanelOpen:true,
        loading:false
      })
    })

  }

  //Close Edit Coach Panel
  closeEditCoachPanel = ()=>{
    this.baseContext.setState({
      editCoachPanelOpen:false
    })
  }

  //Open Panel for Editting A Coach
  editCoach = (coach, index)=>{
    this.baseContext.setState({
      editCoachPanelOpen:true,
      chosenCoachId:coach.id,
      chosenCoachName:coach.name,
      chosenCoachIndex:index,
      chosenCoachSessionPrice: coach.session_price || "0",
      chosenCoachMonthlyPrice: coach.monthly_price || "0",
      chosenCoachCheckinPrice: coach.monthly_checkin_price || "0"
    });
  }

  editWebinars = (organisation, index)=>{
    this.baseContext.setState({
      editWebinarsPanelOpen:true,
      chosenWebinarOrganisationName:organisation.companyName,
      chosenWebinarOrganisationId:organisation.id,
      chosenWebinars:organisation.webinars || []
    })
  }

  //Convert Date to String
  convertToString(dateUTC){
    let localDate = new Date(new Date(dateUTC).getTime() + (new Date().getTimezoneOffset() * 60 * 1000));
    console.log(localDate);
    console.log(dateUTC);
    return localDate.getDate()+"/"+(localDate.getMonth() + 1)+"/"+localDate.getFullYear()+" "+localDate.getHours()+":"+((String.valueOf(localDate.getMinutes()).length == 1)?(localDate.getMinutes()+"0"):(localDate.getMinutes()));
  }

  //Close Panel For Webinars
  closeEditWebinarsPanel = ()=>{
    this.baseContext.setState({
      editWebinarsPanelOpen:false,
      newWebinarPanelOpen:false,
      editWebinarPanelOpen:false,
    })
  }

  //Open Panel to Add Webinars
  openAddWebinarPanel = (organisation, index)=>{
    this.baseContext.setState({
      newWebinarPanelOpen:true,
      NewWebinarDate: new Date(),
      NewWebinarStartTimeHours: '12',
      NewWebinarStartTimeMinutes:'00',
      NewWebinarEndTimeHours:'12',
      NewWebinarEndTimeMinutes:'00',
      NewWebinarName:'',
      NewWebinarCallLink:'',
      NewWebinarDescription:''
    })
  }


  //Update a Webinar
  openUpdateWebinarPanel = async(webinarIndex)=>{

    let startTimeLocal = new Date(this.baseContext.state.chosenWebinars[webinarIndex].startTimeUTC + (new Date().getTimezoneOffset()*60*1000));
    let endTimeLocal = new Date(this.baseContext.state.chosenWebinars[webinarIndex].endTimeUTC + (new Date().getTimezoneOffset()*60*1000));

    let chosenWebinarDate = new Date(this.baseContext.state.chosenWebinars[webinarIndex].startTimeUTC);

    this.baseContext.setState({
      editWebinarPanelOpen:true,
      ChosenWebinarIndex: webinarIndex,
      ChosenWebinarName: this.baseContext.state.chosenWebinars[webinarIndex].name,
      ChosenWebinarDescription: this.baseContext.state.chosenWebinars[webinarIndex].description,
      ChosenWebinarCallLink:this.baseContext.state.chosenWebinars[webinarIndex].callLink,
      ChosenWebinarStartTimeHours:startTimeLocal.getHours(),
      ChosenWebinarStartTimeMinutes:(String.valueOf(startTimeLocal.getMinutes()).length == 1)?(startTimeLocal.getMinutes()+"0"):(String.valueOf(startTimeLocal.getMinutes())),
      ChosenWebinarEndTimeHours:endTimeLocal.getHours(),
      ChosenWebinarEndTimeMinutes:(String.valueOf(endTimeLocal.getMinutes()).length == 1)?(endTimeLocal.getMinutes()+"0"):(String.valueOf(endTimeLocal.getMinutes())),
      ChosenWebinarDate:chosenWebinarDate
    })

    console.log(startTimeLocal.getHours())
  }

  closeEditWebinarPanel = ()=>{
    this.baseContext.setState({
      editWebinarPanelOpen:false
    })
  }

  updateWebinar = async()=>{
    if(this.baseContext.state.ChosenWebinarName == '' || this.baseContext.state.ChosenWebinarDescription == ''){
      this.baseContext.showAlert("Please enter both a title & description for the webinar!");
      return;
    }

    await this.baseContext.setState({
      loading:true
    })

    let webinars = this.baseContext.state.chosenWebinars;

    let webinarName = this.baseContext.state.ChosenWebinarName;
    let webinarDescription = this.baseContext.state.ChosenWebinarDescription;
    let webinarCallLink = this.baseContext.state.ChosenWebinarCallLink;
    let webinarDate = new Date(this.baseContext.state.ChosenWebinarDate);
    webinarDate.setHours(0);
    webinarDate.setMinutes(0);
    let webinarStartTimeUTC = (webinarDate.getTime()) + ((parseInt(this.baseContext.state.ChosenWebinarStartTimeHours)*60*60*1000)+(parseInt(this.baseContext.state.ChosenWebinarStartTimeMinutes)*60*1000)) - (new Date().getTimezoneOffset() * 60 * 1000);
    let webinarEndTimeUTC = (webinarDate.getTime()) + ((parseInt(this.baseContext.state.ChosenWebinarEndTimeHours)*60*60*1000)+(parseInt(this.baseContext.state.ChosenWebinarEndTimeMinutes)*60*1000)) - (new Date().getTimezoneOffset() * 60 * 1000);

    let updatedWebinar = {
      name:webinarName,
      description:webinarDescription,
      callLink:webinarCallLink,
      startTimeUTC:webinarStartTimeUTC,
      endTimeUTC:webinarEndTimeUTC
    }

    webinars[this.baseContext.state.ChosenWebinarIndex] = updatedWebinar;

    firebase.firestore().collection("users").doc(this.baseContext.state.chosenWebinarOrganisationId).set({
      webinars: webinars
    },{merge:true}).then((result)=>{

      this.baseContext.showAlert("Successfully Updated Webinar!");
      this.baseContext.setState({
        loading:false,
        editWebinarPanelOpen:false
      })

  }).catch((error)=>{
    this.baseContext.showAlert("Error: "+ error.message);
    this.baseContext.setState({
      loading:false
    })
  })

  }

  //Create Webinar
  createWebinar = async()=>{

    if(this.baseContext.state.NewWebinarName == '' || this.baseContext.state.NewWebinarDescription == ''){
      this.baseContext.showAlert("Please enter both a title & description for the webinar!");
      return;
    }

    await this.baseContext.setState({
      loading:true
    })

    let webinars = this.baseContext.state.chosenWebinars;

    let webinarName = this.baseContext.state.NewWebinarName;
    let webinarDescription = this.baseContext.state.NewWebinarDescription;
    let webinarCallLink = this.baseContext.state.NewWebinarCallLink;
    let webinarDate = new Date(this.baseContext.state.NewWebinarDate);
    webinarDate.setHours(0);
    webinarDate.setMinutes(0);
    let webinarStartTimeUTC = (webinarDate.getTime()) + ((parseInt(this.baseContext.state.NewWebinarStartTimeHours)*60*60*1000)+(parseInt(this.baseContext.state.NewWebinarStartTimeMinutes)*60*1000)) - (new Date().getTimezoneOffset() * 60 * 1000);
    let webinarEndTimeUTC = (webinarDate.getTime()) + ((parseInt(this.baseContext.state.NewWebinarEndTimeHours)*60*60*1000)+(parseInt(this.baseContext.state.NewWebinarEndTimeMinutes)*60*1000)) - (new Date().getTimezoneOffset() * 60 * 1000);

    let newWebinar = {
      name:webinarName,
      description:webinarDescription,
      callLink:webinarCallLink,
      startTimeUTC:webinarStartTimeUTC,
      endTimeUTC:webinarEndTimeUTC
    }

    webinars.push(newWebinar);

    firebase.firestore().collection("users").doc(this.baseContext.state.chosenWebinarOrganisationId).set({
      webinars: webinars
    },{merge:true}).then(async(result)=>{

      this.baseContext.showAlert("Successfully Created Webinar!");

      this.baseContext.setState({
        loading:false,
        newWebinarPanelOpen:false
      })

      var functions = firebase.app().functions('europe-west1');

      let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
          return idToken;
      });

      await functions.httpsCallable('sendAdminWebinarEmail')(
        {
          token:token,
          adminId:this.baseContext.state.chosenWebinarOrganisationId,
          name:webinarName,
          description:webinarDescription,
          callLink:webinarCallLink,
          startTimeUTC:webinarStartTimeUTC,
          endTimeUTC:webinarEndTimeUTC
        }).then(async(result)=>{

        if(!result.data.success){
          this.baseContext.showAlert('Error Saving Settings:'+result.data.errorMessage);
          await this.baseContext.setState({loading:false, error:result.data.errorMessage});
          return;
        }

      }).catch(async(error)=>{
        this.baseContext.showAlert('Error Saving Settings:'+error.message);
        await this.baseContext.setState({loading:false});
        return;
      });

  }).catch((error)=>{
    this.baseContext.showAlert("Error: "+ error.message);
    this.baseContext.setState({
      loading:false
    })
  })


}

  //Close Panel to Add Webinars
  closeNewWebinarPanel = ()=>{
    this.baseContext.setState({
      newWebinarPanelOpen:false
    })
  }

  //Opens View of Survey results
  openSurvey = async(survey,index)=>{

    const currentUserId = firebase.auth().currentUser.uid;

    this.baseContext.setState({
      openSurveyId:survey.id,
      surveyPanelOpen:true,
      surveyData:survey.surveyData || [],
      userId:currentUserId
    })
  }

  //Closes View Of Survey
  closeSurvey = async(survey,index)=>{


    this.baseContext.setState({
      surveyPanelOpen:false
    })
  }

  //Get Colour of Hooga Survey Data
  getHoogaDataColour = (score)=>{

    if(score < 2){
      return '#d40023';
    }else if(score < 4){
      return '#fa8c9e';
    }else if(score < 8){
      return '#76e3a2';
    }

    return '#00d44e';
  }


  //Opens Events/Event/Course Panel for editting
  editEvent = async(organisation, index)=>{

    let organisationSurveys = [];

    await firebase.firestore().collection("groupSurveys").doc(organisation.id).collection("surveys").get().then((querySnapshot) => {


      querySnapshot.forEach((doc) => {
          let docData = doc.data();
          docData['id'] = doc.id;
          organisationSurveys.push(docData);
        });

        this.baseContext.setState({
          organisationSurveys:organisationSurveys
        });


    }).catch((error)=>{
      console.log(error.message);
    })


    await this.baseContext.setState({
      editEventPanelOpen:true,
      chosenCourseName:organisation.companyName,
      chosenCourseId:organisation.id,
      chosenOrganisationHasPaid:organisation.paid,
      chosenOrganisationIndex:index,
      editLessonPanelOpen:false,
      editPanelLoading:false,
      chosenOrganisationBasicUserNumbers:organisation.numberOfBasicUsers || '0',
      chosenOrganisationMonthlyCoachingNumbers:organisation.numberOfCoachingServices || '0',
      chosenOrganisationMonthlyCheckinNumbers:organisation.numberOfCheckinServices || '0',
      chosenOrganisationBasicUserPrice:organisation.basicUserPrice,
      chosenOrganisationMonthlyCoachingPrice:organisation.coachingServicesPrice,
      chosenOrganisationMonthlyCheckinPrice:organisation.checkinServicesPrice,
      chosenOrganisationMonthlyContentPrice:organisation.contentServicesPrice || '0',
      chosenOrganisationIsEnabled:organisation.enabled || false,
      chosenOrganisationWebinars: organisation.webinars || [],
      chosenOrganisationPaidManually:organisation.paidManually || false
    });



  }

  //Save Organisation Details
  saveOrganisationDetails = async()=>{

    await this.baseContext.setState({
      loading:true
    })

    let organisationEnabled = this.baseContext.state.chosenOrganisationIsEnabled;
    let organisationId = this.baseContext.state.chosenCourseId;


    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    await functions.httpsCallable('setOrganisationEnabled')({token:token,organisationEnabled:organisationEnabled,organisationId:organisationId}).then(async(result)=>{

      if(!result.data.success){
        this.baseContext.showAlert('Error Saving Settings:'+result.data.errorMessage);
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
        return;
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert('Error Saving Settings:'+error.message);
      await this.baseContext.setState({loading:false});
      return;
    });





    firebase.firestore().collection("users").doc(this.baseContext.state.chosenCourseId).set({
      numberOfBasicUsers:this.baseContext.state.chosenOrganisationBasicUserNumbers,
      numberOfCoachingServices:this.baseContext.state.chosenOrganisationMonthlyCoachingNumbers,
      numberOfCheckinServices:this.baseContext.state.chosenOrganisationMonthlyCheckinNumbers,
      basicUserPrice:this.baseContext.state.chosenOrganisationBasicUserPrice,
      coachingServicesPrice:this.baseContext.state.chosenOrganisationMonthlyCoachingPrice,
      checkinServicesPrice: this.baseContext.state.chosenOrganisationMonthlyCheckinPrice,
      contentServicesPrice: this.baseContext.state.chosenOrganisationMonthlyContentPrice,
      paidManually:this.baseContext.state.chosenOrganisationPaidManually
    },{merge:true}).then((result)=>{

      let organisations = this.baseContext.state.coursesList;
      let index = this.baseContext.state.chosenOrganisationIndex;

      organisations[index].numberOfBasicUsers = this.baseContext.state.chosenCoachBasicUserNumbers;
      organisations[index].numberOfCoachingServices = this.baseContext.state.chosenCoachMonthlyCoachingNumbers;
      organisations[index].numberOfCheckinServices = this.baseContext.state.chosenCoachMonthlyCheckinNumbers;
      organisations[index].basicUserPrice = this.baseContext.state.chosenCoachBasicUserPrice;
      organisations[index].coachingServicesPrice = this.baseContext.state.chosenCoachMonthlyCoachingPrice;
      organisations[index].checkinServicesPrice = this.baseContext.state.chosenCoachMonthlyCheckinPrice;
      organisations[index].enabled = this.baseContext.state.chosenOrganisationIsEnabled;
      organisations[index].paidManually = this.baseContext.state.chosenOrganisationPaidManually;

      this.baseContext.showAlert("Successfully Updated Organisation!");

      this.baseContext.setState({
        loading:false,
        editEventPanelOpen:false,
        coursesList:organisations
      })

    }).catch((error)=>{

      this.baseContext.showAlert("Error: "+error.message);

      this.baseContext.setState({
        loading:false,
        editEventPanelOpen:true
      })

    })
  }


  //Opens Lessons for a chosen Module onClick
  openModuleLessons = async(moduleId, lessons)=>{
   await this.baseContext.setState({chosenModuleId:moduleId, chosenModuleLessons:lessons, lessonsPanelOpen:true});
  }

  //Closes the chosen Module's lessons
  closeModuleLessons = async()=>{
    await this.baseContext.setState({lessonsPanelOpen:false});
  }

  //Opens the Panel for creating a Lesson
  openAddLessonPanel = async()=>{
    await this.baseContext.setState({newLessonPanelOpen:true});
  }

  //Closes the Panel for creating a Lesson
  closeAddLessonPanel = async()=>{
    await this.baseContext.setState({newLessonPanelOpen:false, newLessonName:"", newLessonFiles:[], newLessonDescription:""});
  }

  //Closes the Panel for editting a Lesson
  closeEditLessonPanel = async()=>{
    await this.baseContext.setState({editLessonPanelOpen:false, EditLessonName:"", EditLessonFiles:[], EditLessonDescription:""});
  }

  //Opens a Lesson for Editting
  openEditLessonPanel = async(lessonIndex, lessonName, lessonDescription, lessonFiles)=>{
   await this.baseContext.setState({EditLessonIndex:lessonIndex, EditLessonName:lessonName, EditLessonDescription:lessonDescription, EditLessonExistingFiles:lessonFiles, EditLessonNewFiles:[], editLessonPanelOpen:true});
  }


  closeEditEventPanel = ()=>{
    this.baseContext.setState({editEventPanelOpen:false, surveyPanelOpen:false});
  }

  liveToggleChange = ()=>{

  }


  //Create a new course (Default: Not Live & Invite Only)
  createOrganisation = async()=>{
    let state = this.baseContext.state;
    let inputArray = [
      state.newCompanyName,
      state.newCompanyAddress,
      state.newCompanyPhone,
      state.newCompanyAdminName,
      state.newCompanyAdminEmail,
      state.basicUsers,
      state.coachingServices,
      state.checkinServices,
      state.basicUserPrice,
      state.coachingServicesPrice,
      state.checkinServicesPrice,
      state.contentServicesPrice,
      state.newCompanyCounty,
      state.newCompanyAddress,
      state.newCompanyAddress2,
      state.newCompanyCountry,
      state.newCompanyPostcode,
      state.newCompanyTownCity
    ]

    for(let index = 0; index < inputArray.length; index++){
      if(!inputArray[index] || inputArray[index]==""){
        this.baseContext.showAlert("You are missing details!");
        return;
        break;
      }
    }


    const currentUserId = firebase.auth().currentUser.uid;

    await this.baseContext.setState({loading:true, newCoursePanelOpen:false});

    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    let functionData = {
      newCompanyName:state.newCompanyName,
      newCompanyCounty:state.newCompanyCounty,
      newCompanyAddress:state.newCompanyAddress,
      newCompanyAddress2:state.newCompanyAddress2,
      newCompanyCountry:state.newCompanyCountry,
      newCompanyPostcode:state.newCompanyPostcode,
      newCompanyTownCity:state.newCompanyTownCity,
      newCompanyPhone:state.newCompanyPhone,
      newCompanyAdminName:state.newCompanyAdminName,
      newCompanyAdminEmail:state.newCompanyAdminEmail,
      basicUsers:state.basicUsers,
      coachingServices:state.coachingServices,
      checkinServices:state.checkinServices,
      basicUserPrice:state.basicUserPrice,
      coachingServicesPrice:state.coachingServicesPrice,
      checkinServicesPrice:state.checkinServicesPrice,
      contentServicesPrice:state.contentServicesPrice,
      userToken:token
    }

    functions.httpsCallable('createOrganisation')(functionData).then(async(result)=>{

      if(result.data.success){
        this.baseContext.showAlert("Organisation Created!");
        window.location.reload();

      }else{
        this.baseContext.showAlert(result.data.errorMessage);
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });



  }

}

export default CoachLogic;
