import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

//Services
import Language from '../../../services/Language';

class CoachLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Open Send Message Panel
  openSendMessagePanel = (request)=>{
    this.baseContext.setState({sendMessagePanelOpen:true, sendMessageLoading:false, chosenRequest:request});
  }

  //Close Send Message Panel
  closeSendMessagePanel = ()=>{
    this.baseContext.setState({sendMessagePanelOpen:false, sendMessageLoading:false});
  }

  //Send Message
  sendMessage = async()=>{
    if(this.baseContext.state.newMessage == ""){
      this.baseContext.showAlert("Please enter a message!");
      return;
    }

    await this.baseContext.setState({sendMessageLoading:true});

    var database = firebase.database();
    const currentUserId = firebase.auth().currentUser.uid;



    firebase.database().ref('messages/' +currentUserId+"/"+this.baseContext.state.chosenRequest.clientId).update({
      name: this.baseContext.state.chosenRequest.fromName || null,
      profileURL: this.baseContext.state.chosenRequest.profileURL || null
    })

    firebase.database().ref('messages/' +currentUserId+"/"+this.baseContext.state.chosenRequest.clientId).push({
      message: this.baseContext.state.newMessage,
      timestamp: new Date().getTime() - new Date().getTimezoneOffset(),
      fromId:currentUserId,
      toId:this.baseContext.state.chosenRequest.clientId
    }).then(async()=>{
      await this.baseContext.setState({sendMessageLoading:false, sendMessagePanelOpen:false, newMessage:""});
      const currentUserId = firebase.auth().currentUser.uid;

      let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
          return idToken;
      });


      var functions = firebase.app().functions('europe-west1');

      functions.httpsCallable('sendMessageEmail')({
        token:token,
        receiverId:this.baseContext.state.chosenRequest.clientId,
        senderName:this.baseContext.state.accountStatus.name
      });
      this.baseContext.showAlert("Message sent!");
    }).catch(async(error)=>{
      await this.baseContext.setState({sendMessageLoading:false, sendMessagePanelOpen:false, newMessage:""});
      this.baseContext.showAlert("Error: "+error);
    })
  }

  //Open Create Booking Panel
  openCreateBookingPanel = (request)=>{

    let requestPreferredDate = new Date(request.dateTime + (new Date().getTimezoneOffset() * 60 * 1000));
    let requestPrefferedHours = (requestPreferredDate.getHours().toString().length == 1)? "0"+requestPreferredDate.getHours().toString():requestPreferredDate.getHours().toString();
    let requestPrefferedMinutes = (requestPreferredDate.getMinutes().toString().length == 1)? "0"+requestPreferredDate.getMinutes().toString():requestPreferredDate.getMinutes().toString();

    requestPreferredDate.setHours(0);
    requestPreferredDate.setMinutes(0);
    this.baseContext.setState({createBookingPanelOpen:true, createBookingLoading:false, chosenRequest:request, requestLink:'', requestDescription:'', chosenDate: requestPreferredDate, chosenHours: requestPrefferedHours , chosenMinutes: requestPrefferedMinutes});
  }

  //Close Create Booking Panel
  closeCreateBookingPanel = ()=>{
    this.baseContext.setState({createBookingPanelOpen:false, createBookingLoading:false});
  }

  //Change chosen session date
  changeChosenDate = (date)=>{
    this.baseContext.setState({chosenDate:date});
  }

  //Load coach's bookings
  loadBookings = async()=>{
    await this.baseContext.setState({loading:true});

    let requests = [];

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("bookings").where("coachId","==",currentUserId).get().then((querySnapshot) => {

      querySnapshot.forEach((doc) => {
          let bookingData = doc.data();
          bookingData['id'] = doc.id;

          requests.push(bookingData);
        });

      this.baseContext.setState({loading:false, requests:requests});
      console.log(requests);
    })


  }

  //Convert a timestamp to local time in string format
  convertToLocalTime = (timestamp)=>{

    let localTime = (timestamp!=null) ? ((new Date().getTimezoneOffset() * 60 * 1000) + timestamp) : 1;

    let localDateTime = new Date(localTime);
    let localHours = localDateTime.getHours().toString().length == 2 ? localDateTime.getHours():"0"+localDateTime.getHours();
    let localMinutes = localDateTime.getMinutes().toString().length == 2 ? localDateTime.getMinutes():"0"+localDateTime.getMinutes();
    let months = Language.English.Months;
    return localDateTime.getDate()+"/"+(months[localDateTime.getMonth()])+"/"+localDateTime.getFullYear()+" "+localHours+":"+localMinutes;
  }

  //Get user's current time zone
  getTimeZone = ()=>{
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }


  //Attempt a booking confirmation
  createBookingRequest = async()=>{
    let chosenDate = this.baseContext.state.chosenDate;
    let chosenHours  = this.baseContext.state.chosenHours;
    let chosenMinutes  = this.baseContext.state.chosenMinutes;


    chosenDate = new Date(chosenDate);
    chosenDate.setHours(parseInt(chosenHours));
    chosenDate.setMinutes(parseInt(chosenMinutes));

    let offset = new Date().getTimezoneOffset() * 60 * 1000;
    let chosenTime = chosenDate.getTime();

    if(chosenTime <= new Date().getTime()){
      this.baseContext.showAlert("Please choose a future date!");
      return;
    }

    chosenTime -= offset;


    console.log(chosenTime);

    await this.baseContext.setState({createBookingLoading:true});

    firebase.firestore().collection("bookings").doc(this.baseContext.state.chosenRequest.id).set({
      dateTimeSet:true,
      dateTimeUTC:chosenTime,
      opened:true,
      description:this.baseContext.state.requestDescription,
      callLink:this.baseContext.state.requestLink
    },{merge:true}).then(async()=>{



      const currentUserId = firebase.auth().currentUser.uid;

      let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
          return idToken;
      });

      var functions = firebase.app().functions('europe-west1');


      await functions.httpsCallable('sendBookingConfirmationEmail')({
        token:token,
        receiverId:this.baseContext.state.chosenRequest.clientId,
        senderName:this.baseContext.state.accountStatus.name
      });

      this.baseContext.showAlert("Successfully Booked!");

      window.location.reload();


    }).catch(async(error)=>{
      await this.baseContext.setState({createBookingLoading:false});
      this.closeCreateBookingPanel();
      this.baseContext.showAlert("Error: "+error);
    })


  }


}

export default CoachLogic;
