import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Chart } from 'react-charts';
import { withAlert } from 'react-alert';

import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import binIcon from '../../../assets/icons/bin.png';
import surveyTriangle2 from '../../../assets/icons/surveys-triangle-2.png';
import plusTriangle from '../../../assets/icons/plus-triangle.png';
import downloadTriangle from '../../../assets/icons/download-triangle.png';

class CoachDashboard extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      newCoursePanelOpen:false,
      newUserName:"",
      newUserEmail:"",
      coursesList:[],
      chosenCourseModulesList:[],
      editPanelLoading:true,
      newLessonFiles:[],
      chosenModuleLessons:[],
      isBasicUser:props.accountStatus.isBasicUser,
      accountStatus:props.accountStatus,
      mainData:[],

      axes:[
        { primary: true, position: 'bottom', type: 'ordinal', show:false },
        { position: 'left', type: 'linear', stacked: false,hardMin:0, hardMax:10 }
      ],
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);
    this.logic.loadCourses();

    this.filesInput = React.createRef();
    this.EditLessonFilesInput = React.createRef();
  }

  getSeriesStyle = (series) => {
    // Based off my chart bars
    const colorPalette = {
      Engagement: "#00DEDC",
      Physical:"#fc0050",
      Relationships:"#9c00f0",
      Achievement:"#00de51",
      Meaning:'#edd900',
      Happiness:'#4b29e3',
      'Overall Wellbeing':'#f00000'
    };


    return {
      fill: colorPalette[series.label],
      stroke: colorPalette[series.label]
    };
  }

  series = () => {
      return( {
        type: 'bar'
      }
    )

    }


  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{
      return(
        <div className="coach-dashboard-home" style={{overflowY:'scroll'}}>
          {this.renderNewCoursePanel()}
          {this.renderNewCoachingUserPanel()}
          {this.renderNewCheckinUserPanel()}
          {this.renderEditEventPanel()}
          {this.renderHoogaSurveys()}
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.CompanyAdmin.CompanyAdminHeader} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="company-admin-content" style={{overflowY:'scroll'}}>

            <div className="company-admin-courses-panel">
              <div className="company-admin-panel-header">
                <div className="company-admin-panel-text-container">
                  <div className="company-admin-panel-text">
                    {Language.English.CompanyAdmin.BasicUsers}
                  </div>
                </div>
                <div className="courses-panel-button-container">
                  <div className="courses-panel-new-button" onClick={this.logic.openNewCoursePanel}>
                    <img src={plusIcon} className="courses-button-icon" />
                    {Language.English.Dashboard.NewButton}
                  </div>
                </div>
              </div>
              <div className="courses-panel-content">

              <div className="company-admin-display">
                <div className="company-admin-display-left" style={{textAlign:'left'}}>
                  <Toggle
                    id='live-status'
                    defaultChecked={this.state.isBasicUser}
                    onChange={this.logic.updateAdminBasicUserStatus}
                    className="live-toggle" />
                  <label className="company-admin-option-label" htmlFor='live-status'>{Language.English.Dashboard.CompanyAdminOption}</label>
                </div>
              </div>
                {
                  this.state.coursesList.map((course, index)=>{
                    return(
                      <div className="course-display">
                        <div className="course-display-left">
                          <div className="course-display-name">{course.name}</div>
                        </div>

                        <div className="course-display-right">
                          <div className="remove-user-button" onClick={()=>{this.logic.editUser(course)}}>
                            <img src={binIcon} className="remove-user-icon" />
                            {Language.English.CompanyAdmin.RemoveUser}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>

            </div>

            <div className="company-admin-courses-panel">

              <div className="company-admin-panel-header">
                  <div className="company-admin-panel-text-container">
                    <div className="company-admin-panel-text">
                      {Language.English.CompanyAdmin.CheckInUsers}
                    </div>
                  </div>

                <div className="courses-panel-button-container">
                  <div className="courses-panel-new-button" onClick={this.logic.openNewCheckinUserPanel}>
                    <img src={plusIcon} className="courses-button-icon" />
                    {Language.English.Dashboard.NewButton}
                  </div>
                </div>
              </div>

              <div className="courses-panel-content">
                {
                  this.renderAdminIfCheckinUser()
                }

                {
                  this.state.coursesList.map((course, index)=>{

                    if(course.isCheckinUser){
                      return(
                        <div className="course-display">
                          <div className="course-display-left">
                            <div className="course-display-name">{course.name}</div>
                          </div>

                          <div className="course-display-right">
                            <div className="remove-user-button" onClick={()=>{this.logic.removeCheckinUser(course.id)}}>
                              <img src={binIcon} className="remove-user-icon" />
                              {Language.English.CompanyAdmin.RemoveUser}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })

                }
              </div>

            </div>

          </div>

          <br/><br/>

          <div className="company-admin-content" style={{overflowY:'scroll'}}>

            <div className="company-admin-courses-panel">

              <div className="company-admin-panel-header">
                  <div className="company-admin-panel-text-container">
                    <div className="company-admin-panel-text">
                      {Language.English.CompanyAdmin.CoachingUsers}
                    </div>
                  </div>

                <div className="courses-panel-button-container">
                  <div className="courses-panel-new-button" onClick={this.logic.openNewCoachingUserPanel}>
                    <img src={plusIcon} className="courses-button-icon" />
                    {Language.English.Dashboard.NewButton}
                  </div>
                </div>
              </div>

              <div className="courses-panel-content">
              {
                this.renderAdminIfCourseUser()
              }

              {
                this.state.coursesList.map((course, index)=>{

                  if(course.isCoachingUser){
                    return(
                      <div className="course-display">
                        <div className="course-display-left">
                          <div className="course-display-name">{course.name}</div>
                        </div>

                        <div className="course-display-right">
                          <div className="remove-user-button" onClick={()=>{this.logic.removeCoachingUser(course.id)}}>
                            <img src={binIcon} className="remove-user-icon" />
                            {Language.English.CompanyAdmin.RemoveUser}
                          </div>
                        </div>
                      </div>
                    )
                  }
                })

              }
              </div>

            </div>


            <div className="company-admin-courses-panel">

              <div className="company-admin-panel-header">
                  <div className="company-admin-panel-text-container">
                    <div className="company-admin-panel-text">
                      {Language.English.CompanyAdmin.UserSurveyScores}
                    </div>
                  </div>

              </div>

              <div className="courses-panel-content">
                <div className="user-survey-score-button" id="hooga-survey-button" onClick={this.logic.openHoogaScores}>
                  <img src={surveyTriangle2} className="hooga-survey-scores-icon" />
                  {Language.English.CompanyAdmin.HoogaSurveyScores}
                </div>

              </div>

            </div>

          </div>

        </div>
      );
    }
  }

  renderHoogaSurveys = ()=>{
    if(!this.state.surveyPanelOpen) return;

    return(
      <div className="hooga-results-panel">
        <img src={xButton} className="hooga-results-close-button" onClick={this.logic.closeSurveysPanel}/>
        <div className="hooga-results-form">
          {this.renderHoogaSurveyDetails()}
        </div>
      </div>
    )
  }

  renderHoogaSurveyDetails = ()=>{
    if(this.state.loadingSurveys){
      return(
        <div style={{textAlign:'center'}}>
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      )
    }

    return(
      <div>
        <div className="hooga-surveys-header">
          <div className="hooga-surveys-button-container">
            <div className="hooga-surveys-header-text">{Language.English.CompanyAdmin.HoogaSurveysHeader}</div>

            <div className="hooga-download-button" onClick={this.logic.downloadCsvFile}>
              <img className="hooga-download-button-icon" src={downloadTriangle} />
              {Language.English.CompanyAdmin.DownloadButton}
            </div>

          </div>
        </div>
            <table className="hooga-admin-results">

            <tr className="hooga-tr">
              <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderName}</td>
              <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderEngagement}</td>
              <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderRelationship}</td>
              <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderPhysical}</td>
              <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderAchievement}</td>
              <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderMeaning}</td>
              <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderHappiness}</td>
              <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderOverall}</td>
            </tr>



            {
              this.state.hoogaData.map((userData, index)=>{
                return(
                  <tr className="hooga-tr">
                    <td className="hooga-result-data">{userData.name}</td>
                    <td style={{backgroundColor:this.logic.getHoogaDataColour(userData.engagementMean)}} className="hooga-result-data">{userData.engagementMean}</td>
                    <td style={{backgroundColor:this.logic.getHoogaDataColour(userData.relationshipsMean)}} className="hooga-result-data">{userData.relationshipsMean}</td>
                    <td style={{backgroundColor:this.logic.getHoogaDataColour(userData.physicalMean)}} className="hooga-result-data">{userData.physicalMean}</td>
                    <td style={{backgroundColor:this.logic.getHoogaDataColour(userData.achievementMean)}} className="hooga-result-data">{userData.achievementMean}</td>
                    <td style={{backgroundColor:this.logic.getHoogaDataColour(userData.meaningMean)}} className="hooga-result-data">{userData.meaningMean}</td>
                    <td style={{backgroundColor:this.logic.getHoogaDataColour(userData.happinessMean)}} className="hooga-result-data">{userData.happinessMean}</td>
                    <td style={{backgroundColor:this.logic.getHoogaDataColour(userData.overallMean)}} className="hooga-result-data">{userData.overallMean}</td>
                  </tr>
                )
              })
            }


            </table>
      </div>
    )
  }

  renderAdminIfCourseUser = ()=>{
    if(this.state.accountStatus.isCoachingUser){
      return(
        <div className="company-admin-display">
          <div className="company-admin-display-left">
            <div className="company-admin-display-name">{Language.English.CompanyAdmin.Admin}</div>
          </div>

          <div className="company-admin-display-right">
            <div className="remove-user-button" onClick={()=>{this.logic.removeCoachingUser(firebase.auth().currentUser.uid)}}>
              <img src={binIcon} className="remove-user-icon" />
              {Language.English.CompanyAdmin.RemoveUser}
            </div>
          </div>
        </div>
      )
    }
  }

  renderAdminIfCheckinUser = ()=>{
    if(this.state.accountStatus.isCheckinUser){
      return(
        <div className="company-admin-display">
          <div className="company-admin-display-left">
            <div className="company-admin-display-name">{Language.English.CompanyAdmin.Admin}</div>
          </div>

          <div className="company-admin-display-right">
            <div className="remove-user-button" onClick={()=>{this.logic.removeCheckinUser(firebase.auth().currentUser.uid)}}>
              <img src={binIcon} className="remove-user-icon" />
              {Language.English.CompanyAdmin.RemoveUser}
            </div>
          </div>
        </div>
      )
    }
  }

  renderNewCoursePanel = ()=>{
    if(this.state.newCoursePanelOpen){

      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-user-close-button" onClick={this.logic.closeNewCoursePanel}/>
          <div className="new-user-form">
            <input className="new-user-name-input" type="text" placeholder="New User Name" value={this.state.newCourseName} onChange={(event)=>{this.setState({newUserName:event.target.value})}}/>
            <br/><br/>
            <input className="new-user-name-input" type="text" placeholder="New User Email" value={this.state.newCourseName} onChange={(event)=>{this.setState({newUserEmail:event.target.value})}}/>
            <br/>
            <div className="new-user-submit-button" onClick={this.logic.createNewUser}>
              <img className="new-user-plus-icon" src={plusTriangle} />
              {Language.English.Dashboard.AddButton}
            </div>
          </div>
        </div>
      )

    }
  }

  renderNewCoachingUserPanel = ()=>{
    if(!this.state.newCoachingUserPanelOpen) return;

    return(
      <div className="new-user-panel">
        <img src={xButton} className="new-user-close-button" onClick={this.logic.closeNewCoachingUserPanel}/>
        <div className="new-user-form">
          <div className="coaching-users-title">
            {Language.English.CompanyAdmin.ChooseCoachingUser}
          </div>

          <select className="coaching-users-dropdown" value={this.state.chosenNewCoachingUserId} onChange={(event)=>{this.setState({chosenNewCoachingUserId:event.target.value})}}>

            {this.renderCompanyAdminOption()}

            {
              this.state.coursesList.map((course, index)=>{

                if(!course.isCoachingUser){
                  return(
                    <option key={course.email} value={course.id}>{course.name}</option>
                  )
                }

              })
            }
          </select>

          <br/>

          <div className="new-user-submit-button" onClick={this.logic.updateNewUserCoachingStatus}>
            <img className="new-user-plus-icon" src={plusTriangle} />
            {Language.English.CompanyAdmin.AddUser}
          </div>

        </div>
      </div>
    )
  }

  renderNewCheckinUserPanel = ()=>{
    if(!this.state.newCheckinUserPanelOpen) return;

    return(
      <div className="new-user-panel">
        <img src={xButton} className="new-user-close-button" onClick={this.logic.closeNewCheckinUserPanel}/>
        <div className="new-user-form">
          <div className="coaching-users-title">
            {Language.English.CompanyAdmin.ChooseCoachingUser}
          </div>

          <select className="coaching-users-dropdown" value={this.state.chosenNewCheckinUserId} onChange={(event)=>{this.setState({chosenNewCheckinUserId:event.target.value})}}>

            {this.renderCompanyAdminCheckinOption()}

            {
              this.state.coursesList.map((course, index)=>{

                if(!course.isCheckinUser){
                  return(
                    <option key={course.email} value={course.id}>{course.name}</option>
                  )
                }

              })
            }
          </select>

          <br/>

          <div className="new-user-submit-button" onClick={this.logic.updateNewUserCheckinStatus}>
            <img className="new-user-plus-icon" src={plusTriangle} />
            {Language.English.CompanyAdmin.AddUser}
          </div>

        </div>
      </div>
    )
  }

  renderCompanyAdminCheckinOption = ()=>{
    if(this.state.accountStatus.isCheckinUser || !this.state.isBasicUser) return;
    return(
      <option key={Language.English.CompanyAdmin.Admin} value={firebase.auth().currentUser.uid}>{Language.English.CompanyAdmin.Admin}</option>
    )
  }


  renderCompanyAdminOption = ()=>{
    if(this.state.accountStatus.isCoachingUser || !this.state.isBasicUser) return;
    return(
      <option key={Language.English.CompanyAdmin.Admin} value={firebase.auth().currentUser.uid}>{Language.English.CompanyAdmin.Admin}</option>
    )
  }

  renderEditEventPanel = ()=>{
    if(this.state.editEventPanelOpen){

      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeEditEventPanel}/>
          <div className="edit-course-form">
            <div className="edit-course-header">
              {this.state.chosenCourseName}
                <Toggle
                  id='live-status'
                  defaultChecked={this.state.chosenCourseIsLive}
                  onChange={this.logic.liveToggleChange}
                  className="live-toggle" />
                <label className="live-toggle-label" htmlFor='live-status'>{Language.English.Dashboard.Live}</label>

                <Toggle
                  id='live-status'
                  defaultChecked={this.state.chosenCourseIsPublic}
                  onChange={this.logic.publicToggleChange}
                  className="public-toggle" />
                <label className="live-toggle-label" htmlFor='live-status'>{Language.English.Dashboard.Public}</label>
            </div>
            <div className="edit-course-content">
              {this.renderEditCourseContent()}
            </div>
          </div>
        </div>
      )

    }
  }

  renderEditCourseContent = ()=>{
    if(this.state.editPanelLoading){ //Loading Resources
      return(
        <div className="edit-panel-loader">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
          />
        </div>
      )
    }else if(this.state.addModulePanelOpen){ //Adding New Module
      return(
        <div className="new-module-form">
        <input className="new-course-name-input" type="text" placeholder="New Module Name" value={this.state.newModuleName} onChange={(event)=>{this.setState({newModuleName:event.target.value})}}/>
        <br/>
        <div className="new-module-cancel-button" onClick={this.logic.closeAddNewModulePanel}>{Language.English.Dashboard.CancelButton}</div>
        <div className="new-module-submit-button" onClick={this.logic.addEventModule}>{Language.English.Dashboard.AddButton}</div>
        </div>
      )
    }else if(this.state.editLessonPanelOpen){ //Editting an existing lesson

      return(
        <div>
          <div className="modules-header">
            <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeEditLessonPanel} />
            {Language.English.Dashboard.EditLessonHeader}
          </div>

          <div className="new-lesson-form">
          <input className="new-course-name-input" type="text" placeholder="Lesson Name" value={this.state.EditLessonName} onChange={(event)=>{this.setState({EditLessonName:event.target.value})}}/>
          <br/>
          <textarea className="new-lesson-description-input" placeholder="Lesson Description" value={this.state.EditLessonDescription} onChange={(event)=>{this.setState({EditLessonDescription:event.target.value})}} />
          <br/>
          <div className="files-list">
          {Language.English.Dashboard.ExistingFilesHeader}:<br/>
            {
              this.state.EditLessonExistingFiles.map((file,index)=>{
                return(
                  <div div className="file-name-display">
                    {index+1}. {file.name}
                    <img src={xButton} className="cancel-lesson-file" onClick={()=>{this.logic.removeExistingFile(index)}}/>
                  </div>
                )
              })
            }
          </div>
          <br/>
          <div className="files-list">
          {Language.English.Dashboard.NewFilesHeader}:<br/>
            {
              this.state.EditLessonNewFiles.map((file,index)=>{
                return(
                  <div div className="file-name-display">
                    {index+1}. {file.name}
                    <img src={xButton} className="cancel-lesson-file" onClick={()=>{this.logic.removeEditLessonFile(index)}}/>
                  </div>
                )
              })
            }
          </div>
          <br/>
          <input type="file" onChange={this.logic.editLessonAddNewFile} ref={this.EditLessonFilesInput} className="file-input" /><br/>
          <div className="new-module-submit-button" onClick={this.logic.saveLessonUpdate}>{Language.English.Dashboard.SaveButton}</div>
          </div>


        </div>
      )

    }else if(this.state.newLessonPanelOpen){ //Adding a Lesson
      return(
        <div>
          <div className="modules-header">
            <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeAddLessonPanel} />
            {Language.English.Dashboard.NewLessonHeader}
          </div>

          <div className="new-lesson-form">
          <input className="new-course-name-input" type="text" placeholder="New Lesson Name" value={this.state.newLessonName} onChange={(event)=>{this.setState({newLessonName:event.target.value})}}/>
          <br/>
          <textarea className="new-lesson-description-input" placeholder="New Lesson Description" value={this.state.newLessonDescription} onChange={(event)=>{this.setState({newLessonDescription:event.target.value})}} />
          <br/>
          <div className="files-list">
          Files:<br/>
            {
              this.state.newLessonFiles.map((file,index)=>{
                return(
                  <div div className="file-name-display">
                    {index+1}. {file.name}
                    <img src={xButton} className="cancel-lesson-file" onClick={()=>{this.logic.removeNewFile(index)}}/>
                  </div>
                )
              })
            }
          </div>
          <br/>
          <input type="file" onChange={this.logic.addNewFile} ref={this.filesInput} className="file-input" /><br/>
          <div className="new-module-submit-button" onClick={this.logic.createLesson}>{Language.English.Dashboard.AddButton}</div>
          </div>


        </div>
      )
    }else if(this.state.lessonsPanelOpen){ //Viewing A Module's Lessons
      return(
        <div>
          <div className="modules-header">
            <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeModuleLessons} />
            {Language.English.Dashboard.LessonsHeader}
            <div className="add-modules-button-container">
              <div className="add-modules-button" onClick={this.logic.openAddLessonPanel}>
                <img src={greyPlusIcon} className="modules-button-icon" />
                {Language.English.Dashboard.AddLessons}
              </div>

            </div>
           </div>
           {
             this.state.chosenModuleLessons.map((lesson,index)=>{
               return(
                 <div className="module-row" onClick={()=>{this.logic.openEditLessonPanel(index,lesson.name,lesson.description,lesson.files)}} >
                   {index+1}. {lesson.name}
                 </div>
               )
             })
           }
        </div>
      )
    }else{ //Default: Display a Course's Modules
      return(
        <div>
          <div className="modules-header">
            {Language.English.Dashboard.ModulesHeader}
            <div className="add-modules-button-container">
              <div className="add-modules-button" onClick={this.logic.openAddNewModulePanel}>
                <img src={greyPlusIcon} className="modules-button-icon" />
                {Language.English.Dashboard.AddModules}
              </div>

            </div>
          </div>

          <div className="modules-content">
            {
              this.state.chosenCourseModulesList.map((module,index)=>{
                return(
                  <div className="module-row" onClick={()=>{this.logic.openModuleLessons(module.id, module.lessons || [])}}>
                    {index+1}. {module.name}
                  </div>
                )
              })
            }
          </div>
        </div>
      )
    }
  }

}

export default withAlert()(CoachDashboard);
