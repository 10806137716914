import React, {Children} from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { withAlert } from 'react-alert';

import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import whitePlusIcon from '../../../assets/white-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';

import coachingEventTriangle from '../../../assets/icons/coaching-event-triangle.png';
import chemistryEventTrianlge from '../../../assets/icons/chemistry-event-triangle.png';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const ColoredDateCellWrapper = ({children, value}) =>
    React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            backgroundColor: 'white',
        },
    });


class CoachCalendar extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      addAvailabilityPanelOpen:false,
      events:[]
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);
    this.logic.loadEvents();
    console.log('loads');
  }

  renderAddAvailabilityPanel = ()=>{
    if(!this.state.addAvailabilityPanelOpen) return;

    return(
      <div className="add-availability-panel">
        <img src={xButton} className="add-availability-close-button" onClick={this.logic.closeAddAvailabilityPanel} />
        <div className="add-availability-form">
          <div className="add-availability-form-header">Set your Session Availability</div>
          <div className="add-availability-timezone">Your Timezone: {this.state.timeZone}</div>
          <div className="add-availability-chosen-date">Chosen Date: {this.state.chosenDisplayDate}</div>
        </div>
      </div>
    )
  }


  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{

      const localizer = momentLocalizer(moment);

      return(
        <div className="coach-calendar-home">
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Calendar.MyCalendar} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="coach-calendar-dashboard-content">

            <div className="calendar-row">

              <div className="events-list-column">

                {
                  this.state.events.map((event, index)=>{
                    if(event.start > (new Date().getTime())){
                      return(
                        <div className="coach-event-card">


                          <img src={(event.sessionType == 'coaching')?coachingEventTriangle:chemistryEventTrianlge} className="coach-event-image" />
                          <br/>

                          <div className="coach-event-card-name">
                            {event.title}
                          </div>

                          <div className="coach-event-card-times">
                            {Language.English.Calendar.EventDate}{this.logic.getDateString(event.start)}<br/>
                            {Language.English.Calendar.EventStart}{this.logic.getHoursAndMinutes(event.start)}<br/>
                            {Language.English.Calendar.EventEnd}{this.logic.getHoursAndMinutes(event.end)}
                          </div>

                          <div className="coach-event-card-link">
                            {Language.English.Calendar.EventLink}{event.callLink}
                          </div>

                        </div>
                      )
                    }
                  })
                }

              </div>

              <Calendar
                  localizer={localizer}
                  events={this.state.events}
                  startAccessor="start"
                  endAccessor="end"
                  style={{flex:4, height:'80vh',
                  marginTop:'4vh', margin:'2vw',marginBottom:'0vh', backgroundColor:'white',
                  borderRadius:'8px', fontFamily:'Comfortaa, cursive',
                  padding:'2vw', color:'#787878'}}
                 selectable={false}
                 onSelectEvent={(event, e)=>{alert(event.title+'\n'+'Time: '+this.logic.getHoursAndMinutes(event.start)+'\n Description: '+event.description+'\n Call Link: '+event.callLink)}}
                 views={['month']}
                 components={{
                   dateCellWrapper:ColoredDateCellWrapper,
                   month: {
                     dateHeader: ({ date, label }) => {
                          let highlightDate = date.getMonth() != new Date().getMonth();
                          return (
                            <div style={highlightDate ? { display: "none" } : null}>{label}</div>
                          );
                        }
                   },
                  }}
                />

            </div>

          </div>
          {this.renderAddAvailabilityPanel()}
        </div>
      );
    }
  }




}

export default withAlert()(CoachCalendar);
