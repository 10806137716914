import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import { withAlert } from 'react-alert';

//Services & Local Logic
import Language from '../../services/Language';
import Logic from './logic';

//Stripe Modules
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// Assets
import inkelLogo from '../../assets/logo.svg';
import inkelLogoDark from '../../assets/logo-dark.svg';
import registerIcon from '../../assets/lock-icon.png';
import greenCurve from '../../assets/green-curve.png';
import tickIcon from '../../assets/tick.png';
import individualIcon from '../../assets/icons/account.png';
import companyIcon from '../../assets/icons/domain.png';

// Animations
import Fade from 'react-reveal/Fade';


class Register extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }


  constructor(props){
    super(props);

    this.state = {choosingAccountType:false,accountType:'individual',attemptingRegister:false,registered:false,loading:false,emailValue:'',passwordValue:'', passwordConfirmationValue:'', nameValue:'', companyNameValue:''};

    if(!firebase.apps.length){ firebase.initializeApp(ApiKeys.FirebaseConfig); }

    this.logic = new Logic(this);


  }

  render(){
    return(
      <div>
        {this.renderPage()}
      </div>
    );
  }


  renderPage = ()=>{
    if(this.state.attemptingRegister){
      return(
        <div className="page-loading">
          <ClipLoader
            size={160}
            color={"#0056e0"}
            loading={true}
          />
        </div>
      );
    }else{
      return(
      <div>

          <div className="register-welcome-image-container">
            <img src={inkelLogo} className="register-inkel-logo" />

            <div className="register-welcome-content">
              <div className="register-welcome-header">
                {Language.English.Register.RegisterWelcomeHeader}
              </div>

              <div className="register-welcome-text">
                {Language.English.Register.RegisterWelcomeText}
              </div>
            </div>

          </div>

          <img src={inkelLogoDark} className="register-mobile-inkel-logo" />

        <Fade delay={400}>

          <div className="register-panel-container">

              <div className="register-panel">

                <div className="register-header">{Language.English.Register.RegisterHeader}</div>

                <div className="register-content">
                  {this.renderRegisterPanel()}
                </div>

              </div>

          </div>

        </Fade>

        <img src={greenCurve} className="green-curve-image" />

      </div>

      );
    }
  }

  renderRegisterPanel = ()=>{

    if(this.state.loading){
      return(
        <div className="loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={this.state.loading}
          />
        </div>
      );
    }else if(this.state.choosingAccountType){
      return(
        <div className="choice-container">
          <div className="company-choice" onClick={()=>{this.setState({choosingAccountType:false, accountType:"company"})}}>
            <img className="choice-icon" src={companyIcon}/>
            <div className="choice-text">{Language.English.Register.CompanyChoice}</div>
          </div>

          <div className="individual-choice" onClick={()=>{this.setState({choosingAccountType:false, accountType:"individual"})}}>
            <img className="choice-icon" src={individualIcon}/>
            <div className="choice-text">{Language.English.Register.IndividualChoice}</div>
          </div>

        </div>
      )
    }else if(this.state.registered){
      return(
        <div className="loader-holder">
          <img src={tickIcon} className="register-success-icon" />
          <div className="register-success-text">Registration successful!</div>
          <Link to="/">
            <div className="register-success-link">{Language.English.Register.RegisterSuccessLoginLink}</div>
          </Link>
        </div>
      )
    }else if(this.state.accountType=="company"){
      return(
        <div>

          <div className="error-message">{this.state.error}</div>


          <input type="text" id="name-input" className="register-input" placeholder={Language.English.Register.namePlaceholder} value={this.state.nameValue} onChange={this.logic.handleNameChange}></input>
          <input type="text" id="company-name-input" className="register-input" placeholder={Language.English.Register.companyNamePlaceholder} value={this.state.companyNameValue} onChange={this.logic.handleCompanyNameChange}></input>
          <input type="email" id="email-input" className="register-input" placeholder={Language.English.Register.emailPlaceholder} value={this.state.emailValue} onChange={this.logic.handleEmailChange}></input>
          <input type="password" id="password-input" className="register-input" placeholder={Language.English.Register.passwordPlaceholder} value={this.state.passwordValue} onChange={this.logic.handlePasswordChange}></input>
          <input type="password" id="password-confirmation-input" className="register-input" placeholder={Language.English.Register.passwordConfirmationPlaceholder} value={this.state.passwordConfirmationValue} onChange={this.logic.handlePasswordConfirmationChange}></input>
            <br/>


          <a href="/">
            <div className="signup-link">{Language.English.Register.LoginLink}</div>
          </a>

        </div>
      )
    }else if(this.state.accountType=="individual"){

      return(
        <div>

          <div className="error-message">{this.state.error}</div>

          <div className="notice-header">
            Please note: This is the registration for individuals. To register an organization, you must contact info@inkel.health and one of our admins will be in touch.
          </div>


          <input type="text" id="name-input" className="register-input" placeholder={Language.English.Register.namePlaceholder} value={this.state.nameValue} onChange={this.logic.handleNameChange}></input>
          <input type="email" id="email-input" className="register-input" placeholder={Language.English.Register.emailPlaceholder} value={this.state.emailValue} onChange={this.logic.handleEmailChange}></input>
          <input type="password" id="password-input" className="register-input" placeholder={Language.English.Register.passwordPlaceholder} value={this.state.passwordValue} onChange={this.logic.handlePasswordChange}></input>
          <input type="password" id="password-confirmation-input" className="register-input" placeholder={Language.English.Register.passwordConfirmationPlaceholder} value={this.state.passwordConfirmationValue} onChange={this.logic.handlePasswordConfirmationChange}></input>
          <form onSubmit={this.logic.attemptRegistration}>
            <div className="error-message">{this.state.error}</div>
            <button className="register-button">
              <img className="register-button-icon" src={registerIcon} />
              <div className="register-button-text">{Language.English.Register.RegisterButtonText}</div>
            </button>
          </form>
            <br/>


          <a href="/">
            <div className="signup-link">{Language.English.Register.LoginLink}</div>
          </a>

        </div>
      );
    }

  }



}

export default withAlert()(Register);
