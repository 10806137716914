import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class CoachLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Open Panel to create course
  openNewCoursePanel = ()=>{
    this.baseContext.setState({newCoursePanelOpen: true});
  }

  //Close new course panel (Not created)
  closeNewCoursePanel = ()=>{
    this.baseContext.setState({newCoursePanelOpen: false});
  }

  //Download Hooga CSV File
  downloadCsvFile = ()=>{

    const rows = [['Name','Engagement','Relationships','Physical','Achievement','Meaning','Happiness','Overall']];

    for(let index = 0;index < this.baseContext.state.surveyData.length;index++){
      let row = this.baseContext.state.surveyData[index];
      rows.push([row.name,row.engagementMean,row.relationshipsMean,row.physicalMean,row.achievementMean,row.meaningMean,row.happinessMean,row.overallMean]);
    }

    let csvContent = "data:text/csv;charset=utf-8,"
        + rows.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "hooga_data.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  //Load Surveys
  loadSurveys = async()=>{

    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    var functions = firebase.app().functions('europe-west1');

    functions.httpsCallable('loadGroupSurveys')({
      token:token
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false, mySurveys:result.data.surveys});
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
        this.baseContext.showAlert(result.data.errorMessage);
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });

  }

  convertToString = (time)=>{
    let date = new Date(time);
    return date.getDate()+"/"+(((date.getMonth() + 1).toString().length == 1)? "0"+(date.getMonth() +1): (date.getMonth() + 1))+"/"+date.getFullYear();
  }

  openSurvey = async(survey,index)=>{

    const currentUserId = firebase.auth().currentUser.uid;

    this.baseContext.setState({
      openSurveyId:survey.id,
      surveyPanelOpen:true,
      surveyData:survey.surveyData || [],
      userId:currentUserId
    })
  }

  createSurvey = async()=>{
    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    var functions = firebase.app().functions('europe-west1');

    functions.httpsCallable('createGroupSurvey')({
      token:token,
      time: new Date().getTime(),
      organisationName:this.baseContext.state.accountStatus.companyName
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false});
        window.location.reload();
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
        this.baseContext.showAlert(result.data.errorMessage);
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });

  }



  //Get Colour of Hooga Survey Data
  getHoogaDataColour = (score)=>{

    if(score < 2){
      return '#d40023';
    }else if(score < 4){
      return '#fa8c9e';
    }else if(score < 8){
      return '#76e3a2';
    }

    return '#00d44e';
  }

  //Open New Panel for adding checkin users
  openNewCheckinUserPanel = ()=>{
    let chosenNewCheckinUser = false;
    let chosenNewCheckinUserId = null;

    if((!this.baseContext.state.accountStatus.isCheckinUser) && this.baseContext.state.isBasicUser){
      chosenNewCheckinUserId = firebase.auth().currentUser.uid;
    }else{
      for(let index = 0;index < this.baseContext.state.coursesList.length;index++){
        if((!this.baseContext.state.coursesList[index].isCheckinUser) && (this.baseContext.state.coursesList[index].isBasicUser || this.baseContext.state.coursesList[index].authority == 'employee')){
          chosenNewCheckinUserId = this.baseContext.state.coursesList[index].id;
          break;
        }
      }
    }

    if(!chosenNewCheckinUserId) {
      this.baseContext.showAlert("No available basic users to add for check-ins!");
      return;
    }



    this.baseContext.setState({newCheckinUserPanelOpen:true, chosenNewCheckinUserId:chosenNewCheckinUserId});
  }

  //Open Panel for adding coaching users
  openNewCoachingUserPanel = ()=>{

    let chosenNewCoachingUser = false;
    let chosenNewCoachingUserId = null;

    if((!this.baseContext.state.accountStatus.isCoachingUser) && this.baseContext.state.isBasicUser){
      chosenNewCoachingUserId = firebase.auth().currentUser.uid;
    }else{
      for(let index = 0;index < this.baseContext.state.coursesList.length;index++){
        if((!this.baseContext.state.coursesList[index].isCoachingUser) && (this.baseContext.state.coursesList[index].isBasicUser || this.baseContext.state.coursesList[index].authority == 'employee')){
          chosenNewCoachingUserId = this.baseContext.state.coursesList[index].id;
          break;
        }
      }
    }

    if(!chosenNewCoachingUserId) {
      this.baseContext.showAlert("No available basic users to add to coaching!");
      return;
    }



    this.baseContext.setState({newCoachingUserPanelOpen:true, chosenNewCoachingUserId:chosenNewCoachingUserId});
  }

  //Close Panel for adding checkin users
  closeNewCheckinUserPanel = ()=>{
    this.baseContext.setState({newCheckinUserPanelOpen:false});
  }

  //Close Panel for adding coaching users
  closeNewCoachingUserPanel = ()=>{
    this.baseContext.setState({newCoachingUserPanelOpen:false});
  }

  removeCoachingUser = async(userId)=>{
    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("users").doc(userId).set({
      isCoachingUser: false
    }, {merge:true}).then((result)=>{
      this.baseContext.showAlert("Removed coaching user!");
      window.location.reload();
    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
    })

  }

  removeCheckinUser = async(userId)=>{
    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("users").doc(userId).set({
      isCheckinUser: false
    }, {merge:true}).then((result)=>{
      this.baseContext.showAlert("Removed checkin user!");
      window.location.reload();
    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
    })

  }

  updateNewUserCheckinStatus = async()=>{
    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    var functions = firebase.app().functions('europe-west1');

    functions.httpsCallable('updateUserCheckinStatus')({
      token:token,
      isCheckinUser: true,
      employeeId:this.baseContext.state.chosenNewCheckinUserId
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false});
        window.location.reload();
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
        this.baseContext.showAlert(result.data.errorMessage);
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });


    firebase.firestore().collection("users").doc(currentUserId).set({
      isBasicUser: this.baseContext.state.isBasicUser || false
    }, {merge:true}).then((result)=>{

    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
    })
  }

  updateNewUserCoachingStatus = async()=>{

    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    var functions = firebase.app().functions('europe-west1');

    functions.httpsCallable('updateUserCoachingStatus')({
      token:token,
      isCoachingUser: true,
      employeeId:this.baseContext.state.chosenNewCoachingUserId
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false});
        window.location.reload();
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
        this.baseContext.showAlert(result.data.errorMessage);
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });


    firebase.firestore().collection("users").doc(currentUserId).set({
      isBasicUser: this.baseContext.state.isBasicUser || false
    }, {merge:true}).then((result)=>{

    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
    })


  }

  //Update Company Admin's status as a basic user
  updateAdminBasicUserStatus = async()=>{
    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    var functions = firebase.app().functions('europe-west1');

    functions.httpsCallable('updateAdminBasicUserStatus')({
      token:token,
      isBasicUser: !this.baseContext.state.isBasicUser
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false, isBasicUser: !this.baseContext.state.isBasicUser});
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
        this.baseContext.showAlert(result.data.errorMessage);
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });


    firebase.firestore().collection("users").doc(currentUserId).set({
      isBasicUser: this.baseContext.state.isBasicUser || false
    }, {merge:true}).then((result)=>{

    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
    })
  }

  //Load Coach's courses
  loadCourses = ()=>{
    let courses = [];
    let count = 0;

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("users").where("companyId","==",currentUserId).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          courses.push({name:doc.data().name, public:doc.data().public,files: doc.data().files, live:doc.data().live, id:doc.id, index:count, isCoachingUser:doc.data().isCoachingUser, isCheckinUser:doc.data().isCheckinUser,email:doc.data().email, authority:doc.data().authority});
          count++;
        });

        this.baseContext.setState({
          coursesList: courses,
        });



    });

  }

  calculateAverage = (array)=>{
    let total = 0;
    for(let index = 0;index < array.length;index++){
      total+= array[index];
    }

    return Math.ceil(total / array.length);
  }

  openHoogaScores = async()=>{
        await this.baseContext.setState({loadingSurveys:true, surveyPanelOpen:true});


        var functions = firebase.app().functions('europe-west1');

        let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
            return idToken;
        });

        functions.httpsCallable('loadAverageSurveyData')({
          token:token
        }).then(async(result)=>{

          if(result.data.success){




            await this.baseContext.setState({loadingSurveys:false, hoogaData:result.data.users});

          }else{
            await this.baseContext.setState({surveyPanelOpen:false, error:result.data.errorMessage});
            this.baseContext.showAlert(result.data.errorMessage);
          }

        }).catch(async(error)=>{
          this.baseContext.showAlert(error.message);
          await this.baseContext.setState({surveyPanelOpen:false});
        });
  }

  closeSurveysPanel = ()=>{
    this.baseContext.setState({
      loadingSurveys:false,
      surveyPanelOpen:false
    })
  }


  //Opens Event/Course Panel for editting
  editEvent = async(course)=>{
    await this.baseContext.setState({
      editEventPanelOpen:true,
      chosenCourseName:course.name,
      chosenCourseId:course.id,
      editPanelLoading:true,
      chosenCourseIsLive:course.live,
      chosenCourseIsPublic:course.public,
      chosenCourseIndex:course.index,
      editLessonPanelOpen:false,});

    let modules = [];

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("resources").doc(course.id).collection("modules").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          modules.push({name:doc.data().name, files: doc.data().files, description:doc.data().description, id:doc.id, lessons:doc.data().lessons});
        });

        this.baseContext.setState({
          chosenCourseModulesList: modules,
          editPanelLoading:false
        });



    });

  }

  //Add module to course
  addEventModule = async()=>{
    if(this.baseContext.state.newModuleName == ""){
      this.baseContext.showAlert("Your Module Name Cannot be Empty!");
      return;
    }

    const currentUserId = firebase.auth().currentUser.uid;

    await this.baseContext.setState({loading:true, addModulePanelOpen:false});

    let currentCourseId = this.baseContext.state.chosenCourseId;

    firebase.firestore().collection("resources").doc(currentCourseId).collection("modules").add({
      name: this.baseContext.state.newModuleName
    }).then((docRef)=>{
      this.baseContext.showAlert("Module Created!");
      let oldModulesList = this.baseContext.state.chosenCourseModulesList;
      let newModule = {name: this.baseContext.state.newModuleName};
      newModule['id'] = docRef.id;

      oldModulesList.push(newModule);

      this.baseContext.setState({loading:false, chosenCourseModulesList: oldModulesList});
      this.loadCourses();
    }).catch((error)=>{
      this.baseContext.showAlert("Error: "+ error.message);
      this.baseContext.setState({loading:false});
    });

  }


  //Add New File To the Existing Lesson
  editLessonAddNewFile = async()=>{
    let filesArray = this.baseContext.state.EditLessonNewFiles;
    let newFile = this.baseContext.EditLessonFilesInput.current.files[0];
    filesArray.push(newFile);

    await this.baseContext.setState({EditLessonNewFiles: filesArray});
  }

  //Save Editted Lesson
  saveLessonUpdate = async()=>{
    let lessonIndex = this.baseContext.state.EditLessonIndex;
    let lessonName = this.baseContext.state.EditLessonName;
    let lessonDescription = this.baseContext.state.EditLessonDescription;
    let lessonExistingFiles = this.baseContext.state.EditLessonExistingFiles;
    let lessonNewFiles = this.baseContext.state.EditLessonNewFiles;

    if(lessonName == ""){
      this.baseContext.showAlert("The lesson name cannot be empty!");
      return;
    }else if(lessonDescription == ""){
      this.baseContext.showAlert("The lesson description cannot be empty!");
      return;
    }

    await this.baseContext.setState({editPanelLoading:true, editLessonPanelOpen:false});

    let newFilesArray = lessonExistingFiles;
    let storageRef = firebase.storage().ref();
    let currentUserId = firebase.auth().currentUser.uid;
    let currentCourseId = this.baseContext.state.chosenCourseId;
    let chosenModuleId = this.baseContext.state.chosenModuleId;

    for(let index = 0; index < lessonNewFiles.length; index++){

      let newFileRef = storageRef.child('lessonFiles/'+currentUserId+"/"+currentCourseId+"/"+lessonNewFiles[index].name);

      await newFileRef.put(lessonNewFiles[index]).then(async(snapshot)=>{

        await newFileRef.getDownloadURL().then((url)=>{
          let fileObject = {
            name: lessonNewFiles[index].name,
            url: url
          }

          newFilesArray.push(fileObject);
        })

      });


    }

    let existingLessons = this.baseContext.state.chosenModuleLessons;
    let updatedLesson = {
      name:lessonName,
      description:lessonDescription,
      files:newFilesArray
    }
    existingLessons[lessonIndex] = updatedLesson;


    firebase.firestore().collection("resources").doc(currentCourseId).collection("modules").doc(chosenModuleId).set({
      lessons: existingLessons
    },{merge:true}).then((result)=>{
      this.baseContext.showAlert("Lesson saved successfully.");
      this.baseContext.setState({editPanelLoading:false, chosenModuleLessons: existingLessons});
    }).catch((err)=>{
      this.baseContext.showAlert("Error saving your lesson.");
      this.baseContext.setState({editPanelLoading:false});
    })


  }

  //Connection to DB to set course to live or not onChange
  liveToggleChange = async()=>{

    await this.baseContext.setState({chosenCourseIsLive: !this.baseContext.state.chosenCourseIsLive});

    let currentCourseId = this.baseContext.state.chosenCourseId;

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("resources").doc(currentCourseId).set({
      live: this.baseContext.state.chosenCourseIsLive
    },{merge:true}).then((result)=>{

      let coursesList = this.baseContext.state.coursesList;

      coursesList[this.baseContext.state.chosenCourseIndex].live = this.baseContext.state.chosenCourseIsLive;

      this.baseContext.setState({coursesList:coursesList});
    });

  }

  //Connection to DB to set course to public or private onChange
  publicToggleChange = async()=>{

    await this.baseContext.setState({chosenCourseIsPublic: !this.baseContext.state.chosenCourseIsPublic});

    let currentCourseId = this.baseContext.state.chosenCourseId;

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("resources").doc(currentCourseId).set({
      public: this.baseContext.state.chosenCourseIsPublic
    },{merge:true}).then((result)=>{

      let coursesList = this.baseContext.state.coursesList;

      coursesList[this.baseContext.state.chosenCourseIndex].public = this.baseContext.state.chosenCourseIsPublic;

      this.baseContext.setState({coursesList:coursesList});
    });

  }


  //Close Event Panel, details saved automatically
  closeEditEventPanel = async()=>{
    await this.baseContext.setState({editEventPanelOpen:false, surveyPanelOpen:false,lessonsPanelOpen:false, addModulePanelOpen:false, newLessonPanelOpen:false});
  }


  //Opens Pane for adding a new module for a chosen course
  openAddNewModulePanel = async()=>{
    await this.baseContext.setState({addModulePanelOpen:true});
  }

  //Closes Pane for adding a new module for a chosen course
  closeAddNewModulePanel = async()=>{
    await this.baseContext.setState({addModulePanelOpen:false});
  }

  //Add New File to new lesson
  addNewFile = async()=>{
    let filesArray = this.baseContext.state.newLessonFiles;
    let newFile = this.baseContext.filesInput.current.files[0];
    filesArray.push(newFile);

    await this.baseContext.setState({newLessonFiles: filesArray});

  }

  //Remove Added File
  removeNewFile = async(index)=>{
    let filesArray = this.baseContext.state.newLessonFiles;
    filesArray.splice(index, 1);
    await this.baseContext.setState({newLessonFiles: filesArray});
  }



  //Create a new user
  createNewUser = async()=>{

    if(this.baseContext.state.newUserName == "" || this.baseContext.state.newUserEmail == ""){
      this.baseContext.showAlert("Your new user details cannot be Empty!");
      return;
    }

    await this.baseContext.setState({loading:true});

    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    functions.httpsCallable('createCompanyUser')({
      newUserEmail:this.baseContext.state.newUserEmail,
      newUserName:this.baseContext.state.newUserName,
      idToken:token
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false, registered:true});
        this.baseContext.showAlert("Successfully Created User");

        window.location.reload();
      }else{
        this.baseContext.showAlert(result.data.errorMessage);
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });






  }

}

export default CoachLogic;
