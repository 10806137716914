export default{

  FirebaseConfig: {
    apiKey: "AIzaSyCsVTYM-aydRVm0b-vZdlG8qaLiJ-7dZNo",
   authDomain: "inkelhealthweb.firebaseapp.com",
   databaseURL: "https://inkelhealthweb.firebaseio.com",
   projectId: "inkelhealthweb",
   storageBucket: "inkelhealthweb.appspot.com",
   messagingSenderId: "1058765333206",
   appId: "1:1058765333206:web:04573e4f2f3fdb4a78df77"
  }

}
