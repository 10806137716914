import React from 'react';
import './desktop.css';
import './mobile.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

//Assets
import forwardArrow from '../../assets/forward-arrow.png';
import backArrow from '../../assets/back-arrow.png';
import logo from '../../assets/logo.svg';

//Menu Icons
import contentIcon from '../../assets/icons/content.png';
import inboxIcon from '../../assets/icons/inbox.png';
import invitesIcon from '../../assets/icons/invites.png';
import surveyIcon from '../../assets/icons/survey.svg';
import calendarIcon from '../../assets/icons/calendar.png';
import homeIcon from '../../assets/icons/home.png';
import settingsIcon from '../../assets/icons/settings.png';
import subscriptionsIcon from '../../assets/icons/subscription.svg';
import planIcon from '../../assets/icons/plan.svg';
import myContentIcon from '../../assets/icons/content-icon.png';
import planIconTwo from '../../assets/icons/plan.png';
import companyIcon from '../../assets/icons/company.png';
import diaryIcon from '../../assets/icons/notebook.png';

//Services
import Language from '../../services/Language';

//Firebase For Auth Access
import * as firebase from 'firebase';

class SideBar extends React.Component{

  constructor(props){
    super(props);
    this.context = props.context;

    this.state = {
      open:true,
      accountStatus:props.accountStatus,
      openScreen:props.openScreen
    }

  }



  openSideMenu = async()=>{
    await this.setState({open:true});

    document.getElementById("side-bar-logo").style.visibility = "visible";
    document.getElementById("side-bar-logout-button").style.visibility = "visible";

    let i = 5;

    var interval = setInterval(function(){
      if(i <= 17){
        document.getElementById("side-bar").style.width = `${i}%`;
        i++;
      }else{
        clearInterval(interval);
      }
    }, 4);
  }

  closeSideMenu = async()=>{
    await this.setState({open:false});

    let i = 17;

    document.getElementById("side-bar-logo").style.visibility = "hidden";
    document.getElementById("side-bar-logout-button").style.visibility = "hidden";

    var interval = setInterval(function(){
      if(i >= 5){
        document.getElementById("side-bar").style.width = `${i}%`;
        i--;
      }else{
        clearInterval(interval);
      }
    }, 4);
  }

  logout = async()=>{
    await firebase.auth().signOut();
  }


  render(){
    return(
      <div className="side-bar" id="side-bar">
        <img src={logo} className="side-bar-logo" id="side-bar-logo" />
        {this.renderArrowIcon()}
        <div className="side-bar-content">

          <a href="/dashboard?q=h" >
            <div className="side-bar-item" id={(this.state.openScreen == 'dashboard')? 'open-screen':'' }>
              <img className="side-bar-item-icon" src={homeIcon}></img>
              <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
                {Language.English.Menu.Home}
              </div>
            </div>
          </a>

          {this.renderMessagesSection()}

          {this.renderRequestsSection()}

          <a href="/calendar">
            <div className="side-bar-item" id={(this.state.openScreen == 'calendar')? 'open-screen':'' }>
              <img className="side-bar-item-icon" src={calendarIcon}></img>
              <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
                {Language.English.Menu.Calendar}
              </div>
            </div>
          </a>

          <a href="/settings">
            <div className="side-bar-item" id={(this.state.openScreen == 'settings')? 'open-screen':'' }>
              <img className="side-bar-item-icon" src={settingsIcon}></img>
              <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
                {Language.English.Menu.Settings}
              </div>
            </div>
          </a>

          {this.renderContentSection()}
          {this.renderCompanyPlansSection()}
          {this.renderSubscriptionsSection()}
          {this.renderPlanRequestsSection()}
          {this.renderSurveySection()}
          {this.renderInkelAdminSection()}
          {this.renderCompanyAdminSection()}
          {this.renderDiarySection()}
          {this.renderOrgHoogaSection()}


          <div className="side-bar-logout-button" id="side-bar-logout-button" onClick={this.logout}>
            {Language.English.Menu.Logout}
            <img className="logout-arrow-icon" src={forwardArrow}/>
          </div>


        </div>
      </div>
    )
  }

  renderArrowIcon = ()=>{
    if(this.state.open){
      return(
        <img className="side-bar-arrow-icon" src={backArrow} onClick={this.closeSideMenu} />
      )
    }else{
      return(
        <img className="side-bar-arrow-icon" src={forwardArrow} onClick={this.openSideMenu} />
      )
    }
  }


  renderCompanyPlansSection = ()=>{
    if(this.state.accountStatus.accountType == 'company' && this.state.accountStatus.isCoachingUser){
      return(
        <a href="/organisation-plans">
          <div className="side-bar-item" id={(this.state.openScreen == 'company-plans')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={planIconTwo}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.CompanyPlans}
            </div>
          </div>
        </a>
      )
    }
  }

  renderContentSection = ()=>{
    if(this.state.accountStatus.type == 'client'){
      return(
        <a href="/content">
          <div className="side-bar-item" id={(this.state.openScreen == 'content')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={myContentIcon}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.Content}
            </div>
          </div>
        </a>
      )
    }
  }

  renderDiarySection = ()=>{
    if(this.state.accountStatus.type == 'client'){
      return(
        <a href="/diary">
          <div className="side-bar-item" id={(this.state.openScreen == 'diary')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={diaryIcon}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.Diary}
            </div>
          </div>
        </a>
      )
    }
  }


  renderMessagesSection = ()=>{
    if(this.state.accountStatus.type == "coach" || (this.state.accountStatus.coach)){
      return(
        <a href="/messages">
          <div className="side-bar-item" id={(this.state.openScreen == 'messages')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={inboxIcon}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.Inbox}
            </div>
          </div>
        </a>
      )
    }
  }

  renderRequestsSection = ()=>{
    if(this.state.accountStatus.type == 'coach'){
      return(
        <a href="/requests">
          <div className="side-bar-item" id={(this.state.openScreen == 'requests')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={invitesIcon}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.Invites}
            </div>
          </div>
        </a>
      )
    }
  }

  renderSubscriptionsSection = ()=>{

    if((this.state.accountStatus.type == 'client' && this.state.accountStatus.authority != 'employee') || this.state.accountStatus.authority == 'admin'){
      return(
        <a href="/subscriptions">
          <div className="side-bar-item" id={(this.state.openScreen == 'subscriptions')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={subscriptionsIcon}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.Subscriptions}
            </div>
          </div>
        </a>
      )
    }

  }

  renderOrgHoogaSection = ()=>{
    if(this.state.accountStatus.type == 'client' && this.state.accountStatus.authority == 'admin'){
      return(
        <a href="/organisation-surveys">
          <div className="side-bar-item" id={(this.state.openScreen == 'organisation-surveys')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={surveyIcon}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.OrganisationSurveys}
            </div>
          </div>
        </a>
      )
    }
  }

  renderPlanRequestsSection = ()=>{
    if(this.state.accountStatus.type != 'client'){
      return(
        <a href="/plan-requests">
          <div className="side-bar-item" id={(this.state.openScreen == 'plan-requests')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={planIcon}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.PlanRequests}
            </div>
          </div>
        </a>
      )
    }
  }

  renderSurveySection = ()=>{
    if(this.state.accountStatus.type == 'client'){
      return(
        <a href="/surveys">
          <div className="side-bar-item" id={(this.state.openScreen == 'surveys')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={surveyIcon}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.Surveys}
            </div>
          </div>
        </a>
      )
    }
  }

  renderInkelAdminSection = ()=>{
    if(this.state.accountStatus.type == 'coach' && this.state.accountStatus.authority == 'inkel-admin'){
      return(
        <a href="/inkel-admin">
          <div className="side-bar-item" id={(this.state.openScreen == 'inkel-admin')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={surveyIcon}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.InkelAdmin}
            </div>
          </div>
        </a>
      )
    }
  }

  renderCompanyAdminSection = ()=>{
    if(this.state.accountStatus.type == 'client' && this.state.accountStatus.authority == 'admin'){
      return(
        <a href="/company-admin">
          <div className="side-bar-item" id={(this.state.openScreen == 'company-admin')? 'open-screen':'' }>
            <img className="side-bar-item-icon" src={companyIcon}></img>
            <div className="side-bar-item-text" style={{display:(this.state.open)?"inline-block":"none"}}>
              {Language.English.Menu.CompanyAdmin}
            </div>
          </div>
        </a>
      )
    }
  }


}


export default SideBar
