import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

//Services
import Language from '../../../services/Language';

class ClientLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Choose a new Coach
  chooseNewCoach = ()=>{
    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("users").doc(currentUserId).set({
      surveyComplete: false,
      coach: firebase.firestore.FieldValue.delete()
    }, {merge:true}).then((result)=>{
      firebase.firestore().collection("bookings").doc(this.baseContext.state.accountStatus.bookingData.id).delete();
      document.location = '/';
    })

  }

  //Choose to keep my coach after chemistry session
  keepCoach = ()=>{
    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("users").doc(currentUserId).set({
      coachConfirmed:true
    }, {merge:true}).then((result)=>{

      document.location = '/';

    });
  }

  //Open Panel For Booking a session
  openNewSessionPanel = ()=>{
    this.baseContext.setState({
      newSessionPanelOpen:true,
      chosenSessionDate:new Date(),
      chosenSessionHours:'00',
      chosenSessionMinutes:'00'
    })
  }

  //Open Panel For Booking a check-in session
  openNewCheckinPanel = ()=>{
    this.baseContext.setState({
      newCheckinPanelOpen:true,
      chosenCheckinDate:new Date(),
      chosenCheckinHours:'00',
      chosenCheckinMinutes:'00'
    })
  }

  closeNewSessionPanel = ()=>{
    this.baseContext.setState({
      newSessionPanelOpen:false
    })
  }

  closeNewCheckinPanel = ()=>{
    this.baseContext.setState({
      newCheckinPanelOpen:false
    })
  }

  //Session Not Set
  sessionNotSet = ()=>{

    return !this.baseContext.state.accountStatus.bookingData.dateTimeSet;
  }

  //No Coach Chosen
  coachChosen = ()=>{
      return (this.baseContext.state.accountStatus.coach);
  }

  //Save Token for Notifications
  saveToken = async(token)=>{
    const currentUserId = firebase.auth().currentUser.uid;

    let notificationTokens = this.baseContext.state.accountStatus.notificationTokens;

    if(!notificationTokens.includes(token)){
      notificationTokens.push(token);
      firebase.firestore().collection("users").doc(currentUserId).set({notificationTokens:notificationTokens},{merge:true});
    }

  }

  //Boolean: Is the planned session (if any) in the future?
  sessionPlannedInFuture = ()=>{


    if(!this.baseContext.state.accountStatus.bookingData) return false;

    let sessionTimeUTC = this.baseContext.state.accountStatus.bookingData.dateTimeUTC;
    let localSessionTime = sessionTimeUTC + (new Date().getTimezoneOffset() * 60 * 1000);

    let localTime = new Date().getTime();

    return (localTime < localSessionTime);
  }

  //Boolean: Is the planned session (if any) in the past?
  sessionPlannedInPast = ()=>{
    if(!this.baseContext.state.accountStatus.bookingData || (this.baseContext.state.accountStatus.bookingData.dateTimeUTC == 0)) return false;

    let sessionTimeUTC = this.baseContext.state.accountStatus.bookingData.dateTimeUTC;

    let sessionInPast = sessionTimeUTC < (new Date().getTime() - (60*1000*new Date().getTimezoneOffset()));
    console.log(sessionInPast);

    return (sessionTimeUTC != undefined && sessionInPast);
  }

  //Get Local Time of Session
  getLocalSessionTime = ()=>{
    let timestamp = this.baseContext.state.accountStatus.bookingData.dateTimeUTC;

    let localTime = (timestamp!=null) ? ((new Date().getTimezoneOffset() * 60 * 1000) + timestamp) : 1;

    let localDateTime = new Date(localTime);
    let localHours = localDateTime.getHours().toString().length == 2 ? localDateTime.getHours():"0"+localDateTime.getHours();
    let localMinutes = localDateTime.getMinutes().toString().length == 2 ? localDateTime.getMinutes():"0"+localDateTime.getMinutes();
    let months = Language.English.Months;
    return localDateTime.getDate()+"/"+(months[localDateTime.getMonth()])+"/"+localDateTime.getFullYear()+" "+localHours+":"+localMinutes;
  }

  //Load necessary content for the client
  loadContent = async()=>{
    await this.baseContext.setState({loading:true});


      await this.baseContext.setState({accountStatus:this.baseContext.accountStatus});
      let accountStatus = this.baseContext.accountStatus;

      if(!accountStatus.hasChosenCoach){
        this.loadCoaches();
      }else{
        await this.baseContext.setState({loading:false});
      }




  }

  //Load Survey Data
  loadSurveyData = async()=>{
    const currentUserId = firebase.auth().currentUser.uid;

    await this.baseContext.setState({loadingSurveyData:true});

    let pulseSurveyData = this.baseContext.accountStatus.pulseSurveyResults;

    if(pulseSurveyData.length > 0){
      let recentPulseData = pulseSurveyData[pulseSurveyData.length - 1].answers;


      let pulseAbilityScore = this.calculateTotalPulseScore(recentPulseData['Ability Pulse']);
      let pulseVibeScore = this.calculateTotalPulseScore(recentPulseData['Vibe Pulse']);
      let pulseTribeScore = this.calculateTotalPulseScore(recentPulseData['Tribe Pulse']);
      let pulseHappinessScore = this.calculateTotalPulseScore(recentPulseData['Happiness Pulse']);
      let pulseHarmonyScore = this.calculateTotalPulseScore(recentPulseData['Harmony Pulse']);
      let pulseStrengthsScore = this.calculateTotalPulseScore(recentPulseData['Strengths Pulse']);

      console.log(pulseAbilityScore);

      await this.baseContext.setState({
        pulseAbilityScore:pulseAbilityScore,
        pulseVibeScore:pulseVibeScore,
        pulseTribeScore:pulseTribeScore,
        pulseHappinessScore:pulseHappinessScore,
        pulseHarmonyScore:pulseHarmonyScore,
        pulseStrengthsScore:pulseStrengthsScore
      })
    }

    firebase.firestore().collection("users").doc(currentUserId).collection('surveyData').doc('UpZyaeSe0Jb0HCmcWjU4').get().then(async(doc)=>{

      let hoogaInterventions = await firebase.firestore().collection("hooga-pillars").doc('interventions').get().then(async(doc)=>{

        if(!doc.data()) return {};

        return doc.data().content;


      });

      if(!doc.data() || !doc.data().results){

        let scoresArray = [
          {name:'Accomplishment',data:hoogaInterventions['Accomplishment'],score:0},
          {name:'Relationships',data:hoogaInterventions['Relationships'],score:0},
          {name:'Engagement',data:hoogaInterventions['Engagement'],score:0},
          {name:'Health',data:hoogaInterventions['Health'],score:0},
          {name:'Meaning',data:hoogaInterventions['Meaning'],score:0},
          {name:'PositiveEmotions',data:hoogaInterventions['PositiveEmotions'],score:0}
        ];

        let interventionRandomIndex = (Math.floor(Math.random() * 6));
        let intervention = scoresArray[interventionRandomIndex];

        let interventionIndex = Math.floor(Math.random() * intervention.data.length);
        let excercise = intervention.data[interventionIndex];
        let excerciseName = intervention.name;

        await this.baseContext.setState({
          engagementData:[],
          productivityData:[],
          physicalData:[],
          achievementData:[],
          loadingSurveyData:false,
          excercise:excercise,
          excerciseName:excerciseName,
          dayIndex:interventionRandomIndex
        });

        return;
      }


      let results = doc.data().results || [];

      results.sort((a, b) => (a.dateTimeUTC > b.dateTimeUTC) ? 1 : (a.dateTimeUTC === b.dateTimeUTC) ? ((a.size > b.size) ? 1 : -1) : -1 );
      results.reverse();


      let engagementDataArray = [];
      let relationshipsDataArray = [];
      let physicalDataArray = [];
      let achievementDataArray = [];
      let meaningDataArray = [];
      let happinessDataArray = [];
      let overallDataArray = [];

      let timestampsArray = [];

      for(let resultIndex = 0; (resultIndex < results.length)&&(resultIndex < 1); resultIndex++){
        let engagementMean = (parseInt(results[resultIndex]['answers'][1]) + parseInt(results[resultIndex]['answers'][9]) + parseInt(results[resultIndex]['answers'][22])) / 3;
        let relationshipsMean = (parseInt(results[resultIndex]['answers'][7]) + parseInt(results[resultIndex]['answers'][15]) + parseInt(results[resultIndex]['answers'][17])) / 3;
        let physicalMean = (parseInt(results[resultIndex]['answers'][5]) + parseInt(results[resultIndex]['answers'][10]) + parseInt(results[resultIndex]['answers'][14])) / 3;
        let achievementMean = (parseInt(results[resultIndex]['answers'][0]) + parseInt(results[resultIndex]['answers'][4]) + parseInt(results[resultIndex]['answers'][21])) / 3;
        let meaningMean = (parseInt(results[resultIndex]['answers'][6]) + parseInt(results[resultIndex]['answers'][8]) + parseInt(results[resultIndex]['answers'][16])) / 3;
        let happinessMean = parseInt(results[resultIndex]['answers'][19]);

        let overallMean = this.calculateTotalAverage(results[resultIndex]['answers']);

        engagementDataArray.push([2 - resultIndex, engagementMean]);
        relationshipsDataArray.push([2 - resultIndex, relationshipsMean]);
        physicalDataArray.push([2 - resultIndex, physicalMean]);
        achievementDataArray.push([2 - resultIndex, achievementMean]);
        meaningDataArray.push([2 - resultIndex, meaningMean]);
        happinessDataArray.push([2 - resultIndex, happinessMean]);

        overallDataArray.push([2 - resultIndex, overallMean]);

        timestampsArray.push(results[resultIndex]['dateTimeUTC']);



      }

      engagementDataArray.reverse();
      relationshipsDataArray.reverse();
      physicalDataArray.reverse();
      achievementDataArray.reverse();
      meaningDataArray.reverse();
      happinessDataArray.reverse();
      overallDataArray.reverse();
      timestampsArray.reverse();

      let mostRecentOverallScore = overallDataArray[overallDataArray.length - 1][1];

      let mostRecentEngagementScore = engagementDataArray[engagementDataArray.length - 1][1];
      let mostRecentRelationshipScore = relationshipsDataArray[relationshipsDataArray.length - 1][1];
      let mostRecentPhysicalScore = physicalDataArray[physicalDataArray.length - 1][1];
      let mostRecentAchievementScore = achievementDataArray[achievementDataArray.length - 1][1];
      let mostRecentMeaningScore = meaningDataArray[meaningDataArray.length - 1][1];
      let mostRecentHappinessScore = happinessDataArray[happinessDataArray.length - 1][1];

      let mostRecentDate = new Date(new Date(timestampsArray[timestampsArray.length - 1]).getTime() + (new Date().getTimezoneOffset()*60*1000));

      let mostRecentDateFormatted = mostRecentDate.getDate()+'/'+(mostRecentDate.getMonth()+1)+'/'+mostRecentDate.getFullYear();

      let currentDayInt = (new Date().getDay() == 6)?(0):(new Date().getDay()+1);
      let scoresArray = [
        {name:'Accomplishment',data:hoogaInterventions['Accomplishment'],score:mostRecentAchievementScore},
        {name:'Relationships',data:hoogaInterventions['Relationships'],score:mostRecentRelationshipScore},
        {name:'Engagement',data:hoogaInterventions['Engagement'],score:mostRecentEngagementScore},
        {name:'Health',data:hoogaInterventions['Health'],score:mostRecentPhysicalScore},
        {name:'Meaning',data:hoogaInterventions['Meaning'],score:mostRecentMeaningScore},
        {name:'PositiveEmotions',data:hoogaInterventions['PositiveEmotions'],score:mostRecentHappinessScore}
      ];

      scoresArray.sort((a, b) => (a.score > b.score) ? 1 : (a.score === b.score) ? 0 : -1 );

      let dayIndex = (currentDayInt == 6)? (Math.floor(Math.random() * 6)):(currentDayInt);
      let intervention = scoresArray[dayIndex];

      let interventionIndex = Math.floor(Math.random() * intervention.data.length);
      let excercise = intervention.data[interventionIndex];
      let excerciseName = intervention.name;

      console.log(excercise);




      let engagementData = {
        label:'Flow',
        data:engagementDataArray[0][1]
      }

      let relationshipsData = {
        label:'Relationships',
        data:relationshipsDataArray[0][1]
      }

      let physicalData = {
        label:'Physical',
        data:physicalDataArray[0][1]
      }

      let achievementData = {
        label:'Goals',
        data:achievementDataArray[0][1]
      }

      let meaningData = {
        label:'Purpose',
        data:meaningDataArray[0][1]
      }

      let happinessData = {
        label:'Happiness',
        data:happinessDataArray[0][1]
      }

      let overallData = {
        label:'Overall Wellbeing',
        data:overallDataArray[0]
      }

      console.log(happinessDataArray[0][1]);



      let mainData = [engagementData, relationshipsData, physicalData, achievementData, meaningData, happinessData]

      console.log(100 - Math.round(parseFloat(mostRecentPhysicalScore)).toString());

      this.baseContext.setState({
        mainData: mainData,
        recentWellbeingScore:Math.round(parseFloat(mostRecentOverallScore)).toString(),
        recentEngagementScore:Math.round(parseFloat(mostRecentEngagementScore)).toString(),
        recentHappinessScore:Math.round(parseFloat(mostRecentHappinessScore)).toString(),
        recentPhysicalScore:Math.round(parseFloat(mostRecentPhysicalScore)).toString(),
        recentAchievementScore:Math.round(parseFloat(mostRecentAchievementScore)).toString(),
        recentMeaningScore:Math.round(parseFloat(mostRecentMeaningScore)).toString(),
        recentRelationshipScore:Math.round(parseFloat(mostRecentRelationshipScore)).toString(),
        recentDate:mostRecentDateFormatted,
        excercise:excercise,
        excerciseName:excerciseName,
        dayIndex:dayIndex,
        loadingSurveyData:false,
      });

    }).catch((error)=>{
      console.log('ERROR'+error.message);
    })
  }

  //Calculate Average from Array
  calculateTotalAverage = (array)=>{

    let total = 0;

    for(let index = 0;index < array.length;index++){
      total += parseInt(array[index]);
    }

    return (total / array.length);

  }

  calculateTotalScore = (array)=>{

    if(!array) return;

    let total = 0;

    for(let index = 0;index < array.length;index++){
      total += parseInt(array[index]);
    }

    console.log('total'+(total / (7*array.length)));
    return ((total / (7*array.length))*100);
  }


  calculateTotalPulseScore = (array)=>{

    if(!array) return;

    let total = 0;

    for(let index = 0;index < array.length;index++){
      total += parseInt(array[index]);
    }

    console.log('totalPulse'+(total));
    return ((total / (7*array.length))*100);
  }

  //Load available coaches
  loadCoaches = async()=>{
    await this.baseContext.setState({loading:true});

    firebase.firestore().collection("users").where("type","==","coach").get().then((querySnapshot)=>{
      let coaches = [];
      querySnapshot.forEach((doc) => {
        let mainData = doc.data();
        mainData['id'] = doc.id;

        coaches.push(mainData);
      });

      this.baseContext.setState({loading:false, coaches:this.shuffle(coaches), chooseCoachPanelDisplayed:true});


    }).catch((error)=>{
      console.log('ERROR1'+error.message);
    })
  }

  //Randomly Shuffle Array
  shuffle = (array)=>{
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

//Change chosen session date
changeChosenDate = (date)=>{
  this.baseContext.setState({chosenDate:date});
}

//Change chosen regular session date
changeChosenSessionDate = (date)=>{
  this.baseContext.setState({chosenSessionDate:date});
}

//Change chosen checkin session date
changeChosenCheckinDate = (date)=>{
  this.baseContext.setState({chosenCheckinDate:date});
}



//Open Coach Details in new panel
openChooseCoachPanel = (name, id, description, profileURL)=>{
  this.baseContext.setState({chooseCoachPanelOpen:true, chosenDate: new Date(),chosenCoachName:name, chosenCoachId:id, chosenCoachDescription:description, chosenCoachProfileURL:profileURL});
}

//Close Coach Details Panel
closeChooseCoachPanel = ()=>{
  this.baseContext.setState({chooseCoachPanelOpen:false, chosenDate: null, chooseSessionDateOpen:false,chosenCoachName:null, chosenCoachId:null, chosenCoachDescription:null, chosenCoachProfileURL:null});
}

//Open Panel for choosing session date
openChooseSessionDate = ()=>{
  this.baseContext.setState({chooseSessionDateOpen:true});
}

//Attempt a Checkin request
createCheckinBookingRequest = ()=>{

  let chosenDate = this.baseContext.state.chosenCheckinDate;
  let chosenHours  = this.baseContext.state.chosenCheckinHours;
  let chosenMinutes  = this.baseContext.state.chosenCheckinMinutes;

  chosenDate = new Date(chosenDate);
  chosenDate.setHours(parseInt(chosenHours));
  chosenDate.setMinutes(parseInt(chosenMinutes));

  let offset = new Date().getTimezoneOffset() * 60 * 1000;
  let chosenTime = chosenDate.getTime();
  chosenTime -= offset;

  if(chosenTime <= new Date().getTime()){
    this.baseContext.showAlert("Please Enter a future date & time!");
    return;
  }


  firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
    await this.baseContext.setState({loading:true, chooseCoachPanelOpen:false,chooseSessionDateOpen:false});

    var functions = firebase.app().functions('europe-west1');

    functions.httpsCallable('sendRegularCheckinRequest')({
      token:idToken,
      coachId:this.baseContext.state.accountStatus.coach,
      chosenDateTime: chosenTime
    }).then(async(result)=>{


      if(result.data.success){
        this.baseContext.showAlert("Your session request was sent!");
        await this.baseContext.setState({loading:false});
        this.closeNewCheckinPanel();
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
        this.baseContext.showAlert(this.baseContext.state.error);
      }


    }).catch(async(error)=>{
      this.baseContext.showAlert("Error:"+ error.message);
      await this.baseContext.setState({loading:false});
    });

  }).catch((error)=>{
    this.baseContext.showAlert("Error gettting auth token!");
  });

}


//Attempt a Session Request
createSessionBookingRequest = ()=>{

  let chosenDate = this.baseContext.state.chosenSessionDate;
  let chosenHours  = this.baseContext.state.chosenSessionHours;
  let chosenMinutes  = this.baseContext.state.chosenSessionMinutes;

  chosenDate = new Date(chosenDate);
  chosenDate.setHours(parseInt(chosenHours));
  chosenDate.setMinutes(parseInt(chosenMinutes));

  let offset = new Date().getTimezoneOffset() * 60 * 1000;
  let chosenTime = chosenDate.getTime();
  chosenTime -= offset;

  if(chosenTime <= new Date().getTime()){
    this.baseContext.showAlert("Please Enter a future date & time!");
    return;
  }


  firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
    await this.baseContext.setState({loading:true, chooseCoachPanelOpen:false,chooseSessionDateOpen:false});

    var functions = firebase.app().functions('europe-west1');

    functions.httpsCallable('sendRegularSessionRequest')({
      token:idToken,
      coachId:this.baseContext.state.accountStatus.coach,
      chosenDateTime: chosenTime
    }).then(async(result)=>{


      if(result.data.success){
        this.baseContext.showAlert("Your session request was sent!");
        await this.baseContext.setState({loading:false});
        this.closeNewSessionPanel();
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
        this.baseContext.showAlert(this.baseContext.state.error);
      }


    }).catch(async(error)=>{
      this.baseContext.showAlert("Error:"+ error.message);
      await this.baseContext.setState({loading:false});
    });

  }).catch((error)=>{
    this.baseContext.showAlert("Error gettting auth token!");
  });

}

//Attempt a booking request
createBookingRequest = async()=>{
  let chosenDate = this.baseContext.state.chosenDate;
  let chosenHours  = this.baseContext.state.chosenHours;
  let chosenMinutes  = this.baseContext.state.chosenMinutes;

  chosenDate = new Date(chosenDate);
  chosenDate.setHours(parseInt(chosenHours));
  chosenDate.setMinutes(parseInt(chosenMinutes));

  let offset = new Date().getTimezoneOffset() * 60 * 1000;
  let chosenTime = chosenDate.getTime();
  chosenTime -= offset;

  if(chosenTime <= new Date().getTime()){
    this.baseContext.showAlert("Please Enter a future date & time!");
    return;
  }


  firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
    await this.baseContext.setState({loading:true, chooseCoachPanelOpen:false,chooseSessionDateOpen:false});

    var functions = firebase.app().functions('europe-west1');

    functions.httpsCallable('sendChemistrySessionRequest')({
      token:idToken,
      coachId:this.baseContext.state.chosenCoachId,
      chosenDateTime: chosenTime
    }).then(async(result)=>{


      if(result.data.success){
        this.baseContext.showAlert("Your chemistry session request was sent!");
        window.location.reload();
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
        this.baseContext.showAlert(this.baseContext.state.error);
      }

      document.location = '/';

    }).catch(async(error)=>{
      this.baseContext.showAlert("Error:"+ error.message);
      await this.baseContext.setState({loading:false});
    });

  }).catch((error)=>{
    this.baseContext.showAlert("Error gettting auth token!");
  });

}




}

export default ClientLogic;
