import React from 'react';
import './desktop.css';
import './mobile.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

//Assets
import forwardArrow from '../../assets/forward-arrow.png';
import backArrow from '../../assets/back-arrow.png';
import logo from '../../assets/logo.svg';
import logoDark from '../../assets/logo-dark.svg';
import defaultProfileImage from '../../assets/profile-picture.png';

//Menu Icons
import contentIcon from '../../assets/icons/content.png';
import inboxIcon from '../../assets/icons/inbox.png';
import surveyIcon from '../../assets/icons/survey.png';
import calendarIcon from '../../assets/icons/calendar.png';
import homeIcon from '../../assets/icons/home.png';
import settingsIcon from '../../assets/icons/settings.png';
import burgerIcon from '../../assets/icons/burger-menu-icon.png';
import messagesIcon from '../../assets/icons/message-icon.svg';

//Services
import Language from '../../services/Language';

class DashboardHeader extends React.Component{

  constructor(props){
    super(props);
    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.name = props.name;
    this.state = {

    }

  }

  renderMessagesCount = ()=>{
    if(this.state.messagesCount == 0 || this.state.messagesCount  == undefined || this.state.messagesCount == null) return;

    return(
      <div className="messages-count-display">
        {this.state.messagesCount}
      </div>
    )
  }




  render(){
    return(
      <div className="dashboard-header" id="dashboard-header">
        <img src={burgerIcon} className="dashboard-header-mobile-menu" onClick={()=>{this.mobileSideBar.current.openSideBar()}} />
        <img className="dashboard-header-logo" src={logoDark}/>
        <div className="dashboard-header-title">{this.name}</div>

        <div className="profile-display-container">
          <a href="/settings">
            <div className="profile-display">
              <img src={this.props.profileImage || defaultProfileImage} className="profile-display-image" />
              <div className="profile-display-text">{this.props.userName}</div>
            </div>
          </a>

          <a href="/messages">
            <div className="notifications-icon-container">
              <img src={messagesIcon} className="messages-icon" />
              {this.renderMessagesCount()}
            </div>
          </a>

        </div>
      </div>
    )
  }


}


export default DashboardHeader
