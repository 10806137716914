import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class CoachLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  loadEvents = async()=>{
    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    let events = [];

    if(this.baseContext.state.accountStatus.isBasicUser || (this.baseContext.state.accountStatus.authority == 'employee')){

      let companyId = currentUserId;

      if(this.baseContext.state.accountStatus.authority == 'employee') companyId =  this.baseContext.state.accountStatus.companyId;

      await firebase.firestore().collection("users").doc(companyId).get().then((doc)=>{

        let webinars = doc.data().webinars;


        if(webinars){

          for(let index = 0; index < webinars.length; index++){
            events.push({title:"Webinar: "+webinars[index].name, start:this.convertToLocalTime(webinars[index].startTimeUTC), end:this.convertToLocalTime(webinars[index].endTimeUTC) });
          }

        }



      })
    }

    await firebase.firestore().collection("bookings").where('clientId', '==' , currentUserId).get().then((querySnapshot)=>{

      querySnapshot.forEach((doc) => {

          let title = (doc.data().type == 'session')? "Coaching Session":"My Chemistry Session";

          if(doc.data().dateTimeSet){
            events.push({title:title, start:this.convertToLocalTime(doc.data().dateTimeUTC), end:(this.convertToLocalTime(doc.data().dateTimeUTC)+(30*60*1000)), description:(doc.data().description || ''), callLink:(doc.data().callLink || '') });
          }

        });

        this.baseContext.setState({
          events: events,
        });


    }).catch((error)=>{
      this.baseContext.showAlert("Error loading data: "+error.message);
    })



  }

  openAddAvailabilityPanel = (date)=>{
    let monthsArray = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    let chosenDisplayDate = date.getDate()+"-"+monthsArray[date.getMonth()]+"-"+date.getFullYear();
    let chosenDateTimestamp = date.getTime();
    let timeZone = this.getTimeZone();
    this.baseContext.setState({addAvailabilityPanelOpen:true, chosenDisplayDate:chosenDisplayDate, chosenDateTimestamp: chosenDateTimestamp, timeZone:timeZone});
  }

  closeAddAvailabilityPanel = ()=>{
    this.baseContext.setState({addAvailabilityPanelOpen:false, chosenDisplayDate:null, chosenDateTimestamp: null, timeZone:null});
  }

  getHoursAndMinutes = (timestamp)=>{
    let time = new Date(timestamp - (new Date().getTimezoneOffset() * 60 * 1000));

    let minutes = (time.getMinutes().toString().length == 1)? time.getMinutes()+"0" : time.getMinutes();

    console.log(time.getMinutes().toString().length);
    return time.getHours()+':'+minutes;

  }

  getDateString = (timestamp)=>{
    let time = new Date(timestamp);

    return (time.getDate().toString()+'/'+(time.getMonth()+1)+'/'+time.getFullYear())
  }

  convertToLocalTime = (dateTime) =>{
    const today = new Date();
    return new Date(dateTime - (new Date().getTimezoneOffset() * 1000 * 60)).getTime();
  }

  //Get user's current time zone
  getTimeZone = ()=>{
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }


}

export default CoachLogic;
