import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class SubscriptionsLogic {

  constructor(context){
    this.baseContext = context;
  }

  loadUserDetails = ()=>{

    var projects = [];

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("users").doc(currentUserId).get().then(async(doc)=>{
      let name = doc.data().name;
      let type = doc.data().type;
      let accountType = doc.data().accountType;
      let profileURL = doc.data().profileURL;
      let availability = doc.data().availability;

      if(type != 'client') document.location = '/';

      let myPlans = await firebase.firestore().collection("requests").doc(currentUserId).get().then((doc)=>{
          return{
            planSet:doc.data().planSet,
            planRequested:doc.data().planRequested,
            planPaid:doc.data().planPaid,
            subscriptionSet:doc.data().subscriptionSet,
            subscriptionRequested:doc.data().subscriptionRequested,
            subscriptionPaid:doc.data().subscriptionPaid
          }
      }).catch((error)=>{
        return{
          planSet:false,
          planRequested:false,
          planPaid:false,
          subscriptionSet:false,
          subscriptionRequested:false,
          subscriptionPaid:false
        }
      })

      this.baseContext.setState({
        name: name,
        type:type,
        profileURL:profileURL,
        isLoadingUserData: false,
        accountType:accountType,
        availability:availability,
        accountStatus:{
          name:name,
          type:type,
          accountType:accountType,
          profileURL:profileURL,
          hasFilledSurvey:doc.data().surveyComplete,
          hasChosenCoach:doc.data().coachChosen,
          hasAssignedCoach:doc.data().coachAssigned,
          coach:doc.data().coach,
          authority:doc.data().authority,
          subscriptionPaid:doc.data().subscriptionPaid,
          planPaid:doc.data().planPaid,
          subscriptionId:doc.data().subscriptionId,
          planId:doc.data().planId,
          planSet:myPlans.planSet,
          planRequested:myPlans.planRequested,
          planPaid:myPlans.planPaid,
          subscriptionSet:myPlans.subscriptionSet,
          subscriptionRequested:myPlans.subscriptionRequested,
          subscriptionPaid:myPlans.subscriptionPaid,
          authority:doc.data().authority,
          isBasicUser:doc.data().isBasicUser,
          isCoachingUser:doc.data().isCoachingUser,
          coreValues:doc.data().coreValues || [],
          pulseSurveyAvailable:doc.data().pulseSurveyAvailable,
          coreSurveyAvailable:doc.data().coreSurveyAvailable,
          hopeSurveyAvailable:doc.data().hopeSurveyAvailable,
          pulseSurveyResults:doc.data().pulseSurveyResults || [],
          enabled:doc.data().enabled,
          hopeSurveyValues:doc.data().hopeSurveyValues
        }
      });

    }).catch((error)=>{
      alert(error.message);
    });

    firebase.firestore().collection("users").doc(currentUserId).collection("resources").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          projects.push({name:doc.data().name});
        });

        this.baseContext.setState({
          projectsList: projects,
          isLoadingProjects: false,
        });


    });


  }


  validateAuth = ()=>{

    firebase.auth().onAuthStateChanged((user)=>{

      if (user && !(this.baseContext.state.loading)) {
        this.baseContext.setState({authenticating:false});
        this.loadUserDetails();
      }else{
        document.location = "/";
      }

    });

  }






}

export default SubscriptionsLogic;
