import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

//Services
import Language from '../../../services/Language';

class ClientLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Get Coach's Name
  loadCoachName = async()=>{
    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    var functions = firebase.app().functions('europe-west1');

    functions.httpsCallable('getCoachName')({
      token:token,
      coachId:this.baseContext.state.accountStatus.coach
    }).then((result)=>{
        if(result.data.success){
          this.baseContext.setState({coachName:result.data.name});
        }
    }).catch(async(error)=>{

    })

  }

  //Load messages
  loadMessages = async(coachId)=>{

    let scrolledToBottom = (!document.getElementById('client-messages') || document.getElementById('client-messages').scrollTop == 0);
    if(!coachId && this.baseContext.state.accountStatus.accountType != 'company') document.location = '/';

    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    let messagesRef = firebase.database().ref('messages/' + coachId + '/' + currentUserId);

    let messageDataRef = firebase.database().ref('messages/' + coachId + '/' + currentUserId + '/data');

    messagesRef.on('value', async(snapshot)=>{
      let messages = [];
      snapshot.forEach((message)=>{
        messages.push(message.toJSON());
      });

      messageDataRef.update({lastSeen: (new Date().getTime() + (new Date().getTimezoneOffset() * 60 * 1000))});

      await this.baseContext.setState({messages:messages});

      if(scrolledToBottom) document.getElementById('client-messages').scrollTo(0,document.getElementById('client-messages').scrollHeight);

    });



  }

  //Send Message
  sendMessage = async(event)=>{
    event.preventDefault();

    if(this.baseContext.state.message == ""){
      this.baseContext.showAlert("Please enter a message!");
      return;
    }

    const message = this.baseContext.state.message;

    await this.baseContext.setState({message:""});

    var database = firebase.database();
    const currentUserId = firebase.auth().currentUser.uid;

    firebase.database().ref('messages/' +this.baseContext.state.coachId+"/"+currentUserId).push({
      message: message,
      timestamp: new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000),
      fromId:currentUserId,
      toId:this.baseContext.state.coachId
    }).then(async()=>{
      const currentUserId = firebase.auth().currentUser.uid;

      let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
          return idToken;
      });

      var functions = firebase.app().functions('europe-west1');

      functions.httpsCallable('sendMessageEmail')({
        token:token,
        receiverId:this.baseContext.state.coachId,
        senderName:this.baseContext.state.accountStatus.name
      })

    }).catch(async(error)=>{
    })
  }

  //Convert To Local Date Time
  convertToLocalDateTime = (timestamp)=>{
    let dateTime = new Date(timestamp + new Date().getTimezoneOffset() * 60 * 1000);

    let minutes = dateTime.getMinutes().toString().length == 1 ? "0"+dateTime.getMinutes():dateTime.getMinutes();


    return dateTime.getDate()+" "+Language.English.Months[dateTime.getMonth()]+" "+dateTime.getHours()+":"+minutes;
  }

  //Get user's current time zone
  getTimeZone = ()=>{
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }


}

export default ClientLogic;
