import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Chart } from 'react-charts';
import { withAlert } from 'react-alert';

import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import binIcon from '../../../assets/icons/bin.png';
import courseIcon from '../../../assets/icons/course-icon.png';
import modulesIcon from '../../../assets/icons/course-module.png';
import fileIcon from '../../../assets/icons/file-icon.png';
import noContentIcon from '../../../assets/icons/no-content-icon.png';

class CoachDashboard extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      newCoursePanelOpen:false,
      newUserName:"",
      newUserEmail:"",
      coursesList:[],
      chosenCourseModulesList:[],
      editPanelLoading:true,
      newLessonFiles:[],
      chosenModuleLessons:[],
      isBasicUser:props.accountStatus.isBasicUser,
      accountStatus:props.accountStatus,
      mainData:[],

      chosenCourseModules:[],

      axes:[
        { primary: true, position: 'bottom', type: 'ordinal', show:false },
        { position: 'left', type: 'linear', stacked: false }
      ],
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);
    this.logic.loadCourses();

    this.filesInput = React.createRef();
    this.EditLessonFilesInput = React.createRef();
  }

  series = () => {
      return( {
        type: 'bar'
      }
    )

    }


  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else if(this.state.moduleOpen){

      return(
        <div className="client-dashboard-home" style={{overflowY:'auto'}}>
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Dashboard.HomeHeader} userName={this.props.userName} profileImage={this.props.profileImage}/>

          <div className="open-course-modules-container">

          <div className="course-close-button" onClick={this.logic.closeModule}>
            <img src={xButton} className="course-close-icon" />
            <div className="course-close-text">{Language.English.Content.CloseModule}</div>
          </div>

          <div className="module-chosen-header">
            <img src={modulesIcon} className="module-chosen-header-icon" />
            <div className="module-chosen-header-text">{this.state.chosenCourseModules[this.state.openModuleIndex].name}</div>
          </div>

          {
            this.state.chosenCourseModules[this.state.openModuleIndex].lessons.map((lesson,index)=>{
              return(
                <div className="lesson-item">
                  <div className="lesson-item-header">{lesson.name}</div>
                  <div className="lesson-item-subheader">{lesson.description}</div>

                  <div className="lesson-item-files">
                    {this.renderLessonFiles(lesson)}
                  </div>
                </div>
              )
            })
          }





          </div>
        </div>
      )


    }else if(this.state.courseOpen){

      return(
        <div className="client-dashboard-home" style={{overflowY:'auto'}}>
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Dashboard.HomeHeader} userName={this.props.userName} profileImage={this.props.profileImage}/>

          <div className="open-course-modules-container">

          <div className="course-close-button" onClick={this.logic.closeCourse}>
            <img src={xButton} className="course-close-icon" />
            <div className="course-close-text">{Language.English.Content.CloseCourse}</div>
          </div>

          {this.renderCourseContent()}


          </div>
        </div>
      )


    }else{
      return(
        <div className="client-dashboard-home" style={{overflowY:'auto'}}>
          {this.renderCoursePanel()}
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Dashboard.HomeHeader} userName={this.props.userName} profileImage={this.props.profileImage}/>

          <div className="client-dashboard-content" style={{overflowY:'auto'}}>

                    <div className="client-courses-panel">

                    <div className="client-courses-panel-header">
                      <div className="client-courses-panel-text-container">
                        <div className="client-courses-panel-text">
                          {Language.English.Content.MyCourses}
                        </div>
                      </div>
                    </div>

                    <div className="client-courses-panel-content">

                      {
                        this.renderNoCourseNotice()
                      }

                      {
                        this.state.coursesList.map((course,index)=>{
                          return(
                            <div className="user-course-row" onClick={()=>{this.logic.openCourse(index)}}>
                              <img className="user-course-icon" src={courseIcon} />
                              <div className="user-course-text">{course.name}</div>
                            </div>
                          )
                        })
                      }

                    </div>

                    </div>

          </div>

        </div>
      );
    }
  }

  renderNoCourseNotice = ()=>{
    if(this.state.coursesList.length != 0) return;

    return(
      <div className="no-content-container">

        <img src={noContentIcon} className="no-content-icon"/>

        <div className="no-content-text">
          {Language.English.Content.NoContentText}
        </div>

      </div>
    )
  }

  renderLessonFiles = (lesson)=>{
    if(!lesson.files) return;

    return lesson.files.map((file,index)=>{
      return(
        <div onClick={()=>{this.logic.openLink(file.url)}} className="lesson-item-file">
          <img className="lesson-item-file-icon" src={fileIcon} />
          <div className="lesson-item-file-name">{file.name}</div>
        </div>
      )
    })
  }

  renderCourseContent = ()=>{
    if(this.state.loadingModules){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      )
    }

    return(
      <div>
        {
          this.state.chosenCourseModules.map((module,index)=>{

            return(
              <div className="course-module" onClick={()=>{this.logic.openModule(index)}}>

                <div className="course-module-header">{module.name}</div>

                <div className="course-module-lessons-display">

                  <img src={modulesIcon} className="course-module-icon" />

                  <div className="course-module-text">
                    {module.lessons.length} {(module.lessons.length == 1)? 'Lesson':'Lessons'}
                  </div>

                </div>

              </div>
            )


          })
        }
      </div>
    )
  }

  renderCoursePanel = ()=>{
    if(this.state.newCoursePanelOpen){

      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeNewCoursePanel}/>
          <div className="new-course-form">
            <input className="new-course-name-input" type="text" placeholder="New User Name" value={this.state.newCourseName} onChange={(event)=>{this.setState({newUserName:event.target.value})}}/>
            <br/><br/>
            <input className="new-course-name-input" type="text" placeholder="New User Email" value={this.state.newCourseName} onChange={(event)=>{this.setState({newUserEmail:event.target.value})}}/>
            <div className="new-course-submit-button" onClick={this.logic.createNewUser}>{Language.English.Dashboard.AddButton}</div>
          </div>
        </div>
      )

    }
  }



}

export default withAlert()(CoachDashboard);
