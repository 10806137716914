import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Chart } from 'react-charts';
import { withAlert } from 'react-alert';

import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import binIcon from '../../../assets/icons/bin.png';
import surveyTriangle2 from '../../../assets/icons/surveys-triangle-2.png';
import plusTriangle from '../../../assets/icons/plus-triangle.png';
import downloadTriangle from '../../../assets/icons/download-triangle.png';

class CoachDashboard extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      newCoursePanelOpen:false,
      mySurveys:[],
      newUserName:"",
      newUserEmail:"",
      coursesList:[],
      chosenCourseModulesList:[],
      editPanelLoading:true,
      newLessonFiles:[],
      chosenModuleLessons:[],
      isBasicUser:props.accountStatus.isBasicUser,
      accountStatus:props.accountStatus,
      mainData:[],

      axes:[
        { primary: true, position: 'bottom', type: 'ordinal', show:false },
        { position: 'left', type: 'linear', stacked: false,hardMin:0, hardMax:10 }
      ],
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);
    this.logic.loadSurveys();

    this.filesInput = React.createRef();
    this.EditLessonFilesInput = React.createRef();



  }

  getSeriesStyle = (series) => {
    // Based off my chart bars
    const colorPalette = {
      Engagement: "#00DEDC",
      Physical:"#fc0050",
      Relationships:"#9c00f0",
      Achievement:"#00de51",
      Meaning:'#edd900',
      Happiness:'#4b29e3',
      'Overall Wellbeing':'#f00000'
    };


    return {
      fill: colorPalette[series.label],
      stroke: colorPalette[series.label]
    };
  }

  series = () => {
      return( {
        type: 'bar'
      }
    )

    }


  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{
      return(
        <div className="coach-dashboard-home" style={{overflowY:'scroll'}}>
          {this.renderSurveyDetailsPanel()}
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.CompanyAdmin.CompanyAdminHeader} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="company-admin-content" style={{overflowY:'scroll'}}>

            <div className="company-admin-courses-panel">
              <div className="company-admin-panel-header">
                <div className="company-admin-panel-text-container">
                  <div className="company-admin-panel-text">
                    {Language.English.GroupSurveys.AdminHeader}
                  </div>
                </div>
                <div className="courses-panel-button-container">
                  <div className="courses-panel-new-button" onClick={this.logic.createSurvey}>
                    <img src={plusIcon} className="courses-button-icon" />
                    {Language.English.Dashboard.NewButton}
                  </div>
                </div>
              </div>

              <div className="courses-panel-content">
                {
                  this.state.mySurveys.map((survey,index)=>{
                    return(
                      <div className="inkel-admin-data-display">
                        <div className="course-display-left">
                          <div className="course-display-name">Group Survey - Created at: {this.logic.convertToString(survey.creationTime)}</div>
                        </div>

                        <div className="course-display-right"></div>




                        <div className="webinars-button" onClick={()=>{this.logic.openSurvey(survey, index)}}>
                          {Language.English.GroupSurveys.Details}
                        </div>

                      </div>
                    )
                  })
                }
              </div>

            </div>

          </div>

          <br/><br/>


        </div>
      );
    }
  }

  renderSurveyDetailsPanel = ()=>{
    if(!this.state.surveyPanelOpen) return;

    return(
      <div className="hooga-results-panel">
        <img src={xButton} className="hooga-results-close-button" onClick={this.logic.closeSurveysPanel}/>
        <div className="hooga-results-form">
          {this.renderSurveyDetails()}
        </div>
      </div>
    )
  }

  renderSurveyDetails = ()=>{
    if(this.state.loadingSurveys){
      return(
        <div style={{textAlign:'center'}}>
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      )
    }

    return(
      <div>
        <div className="hooga-surveys-header">
          <div className="hooga-surveys-button-container">
            <div className="hooga-surveys-header-text">{Language.English.CompanyAdmin.HoogaSurveysHeader}</div>

            <div className="hooga-download-button" onClick={this.logic.downloadCsvFile}>
              <img className="hooga-download-button-icon" src={downloadTriangle} />
              {Language.English.CompanyAdmin.DownloadButton}
            </div>

          </div>
        </div>
            <br/><br/>
            <div className="hooga-surveys-header" style={{fontSize:"1rem"}}>Share this link with employees that you would like to take your survey:</div>
            <div className="hooga-surveys-header" style={{fontSize:"1rem"}}>https://app.inkel.health/public-surveys?a={this.state.userId}&s={this.state.openSurveyId}</div>
            <br/>
            <div className="hooga-surveys-header" style={{fontSize:"1rem"}}>For results from coaching surveys, please contact your Inkel Admin</div>
      </div>
    )
  }








}

export default withAlert()(CoachDashboard);
