import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-toggle/style.css";
import { withAlert } from 'react-alert';

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import whitePlusIcon from '../../../assets/white-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import defaultProfileImage from '../../../assets/profile-picture.png';
import sendIcon from '../../../assets/icons/send.svg';
import sendIconWhite from '../../../assets/icons/send-white.svg';
import bookingIcon from '../../../assets/icons/calendar-clock.svg';


import 'react-big-calendar/lib/css/react-big-calendar.css';

class CoachRequests extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:true,
      addAvailabilityPanelOpen:false,
      newMessage:"",

      hours:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
      minutes:['00','15','30','45'],

      chosenHours:'00',
      chosenMinutes:'00',
      chosenDate: new Date(),
      planDescription:'',
      accountStatus:props.accountStatus
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);
    this.logic.loadBookings();

  }


  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{

      const localizer = momentLocalizer(moment);

      return(
        <div className="coach-calendar-home">
          {this.renderSendMessagePanel()}
          {this.renderCreateBookingPanel()}
          {this.renderSendBackToUserPanel()}
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.PlanRequests.PlanRequestsHeader} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="coach-requests-container">
          {
            this.state.requests.map((request, index)=>{

              if(request.type == 'organisation-plan'){

                return(
                  <div className="request-item" >
                    <img src={request.profileURL || defaultProfileImage} className="request-profile-image" />
                    <div className="request-from">{Language.English.Requests.From} {request.name || 'No Name'}</div>
                    <div className="request-received-date">{Language.English.Requests.ReceivedOn} {this.logic.convertToLocalTime(request.createdUTC)}</div>

                    <div className="request-buttons-container">
                      <div className="create-booking-button" onClick={()=>{this.logic.openSendToUserPanel(request)}}>
                        {Language.English.PlanRequests.SendBackToUser}
                        <img className="request-response-icon" src={bookingIcon} />
                      </div>
                      <div className="discuss-booking-button" onClick={()=>{this.logic.openSendMessagePanel(request)}}>
                        {Language.English.PlanRequests.SendMessage}
                        <img className="request-response-icon" src={sendIcon} />
                      </div>
                    </div>
                  </div>
                )

                }

              return(
                <div className="request-item" >
                  <img src={request.profileURL || defaultProfileImage} className="request-profile-image" />
                  <div className="request-from">{Language.English.Requests.From} {request.name || 'No Name'}</div>
                  <div className="request-received-date">{Language.English.Requests.ReceivedOn} {this.logic.convertToLocalTime(request.createdUTC)}</div>

                  <div className="request-buttons-container">
                    <div className="create-booking-button" onClick={()=>{this.logic.openSendToAdminPanel(request)}}>
                      {Language.English.PlanRequests.SendToAdmin}
                      <img className="request-response-icon" src={bookingIcon} />
                    </div>
                    <div className="discuss-booking-button" onClick={()=>{this.logic.openSendMessagePanel(request)}}>
                      {Language.English.PlanRequests.SendMessage}
                      <img className="request-response-icon" src={sendIcon} />
                    </div>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
      );
    }
  }

  renderSendMessagePanel = ()=>{
    if(!this.state.sendMessagePanelOpen) return;

    if(this.state.sendMessageLoading){
      return(
        <div className="new-message-panel">
          <img src={xButton} className="new-message-close-button" onClick={this.logic.closeSendMessagePanel}/>
          <div className="new-message-form">
            <ClipLoader
              size={100}
              color={"#005c9e"}
              loading={true}
              className="coach-dashboard-loader-icon"
            />
          </div>
        </div>
      )
    }

    return(
      <div className="new-message-panel">
        <img src={xButton} className="new-message-close-button" onClick={this.logic.closeSendMessagePanel}/>
        <div className="new-message-form">
          <div className="new-message-to">{Language.English.Requests.To} {this.state.chosenRequest.name || 'No Name'}</div>
          <textarea className="new-message-input" type="text" placeholder="Message" value={this.state.newMessage} onChange={(event)=>{this.setState({newMessage:event.target.value})}}/>
          <div className="new-message-submit-button" onClick={this.logic.sendMessage}>
            {Language.English.Requests.SendButton}
            <img className="request-response-icon" src={sendIconWhite} />
          </div>
        </div>
      </div>
    )

  }

  renderSendBackToUserPanel = ()=>{

    if(!this.state.sendBackToUserPanelOpen) return;

    if(this.state.createBookingLoading){
      return(
        <div className="new-message-panel">
          <img src={xButton} className="new-message-close-button" onClick={this.logic.closeSendToUserPanel}/>
          <div className="new-message-form">
            <ClipLoader
              size={100}
              color={"#005c9e"}
              loading={true}
              className="coach-dashboard-loader-icon"
            />
          </div>
        </div>
      )
    }

    return(
      <div className="view-coach-panel">
        <img src={xButton} className="view-coach-close-button" onClick={this.logic.closeSendToUserPanel} />
        <div className="view-coach-panel-content">
          <div className="client-input-time-container">
            <div className="choose-coach-date-header">
              {Language.English.PlanRequests.CompanyDescription}
            </div><br/>

              <textarea className="plan-description-input" value={this.state.planDescription} onChange={(event)=>{this.setState({planDescription:event.target.value})}} />

              <br/>

             <div className="choose-time-submit-button" onClick={this.logic.sendBackToUser}>
               Submit
               <img className="request-response-icon" src={bookingIcon} />
             </div>
          </div>

        </div>
      </div>
    )


  }

  renderCreateBookingPanel = ()=>{
    if(!this.state.createBookingPanelOpen) return;

    if(this.state.createBookingLoading){
      return(
        <div className="new-message-panel">
          <img src={xButton} className="new-message-close-button" onClick={this.logic.closeSendMessagePanel}/>
          <div className="new-message-form">
            <ClipLoader
              size={100}
              color={"#005c9e"}
              loading={true}
              className="coach-dashboard-loader-icon"
            />
          </div>
        </div>
      )
    }

    return(
      <div className="view-coach-panel">
        <img src={xButton} className="view-coach-close-button" onClick={this.logic.closeCreateBookingPanel} />
        <div className="view-coach-panel-content">
          <div className="client-input-time-container">
            <div className="choose-coach-date-header">
              {Language.English.PlanRequests.Description}
            </div><br/>

              <textarea className="plan-description-input" value={this.state.planDescription} onChange={(event)=>{this.setState({planDescription:event.target.value})}} />

              <br/>

             <div className="choose-time-submit-button" onClick={this.logic.sendToAdmin}>
               Submit
               <img className="request-response-icon" src={bookingIcon} />
             </div>
          </div>

        </div>
      </div>
    )

  }




}

export default withAlert()(CoachRequests);
