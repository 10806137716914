import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import { withAlert } from 'react-alert';
import RangeSlider from 'react-bootstrap-range-slider';

//Services & Local Logic
import Language from '../../services/Language';
import Logic from './logic';

// Assets
import inkelLogo from '../../assets/logo.svg';
import inkelLogoDark from '../../assets/logo-dark.svg';
import loginIcon from '../../assets/lock-icon.png';
import yellowCurve from '../../assets/yellow-curve.png';
import xButton from '../../assets/x-button.png';
import surveyIconDark from '../../assets/icons/surveys-triangle-2.png';
import surveyBar from '../../assets/icons/survey-bar.png';
import tickIcon from '../../assets/tick.png';

// Animations
import Fade from 'react-reveal/Fade';

class Login extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {publicSurveyName:"",attemptingLogin:false,authenticating:true,loading:false,emailValue:'',passwordValue:'', resetPasswordEmail:''};
    this.logic = new Logic(this);
    if(!firebase.apps.length){ firebase.initializeApp(ApiKeys.FirebaseConfig); }
    this.logic.loadSurveys();

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let query = params.get('s');
    let admin= params.get('a');

    if(!admin || !query){
      document.location = '/';
    }else{
      this.state = {adminId:admin,surveyId:query,publicSurveyName:"",attemptingLogin:false,authenticating:true,loading:false,emailValue:'',passwordValue:'', resetPasswordEmail:''};

    }

  }

  render(){
    return(
      <div>
        {this.renderPage()}
      </div>
    );
  }


  renderPage = ()=>{
    if(this.state.loading){
      return(
        <div className="page-loading">
          <ClipLoader
            size={160}
            color={"#0056e0"}
            loading={true}
          />
        </div>
      );
    }else if(this.state.success){

      return(
        <div>

          <div className="page-header">
            <img src={inkelLogoDark} className="page-logo" />
          </div>

          <div className="page-content">

            <img className="public-survey-success-icon" src={tickIcon} />

            <div className="public-survey-success">
                Thank You! Your survey has been sent.
            </div>


          </div>


          <img src={yellowCurve} className="yellow-curve-image" />


        </div>
      )


    }else{
      return(
      <div>

        <div className="page-header">
          <img src={inkelLogoDark} className="page-logo" />
        </div>

        <div className="page-content">

          {this.renderOpenSurvey()}


        </div>


        <img src={yellowCurve} className="yellow-curve-image" />


      </div>

      );
    }
  }

  renderOpenSurvey = ()=>{
      if(!this.state.surveyOpen) return;

      return(
          <div>
            <br/><br/>
            <div className="survey-header" style={{borderRadius:'16px'}}>
              <img className="survey-header-icon" src={surveyIconDark} />
              {this.state.chosenSurvey.name}
            </div>

            <br/>

            <img className="survey-bar-image" src={surveyBar} />


            <div className="survey-content">

            <div className="survey-question">Please enter your full name:</div>
            <input className="public-survey-input" value={this.state.publicSurveyName} onChange={(event)=>{this.setState({publicSurveyName:event.target.value})}} />


              {
                this.state.chosenSurvey.questions.map((question, index)=>{
                  return(
                    <div className="survey-question">
                      {(index + 1)}. {question}
                      <RangeSlider
                          value={this.state.surveyQuestionValues[index]}
                          onChange={(changeEvent) => {this.logic.updateSurveyQuestionValue(changeEvent.target.value,index)}}
                          min={0}
                          max={10}
                          step={1}
                          tooltip="on"
                        />
                    </div>
                  )
                })
              }

              <br/>
              <div className="survey-question">By submitting this survey you agree to have your results sent to Inkel.Health.</div>

              <div className="submit-survey" onClick={this.logic.submitSurvey}>
                {Language.English.Surveys.SubmitSurvey}
              </div>
            </div>

          </div>
      )
  }



}

export default withAlert()(Login);
