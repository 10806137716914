
import Language from '../Language';

class Validate {

  //Compare Values
  static equals = (input1, input2)=>{
    if(input1 == input2){
      return true;
    }

    return false;
  }

  //Check array for empty parameters
  static hasEmptyParameters = (input)=>{

    for(let index = 0; index < input.length; index++){
      if(input[index].length == 0){
        return true;
      }
    }

    return false;
  }


  static registrationValidation = (name, email, newPassword, newPasswordConfirmation, accountType, companyName)=>{
      if(!this.equals(newPassword, newPasswordConfirmation)){
        //Passwords Don't Match
        return{
          succeeded:false,
          errorMessage:Language.English.Errors.Validate.passwordsDoNotMatch
        }

      }else if(this.hasEmptyParameters([name, email, newPassword, newPasswordConfirmation])){

        //Contains an empty value
        return{
          succeeded:false,
          errorMessage:Language.English.Errors.Validate.missingDetails
        }

      }else if(accountType=="company" && this.hasEmptyParameters([companyName])){
        //Company Type chosen but empty company name
        return{
          succeeded:false,
          errorMessage:Language.Errors.Validate.noCompanyName
        }
      }

      return{
        succeeded:true,
        errorMessage:null
      }


  }

}


export default Validate;
