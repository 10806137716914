import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

//Services & Local Logic
import Language from '../../../services/Language';

class ClientLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Open Panel to create entry
  openNewEntryPanel = ()=>{
    this.baseContext.setState({newEntryPanelOpen: true, newEntryEmotion:null, newEntryReflectionText:''});
  }

  //Close new entry panel (Not created)
  closeNewEntryPanel = ()=>{
    this.baseContext.setState({newEntryPanelOpen: false});
  }

  //Select Emotion in entry
  chooseEmotion = (emotion)=>{
    this.baseContext.setState({newEntryEmotion:emotion});
  }

  //getEntryEmoji
  getEntryEmoji = (emotion)=>{
    let emotions = this.baseContext.state.emotions;

    for(let index = 0; index < emotions.length; index++){
      if(emotions[index].name == emotion){
        return emotions[index].emoji;
      }
    }

  }

  //Open Panel for Goal Tracking
  openNewGoalTrackingPanel = ()=>{

    let scores = [];

    for(let index = 0;index < Language.English.Diary.GoalTrackingQuestions.length;index++){
      scores.push(5);
    }

    this.baseContext.setState({
      goalTrackingPanelOpen:true,
      goalTrackingScores:scores,
      goalTrackingQuestions:Language.English.Diary.GoalTrackingQuestions
    })


  }

  //Close Goal Tracking
  closeNewGoalTrackingPanel = ()=>{
    this.baseContext.setState({
      goalTrackingPanelOpen:false,
      goalTrackingScores:[]
    })
  }

  //Change Question Value
  updateGoalTrackingValue = (value,index)=>{
    let scores = this.baseContext.state.goalTrackingScores;

    scores[index] = value;

    this.baseContext.setState({goalTrackingScores:scores});
  }

  getGoalsScoreString = (scores)=>{
    return 'Total: '+parseInt(scores.reduce((a, b) => parseInt(a) + parseInt(b), 0))+'/40';
  }

  saveNewGoalTrackingEntry = async()=>{

    await this.baseContext.setState({loading:true});

    let currentDateTimeUTC = new Date().getTime() - (new Date().getTimezoneOffset()*60*1000);
    let currentUserId = firebase.auth().currentUser.uid;
    let scores = this.baseContext.state.goalTrackingScores;

    let diaryEntries = this.baseContext.state.entriesList;


    let newEntry = {type:'goal-tracking-entry',scores:scores, dateTimeUTC:currentDateTimeUTC};

    diaryEntries.reverse();
    diaryEntries.push(newEntry);
    diaryEntries.reverse();

    firebase.firestore().collection("users").doc(currentUserId).set({
      diaryEntries:diaryEntries
    },{merge:true}).then((result)=>{
      this.baseContext.showAlert("Entry saved successfully.");
      this.baseContext.setState({loading:false, entriesList: diaryEntries});
      this.closeNewEntryPanel();
    }).catch((err)=>{
      this.baseContext.showAlert("Error saving your entry.");
      this.baseContext.setState({loading:false});
    })

  }

  //Open an entry
  openEntry = (entry)=>{
    this.baseContext.setState({
      entryOpen:true,
      openEntryEmotion:this.getEntryEmoji(entry.emotion),
      openEntryDateString:this.dateTimeUTCToString(entry.dateTimeUTC),
      openEntryReflectionText:entry.reflectionText,
      openEntryScores:entry.scores,
      openEntryType:entry.type,
      goalTrackingQuestions:Language.English.Diary.GoalTrackingQuestions
    });
  }

  //Close an Entry
  closeEntry = ()=>{
    this.baseContext.setState({
      entryOpen:false
    });
  }

  //Get String for Date
  dateTimeUTCToString = (date)=>{
    let localDateTime = new Date(date).getTime() + (new Date().getTimezoneOffset()*60*1000);
    let localDate = new Date(localDateTime);

    let dateString = localDate.getDate()+'/'+(localDate.getMonth()+1)+'/'+localDate.getFullYear();
    let timeString = localDate.getHours()+':'+((localDate.getMinutes().toString().length == 1)? ('0'+localDate.getMinutes()):localDate.getMinutes());

    let dateTimeString = dateString+' '+timeString;

    return dateTimeString

  }

  getMonth = (date)=>{
    let localDateTime = new Date(date).getTime() + (new Date().getTimezoneOffset()*60*1000);
    let localDate = new Date(localDateTime);

    return (localDate.getMonth()+1);
  }

  trimString = (string)=>{
    if(string) return (string.length <= 20)? string:string.substring(0,20)+'...';
  }

  //Save An entry
  createNewDiaryEntry = async()=>{
    let chosenEmotion = this.baseContext.state.newEntryEmotion;
    let reflectionText = this.baseContext.state.newEntryReflectionText || '';
    let diaryEntries = this.baseContext.state.entriesList;
    let currentDateTimeUTC = new Date().getTime() - (new Date().getTimezoneOffset()*60*1000);
    let currentUserId = firebase.auth().currentUser.uid;

    if(!chosenEmotion || reflectionText.length == 0){
      this.baseContext.showAlert("Please fill out your emotion & reflection text!");
      return;
    }

    await this.baseContext.setState({loading:true});

    let newEntry = {type:'diary-entry',emotion:chosenEmotion, reflectionText:reflectionText, dateTimeUTC:currentDateTimeUTC};

    diaryEntries.reverse();
    diaryEntries.push(newEntry);
    diaryEntries.reverse();

    firebase.firestore().collection("users").doc(currentUserId).set({
      diaryEntries:diaryEntries
    },{merge:true}).then((result)=>{
      this.baseContext.showAlert("Entry saved successfully.");
      this.baseContext.setState({loading:false, entriesList: diaryEntries});
      this.closeNewEntryPanel();
    }).catch((err)=>{
      this.baseContext.showAlert("Error saving your entry.");
      this.baseContext.setState({loading:false});
    })

  }






}

export default ClientLogic;
