import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class ClientLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Open panel for adding payment
  openCreatePaymentPanel = ()=>{
    this.baseContext.setState({paymentPanelOpen:true});
  }

  //Open panel for adding payment
  closeCreatePaymentPanel = ()=>{
    this.baseContext.setState({paymentPanelOpen:false});
  }

  //Calculate total price of services
  calculateTotalPrice = (pricesArray, quantityArray)=>{

    if(pricesArray.length != quantityArray.length) return;

    let price = 0;
    for(let priceIndex = 0;priceIndex < pricesArray.length; priceIndex++){
      price += parseInt(pricesArray[priceIndex]) * parseInt(quantityArray[priceIndex]);
    }

    return price;
  }

  isOutDated = (inputTimeUTC)=>{
    return inputTimeUTC > (new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000));
  }

  isInPast = (inputTimeUTC)=>{
    return inputTimeUTC > (new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000));
  }

  //Load Plan Data
  loadPlanData = async()=>{
    await this.baseContext.setState({loading:true});

    let currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection('planRequests').doc(currentUserId).get().then((doc)=>{

      if(!doc.data()){
        this.baseContext.setState({loading:false, planRequested:false});
      }else{
        this.baseContext.setState({loading:false, planDescription:doc.data().planDescription,planPrice:doc.data().planPrice,planRequested:doc.data().planRequested, planPaid:doc.data().planPaid,processedByCoach:doc.data().processedByCoach, processedByInkelAdmin:doc.data().processedByInkelAdmin, endTimeUTC:doc.data().endTimeUTC});

      }
      }).catch((error)=>{
      this.baseContext.showAlert(error.message);
      this.baseContext.setState({loading:false});
    })
  }

  openPlanPaymentPanel = ()=>{
    this.baseContext.setState({
      planPaymentPanelOpen:true
    })
  }

  closePlanPaymentPanel = ()=>{
    this.baseContext.setState({
      planPaymentPanelOpen:false
    })
  }

  //Request a plan for an individual
  requestPlan = async()=>{

    if(!this.baseContext.state.accountStatus.coach){
      this.baseContext.showAlert("Please choose a coach at the dashboard before requesting a plan!");
    }

    await this.baseContext.setState({loading:true});



    let authToken = await firebase.auth().currentUser.getIdToken(true).then((idToken)=>{
      return {
        id:idToken,
        success:true
      }
    }).catch((error)=>{
      return {
        error:error.message,
        success:false
      }
    });

    if(!authToken.success){
      this.baseContext.showAlert("There was an error with your login details!");
      await this.baseContext.setState({loading:false, paymentPanelOpen:true});
      return;
    }



    var functions = firebase.app().functions('europe-west1');

    const currentUser = firebase.auth().currentUser;

    functions.httpsCallable('requestIndividualPlan')({
      token:authToken.id,
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false});
        window.location.reload();
      }else{
        this.baseContext.showAlert(result.data.errorMessage);
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });


  }

  openUpdateOrganizationPaymentPanel = async()=>{
    await this.baseContext.setState({loading:false, updatePaymentPanelOpen:true});
  }


  //Pay for an Individual Plan

  payForPlan = async(stripeToken)=>{
    await this.baseContext.setState({loading:true, paymentPanelOpen:false});

    let authToken = await firebase.auth().currentUser.getIdToken(true).then((idToken)=>{
      return {
        id:idToken,
        success:true
      }
    }).catch((error)=>{
      return {
        error:error.message,
        success:false
      }
    });

    if(!authToken.success){
      this.baseContext.showAlert("There was an error with your login details!");
      await this.baseContext.setState({loading:false, paymentPanelOpen:true});
      return;
    }



    var functions = firebase.app().functions('europe-west1');

    const currentUser = firebase.auth().currentUser;

    functions.httpsCallable('createUserPlan')({
      email:currentUser.email,
      token:authToken.id,
      stripeToken:stripeToken
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false});
        window.location.reload();
      }else{
        this.baseContext.showAlert(result.data.errorMessage);
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });

  }

  cancelOrganizationSubscription = async()=>{
    await this.baseContext.setState({loading:true, paymentPanelOpen:false});

    let authToken = await firebase.auth().currentUser.getIdToken(true).then((idToken)=>{
      return {
        id:idToken,
        success:true
      }
    }).catch((error)=>{
      return {
        error:error.message,
        success:false
      }
    });

    if(!authToken.success){
      this.baseContext.showAlert("There was an error with your login details!");
      await this.baseContext.setState({loading:false, paymentPanelOpen:true});
      return;
    }



    var functions = firebase.app().functions('europe-west1');

    const currentUser = firebase.auth().currentUser;

    functions.httpsCallable('cancelOrganizationPayment')({
      token:authToken.id,
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false});
        window.location.reload();
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });

  }


  createOrganisationPayment = async(stripeToken)=>{
    await this.baseContext.setState({loading:true, paymentPanelOpen:false});

    let authToken = await firebase.auth().currentUser.getIdToken(true).then((idToken)=>{
      return {
        id:idToken,
        success:true
      }
    }).catch((error)=>{
      return {
        error:error.message,
        success:false
      }
    });

    if(!authToken.success){
      this.baseContext.showAlert("There was an error with your login details!");
      await this.baseContext.setState({loading:false, paymentPanelOpen:true});
      return;
    }



    var functions = firebase.app().functions('europe-west1');

    const currentUser = firebase.auth().currentUser;

    functions.httpsCallable('createUserPaymentAccount')({
      email:currentUser.email,
      token:authToken.id,
      stripeToken:stripeToken
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false});
        window.location.reload();
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
      }

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    });

  }





  //Get user's current time zone
  getTimeZone = ()=>{
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }


}

export default ClientLogic;
