import React from 'react';

//Firebase Data
import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

// Assets
import inkelLogo from '../../assets/logo.svg';
import inkelLogoDark from '../../assets/logo-dark.svg';
import registerIcon from '../../assets/lock-icon.png';
import greenCurve from '../../assets/green-curve.png';

//Services
import Language from '../../services/Language';
import Validate from '../../services/Validate';

//Stripe Modules
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

class RegisterLogic {

  constructor(context){
    //Set the context value to that of the Parent class
    this.baseContext = context;
  }

  //Update Email Value
  handleEmailChange = (event)=>{
    this.baseContext.setState({emailValue: event.target.value});
  }

  //Update Password Value
  handlePasswordChange = (event)=>{
    this.baseContext.setState({passwordValue: event.target.value});
  }

  //Update Password Confirmation Value
  handlePasswordConfirmationChange = (event)=>{
    this.baseContext.setState({passwordConfirmationValue: event.target.value});
  }

  //Update Name Value
  handleNameChange = (event)=>{
    this.baseContext.setState({nameValue: event.target.value});
  }

  //Update Company Name Value
  handleCompanyNameChange = (event)=>{
    this.baseContext.setState({companyNameValue: event.target.value});
  }

attemptRegistration = async(event)=>{
      event.preventDefault();


      let state = this.baseContext.state;
      let validationAttempt = Validate.registrationValidation(state.nameValue, state.emailValue, state.passwordValue, state.passwordConfirmationValue, state.accountType, state.companyNameValue);

      if(!validationAttempt.succeeded){
        await this.baseContext.setState({error: validationAttempt.errorMessage});
        return;
      }

      this.attemptAccountCreation(state.nameValue, state.emailValue, state.passwordValue, state.accountType, state.companyNameValue);



    }


  //Called from attemptRegistration()
  //Connection to cloud functions to charge the card and attempt account creation
  attemptAccountCreation = async(userName, email, password, accountType, companyName)=>{
    await this.baseContext.setState({loading:true});

    var functions = firebase.app().functions('europe-west1');

    functions.httpsCallable('registerAccount')({
      email:email,
      password:password,
      userName:userName,
      accountType:accountType,
      companyName:companyName
    }).then(async(result)=>{

      if(result.data.success){
        await this.baseContext.setState({loading:false, registered:true});
      }else{
        await this.baseContext.setState({loading:false, error:result.data.errorMessage});
      }

    }).catch(async(error)=>{
      console.log(error);
      await this.baseContext.setState({loading:false});
    });

  }





}

export default RegisterLogic;
