
export default{

  English : {

    Errors:{
      Validate:{
        passwordsDoNotMatch:"Your passwords did not match!",
        missingDetails:"You are missing details!",
        noCompanyName:"Your Organisation Name cannot be empty!"
      }
    },

    GroupSurveys:{
      AdminHeader:'My Organisation Group Surveys',
      Details:'Survey Details'
    },

    Months:[
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],

    Login : {
      LoginWelcomeHeader:'Welcome',
      LoginWelcomeText:'We have created a people success platform where people can work, live and be well.',
      LoginHeader: 'Login to your Inkel Account',
      emailPlaceholder:'Email',
      passwordPlaceholder:'Password',
      LoginButtonText:'Log In',
      SignupLink:'Don\'t have an account? Sign up now',
      ResetPassword:'Reset Password',
      ResetPasswordButton:'Reset'
    },

    Register : {
      RegisterWelcomeHeader:'Welcome',
      RegisterWelcomeText:'We\'re Helping to create an organisational culture where people work well, thrive, and feel psychologically safe',
      RegisterHeader: 'Register your Inkel Account',
      namePlaceholder:'Name',
      companyNamePlaceholder:'Company Name',
      emailPlaceholder:'Email',
      passwordPlaceholder:'New Password',
      passwordConfirmationPlaceholder:'Confirm New Password',
      RegisterButtonText:'Register',
      LoginLink:'Have an account? Log In',
      cardTitle:'Please Pay',
      RegisterSuccessLoginLink:'Log In',
      CompanyChoice:"Register an Organisation",
      IndividualChoice:"Register as an Individual"
    },

    Menu:{
      Home:'Home',
      Surveys:'My Surveys',
      Inbox:'Messages',
      Invites:'Bookings',
      Calendar:'Calendar',
      Settings:'Settings',
      Subscriptions:'Subscriptions',
      PlanRequests:'Plan Requests',
      InkelAdmin:'Inkel Admin',
      Logout:'Log Out',
      CompanyAdmin:'Admin',
      Content:'Content',
      CompanyPlans:'My Plan',
      Diary:'Diary',
      OrganisationSurveys:'Group Surveys'
    },

    Dashboard:{
      HomeHeader:"Dashboard",
      MyCoursesHeader:"My Courses",
      NewButton:"New",
      MyUsersHeader:"My Clients",
      AddButton:"Create",
      Edit:"Edit",
      Live:"Live",
      NotLive:"Not Live",
      ModulesHeader:"Modules",
      AddModules:"Add Module",
      Live:"Live",
      Public:"Public",
      CancelButton:"Cancel",
      LessonsHeader:"Edit Module",
      AddLessons:"Add Lesson",
      NewLessonHeader:"New Lesson",
      SaveButton:"Save",
      EditLessonHeader:"Edit Lesson",
      ExistingFilesHeader:"Existing Files",
      NewFilesHeader:"New Files",
      ChooseCoach:"Please choose a coach to book a chemistry session.",
      ChooseCoachButton:"Schedule Chemistry Session",
      BookingConfirmation:'Thank you for requesting a chemistry session. Your coach will message you to discuss an appropriate date shortly. After this you may choose a plan or a new coach!',
      SessionRegistrationComplete:'Thank you for requesting a chemistry session. Your coach has set a session for:',
      SessionComplete:'Your chemistry session is complete. Please choose an option below:',
      AfterSessionNote:'After this you may choose a plan or a new coach!',
      NewCoachOption:'Choose A Different Coach',
      KeepCoachOption:'Keep Chosen Coach',
      SubscriptionRecommendation:'Please visit the \'Subscriptions\' section to create a subscription or coaching plan',
      NotBasicUser:'Sorry, you haven\'t added yourself to your company\'s list of basic users. You can do this in your \'Subscriptions\' section to use our services yourself.',
      CompanyAdminOption:'Me (Company Admin)',
      HoogaScores:'Hooga Survey Scores',
      AddUsers:'Add Users',
      SaveModuleName:'Save Details',
      MyModules:'Course Modules',
      BookCoachingSession:'Book Coaching Session',
      BookCheckinSession:'Book Checkin Session',
      MyContent:'My Content',
      MyWellbeing:'Hooga Score',
      MyCalendar:'My Calendar',
      MySurveys:'My Surveys',
      SendPulseSurvey:'Send Pulse Survey',
      SendCoreSurvey:'Send Core Survey',
      SendHopeSurvey:'Send Hope Survey',
      pulseSurveySent:'(Pulse Survey Sent)',
      coreSurveySent:'(Core Survey Sent)',
      hopeSurveySent:'(Hope Survey Sent)',
      PulseSurveyNotification:'New Pulse Survey Available',
      MyDiaryWidget:'My Diary',
      NotEnabled:'Your organisation is not enabled! Please contact your organisation admin or the Inkel Health admin.',
      OpenResultsButton:'View',
      AddOrganisations:'Add Organisations',
      AddOrganisation:'Add Organisation'
    },

    Calendar:{
      MyCalendar:'My Calendar',
      CreateEvent:'Create Event',
      EventDate:'Date: ',
      EventStart:'Start Time: ',
      EventEnd:'End Time: ',
      EventLink:'Call Link: '
    },

    Requests:{
      MyRequests:'Requests',
      From:'From: ',
      ReceivedOn:'Received: ',
      CreateBooking:'Create Booking',
      SendMessage:'Send Message',
      PreferredTime:'Preferred Booking Time: ',
      SendButton:'Send',
      To:'To: ',
      RequestLink:'Request Link',
      RequestDescription:'Request Description'
    },

    Messages:{
      MyMessages:'My Messages',
      CoachMessages:'Coach Messages',
      Message:'Message'
    },

    Settings:{
      MySettings:'My Settings',
      PersonalDetails:'Account Details',
      MyName:'My Name',
      ProfilePicture:'Profile Picture',
      MyProfilePicture:'My Profile Picture',
      MyEmail:'My Email',
      MyPassword:'My Password',
      Save:'Save',
      MyDescription:'My Coach Description',
      ChangeEmail:'Change Email',
      ChangeEmailHeader:'Please enter your new email & existing password to change it.',
      ChangePasswordHeader:'Please enter your old password & new password to change it.',
      OldPassword:'Old Password',
      NewPassword:'New Password',
      ChangePassword:'Change Password',
      NotificationsHeader:'Notifications',
      MyDays:'Diary Notification Days',
      Notifications:'Browser Diary Notifications',
      Emails:'Email Diary Notifications',
      NotificationTime:'Notifications Time (24-hour format)'
    },

    Weekdays:{
      Monday:'Monday',
      Tuesday:'Tuesday',
      Wednesday:'Wednesday',
      Thursday:'Thursday',
      Friday:'Friday',
      Saturday:'Saturday',
      Sunday:'Sunday'
    },

    Subscriptions:{
      MySubscriptions:'My Subscriptions',
      SubscriptionsHeader:'Subscription Manager',
      PlansHeader:'Plan Manager',
      NoPlan:'You don\'t currently have a plan. You can request one off your chosen coach below. They will then assign a price to your chosen plan.',
      NoSubscription:'You don\'t currently have a subscription. You can request one off your chosen coach below. They will then assign a price to your chosen plan.',
      RequestPayment:'Your Plan Has been updated. Please pay:',
      RequestPlan:'Request Plan',
      RequestPaymentSubscription:'Your Subscription Has been updated. Please pay:',
      RequestSubscription:'Request Subscription',
      PlanRequested:'You have requested a plan from your coach. They will return a price shortly and may message you regarding your requirements soon.',
      SubscriptionRequested:'You have requested a subscription from your coach. They will return a price shortly and may message you regarding your requirements soon.',

      NoCompanyStripeAccount:'You may add a payment method below to pay for your company\'s plan',
      NoCompanySubscription:'Your company hasn\'t paid for your current plan. You may pay monthly below or via bank transfer.',
      AddPayment:'New Payment Method',
      CreatePayment:'Create Payment Method',
      CancelSubscription:'Cancel Subscription',
      UpToDatePayment:'Your company\'s payment is up to date! Thanks for using Inkel Health.',

      PlanUpToDate:'You have paid for your current plan. We hope you enjoy working with our team!',
      CompanyPlanUpToDate:'You have accepted your current plan. We hope you enjoy working with our team!',
      RequestPlanPayment:'Your Plan Has been updated. Please pay below or discuss ammendments with your coach and request a new plan:',
      RequestPlanAcceptance:'Your Plan Has been updated. Please accept below or discuss ammendments with your coach and request a new plan:',
      RequestNewPlan:'Different Plan',
      NoCoach:'Please choose a coach before requesting a plan.',
      AcceptPlan:'Accept Plan',

      CancelPlan:'Cancel Plan Request'

    },

    PlanRequests:{
      PlanRequestsHeader:'Plan Requests',
      SendMessage:'Send Message',
      SendToAdmin:'Send To Admin',
      SendBackToUser:'Send to Client',
      Description:'Please Describe the client\'s requirements for the Inkel Admin, so they may price it for the user.',
      CompanyDescription:'Please Describe the client\'s requirements for the client, so they may assess if it is right for them.'
    },

    Surveys:{
      SurveysHeader:'My Surveys',
      Surveys:'Surveys',
      Charts:'My Stats',
      SubmitSurvey:'Submit Survey',
      MyWellbeing:'My Wellbeing Score',
      CoreValuesHeader:'Please choose 6 core values that you value most and give yourself a score for each, ranging from 1 to 10.',
      PulseSurvey:'Pulse Survey',
      NextStep:'Next Step',
      CoreValueEffortPlaceholder:'I will improve this by...',
      CoreValuesEffortsHeader:'Please enter your efforts to improve these scores below, along with the score you would give for this effort currently.',
      CoreValuesButton:'My Core Values',
      LastUpdated:'Last Updated: '
    },

    InkelAdmin:{
      InkelAdmin:'Inkel Admin',
      ManageOrganisationsHeader:'Organisations',
      PricingHeader:'Coaches Pricing',
      PricingLabel:'Price Per Coaching Session (€)',
      PricingPlaceholder:'Price (€)',
      PricingSaveButton:'Save',
      UserMonthlyPricingLabel:'Monthly price per user (€)',
      UserMonthlyPricingPlaceholder:'Price (€)',
      MonthlyCheckinPriceLabel:'Monthly Checkin Price (€)',
      MonthlyCheckinPricePlaceholder:'Price (€)',
      NewCompanyNamePlaceholder:'New Organisation Name',
      NewCompanyAddress1Placeholder:'New Organisation Address Line 1',
      NewCompanyAddress2Placeholder:'New Organisation Address Line 2',
      NewCompanyTownCityPlaceholder:'New Organisation Town/City',
      NewCompanyCountyPlaceholder:'New Organisation County',
      NewCompanyCountryPlaceholder:'New Organisation Country',
      NewCompanyPostcodePlaceholder:'New Organisation Postcode',
      NewCompanyPhonePlaceholder:'New Organisation Phone',
      NewCompanyAdminNamePlaceholder:'New Organisation Admin Name',
      NewCompanyAdminEmailPlaceholder:'New Organisation Admin Email',

      BasicUsersPlaceholder:'No. of Basic Users',
      CoachingServicesPlaceholder:'No. of Coaching Services',
      SessionsPlaceholder:'No. of Sessions per Coaching Service',
      CheckInServicePlaceholder:'No. of Users getting Check In service',
      AdditionalDetailsPlaceholder:'Additional Subscription Details',

      BasicUserPrice:'Price per basic user per month (€)',
      CoachingServicesPrice:'Price per coaching session  user/month(€)',
      CheckInPrice:'Price per checkIn service user/month (€)',
      ContentPrice:'Total Content Price (€)',

      Paid:'Paid',
      NotPaid:'Not Paid',
      Enabled:'Organisation Enabled',

      EditName:'Name',

      EditBasicUserPrice:'Monthly Basic User Price (€):',
      EditCoachingPrice:'Monthly Coaching Price (€):',
      EditMonthlyCheckinPrice:'Monthly Checkin Price (€):',
      EditMonthlyContentPrice:'Monthly Content Price (€):',

      EditBasicUserNumbers:'Monthly Basic Users:',
      EditCoachingNumbers:'Monthly Coaching Users:',
      EditMonthlyCheckinNumbers:'Monthly Checkin Users:',

      PaymentStatus:'Payment Status: ',
      SubmitDetails:'Submit',

      EditWebinars:'Webinars',
      AddWebinar:'Add Webinar',

      WebinarStartTime:'Start Time',
      WebinarEndTime:'End Time',
      WebinarDuration:'Duration',
      WebinarTitle:'Webinar Title',
      WebinarDescription:'Webinar Description',
      WebinarCallLink:'Webinar Call Link (Optional)',
      WebinarDateTime:'Please Choose a date, time & duration for the webinar.',

      Delete:'Delete',

      RequestsHeader:'Individual Plan Requests',
      SetPrice:'Set Price',
      SetPriceTitle:'Please set a price for the following plan (in Euro), based on the description provided by the Inkel Coach. You may need to consult further with the coach for more details first.',
      PlanPricePlaceholder:'Plan Price (€)',

      PayNow:'Pay Now',
      PaidManually:'Paid Manually'

    },

    CompanyAdmin:{
      BasicUsers:'Basic Users',
      CheckInUsers:'Check-in Users',
      CoachingUsers:'Coaching Services',
      UserSurveyScores:'User Survey Scores',
      HoogaSurveyScores:'Hooga Survey Scores',
      RemoveUser:'Remove',
      ChooseCoachingUser:'Choose a user to receive coaching',
      AddUser:'Add User',
      Admin:'Me (Company Admin)',
      HoogaSurveysHeader:'Average Hooga Scores',
      CompanyAdminHeader:'Company Admin',
      //Headers for table
      TableHeaderName:'Name',
      TableHeaderEngagement:'Engagement',
      TableHeaderRelationship:'Relationships',
      TableHeaderPhysical:'Physical',
      TableHeaderAchievement:'Achievement',
      TableHeaderMeaning:'Meaning',
      TableHeaderHappiness:'Happiness',
      TableHeaderOverall:'Overall',

      DownloadButton:'Download CSV'
    },

    Content:{
      MyCourses:'My Courses',
      CloseCourse:'Close Course',
      CloseModule:'Close Module',
      NoContentText:'Your coach will assign you content as you progress!'
    },

    Diary:{
      MyEntries:'My Diary Entries',
      NewButton:'New Entry',
      NewGoalTrackingButton:'Goal Tracking',
      AddButton:'Create Entry',
      NewEntryHeader:'Please choose an emotion you are feeling today.',
      NewEntryReflection:'Please enter your reflection, in your own words.',
      NewGoalTrackingEntryHeader:'Please enter your feelings on some of your goals',
      ReflectionInput:'Reflection...',
      GoalTrackingQuestions:[
          'How often do you feel you progressing toward your goals?',
          'In general, are you handling your responsibilities ?',
          'Do you feel your achieving the important goals you set?',
          'Are you feeling like you have a sense of direction?'
      ]
    }



  }

}
