import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Chart } from 'react-charts';
import { withAlert } from 'react-alert';
import RangeSlider from 'react-bootstrap-range-slider';

import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import binIcon from '../../../assets/icons/bin.png';
import courseIcon from '../../../assets/icons/course-icon.png';
import modulesIcon from '../../../assets/icons/course-module.png';
import fileIcon from '../../../assets/icons/file-icon.png';
import targetIcon from '../../../assets/icons/target.png';

import neutralEmoji from '../../../assets/icons/neutral.png';
import happyEmoji from '../../../assets/icons/happy.png';
import sadEmoji from '../../../assets/icons/sad.png';
import fearEmoji from '../../../assets/icons/fear.png';
import angerEmoji from '../../../assets/icons/angry.png';
import surpriseEmoji from '../../../assets/icons/surprise.png';

class CoachDashboard extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      newEntryPanelOpen:false,
      isBasicUser:props.accountStatus.isBasicUser,
      accountStatus:props.accountStatus,
      mainData:[],
      entriesList:props.accountStatus.diaryEntries || [],
      emotions:[{name:'Neutral', emoji:neutralEmoji},{name:'Happy', emoji:happyEmoji},{name:'Sad', emoji:sadEmoji},{name:'Fear', emoji:fearEmoji},{name:'Anger', emoji:angerEmoji},{name:'Surprise', emoji:surpriseEmoji}],
      entryMonths:['All Months','January','February','March','April','May','June','July','August','September','October','November','December']
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);

    this.filesInput = React.createRef();
    this.EditLessonFilesInput = React.createRef();
  }



  render(){
    if(this.state.loading){
      return(
        <div className="client-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="client-dashboard-loader-icon"
          />
        </div>
      );

    }else{
      return(
        <div className="client-dashboard-home" style={{overflowY:'auto'}}>
          {this.renderGoalTrackingPanel()}
          {this.renderDiaryPanel()}
          {this.renderEntryPanel()}
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Dashboard.HomeHeader} userName={this.props.userName} profileImage={this.props.profileImage}/>

          <div className="client-dashboard-content" style={{overflowY:'auto'}}>

                    <div className="client-diary-panel">

                    <div className="diary-panel-header">
                      <div className="diary-panel-text-container">
                        <div className="diary-panel-text">
                          {Language.English.Diary.MyEntries}
                        </div>
                      </div>

                      <div className="diary-panel-button-container">

                        <div className="diary-panel-new-button" onClick={this.logic.openNewGoalTrackingPanel}>
                          <img src={plusIcon} className="diary-button-icon" />
                          {Language.English.Diary.NewGoalTrackingButton}
                        </div>

                        <div className="diary-panel-new-button" onClick={this.logic.openNewEntryPanel}>
                          <img src={plusIcon} className="diary-button-icon" />
                          {Language.English.Diary.NewButton}
                        </div>

                      </div>

                    </div>

                    <div className="diary-month-container">
                      <select className="select-diary-month" value={this.state.diaryMonth} onChange={(event)=>{this.setState({diaryMonth:event.target.value})}}>


                        {
                          this.state.entryMonths.map((month, index)=>{

                              return(
                                <option key={month} value={index}>{month}</option>
                              )

                          })
                        }
                      </select>
                    </div>

                    <div className="diary-panel-content">

                      {
                        this.state.entriesList.map((entry,index)=>{

                          if((this.state.diaryMonth) && (this.state.diaryMonth != 0) && (this.logic.getMonth(entry.dateTimeUTC) != this.state.diaryMonth)) return;

                          if(entry.type == 'goal-tracking-entry'){
                            return(
                              <div className="goal-tracking-entry-row" onClick={()=>{this.logic.openEntry(entry)}}>
                                <img className="goal-tracking-entry-icon" src={targetIcon} />
                                <div className="diary-entry-text">{this.logic.dateTimeUTCToString(entry.dateTimeUTC)}</div>
                                <div className="diary-entry-text">{this.logic.getGoalsScoreString(entry.scores)}</div>
                              </div>
                            )
                          }else{
                            return(
                              <div className="diary-entry-row" onClick={()=>{this.logic.openEntry(entry)}}>
                                <img className="diary-entry-icon" src={this.logic.getEntryEmoji(entry.emotion)} />
                                <div className="diary-entry-text">{this.logic.dateTimeUTCToString(entry.dateTimeUTC)}</div>
                                <div className="diary-entry-text">{this.logic.trimString(entry.reflectionText)}</div>
                              </div>
                            )
                          }
                        })
                      }

                    </div>

                    </div>

          </div>

        </div>
      );
    }
  }

  renderEntryPanel = ()=>{
    if(this.state.entryOpen){

      if(this.state.openEntryType == 'goal-tracking-entry'){
        return(
          <div className="diary-entry-panel">
            <img src={xButton} className="diary-entry-close-button" onClick={this.logic.closeEntry}/>
            <div className="diary-entry-form">
              <div className="diary-entry-form-header">
                  {Language.English.Diary.NewGoalTrackingButton}
              </div>

              <div className="diary-entry-date-header">{this.state.openEntryDateString}</div>

              <div className="goal-tracking-entry-header">{this.logic.getGoalsScoreString(this.state.openEntryScores)}</div>

              {
                this.state.goalTrackingQuestions.map((question,index)=>{
                  return(
                    <div className="goal-tracking-answer-display">
                      {question}
                      <div className="goal-tracking-score-display">
                        {' Score: '+this.state.openEntryScores[index]+'/10'}
                      </div>
                    </div>
                  )
                })
              }

              </div>
          </div>
        )
      }else{
        return(
          <div className="diary-entry-panel">
            <img src={xButton} className="diary-entry-close-button" onClick={this.logic.closeEntry}/>
            <div className="diary-entry-form">

              <img className="diary-entry-emotion-header-icon" src={this.state.openEntryEmotion} />

              <div className="diary-entry-date-header">{this.state.openEntryDateString}</div>

              <div className="diary-entry-header">{this.state.openEntryReflectionText}</div>

              </div>
          </div>
        )
      }

    }
  }

  renderGoalTrackingPanel = ()=>{
    if(this.state.goalTrackingPanelOpen){
      return(
        <div className="diary-entry-panel">
          <img src={xButton} className="diary-entry-close-button" onClick={this.logic.closeNewGoalTrackingPanel}/>
          <div className="diary-entry-form">

            <div className="diary-entry-header">{Language.English.Diary.NewGoalTrackingEntryHeader}</div>

            {
              this.state.goalTrackingQuestions.map((question,index)=>{
                return(
                  <div className="goal-tracking-question">
                    {question}
                    <RangeSlider
                        value={this.state.goalTrackingScores[index]}
                        onChange={(changeEvent) => {this.logic.updateGoalTrackingValue(changeEvent.target.value,index)}}
                        min={0}
                        max={10}
                        step={1}
                        tooltip="on"
                      />
                  </div>
                )
              })
            }

            <div className="diary-entry-submit-button" onClick={this.logic.saveNewGoalTrackingEntry}>{Language.English.Diary.AddButton}</div>


          </div>

        </div>
      )
    }
  }



  renderDiaryPanel = ()=>{
    if(this.state.newEntryPanelOpen){

      return(
        <div className="diary-entry-panel">
          <img src={xButton} className="diary-entry-close-button" onClick={this.logic.closeNewEntryPanel}/>
          <div className="diary-entry-form">

            <div className="diary-entry-header">{Language.English.Diary.NewEntryHeader}</div>

            {this.state.emotions.map((emotion,index)=>{
              if(emotion.name == this.state.newEntryEmotion){
                return(
                  <div className="diary-entry-emotion-chosen">
                    <img className="diary-entry-emotion-icon" src={emotion.emoji} />
                    <div className="diary-entry-emotion-text">{emotion.name}</div>
                  </div>
                )
              }

              return(
                <div className="diary-entry-emotion" onClick={()=>{this.logic.chooseEmotion(emotion.name)}}>
                  <img className="diary-entry-emotion-icon" src={emotion.emoji} />
                  <div className="diary-entry-emotion-text">{emotion.name}</div>
                </div>
              )

            })}

            <div className="diary-entry-header">{Language.English.Diary.NewEntryReflection}</div>

            <textarea placeholder={Language.English.Diary.ReflectionInput} className="diary-entry-reflection-input" value={this.state.newEntryReflectionText} onChange={(event)=>{this.setState({newEntryReflectionText:event.target.value})}} />

            <div className="diary-entry-submit-button" onClick={this.logic.createNewDiaryEntry}>{Language.English.Diary.AddButton}</div>
          </div>
        </div>
      )

    }
  }



}

export default withAlert()(CoachDashboard);
