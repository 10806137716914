import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class ClientLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Load Survey Data
  loadSurveyData = async()=>{
    const currentUserId = firebase.auth().currentUser.uid;


    firebase.firestore().collection("users").doc(currentUserId).collection('surveyData').doc('UpZyaeSe0Jb0HCmcWjU4').get().then(async(doc)=>{

      if(!doc.data() || !doc.data().results ){
        await this.baseContext.setState({engagementData:[], productivityData:[], physicalData:[], achievementData:[]});
        return;
      }

      let results = doc.data().results || [];

      results.sort((a, b) => (a.dateTimeUTC > b.dateTimeUTC) ? 1 : (a.dateTimeUTC === b.dateTimeUTC) ? ((a.size > b.size) ? 1 : -1) : -1 );
      results.reverse();


      let engagementDataArray = [];
      let relationshipsDataArray = [];
      let physicalDataArray = [];
      let achievementDataArray = [];
      let meaningDataArray = [];
      let happinessDataArray = [];
      let overallDataArray = [];

      let timestampsArray = [];

      for(let resultIndex = 0; (resultIndex < results.length)&&(resultIndex < 3); resultIndex++){
        let engagementMean = (parseInt(results[resultIndex]['answers'][1]) + parseInt(results[resultIndex]['answers'][9]) + parseInt(results[resultIndex]['answers'][22])) / 3;
        let relationshipsMean = (parseInt(results[resultIndex]['answers'][7]) + parseInt(results[resultIndex]['answers'][15]) + parseInt(results[resultIndex]['answers'][17])) / 3;
        let physicalMean = (parseInt(results[resultIndex]['answers'][5]) + parseInt(results[resultIndex]['answers'][10]) + parseInt(results[resultIndex]['answers'][14])) / 3;
        let achievementMean = (parseInt(results[resultIndex]['answers'][0]) + parseInt(results[resultIndex]['answers'][4]) + parseInt(results[resultIndex]['answers'][21])) / 3;
        let meaningMean = (parseInt(results[resultIndex]['answers'][6]) + parseInt(results[resultIndex]['answers'][8]) + parseInt(results[resultIndex]['answers'][16])) / 3;
        let happinessMean = parseInt(results[resultIndex]['answers'][19]);

        let overallMean = this.calculateTotalAverage(results[resultIndex]['answers']);

        engagementDataArray.push([2 - resultIndex, engagementMean]);
        relationshipsDataArray.push([2 - resultIndex, relationshipsMean]);
        physicalDataArray.push([2 - resultIndex, physicalMean]);
        achievementDataArray.push([2 - resultIndex, achievementMean]);
        meaningDataArray.push([2 - resultIndex, meaningMean]);
        happinessDataArray.push([2 - resultIndex, happinessMean]);

        overallDataArray.push([2 - resultIndex, overallMean]);

        timestampsArray.push(results[resultIndex]['dateTimeUTC']);



      }

      engagementDataArray.reverse();
      relationshipsDataArray.reverse();
      physicalDataArray.reverse();
      achievementDataArray.reverse();
      meaningDataArray.reverse();
      happinessDataArray.reverse();
      overallDataArray.reverse();
      timestampsArray.reverse();

      let mostRecentOverallScore = overallDataArray[overallDataArray.length - 1][1];

      let mostRecentEngagementScore = engagementDataArray[engagementDataArray.length - 1][1];
      let mostRecentRelationshipScore = relationshipsDataArray[relationshipsDataArray.length - 1][1];
      let mostRecentPhysicalScore = physicalDataArray[physicalDataArray.length - 1][1];
      let mostRecentAchievementScore = achievementDataArray[achievementDataArray.length - 1][1];
      let mostRecentMeaningScore = meaningDataArray[meaningDataArray.length - 1][1];
      let mostRecentHappinessScore = happinessDataArray[happinessDataArray.length - 1][1];

      let mostRecentDate = new Date(new Date(timestampsArray[timestampsArray.length - 1]).getTime() + (new Date().getTimezoneOffset()*60*1000));

      let mostRecentDateFormatted = mostRecentDate.getDate()+'/'+(mostRecentDate.getMonth()+1)+'/'+mostRecentDate.getFullYear();


      let engagementData = {
        label:'Engagement',
        data:engagementDataArray
      }

      let relationshipsData = {
        label:'Relationships',
        data:relationshipsDataArray
      }

      let physicalData = {
        label:'Physical',
        data:physicalDataArray
      }

      let achievementData = {
        label:'Achievement',
        data:achievementDataArray
      }

      let meaningData = {
        label:'Meaning',
        data:meaningDataArray
      }

      let happinessData = {
        label:'Happiness',
        data:happinessDataArray
      }

      let overallData = {
        label:'Overall Wellbeing',
        data:overallDataArray
      }




      let mainData = [engagementData, relationshipsData, physicalData, achievementData, meaningData, happinessData]

      console.log(100 - Math.round(parseFloat(mostRecentPhysicalScore)).toString());

      this.baseContext.setState({
        mainData: mainData,
        recentWellbeingScore:Math.round(parseFloat(mostRecentOverallScore)).toString(),
        recentEngagementScore:Math.round(parseFloat(mostRecentEngagementScore)).toString(),
        recentHappinessScore:Math.round(parseFloat(mostRecentHappinessScore)).toString(),
        recentPhysicalScore:Math.round(parseFloat(mostRecentPhysicalScore)).toString(),
        recentAchievementScore:Math.round(parseFloat(mostRecentAchievementScore)).toString(),
        recentMeaningScore:Math.round(parseFloat(mostRecentMeaningScore)).toString(),
        recentRelationshipScore:Math.round(parseFloat(mostRecentRelationshipScore)).toString(),
        recentDate:mostRecentDateFormatted
      });

    });
  }

  calculateTotalAverage = (array)=>{

    let total = 0;

    for(let index = 0;index < array.length;index++){
      total += parseInt(array[index]);
    }

    return (total / array.length);

  }

  closePulseSurveyPanel = ()=>{
    this.baseContext.setState({
      pulseSurveyOpen:false,
      chosenSurvey:null
    })
  }


  //Load Surveys
  loadSurveys = async()=>{
    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("surveys").get().then(async(surveys)=>{

        let surveyArray = [];

        surveys.forEach((survey) => {

          let surveyData = survey.data();
          surveyData['id'] = survey.id;
          surveyArray.push(surveyData);
        });

        console.log(surveyArray);

        await this.baseContext.setState({loading:false, surveys:surveyArray});

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    })

  }

  //Update Values for Pulse Survey Sliders
  updatePulseQuestionValue = (value,section,index)=>{
    let values = this.baseContext.state.pulseQuestionValues;

    values[section][index] = parseInt(value);

    console.log(values[section]);

    this.baseContext.setState({pulseQuestionValues:values});
  }

  //Submit Pulse Survey
  submitPulseSurvey = async()=>{
    let currentUserId = firebase.auth().currentUser.uid;

    let pulseSurveys = this.baseContext.state.accountStatus.pulseSurveyResults;
    let answers = {};
    let dateTimeUTC = new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000);

    let newAnswers = this.baseContext.state.pulseQuestionValues;
    let sections = this.baseContext.state.pulseSurveyQuestionSections;

    for(let sectionsIndex = 0; sectionsIndex < sections.length;sectionsIndex++){
      answers[sections[sectionsIndex]] = newAnswers[sectionsIndex];
    }


    pulseSurveys.push({answers:answers, dateTimeUTC:dateTimeUTC});

    await this.baseContext.setState({loading:true});

    firebase.firestore().collection("users").doc(currentUserId).set({
      pulseSurveyResults:pulseSurveys,
      pulseSurveyAvailable:false
    },{merge:true}).then((result)=>{
      window.location.reload();
    }).catch((error)=>{
      this.baseContext.showALert("Error: "+error.message);
      this.baseContext.setState({loading:false});
    })
  }

  //Close Hope Survey
  closeHopeSurveyPanel = ()=>{
    this.baseContext.setState({hopeSurveyOpen:false});
  }

  //Open Survey Panel
  openSurveyPanel = (survey)=>{


    if(survey.name == 'Pulse Survey'){

      let questionsMap = survey.questions;

      let categoriesArray = [];
      let valuesArray = [];

      for (const [key, value] of Object.entries(questionsMap)) {
        categoriesArray.push(key);

        let categoryValues = [];

        for(let valuesIndex = 0;valuesIndex < value.length; valuesIndex++){
          categoryValues.push(4);
        }

        valuesArray.push(categoryValues);
      }

      console.log(valuesArray);

      this.baseContext.setState({
        pulseSurveyOpen:true,
        pulseSurveyQuestions:questionsMap,
        pulseSurveyQuestionSections:categoriesArray,
        pulseQuestionValues:valuesArray,
        chosenSurvey:{
          name:'Pulse Survey'
        }
      })


      return;

    }else if(survey.name == 'Hope Survey'){

      let questionsMap = survey.questions;

      let valuesArray = [];

      for(let valuesIndex = 0;valuesIndex < questionsMap.length; valuesIndex++){
        valuesArray.push(5);
      }

      console.log(valuesArray);

      this.baseContext.setState({
        hopeSurveyOpen:true,
        hopeSurveyQuestions:questionsMap,
        hopeQuestionValues:valuesArray,
        chosenSurvey:{
          name:'Hope Survey'
        }
      })

      return;


    }else if(survey.name == 'Core Values Survey'){

      let categoriesArray = [];
      let valuesMap = survey.values;

      for (const [key, value] of Object.entries(survey.values)) {
        categoriesArray.push(key);
      }

      let categories = categoriesArray;
      let values = valuesMap[categories[0]];

      let coreValuesData = [
        {score:5,category:categories[0],value:values[0],valueOptions:values,effortsNote:'',effortsScore:5},
        {score:5,category:categories[0],value:values[0],valueOptions:values,effortsNote:'',effortsScore:5},
        {score:5,category:categories[0],value:values[0],valueOptions:values,effortsNote:'',effortsScore:5},
        {score:5,category:categories[0],value:values[0],valueOptions:values,effortsNote:'',effortsScore:5},
        {score:5,category:categories[0],value:values[0],valueOptions:values,effortsNote:'',effortsScore:5},
        {score:5,category:categories[0],value:values[0],valueOptions:values,effortsNote:'',effortsScore:5}
      ]

      this.baseContext.setState({coreValuesOpen:true, coreValuesChosen:false,chosenSurvey: survey, surveyAttributes:survey.values, coreValuesData:coreValuesData, coreValuesList:values, coreValuesMap:valuesMap,coreValuesCategories:categories});
      return;
    }

    let questionValues = [];

    for(let index = 0;index < survey.questions.length;index++){
      questionValues[index] = 5;
    }

    console.log(questionValues);

    this.baseContext.setState({surveyOpen:true, chosenSurvey: survey, surveyQuestionValues:questionValues});
  }

  //Close Survey Panel
  closeSurveyPanel = ()=>{
    this.baseContext.setState({surveyOpen:false, coreValuesOpen:false});
  }

  //Opens Core Values Results
  openCoreValuesDisplay = ()=>{
    this.baseContext.setState({coreValuesDisplayOpen:true});
  }

  //Closes Core Values Results
  closeCoreValuesDisplay = ()=>{
    this.baseContext.setState({coreValuesDisplayOpen:false});
  }

  getLastCoreValueDate = ()=>{
    let lastDate = this.baseContext.state.coreValues[this.baseContext.state.coreValues.length - 1].timestampUTC;
    let localLastDate = lastDate + (new Date().getTimezoneOffset()*60*1000);

    let localDate = new Date(localLastDate);

    let dateString = localDate.getDate()+'/'+(localDate.getMonth()+1)+'/'+localDate.getFullYear();
    let timeString = localDate.getHours()+':'+((localDate.getMinutes().toString().length == 1)? ('0'+localDate.getMinutes()):localDate.getMinutes());

    let dateTimeString = dateString+' '+timeString;

    return dateTimeString

  }

  getCoreValueData = (index)=>{
    let coreValue = this.baseContext.state.coreValues[this.baseContext.state.coreValues.length - 1].data[index];

    return 'Value: '+coreValue.value+' (Score: '+coreValue.score+')';

  }


  getCoreValueNotes = (index)=>{
    let coreValue = this.baseContext.state.coreValues[this.baseContext.state.coreValues.length - 1].data[index];

    return 'Notes: '+(coreValue.effortsNote || '')+' (Efforts Score: '+(coreValue.effortsScore || '0')+')';
  }

  //Submit Survey
  submitSurvey = async()=>{
    await this.baseContext.setState({loading:true, surveyOpen:false});

    let currentUserId = firebase.auth().currentUser.uid;

    let surveyId = this.baseContext.state.chosenSurvey.id;
    let answersArray = this.baseContext.state.surveyQuestionValues;
    let dateTimeUTC = new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000);

    let surveyData = await firebase.firestore().collection("users").doc(currentUserId).collection("surveyData").doc(surveyId).get().then(async(surveyData)=>{

        let surveyResults = [];

        if(surveyData.data()) surveyResults = surveyData.data().results;

        return surveyResults;

    });

    let surveyDataArray = surveyData || [];

    surveyDataArray.push({answers: answersArray, dateTimeUTC:dateTimeUTC});

    firebase.firestore().collection("users").doc(currentUserId).collection("surveyData").doc(surveyId).set({
      results: surveyDataArray
    }, {merge:true}).then((result)=>{
      this.baseContext.showAlert("Survey Complete!");
      this.baseContext.setState({loading:false});
    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
      this.baseContext.setState({loading:false});
    })

    if(this.baseContext.state.chosenSurvey.name == 'Hooga Survey'){
      let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
          return idToken;
      });


      var functions = firebase.app().functions('europe-west1');

      functions.httpsCallable('sendHoogaInterventionEmail')({
        token:token,
        hoogaData:answersArray
      }).catch((error)=>{

      })
    }


  }

  coreValuesNextStep = async()=>{
    await this.baseContext.setState({coreValuesChosen:true});
    document.getElementById('container-x').scrollTop = 0;
  }

  submitCoreValues = async()=>{

    await this.baseContext.setState({loading:true, surveyOpen:false, coreValuesOpen:false});

    let currentUserId = firebase.auth().currentUser.uid;

    let coreValuesArray = this.baseContext.state.coreValues;
    let coreValues = this.baseContext.state.coreValuesData;
    let timestampUTC = new Date().getTime() - (new Date().getTimezoneOffset()*60*1000);

    coreValuesArray.push({data:coreValues, timestampUTC:timestampUTC});

    firebase.firestore().collection("users").doc(currentUserId).set({
      coreValues:coreValuesArray,
      coreSurveyAvailable:false
    }, {merge:true}).then((result)=>{
      this.baseContext.showAlert("Survey Complete!");

      let accountStatus = this.baseContext.state.accountStatus;
      accountStatus.coreSurveyAvailable = false;

      this.baseContext.setState({loading:false, accountStatus:accountStatus});
    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
      this.baseContext.setState({loading:false});
    })


  }

  //Submit Hope Survey
  submitHopeSurvey = async()=>{

    await this.baseContext.setState({loading:true, hopeSurveyOpen:false});

    let currentUserId = firebase.auth().currentUser.uid;

    let timestampUTC = new Date().getTime() - (new Date().getTimezoneOffset()*60*1000);

    let hopeSurveyData = (Array.isArray(this.baseContext.state.accountStatus.hopeSurveyValues))?this.baseContext.state.accountStatus.hopeSurveyValues : [];

    hopeSurveyData.push({data:this.baseContext.state.hopeQuestionValues,timestampUTC:timestampUTC});

    firebase.firestore().collection("users").doc(currentUserId).set({
      hopeSurveyValues:hopeSurveyData,
      hopeSurveyAvailable:false
    }, {merge:true}).then((result)=>{
      this.baseContext.showAlert("Survey Complete!");

      let accountStatus = this.baseContext.state.accountStatus;
      accountStatus.hopeSurveyAvailable = false;

      this.baseContext.setState({loading:false,accountStatus:accountStatus});
    }).catch((error)=>{
      this.baseContext.showAlert(error.message);
      this.baseContext.setState({loading:false});
    })


  }

  //Update Survey Question Value
  updateSurveyQuestionValue = (value, index)=>{
    let surveyQuestionValues = this.baseContext.state.surveyQuestionValues;

    surveyQuestionValues[index] = parseInt(value);

    this.baseContext.setState({surveyQuestionValues:surveyQuestionValues});
  }

  //Update Hope Question Value
  updateHopeQuestionValue = (value, index)=>{
    let surveyQuestionValues = this.baseContext.state.hopeQuestionValues;

    surveyQuestionValues[index] = parseInt(value);

    this.baseContext.setState({hopeQuestionValues:surveyQuestionValues});
  }

  //Update slider on Core Value Survey
  updateCoreValue = (value, index)=>{
    let coreValuesData = this.baseContext.state.coreValuesData;

    coreValuesData[index].score = parseInt(value);

    this.baseContext.setState({coreValuesData:coreValuesData});
  }

  //Update slider on Core Value Survey
  updateCoreValueNote = (value, index)=>{
    let coreValuesData = this.baseContext.state.coreValuesData;

    coreValuesData[index].effortsNote = value;

    this.baseContext.setState({coreValuesData:coreValuesData});
  }


  //Update slider on Core Value Effort
  updateCoreValueEffort = (value, index)=>{
    let coreValuesData = this.baseContext.state.coreValuesData;

    coreValuesData[index].effortsScore = value;

    this.baseContext.setState({coreValuesData:coreValuesData});
  }

  //Update selection for core value
  updateCoreValueSelection = (value, index)=>{
    let coreValuesData = this.baseContext.state.coreValuesData;

    coreValuesData[index].value = value;

    this.baseContext.setState({coreValuesData:coreValuesData});
  }

  //Update Core Value Category
  updateCoreValueCategory = (value, index)=>{
    let coreValuesData = this.baseContext.state.coreValuesData;

    coreValuesData[index].category = value;
    coreValuesData[index].value = this.baseContext.state.surveyAttributes[value][0];
    coreValuesData[index].valueOptions = this.baseContext.state.surveyAttributes[value];

    this.baseContext.setState({coreValuesData:coreValuesData});
  }



  //Get user's current time zone
  getTimeZone = ()=>{
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }


}

export default ClientLogic;
