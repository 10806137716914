import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

//Services
import Language from '../../../services/Language';

class CoachLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  //Open Send Message Panel
  openSendMessagePanel = (request)=>{
    this.baseContext.setState({sendMessagePanelOpen:true, sendMessageLoading:false, chosenRequest:request});
  }

  //Close Send Message Panel
  closeSendMessagePanel = ()=>{
    this.baseContext.setState({sendMessagePanelOpen:false, sendMessageLoading:false});
  }

  //Send Message
  sendMessage = async()=>{
    if(this.baseContext.state.newMessage == ""){
      this.baseContext.showAlert("Please enter a message!");
      return;
    }

    await this.baseContext.setState({sendMessageLoading:true});

    var database = firebase.database();
    const currentUserId = firebase.auth().currentUser.uid;



    firebase.database().ref('messages/' +currentUserId+"/"+this.baseContext.state.chosenRequest.id).update({
      name: this.baseContext.state.chosenRequest.fromName || null,
      profileURL: this.baseContext.state.chosenRequest.profileURL || null
    })

    firebase.database().ref('messages/' +currentUserId+"/"+this.baseContext.state.chosenRequest.id).push({
      message: this.baseContext.state.newMessage,
      timestamp: (new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000),
      fromId:currentUserId,
      toId:this.baseContext.state.chosenRequest.id
    }).then(async()=>{
      await this.baseContext.setState({sendMessageLoading:false, sendMessagePanelOpen:false, newMessage:""});
      const currentUserId = firebase.auth().currentUser.uid;

      let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
          return idToken;
      });


      var functions = firebase.app().functions('europe-west1');

      functions.httpsCallable('sendMessageEmail')({
        token:token,
        receiverId:this.baseContext.state.chosenRequest.id,
        senderName:this.baseContext.state.accountStatus.name
      });
      this.baseContext.showAlert("Message sent!");
    }).catch(async(error)=>{
      await this.baseContext.setState({sendMessageLoading:false, sendMessagePanelOpen:false, newMessage:""});
      this.baseContext.showAlert("Error: "+error);
    })
  }

  //Open Send To Admin Panel
  openSendToAdminPanel = (request)=>{
    this.baseContext.setState({createBookingPanelOpen:true, createBookingLoading:false, chosenRequest:request});
  }

  //Close Create Booking Panel
  closeCreateBookingPanel = ()=>{
    this.baseContext.setState({createBookingPanelOpen:false, createBookingLoading:false});
  }

  //Change chosen session date
  changeChosenDate = (date)=>{
    this.baseContext.setState({chosenDate:date});
  }

  //Load coach's bookings
  loadBookings = async()=>{
    await this.baseContext.setState({loading:true});

    let requests = [];

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("planRequests").where("coach","==",currentUserId).get().then((querySnapshot) => {

      querySnapshot.forEach((doc) => {
          let bookingData = doc.data();

          if(!doc.data().processedByCoach){
            bookingData['id'] = doc.id;
            requests.push(bookingData);
          }

        });

      this.baseContext.setState({loading:false, requests:requests});
      console.log(requests);
    })


  }

  //Convert a timestamp to local time in string format
  convertToLocalTime = (timestamp)=>{

    let localTime = (timestamp!=null) ? (timestamp - (new Date().getTimezoneOffset() * 60 * 1000)) : 1;

    let localDateTime = new Date(localTime);
    let localHours = localDateTime.getHours().toString().length == 2 ? localDateTime.getHours():"0"+localDateTime.getHours();
    let localMinutes = localDateTime.getMinutes().toString().length == 2 ? localDateTime.getMinutes():"0"+localDateTime.getMinutes();
    let months = Language.English.Months;
    return localDateTime.getDate()+"/"+(months[localDateTime.getMonth()])+"/"+localDateTime.getFullYear()+" "+localHours+":"+localMinutes;
  }

  //Get user's current time zone
  getTimeZone = ()=>{
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }

  openSendToUserPanel = (request)=>{
    this.baseContext.setState({sendBackToUserPanelOpen:true, chosenRequest:request});
  }

  closeSendToUserPanel = ()=>{
    this.baseContext.setState({sendBackToUserPanelOpen:false});
  }

  sendBackToUser = async()=>{
    if(this.baseContext.state.planDescription == ''){
      this.baseContext.showAlert("Please enter a description of the plan for your client");
      return;
    }

    await this.baseContext.setState({loading:true});

    firebase.firestore().collection("planRequests").doc(this.baseContext.state.chosenRequest.id).set({
      planDescription:this.baseContext.state.planDescription,
      processedByCoach:true,
    },{merge:true}).then(async()=>{
      await this.baseContext.setState({loading:false});


      let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
          return idToken;
      });


      var functions = firebase.app().functions('europe-west1');

      await functions.httpsCallable('sendAdminRequestConfirmationEmail')({
        token:token,
        receiverId:this.baseContext.state.chosenRequest.id,
      })

      this.baseContext.showAlert("Successfully Sent Plan To User!");


      window.location.reload();
    }).catch(async(error)=>{
      await this.baseContext.setState({loading:false});
      this.closeCreateBookingPanel();
      this.baseContext.showAlert("Error: "+error.message);
    })
  }

  sendToAdmin = async()=>{
    await this.baseContext.setState({loading:true});

    if(this.baseContext.state.planDescription == ''){
      this.baseContext.showAlert("Please enter a description of the plan for your client");
      return;
    }

    firebase.firestore().collection("planRequests").doc(this.baseContext.state.chosenRequest.id).set({
      planDescription:this.baseContext.state.planDescription,
      processedByInkelAdmin:false,
      processedByCoach:true,
    },{merge:true}).then(async()=>{
      await this.baseContext.setState({loading:false});
      this.baseContext.showAlert("Successfully Booked!");

      let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
          return idToken;
      });

      var functions = firebase.app().functions('europe-west1');

      functions.httpsCallable('sendAdminNewRequestEmail')({
        token:token
      })

      window.location.reload();
    }).catch(async(error)=>{
      await this.baseContext.setState({loading:false});
      this.closeCreateBookingPanel();
      this.baseContext.showAlert("Error: "+error.message);
    })
  }


  //Attempt a booking confirmation
  createBookingRequest = async()=>{
    let chosenDate = this.baseContext.state.chosenDate;
    let chosenHours  = this.baseContext.state.chosenHours;
    let chosenMinutes  = this.baseContext.state.chosenMinutes;

    chosenDate = new Date(chosenDate);
    chosenDate.setHours(parseInt(chosenHours));
    chosenDate.setMinutes(parseInt(chosenMinutes));

    let offset = new Date().getTimezoneOffset() * 60 * 1000;
    let chosenTime = chosenDate.getTime();
    chosenTime -= offset;

    console.log(chosenTime);

    await this.baseContext.setState({createBookingLoading:true});

  }


}

export default CoachLogic;
