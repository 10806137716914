import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import { withAlert } from 'react-alert';

import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import whitePlusIcon from '../../../assets/white-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import defaultProfileImage from '../../../assets/profile-picture.png';
import surveyIconDark from '../../../assets/icons/surveys-triangle-2.png';
import surveyBar from '../../../assets/icons/survey-bar.png';
import pulseBar from '../../../assets/icons/pulse-bar.png';
import coreValuesIcon from '../../../assets/icons/core-values-triangle.png';
import lockTriangle from '../../../assets/lock-triangle.png';
import hopeSurveyBar from '../../../assets/icons/hope-survey-bar.png';

import 'react-big-calendar/lib/css/react-big-calendar.css';

class ClientSurveys extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      accountStatus:props.accountStatus,
      myName:props.accountStatus.name,
      myCompanyName:props.accountStatus.companyName,
      profileImage:props.accountStatus.profileURL,
      coreValues:props.accountStatus.coreValues,
      surveys:[]
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.profileImageInput = React.createRef();
    this.coreValuesEffortsContent = React.createRef();
    this.logic = new Logic(this);

    this.logic.loadSurveys();
    this.logic.loadSurveyData();

  }

  renderAddAvailabilityPanel = ()=>{
    if(!this.state.addAvailabilityPanelOpen) return;

    return(
      <div className="add-availability-panel">
        <img src={xButton} className="add-availability-close-button" onClick={this.logic.closeAddAvailabilityPanel} />
        <div className="add-availability-form">
          <div className="add-availability-form-header">Set your Session Availability</div>
          <div className="add-availability-timezone">Your Timezone: {this.state.timeZone}</div>
          <div className="add-availability-chosen-date">Chosen Date: {this.state.chosenDisplayDate}</div>
        </div>
      </div>
    )
  }


  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{

      const localizer = momentLocalizer(moment);

      return(
        <div className="client-settings-home">
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Surveys.SurveysHeader} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="surveys-manager-container">

            <div className="surveys-manager">
              <div className="surveys-manager-header">{Language.English.Surveys.Surveys}</div>
              {this.renderSurveys()}
            </div>

            <div className="surveys-data-widget">

              <div className="surveys-data-content">

                <div className="surveys-data-header">
                {Language.English.Surveys.MyWellbeing}<br/><br/>
                ({this.state.recentDate || 'No Recent Surveys'})
                </div>

                <div className="surveys-data-text">
                {this.state.recentWellbeingScore || '0'} / 10
                </div>

                {this.renderCoreValuesButton()}

              </div>

            </div>


          </div>

          {this.renderOpenSurvey()}
          {this.renderPulseSurveyPanel()}
          {this.renderHopeSurveyPanel()}
          {this.renderCoreValues()}
          {this.renderCoreValuesDisplay()}
        </div>
      );

    }

  }

  renderCoreValuesButton = ()=>{
    if(!this.state.coreValues[0]) return;

    return(
      <div className="core-values-button" onClick={this.logic.openCoreValuesDisplay}>
        {Language.English.Surveys.CoreValuesButton}
      </div>
    )
  }

  renderCoreValuesDisplay = ()=>{
    if(!this.state.coreValuesDisplayOpen) return;

    return(
      <div className="survey-container">
        <img src={xButton} className="close-survey-button" onClick={this.logic.closeCoreValuesDisplay} />
        <div className="survey-form">
          <div className="survey-header">
            <img className="survey-header-icon" src={surveyIconDark} />
            {Language.English.Surveys.CoreValuesButton}
          </div>

          <div className="survey-content">

            <div className="core-values-time-header">{Language.English.Surveys.LastUpdated}{this.logic.getLastCoreValueDate()}</div>

            {
              this.state.coreValues[this.state.coreValues.length - 1].data.map((value,index)=>{
                return(
                  <div className="core-value-display-box">
                    {this.logic.getCoreValueData(index)}
                    <br/><br/>
                    {this.logic.getCoreValueNotes(index)}
                  </div>
                )
              })
            }

          </div>

        </div>
      </div>
    )

  }


  renderSurveys = ()=>{
    return(
      <div>


        {
          this.state.surveys.map((survey, index)=>{

            if((survey.name == 'Pulse Survey') && (!this.state.accountStatus.pulseSurveyAvailable)){
              return(
                <div className="survey-display-unavailable">
                  <img className="survey-display-icon" src={lockTriangle} />
                  {survey.name}
                </div>
              )
            }else if((survey.name == 'Core Values Survey') && (!this.state.accountStatus.coreSurveyAvailable)){
              return(
                <div className="survey-display-unavailable">
                  <img className="survey-display-icon" src={lockTriangle} />
                  {survey.name}
                </div>
              )
            }else if((survey.name == 'Hope Survey') && (!this.state.accountStatus.hopeSurveyAvailable)){
              return(
                <div className="survey-display-unavailable">
                  <img className="survey-display-icon" src={lockTriangle} />
                  {survey.name}
                </div>
              )
            }

              return(
                <div className="survey-display" onClick={()=>{this.logic.openSurveyPanel(survey)}}>
                  <img className="survey-display-icon" src={surveyIconDark} />
                  {survey.name}
                </div>
              )


          })
        }
      </div>
    )
  }

  renderHopeSurveyPanel = ()=>{
    if(!this.state.hopeSurveyOpen) return;

    return(
      <div className="survey-container">
        <img src={xButton} className="close-survey-button" onClick={this.logic.closeHopeSurveyPanel} />
        <div className="survey-form">
          <div className="survey-header">
            <img className="survey-header-icon" src={surveyIconDark} />
            {this.state.chosenSurvey.name}
          </div>

          <img className="survey-bar-image" src={hopeSurveyBar} />

          <div className="survey-content">

                    {
                      this.state.hopeSurveyQuestions.map((question,questionIndex)=>{
                        return(
                          <div>
                            <div className="pulse-question">{question}</div>
                            <RangeSlider
                                value={this.state.hopeQuestionValues[questionIndex]}
                                onChange={(changeEvent) => {this.logic.updateHopeQuestionValue(changeEvent.target.value,questionIndex)}}
                                min={1}
                                max={8}
                                step={1}
                                tooltip="on"
                              />
                          </div>
                        )
                      })
                    }

                    <div className="submit-survey" onClick={this.logic.submitHopeSurvey}>
                      {Language.English.Surveys.SubmitSurvey}
                    </div>

          </div>



          </div>

        </div>
    )
  }



  renderPulseSurveyPanel = ()=>{
    if(!this.state.pulseSurveyOpen) return;

    return(
      <div className="survey-container">
        <img src={xButton} className="close-survey-button" onClick={this.logic.closePulseSurveyPanel} />
        <div className="survey-form">
          <div className="survey-header">
            <img className="survey-header-icon" src={surveyIconDark} />
            {this.state.chosenSurvey.name}
          </div>

          <img className="survey-bar-image" src={pulseBar} />

          <div className="survey-content">

            {
              this.state.pulseSurveyQuestionSections.map((section,sectionIndex)=>{
                return(
                  <div>
                    <div className="pulse-survey-section-header">
                      {section}
                    </div>

                    {
                      this.state.pulseSurveyQuestions[section].map((question,questionIndex)=>{
                        return(
                          <div>
                            <div className="pulse-question">{question}</div>
                            <RangeSlider
                                value={this.state.pulseQuestionValues[sectionIndex][questionIndex]}
                                onChange={(changeEvent) => {this.logic.updatePulseQuestionValue(changeEvent.target.value,sectionIndex,questionIndex)}}
                                min={0}
                                max={7}
                                step={1}
                                tooltip="on"
                              />
                          </div>
                        )
                      })
                    }

                  </div>
                )
              })
            }

            <div className="submit-survey" onClick={this.logic.submitPulseSurvey}>
              {Language.English.Surveys.SubmitSurvey}
            </div>
          </div>

        </div>
      </div>
    )
  }

  renderOpenSurvey = ()=>{
      if(! this.state.surveyOpen) return;

      return(
        <div className="survey-container">
          <img src={xButton} className="close-survey-button" onClick={this.logic.closeSurveyPanel} />
          <div className="survey-form">
            <div className="survey-header">
              <img className="survey-header-icon" src={surveyIconDark} />
              {this.state.chosenSurvey.name}
            </div>

            <img className="survey-bar-image" src={surveyBar} />

            <div className="survey-content">

              {
                this.state.chosenSurvey.questions.map((question, index)=>{
                  return(
                    <div className="survey-question">
                      {(index + 1)}. {question}
                      <RangeSlider
                          value={this.state.surveyQuestionValues[index]}
                          onChange={(changeEvent) => {this.logic.updateSurveyQuestionValue(changeEvent.target.value,index)}}
                          min={0}
                          max={10}
                          step={1}
                          tooltip="on"
                        />
                    </div>
                  )
                })
              }
              <div className="submit-survey" onClick={this.logic.submitSurvey}>
                {Language.English.Surveys.SubmitSurvey}
              </div>
            </div>

          </div>
        </div>
      )
  }

  renderCoreValues = ()=>{
      if(! this.state.coreValuesOpen) return;

      if(this.state.coreValuesChosen){
        return(
          <div className="core-values-efforts-survey-container">
            <img src={xButton} className="close-survey-button" onClick={this.logic.closeSurveyPanel} />
            <div className="core-values-efforts-survey-form">
              <div className="survey-header">
                <img className="survey-header-icon" src={surveyIconDark} />
                {this.state.chosenSurvey.name}
              </div>

              <div className="core-values-efforts-survey-content" id="container-x" ref={this.coreValuesEffortsContent}>

                <div className="core-values-header">
                  {Language.English.Surveys.CoreValuesEffortsHeader}
                </div>

                {
                  this.state.coreValuesData.map((value,index)=>{
                    return(
                      <div className="core-value-container">

                        <div className="core-value-label">Category: {value.category}</div>
                        <div className="core-value-label">Core Value: {value.value}</div>
                        <div className="core-value-label">Current Score: {value.score}</div>

                        <input value={this.state.coreValuesData[index].effortsNote} onChange = {(event)=>{this.logic.updateCoreValueNote(event.target.value,index)}} className="core-values-note-input" placeholder={Language.English.Surveys.CoreValueEffortPlaceholder} />

                        <RangeSlider
                            value={this.state.coreValuesData[index].effortsScore}
                            onChange={(changeEvent) => {this.logic.updateCoreValueEffort(changeEvent.target.value,index)}}
                            min={0}
                            max={10}
                            step={1}
                            tooltip="on"
                          />
                      </div>
                    )
                  })
                }

                <div className="submit-survey" onClick={this.logic.submitCoreValues}>
                  {Language.English.Surveys.SubmitSurvey}
                </div>
              </div>

            </div>
          </div>
        )
      }

      return(
        <div className="survey-container">
          <img src={xButton} className="close-survey-button" onClick={this.logic.closeSurveyPanel} />
          <div className="survey-form">
            <div className="survey-header">
              <img className="survey-header-icon" src={surveyIconDark} />
              {this.state.chosenSurvey.name}
            </div>

            <div className="survey-content">

              <div className="core-values-header">
                {Language.English.Surveys.CoreValuesHeader}
              </div>

              {
                this.state.coreValuesData.map((value,index)=>{
                  return(
                    <div className="core-value-container">

                      <div className="core-value-label">Category:</div><select className="core-values-dropdown" id="core-values-category-dropdown" value={this.state.coreValuesData[index].category} onChange={(event)=>{this.logic.updateCoreValueCategory(event.target.value,index)}}>
                        {
                          this.state.coreValuesCategories.map((category, index)=>{
                              return(
                                <option key={category} value={category}>{category}</option>
                              )
                          })
                        }
                      </select>
                      <div className="core-value-label">Core Value:</div><select className="core-values-dropdown" value={this.state.coreValuesData[index].value} onChange={(event)=>{this.logic.updateCoreValueSelection(event.target.value,index)}}>
                        {
                          this.state.coreValuesData[index].valueOptions.map((category, index)=>{
                              return(
                                <option key={category} value={category}>{category}</option>
                              )
                          })
                        }
                      </select>

                      <RangeSlider
                          value={this.state.coreValuesData[index].score}
                          onChange={(changeEvent) => {this.logic.updateCoreValue(changeEvent.target.value,index)}}
                          min={0}
                          max={10}
                          step={1}
                          tooltip="on"
                        />
                    </div>
                  )
                })
              }

              <div className="submit-survey" onClick={this.logic.coreValuesNextStep}>
                {Language.English.Surveys.NextStep}
              </div>
            </div>

          </div>
        </div>
      )
  }









}

export default withAlert()(ClientSurveys);
