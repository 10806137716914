import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

//Services
import Language from '../../../services/Language';

class CoachLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
    this.messageListener = null;
  }


  //Set the current chosen client
  setCurrentClient = async(clientName,clientId, index)=>{
    let chosenPreview = this.baseContext.state.messagePreviews[index];
    let messages = [];

    for (const [key, value] of Object.entries(chosenPreview)) {
      if(value.fromId != undefined && value.toId != undefined) messages.push(value);
    };

    const currentUserId = firebase.auth().currentUser.uid;

    let messageDataRef = firebase.database().ref('messages/' + currentUserId + '/' + clientId + '/data');

    let newTimestamp = (new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000));

    await this.baseContext.setState({coachClientChosen:true,chosenClientName:clientName,chosenClientId: clientId, coachLastSeen:newTimestamp, messages: messages});


    messageDataRef.update({coachLastSeen: newTimestamp});

  }


  //Trim message preview
  trimMessagePreview = (message)=>{
    return (message.length > 20) ? (message.substring(0, 19)+'...') : message;
  }

  newMessageAvailable = (messages)=>{

    const currentUserId = firebase.auth().currentUser.uid;


    for(let messageIndex = 0; messageIndex < Object.keys(messages).length; messageIndex++){

      if((!messages['data'].coachLastSeen && Object.values(messages)[messageIndex].toId == currentUserId)||((this.convertToLocalTimeStamp(Object.values(messages)[messageIndex].timestamp) > this.convertToLocalTimeStamp(messages['data'].coachLastSeen) ) && (Object.values(messages)[messageIndex].toId == currentUserId)) ){
        return true;
      }
    }

    return false;
  }

  //Load messages
  loadMessages = async(coachId)=>{

    let scrolledToBottom = (!document.getElementById('client-messages') || document.getElementById('client-messages').scrollTop == 0);

    await this.baseContext.setState({loading:true});

    const currentUserId = firebase.auth().currentUser.uid;

    let messagePreviewsRef = firebase.database().ref('messages/' + currentUserId);
    let chosenPreview = null;

    messagePreviewsRef.on('value', async(snapshot)=>{
      let messagePreviews = [];
      let timestampChanged = false;

      snapshot.forEach((messagePreview)=>{
        let messagePreviewData = messagePreview.toJSON();

        let recentPreview = {
          timestamp:0,
          message:''
        };

        let messagePreviewDataArray = Object.values(messagePreviewData);

        for(let index = 0;index < messagePreviewDataArray.length;index++){
          if(messagePreviewDataArray[index].timestamp && (messagePreviewDataArray[index].timestamp > ((recentPreview)? (recentPreview.timestamp):0))) recentPreview = messagePreviewDataArray[index];
        }

        messagePreviewData['key'] = messagePreview.key;
        messagePreviewData['recentMessage'] = recentPreview.message;
        messagePreviews.push(messagePreviewData);





        if((messagePreviewData['data'].coachLastSeen != this.baseContext.state.coachLastSeen) && (messagePreview.key == this.baseContext.state.chosenClientId) ){
            timestampChanged = true;
        }

        if(messagePreview.key == this.baseContext.state.chosenClientId) {
          chosenPreview = messagePreview.toJSON();
        }
      });

      messagePreviews.sort((a, b) => (a[Object.keys(a)[Object.keys(a).length - 3]] == undefined || b[Object.keys(b)[Object.keys(b).length - 3]] == undefined)?((b[Object.keys(b)[Object.keys(b).length - 3]]==undefined)? 1:-1):(a[Object.keys(a)[Object.keys(a).length - 3]].timestamp > b[Object.keys(b)[Object.keys(b).length - 3]].timestamp) ? 1 : (a[Object.keys(a)[Object.keys(a).length - 3]].timestamp === b[Object.keys(b)[Object.keys(b).length - 3]].timestamp) ? ((Object.keys(a).length > Object.keys(b).length) ? 1 : -1) : -1 );
      messagePreviews.reverse();

      await this.baseContext.setState({messagePreviews:messagePreviews});


      let messages = [];

      if(!chosenPreview){
        return;
      }

      for (const [key, value] of Object.entries(chosenPreview)) {
        if(value.fromId != undefined && value.toId != undefined) messages.push(value);
      };





      if(!timestampChanged){
        let messageDataRef = firebase.database().ref('messages/' + currentUserId + '/' + this.baseContext.state.chosenClientId + '/data');

        messageDataRef.update({coachLastSeen: (new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000))});
      }



      await this.baseContext.setState({messages: messages});

      if(scrolledToBottom) document.getElementById('coach-messages').scrollTo(0,document.getElementById('coach-messages').scrollHeight);




    });


  }

  //Send Message to client
  sendMessage = async(event)=>{
    event.preventDefault();

    if(this.baseContext.state.message == ""){
      this.baseContext.showAlert("Please enter a message!");
      return;
    }

    const message = this.baseContext.state.message;

    await this.baseContext.setState({message:""});

    var database = firebase.database();
    const currentUserId = firebase.auth().currentUser.uid;

    firebase.database().ref('messages/' +currentUserId+"/"+this.baseContext.state.chosenClientId).push({
      message: message,
      timestamp: new Date().getTime() - (new Date().getTimezoneOffset() * 60 * 1000),
      fromId:currentUserId,
      toId:this.baseContext.state.chosenClientId
    }).then(async()=>{

      const currentUserId = firebase.auth().currentUser.uid;

      let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
          return idToken;
      });


      var functions = firebase.app().functions('europe-west1');

      functions.httpsCallable('sendMessageEmail')({
        token:token,
        receiverId:this.baseContext.state.chosenClientId,
        senderName:this.baseContext.state.accountStatus.name
      });


    }).catch(async(error)=>{
    })
  }

  //Convert To Local Date Time
  convertToLocalDateTime = (timestamp)=>{
    let dateTime = new Date(timestamp + new Date().getTimezoneOffset() * 60 * 1000);

    let minutes = dateTime.getMinutes().toString().length == 1 ? "0"+dateTime.getMinutes():dateTime.getMinutes();


    return dateTime.getDate()+" "+Language.English.Months[dateTime.getMonth()]+" "+dateTime.getHours()+":"+minutes;
  }

  convertToLocalTimeStamp = (time)=>{
    return ((new Date(time).getTime()) + (new Date().getTimezoneOffset() * 60 * 1000));
  }

  //Get user's current time zone
  getTimeZone = ()=>{
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }


}

export default CoachLogic;
