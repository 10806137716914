import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';
import "react-toggle/style.css";

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { withAlert } from 'react-alert';


//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import whitePlusIcon from '../../../assets/white-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import sendIcon from '../../../assets/icons/send.png';
import defaultProfileImage from '../../../assets/profile-picture.png';
import backArrowGrey from '../../../assets/icons/grey-arrow-icon.png';

import 'react-big-calendar/lib/css/react-big-calendar.css';

class ClientMessages extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      coachId:props.accountStatus.coach,
      accountStatus:props.accountStatus,
      messages:[],
      messagePreviews:[],
      message:""
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);
    this.logic.loadMessages(props.accountStatus.coach);
  }

  renderUnseenIcon = (isUnseen)=>{
    if(isUnseen){
      return(
        <div className="unseen-icon"></div>
      )
    }
  }

  renderCoachMessagePreviewsMobile = ()=>{
    if(this.state.coachClientChosen) return;

    return(
      <div className="coach-messages-column-mobile">
        {

        this.state.messagePreviews.map((preview, index)=>{

          return(
            <div className="message-preview" onClick={()=>{this.logic.setCurrentClient(preview['data'].name || 'No Name',preview.key, index)}}>
              {this.renderUnseenIcon(this.logic.newMessageAvailable(preview))}
              <img className="message-preview-image" src={preview['data'].profileURL || defaultProfileImage} />

              <div className="message-details-container">
                <div className="message-preview-name">{preview['data'].name || 'No Name'}</div>
              </div>

            </div>
          )
        })

        }
      </div>
    )
  }




  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{

      const localizer = momentLocalizer(moment);

      return(
        <div className="coach-messages-home">
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Messages.CoachMessages} userName={this.props.userName} profileImage={this.props.profileImage}/>
          {this.renderCoachMessagePreviewsMobile()}

          <div className="message-back-bar-mobile">
            <img src={backArrowGrey} className="message-back-arrow-mobile" onClick={()=>{this.setState({coachClientChosen:false})}} />
          </div>

          <div className="coach-messages-row">

          <div className="coach-messages-column">
            {

            this.state.messagePreviews.map((preview, index)=>{

              return(
                <div className="message-preview" onClick={()=>{this.logic.setCurrentClient(preview['data'].name || 'No Name',preview.key, index)}}>
                  {this.renderUnseenIcon(this.logic.newMessageAvailable(preview))}
                  <img className="message-preview-image" src={preview['data'].profileURL || defaultProfileImage} />

                  <div className="message-details-container">
                    <div className="message-preview-name">{preview['data'].name || 'No Name'}</div>
                  </div>

                  <div className="coach-recent-message-preview">
                    {preview['recentMessage']}
                  </div>

                </div>
              )
            })

            }
          </div>

          <div className="coach-messages-container">
            <div className="coach-messages" id="coach-messages">
              {
                this.state.messages.map((message, index)=>{
                  if(message.toId == firebase.auth().currentUser.uid){
                    return(
                      <div className="message-received-row">
                        <div className="message-received-text">
                          {message.message}
                        </div>
                        <div className="message-timestamp">{this.logic.convertToLocalDateTime(message.timestamp)}</div>
                      </div>
                    )
                  }

                  return(
                    <div className="message-sent-row">
                      <div className="message-timestamp">{this.logic.convertToLocalDateTime(message.timestamp)}</div>
                      <div className="message-sent-text">
                        {message.message}
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <div className="coach-send-message-bar">
              <form onSubmit={this.logic.sendMessage}>
                <input className="send-message-textbox" placeholder={Language.English.Messages.Message} value={this.state.message} onChange={(event)=>{this.setState({message:event.target.value})}} />
                <button className="send-message-button" onClick={this.logic.sendMessage}>
                  Send
                  <img src={sendIcon} className="send-message-icon" />
                </button>
              </form>
            </div>

            </div>
          </div>
        </div>
      );
    }
  }




}

export default withAlert()(ClientMessages);
