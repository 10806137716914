import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import whitePlusIcon from '../../../assets/white-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png'

import 'react-big-calendar/lib/css/react-big-calendar.css';

class ClientRequests extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      addAvailabilityPanelOpen:false
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);
    this.logic.loadEvents();
    console.log('loads');
  }

  renderAddAvailabilityPanel = ()=>{
    if(!this.state.addAvailabilityPanelOpen) return;

    return(
      <div className="add-availability-panel">
        <img src={xButton} className="add-availability-close-button" onClick={this.logic.closeAddAvailabilityPanel} />
        <div className="add-availability-form">
          <div className="add-availability-form-header">Set your Session Availability</div>
          <div className="add-availability-timezone">Your Timezone: {this.state.timeZone}</div>
          <div className="add-availability-chosen-date">Chosen Date: {this.state.chosenDisplayDate}</div>
        </div>
      </div>
    )
  }


  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{

      const localizer = momentLocalizer(moment);

      return(
        <div className="coach-calendar-home">
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Requests.MyRequests} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="coach-request-content-container">

          </div>
        </div>
      );
    }
  }




}

export default ClientRequests;
