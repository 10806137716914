import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class LoginLogic {

  constructor(context){
    this.baseContext = context;
  }

  handleEmailChange = (event)=>{
    this.baseContext.setState({emailValue: event.target.value});
  }

  handlePasswordChange = (event)=>{
    this.baseContext.setState({passwordValue: event.target.value});
  }

  openResetPassword = ()=>{
    this.baseContext.setState({resetPasswordOpen:true});
  }

  closeResetPassword = ()=>{
    this.baseContext.setState({resetPasswordOpen:false});
  }

  loadSurveys = async()=>{
    await this.baseContext.setState({loading:true});


    firebase.firestore().collection("surveys").doc("UpZyaeSe0Jb0HCmcWjU4").get().then(async(survey)=>{

        let surveyArray = [];

          let surveyData = survey.data();
          surveyData['id'] = survey.id;
          surveyArray.push(surveyData);


          console.log(surveyData);

          let questionValues = [];

          for(let index = 0;index < surveyData.questions.length;index++){
            questionValues[index] = 5;
          }


        await this.baseContext.setState({loading:false, surveys:surveyArray, chosenSurvey:surveyData, surveyOpen:true, surveyQuestionValues:questionValues});

    }).catch(async(error)=>{
      this.baseContext.showAlert(error.message);
      await this.baseContext.setState({loading:false});
    })

  }


  //Update Survey Question Value
  updateSurveyQuestionValue = (value, index)=>{
    let surveyQuestionValues = this.baseContext.state.surveyQuestionValues;

    surveyQuestionValues[index] = parseInt(value);

    this.baseContext.setState({surveyQuestionValues:surveyQuestionValues});
  }





  submitSurvey = async()=>{
      if(this.baseContext.state.publicSurveyName.length == 0) {
        this.baseContext.showAlert("Please enter your full name!");
        return;
      }


      this.baseContext.setState({loading:true});

      let answersArray = this.baseContext.state.surveyQuestionValues;


      var functions = firebase.app().functions('europe-west1');



      functions.httpsCallable('submitGroupSurvey')({
        surveyData:answersArray,
        name:this.baseContext.state.publicSurveyName,
        adminId:this.baseContext.state.adminId,
        surveyId:this.baseContext.state.surveyId
      }).then((result)=>{
          if(result.data.success){
            this.baseContext.setState({loading:false, success:true});
          }else{
            alert(result.data.errorMessage);
            this.baseContext.setState({loading:false});
          }
      }).catch((error)=>{
        alert(error.message);
        this.baseContext.setState({loading:false});
      })


  }



}

export default LoginLogic;
