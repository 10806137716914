import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class CoachLogic {

  //Set base context to parent
  constructor(context){
    this.baseContext = context;
  }

  openChangePasswordPanel = ()=>{
    this.baseContext.setState({passwordPanelOpen:true, updatedPassword:'', oldPassword:''});
  }

  closeChangePasswordPanel = ()=>{
    this.baseContext.setState({passwordPanelOpen:false});
  }

  updatePassword = async()=>{
    let oldPassword = this.baseContext.state.oldPassword;
    let newPassword = this.baseContext.state.updatedPassword;

    if(oldPassword === newPassword){
      this.baseContext.showAlert('Your password hasn\'t changed');
      return;
    }

    await this.baseContext.setState({passwordLoading:true});

    firebase.auth().signInWithEmailAndPassword(this.baseContext.state.accountStatus.userEmail, oldPassword)
    .then((result)=> {

    const currentUser = firebase.auth().currentUser;
    const currentUserId = firebase.auth().currentUser.uid;


    currentUser.updatePassword(newPassword).then(async()=>{

      this.baseContext.setState({passwordLoading:false});
      this.baseContext.showAlert("New Password Saved!");
      this.closeChangePasswordPanel();

      }).catch((error)=>{
        this.baseContext.showAlert('Error: '+error.message);
        this.baseContext.setState({passwordLoading:false});
      });




  }).catch((error)=> {
    this.baseContext.showAlert('Error: '+error.message);
    this.baseContext.setState({passwordLoading:false});
  });






  }

  openChangeEmailPanel = ()=>{
    this.baseContext.setState({emailPanelOpen:true, emailUpdatePassword:''});
  }

  closeChangeEmailPanel = ()=>{
    this.baseContext.setState({emailPanelOpen:false});
  }

  updateEmail = async()=>{
    if(this.baseContext.state.accountStatus.userEmail == this.baseContext.state.userEmail){
      this.baseContext.showAlert('Your email hasn\'t changed');
      return;
    }

    await this.baseContext.setState({emailLoading:true});

    firebase.auth().signInWithEmailAndPassword(this.baseContext.state.accountStatus.userEmail, this.baseContext.state.emailUpdatePassword)
    .then((result)=> {

    const currentUser = firebase.auth().currentUser;
    const currentUserId = firebase.auth().currentUser.uid;

    currentUser.updateEmail(this.baseContext.state.userEmail).then((result)=>{


      firebase.firestore().collection("users").doc(currentUserId).set({
        email:this.baseContext.state.userEmail
      }, {merge:true}).then(()=>{
        this.baseContext.setState({emailLoading:false});
        this.baseContext.showAlert("New Email Saved!");
        this.closeChangeEmailPanel();
        window.location.reload();
      }).catch((error)=>{
        this.baseContext.showAlert('Error: '+error.message);
        this.baseContext.setState({emailLoading:false});
      })


    }).catch((error)=>{
      this.baseContext.showAlert('Error: '+error.message);
      this.baseContext.setState({emailLoading:false});
    });

  }).catch((error)=> {
    this.baseContext.showAlert('Error: '+error.message);
    this.baseContext.setState({emailLoading:false});
  });

  }

  //Load Notification Settings From main document
  loadNotificationSettings = async()=>{

    await this.baseContext.setState({loadingNotificationSettings:true});

    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });


    functions.httpsCallable('getUserNotificationSettings')({
      token:token
    }).then((result)=>{
      if(result.data.success){
        this.baseContext.setState({
          loadingNotificationSettings:false,
          notificationTime:result.data.time,
          notificationsChecked:result.data.notifications,
          emailsChecked:result.data.emails,
          mondayChecked:result.data.days[0],
          tuesdayChecked:result.data.days[1],
          wednesdayChecked:result.data.days[2],
          thursdayChecked:result.data.days[3],
          fridayChecked:result.data.days[4],
          saturdayChecked:result.data.days[5],
          sundayChecked:result.data.days[6]
        })
      }else{
        this.baseContext.showAlert('Error: '+result.data.errorMessage);
      }

    }).catch((error)=>{
      this.baseContext.showAlert('Error Loading: '+error.message);
    })
  }

  //Save Personal Details
  saveSettings = async()=>{
    await this.baseContext.setState({loading:true});

    const currentUser = firebase.auth().currentUser;
    const currentUserEmail = firebase.auth().currentUser.email;
    const currentUserId = firebase.auth().currentUser.uid;


    const newDetails = {
      name: this.baseContext.state.myName
    }

    if(this.baseContext.state.accountStatus.authority == 'admin'){
      newDetails['companyName'] = this.baseContext.state.myCompanyName;
      newDetails['companyAddress1'] = this.baseContext.state.myCompanyAddress1;
      newDetails['companyAddress2'] = this.baseContext.state.myCompanyAddress2;
      newDetails['companyTownCity'] = this.baseContext.state.myCompanyTownCity;
      newDetails['companyCounty'] = this.baseContext.state.myCompanyCounty;
      newDetails['companyCountry'] = this.baseContext.state.myCompanyCountry;
      newDetails['companyPhone'] = this.baseContext.state.myCompanyPhone;
      newDetails['companyPostcode'] = this.baseContext.state.myCompanyPostcode;
    }

    firebase.firestore().collection("users").doc(currentUserId).set(newDetails, {merge:true}).then(()=>{
      this.baseContext.setState({loading:false});
      this.baseContext.showAlert("Settings Saved!");
    }).catch((error)=>{
      this.baseContext.setState({loading:false});
      this.baseContext.showAlert("Error: "+error);
    })

  }

  //Save Notification Settings
  saveNotificationSettings = async()=>{
    await this.baseContext.setState({loadingNotificationSettings:true});

    var functions = firebase.app().functions('europe-west1');

    let token = await firebase.auth().currentUser.getIdToken(true).then(async(idToken)=>{
        return idToken;
    });

    let currentState = this.baseContext.state;


    functions.httpsCallable('setUserNotificationSettings')({
      token:token,
      chosenTime:currentState.notificationTime,
      chosenDays:[currentState.mondayChecked,currentState.tuesdayChecked,currentState.wednesdayChecked,currentState.thursdayChecked,currentState.fridayChecked,currentState.saturdayChecked,currentState.sundayChecked],
      emails:currentState.emailsChecked,
      notifications:currentState.notificationsChecked,
      offsetTime:(new Date().getTimezoneOffset() * 60 * 1000),
      email: this.baseContext.state.accountStatus.userEmail
    }).then((result)=>{
      if(result.data.success){
        this.baseContext.setState({
          loadingNotificationSettings:false
        })

        this.baseContext.showAlert('Settings Saved!');
      }else{
        this.baseContext.showAlert('Error: '+result.data.errorMessage);
        this.baseContext.setState({
          loadingNotificationSettings:false
        })
      }

    }).catch((error)=>{
      this.baseContext.showAlert('Error Loading: '+error.message);
      this.baseContext.setState({
        loadingNotificationSettings:false
      })
    })
  }

  //Save Profile Image onChange
  saveProfileImage = async()=>{

    let newFile = this.baseContext.profileImageInput.current.files[0];
    let storageRef = firebase.storage().ref();
    let currentUserId = firebase.auth().currentUser.uid;

    let newFileRef = storageRef.child('profileImages/'+currentUserId+"/"+newFile.name);

    await this.baseContext.setState({loading:true});

    await newFileRef.put(newFile).then(async(snapshot)=>{

      await newFileRef.getDownloadURL().then((url)=>{

        const newDetails = {
          profileURL: url
        }

        firebase.firestore().collection("users").doc(currentUserId).set(newDetails, {merge:true}).then(()=>{
          this.baseContext.setState({loading:false});
          this.baseContext.showAlert("Settings Saved!");
        }).catch((error)=>{
          this.baseContext.setState({loading:false});
          this.baseContext.showAlert("Error: "+error);
        })

      })

    }).catch((error)=>{
      this.baseContext.setState({loading:false});
      this.baseContext.showAlert("Error: "+error);
    })

  }


  //Get user's current time zone
  getTimeZone = ()=>{
    const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    }
  }


}

export default CoachLogic;
