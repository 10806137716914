import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import DatePicker from "react-datepicker";
import { withAlert } from 'react-alert';

import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import binIcon from '../../../assets/icons/bin.png';
import downloadTriangle from '../../../assets/icons/download-triangle.png';

class CoachAdmin extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      newCoursePanelOpen:false,
      newCourseName:"",
      minutes:['00','15','30','45'],
      hours:['0','1','2','3','4'],
      coursesList:[],
      coachesList:[],
      planRequests:[],
      chosenCourseModulesList:[],
      editPanelLoading:true,
      newLessonFiles:[],
      chosenModuleLessons:[],
      newLessonName:"",
      newLessonDescription:"",
      sessionPrice:"",
      monthlyUserPrice:"",

      NewWebinarDescription:"",
      NewWebinarCallLink:"",

      timeHours:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
      timeMinutes:['00','15','30','45'],

      loadingPaymentDetails:false,

      organisationSurveys:[],

      coachSearch:"",
      orgSearch:""
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.logic = new Logic(this);
    this.logic.loadOrganisations();
    this.logic.loadCoaches();
    this.logic.checkIfClientsPaid();

    this.filesInput = React.createRef();
    this.EditLessonFilesInput = React.createRef();
  }


  render(){
    if(this.state.loading || this.state.loadingPaymentDetails){
      return(
        <div className="inkel-admin-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{
      return(
        <div className="coach-dashboard-home" style={{overflowY:'auto'}}>
          {this.renderNewCoursePanel()}
          {this.renderEditEventPanel()}
          {this.renderEditCoachPanel()}
          {this.renderWebinarsPanel()}
          {this.renderSetPricePanel()}
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.InkelAdmin.InkelAdmin} userName={this.props.userName} profileImage={this.props.profileImage}/>
          <div className="coach-dashboard-content">

            <div className="inkel-admin-panel">
              <div className="inkel-admin-panel-header">
                <div className="courses-panel-text-container">
                  <div className="courses-panel-text">
                    {Language.English.InkelAdmin.ManageOrganisationsHeader}
                  </div>
                </div>
                <div className="courses-panel-button-container">
                  <div className="courses-panel-new-button" onClick={this.logic.openNewCoursePanel}>
                    <img src={plusIcon} className="courses-button-icon" />
                    {Language.English.Dashboard.NewButton}
                  </div>
                </div>
              </div>

              <input type="text" placeholder="Search organisations..." className="search-input" value={this.state.orgSearch} onChange={(event)=>{this.setState({orgSearch:event.target.value})}} />


              <div className="courses-panel-content">
                {

                  this.state.coursesList.map((organisation, index)=>{

                    if(this.state.orgSearch.length > 0 && organisation.companyName.toUpperCase().search(this.state.orgSearch.toUpperCase()) == -1) return;


                    return(
                      <div className="inkel-admin-data-display">
                        <div className="course-display-left">
                          <div className="course-display-name">{organisation.companyName}</div>
                        </div>

                        <div className="course-display-right">
                          <div className="course-display-live">{(organisation.paid || organisation.paidManually)? Language.English.InkelAdmin.Paid:Language.English.InkelAdmin.NotPaid}</div>

                        </div>


                        <div className="edit-course-button" onClick={()=>{this.logic.editEvent(organisation, index)}}>
                          <img src={editIcon} className="edit-course-icon" />
                          {Language.English.Dashboard.Edit}
                        </div>

                        <div className="webinars-button" onClick={()=>{this.logic.editWebinars(organisation, index)}}>
                          {Language.English.InkelAdmin.EditWebinars}
                        </div>

                      </div>
                    )
                  })
                }
              </div>

            </div>

            <div className="inkel-admin-panel">

            <div className="inkel-admin-panel-header">
              <div className="courses-panel-text-container">
                <div className="courses-panel-text">
                  {Language.English.InkelAdmin.PricingHeader}
                </div>
              </div>
            </div>

            <input type="text" placeholder="Search coaches..." className="search-input" value={this.state.coachSearch} onChange={(event)=>{this.setState({coachSearch:event.target.value})}} />

            <div className="courses-panel-content">

              {

                this.state.coachesList.map((coach, index)=>{

                  if(this.state.coachSearch.length > 0 && coach.name.toUpperCase().search(this.state.coachSearch.toUpperCase()) == -1) return;

                  return(
                    <div className="inkel-admin-data-display">
                      <div className="course-display-left">
                        <div className="course-display-name">{coach.name}</div>
                      </div>

                      <div className="course-display-right">
                        <div className="edit-course-button" onClick={()=>{this.logic.editCoach(coach,index)}}>
                          <img src={editIcon} className="edit-course-icon" />
                          {Language.English.Dashboard.Edit}
                        </div>
                      </div>
                    </div>
                  )
                })
              }

            </div>

            </div>

          </div>

          <div className="coach-dashboard-content">
          <div className="inkel-admin-panel">

          <div className="inkel-admin-panel-header">
            <div className="courses-panel-text-container">
              <div className="courses-panel-text">
                {Language.English.InkelAdmin.RequestsHeader}
              </div>
            </div>
          </div>

          <div className="courses-panel-content">

            {
              this.state.planRequests.map((request, index)=>{
                return(
                  <div className="inkel-admin-data-display">
                    <div className="course-display-left">
                      <div className="course-display-name">{request.name}</div>
                    </div>

                    <div className="course-display-right">
                      <div className="edit-course-button" onClick={()=>{this.logic.openSetPricePanel(request,index)}}>
                        <img src={editIcon} className="edit-course-icon" />
                        {Language.English.InkelAdmin.SetPrice}
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </div>

          </div>
          </div>
        </div>
      );
    }
  }

  renderSetPricePanel = ()=>{
    if(!this.state.setPricePanelOpen) return;

    return(
      <div className="new-course-panel">
        <img src={xButton} className="new-course-close-button" onClick={this.logic.closeSetPricePanel}/>
        <div className="edit-course-form">

          <div className="inkel-admin-popup-header">
            {'Set Plan Price'}
          </div>

          <div className="new-webinar-text">
            {Language.English.InkelAdmin.SetPriceTitle}<br/><br/>{'Description: '+ this.state.chosenRequest.planDescription}
          </div>

          <br/>

          <input className="new-company-input" type="text" value={this.state.planRequestPrice} placeholder={Language.English.InkelAdmin.PlanPricePlaceholder} onChange={(event)=>{this.setState({planRequestPrice:event.target.value})}}/>

          <div style={{textAlign:'center'}}>
            <div className="webinar-time-submit-button" onClick={this.logic.setRequestPrice}>Submit</div>
          </div>





        </div>

      </div>
    )
  }

  renderWebinarsPanel = ()=>{
    if(!this.state.editWebinarsPanelOpen) return;

    if(this.state.editWebinarPanelOpen){


            return(
              <div className="new-course-panel">
                <img src={xButton} className="new-course-close-button" onClick={this.logic.closeEditWebinarsPanel}/>
                <div className="edit-course-form">
                  <div className="inkel-admin-popup-header">
                    {this.state.chosenWebinarOrganisationName+' - New Webinar'}

                  </div>

                  <div className="modules-header">
                    <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeEditWebinarPanel} />
                  </div>


                  <div className="new-webinar-content">

                    <div className="new-webinar-text">
                      {Language.English.InkelAdmin.WebinarTitle}
                    </div>

                    <input className="new-company-input" type="text" value={this.state.ChosenWebinarName} onChange={(event)=>{this.setState({ChosenWebinarName:event.target.value})}}/>

                    <div className="new-webinar-text">
                      {Language.English.InkelAdmin.WebinarDescription}
                    </div>

                    <input className="new-company-input" type="text" value={this.state.ChosenWebinarDescription} onChange={(event)=>{this.setState({ChosenWebinarDescription:event.target.value})}}/>

                    <div className="new-webinar-text">
                      {Language.English.InkelAdmin.WebinarCallLink}
                    </div>

                    <input className="new-company-input" type="text" value={this.state.ChosenWebinarCallLink} onChange={(event)=>{this.setState({ChosenWebinarCallLink:event.target.value})}}/>



                    <div className="new-webinar-text">
                      {Language.English.InkelAdmin.WebinarDateTime}
                    </div>
                    <div className="new-webinar-form">
                      <DatePicker onChange={(date)=>{this.setState({ChosenWebinarDate:date})}} selected={this.state.ChosenWebinarDate} className="client-input-date" dateFormat="dd/MM/yyyy" calendarClassName="rasta-stripes" />
                      <br/><br/>
                      <div className="webinar-time-picker-header">
                        {Language.English.InkelAdmin.WebinarStartTime}
                      </div>

                      <select  value={this.state.ChosenWebinarStartTimeHours} onChange={(event)=>{this.setState({ChosenWebinarStartTimeHours:event.target.value})}} className="client-input-time-hours">
                        {
                          this.state.timeHours.map((option)=> <option key={option} value={option}>{option}</option>)
                        }
                      </select>
                       :
                       <select  value={this.state.ChosenWebinarStartTimeMinutes} onChange={(event)=>{this.setState({ChosenWebinarStartTimeMinutes:event.target.value})}} className="client-input-time-minutes">
                         {
                           this.state.timeMinutes.map((option)=> <option key={option} value={option}>{option}</option>)
                         }
                       </select>

                       <div className="webinar-time-picker-header">
                         {Language.English.InkelAdmin.WebinarEndTime}
                       </div>

                       <select  value={this.state.ChosenWebinarEndTimeHours} onChange={(event)=>{this.setState({ChosenWebinarEndTimeHours:event.target.value})}} className="client-input-time-hours">
                         {
                           this.state.timeHours.map((option)=> <option key={option} value={option}>{option}</option>)
                         }
                       </select>
                        :
                        <select  value={this.state.ChosenWebinarEndTimeMinutes} onChange={(event)=>{this.setState({ChosenWebinarEndTimeMinutes:event.target.value})}} className="client-input-time-minutes">
                          {
                            this.state.timeMinutes.map((option)=> <option key={option} value={option}>{option}</option>)
                          }
                        </select>

                       <br/>

                       <div className="webinar-time-submit-button" onClick={this.logic.updateWebinar}>Submit</div>

                      </div>
                  </div>

                </div>
              </div>
            )





    }else if(this.state.newWebinarPanelOpen){

      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeEditWebinarsPanel}/>
          <div className="edit-course-form">
            <div className="inkel-admin-popup-header">
              {this.state.chosenWebinarOrganisationName+' - New Webinar'}

            </div>

            <div className="modules-header">
              <img src={greyBackArrow} className="lessons-back-button" onClick={this.logic.closeNewWebinarPanel} />
            </div>


            <div className="new-webinar-content">

              <div className="new-webinar-text">
                {Language.English.InkelAdmin.WebinarTitle}
              </div>

              <input className="new-company-input" type="text" value={this.state.NewWebinarName} onChange={(event)=>{this.setState({NewWebinarName:event.target.value})}}/>

              <div className="new-webinar-text">
                {Language.English.InkelAdmin.WebinarDescription}
              </div>

              <input className="new-company-input" type="text" value={this.state.NewWebinarDescription} onChange={(event)=>{this.setState({NewWebinarDescription:event.target.value})}}/>

              <div className="new-webinar-text">
                {Language.English.InkelAdmin.WebinarCallLink}
              </div>

              <input className="new-company-input" type="text" value={this.state.NewWebinarCallLink} onChange={(event)=>{this.setState({NewWebinarCallLink:event.target.value})}}/>



              <div className="new-webinar-text">
                {Language.English.InkelAdmin.WebinarDateTime}
              </div>
              <div className="new-webinar-form">
                <DatePicker onChange={(date)=>{this.setState({NewWebinarDate:date})}} selected={this.state.NewWebinarDate} className="client-input-date" dateFormat="dd/MM/yyyy" calendarClassName="rasta-stripes" />
                <br/><br/>
                <div className="webinar-time-picker-header">
                  {Language.English.InkelAdmin.WebinarStartTime}
                </div>

                <select  value={this.state.NewWebinarStartTimeHours} onChange={(event)=>{this.setState({NewWebinarStartTimeHours:event.target.value})}} className="client-input-time-hours">
                  {
                    this.state.timeHours.map((option)=> <option key={option} value={option}>{option}</option>)
                  }
                </select>
                 :
                 <select  value={this.state.NewWebinarStartTimeMinutes} onChange={(event)=>{this.setState({NewWebinarStartTimeMinutes:event.target.value})}} className="client-input-time-minutes">
                   {
                     this.state.timeMinutes.map((option)=> <option key={option} value={option}>{option}</option>)
                   }
                 </select>

                 <div className="webinar-time-picker-header">
                   {Language.English.InkelAdmin.WebinarEndTime}
                 </div>

                 <select  value={this.state.NewWebinarEndTimeHours} onChange={(event)=>{this.setState({NewWebinarEndTimeHours:event.target.value})}} className="client-input-time-hours">
                   {
                     this.state.timeHours.map((option)=> <option key={option} value={option}>{option}</option>)
                   }
                 </select>
                  :
                  <select  value={this.state.NewWebinarEndTimeMinutes} onChange={(event)=>{this.setState({NewWebinarEndTimeMinutes:event.target.value})}} className="client-input-time-minutes">
                    {
                      this.state.timeMinutes.map((option)=> <option key={option} value={option}>{option}</option>)
                    }
                  </select>

                 <br/>

                 <div className="webinar-time-submit-button" onClick={this.logic.createWebinar}>Submit</div>

                </div>
            </div>

          </div>
        </div>
      )

    }else{

      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeEditWebinarsPanel}/>
          <div className="edit-course-form">
            <div className="inkel-admin-popup-header">
              {this.state.chosenWebinarOrganisationName+' '}

              <div className="add-modules-button-container">
                <div className="add-modules-button" onClick={this.logic.openAddWebinarPanel}>
                  <span className="white-text">
                    {Language.English.InkelAdmin.AddWebinar}
                  </span>
                </div>
              </div>


            </div>

            <div className="edit-organisation-content">
              {
                this.state.chosenWebinars.map((webinar, index)=>{
                  return(
                    <div className="webinar-display">

                      <div className="webinar-display-text">
                        {index+1}.{' '+webinar.name} - {this.logic.convertToString(webinar.startTimeUTC)}
                      </div>

                      <div className="webinar-display-buttons-container">

                        <div className="webinar-edit-button" onClick={()=>{this.logic.openUpdateWebinarPanel(index)}}>
                          <img src={editIcon} className="edit-course-icon" />
                          {Language.English.Dashboard.Edit}
                        </div>

                        <div className="webinar-delete-button" onClick={()=>{this.logic.deleteWebinar(index)}}>
                          <img src={binIcon} className="bin-icon" />
                          {Language.English.InkelAdmin.Delete}
                        </div>

                      </div>

                    </div>
                  )
                })
              }
            </div>

          </div>
        </div>
      )

    }


  }

  renderNewCoursePanel = ()=>{
    if(this.state.newCoursePanelOpen){

      return(
        <div className="new-company-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeNewCoursePanel}/>
          <div className="new-company-form">

            <div className="new-organisation-label">{Language.English.InkelAdmin.NewCompanyNamePlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.NewCompanyNamePlaceholder} value={this.state.newCompanyName} onChange={(event)=>{this.setState({newCompanyName:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.NewCompanyAddress1Placeholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.NewCompanyAddress1Placeholder} value={this.state.newCompanyAddress} onChange={(event)=>{this.setState({newCompanyAddress:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.NewCompanyAddress2Placeholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.NewCompanyAddress2Placeholder} value={this.state.newCompanyAddress2} onChange={(event)=>{this.setState({newCompanyAddress2:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.NewCompanyTownCityPlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.NewCompanyTownCityPlaceholder} value={this.state.newCompanyTownCity} onChange={(event)=>{this.setState({newCompanyTownCity:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.NewCompanyCountyPlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.NewCompanyCountyPlaceholder} value={this.state.newCompanyCounty} onChange={(event)=>{this.setState({newCompanyCounty:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.NewCompanyCountryPlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.NewCompanyCountryPlaceholder} value={this.state.newCompanyCountry} onChange={(event)=>{this.setState({newCompanyCountry:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.NewCompanyPostcodePlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.NewCompanyPostcodePlaceholder} value={this.state.newCompanyPostcode} onChange={(event)=>{this.setState({newCompanyPostcode:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.NewCompanyPhonePlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.NewCompanyPhonePlaceholder} value={this.state.newCompanyPhone} onChange={(event)=>{this.setState({newCompanyPhone:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.NewCompanyAdminNamePlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.NewCompanyAdminNamePlaceholder} value={this.state.newCompanyAdminName} onChange={(event)=>{this.setState({newCompanyAdminName:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.NewCompanyAdminEmailPlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.NewCompanyAdminEmailPlaceholder} value={this.state.newCompanyAdminEmail} onChange={(event)=>{this.setState({newCompanyAdminEmail:event.target.value})}}/>
            <br/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.BasicUsersPlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.BasicUsersPlaceholder} value={this.state.basicUsers} onChange={(event)=>{this.setState({basicUsers:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.CoachingServicesPlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.CoachingServicesPlaceholder} value={this.state.coachingServices} onChange={(event)=>{this.setState({coachingServices:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.CheckInServicePlaceholder}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.CheckInServicePlaceholder} value={this.state.checkinServices} onChange={(event)=>{this.setState({checkinServices:event.target.value})}}/>

            <br/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.BasicUserPrice}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.BasicUserPrice} value={this.state.basicUserPrice} onChange={(event)=>{this.setState({basicUserPrice:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.CoachingServicesPrice}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.CoachingServicesPrice} value={this.state.coachingServicesPrice} onChange={(event)=>{this.setState({coachingServicesPrice:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.CheckInPrice}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.CheckInPrice} value={this.state.checkinServicesPrice} onChange={(event)=>{this.setState({checkinServicesPrice:event.target.value})}}/>

            <div className="new-organisation-label">{Language.English.InkelAdmin.ContentPrice}</div>
            <input className="new-company-input" type="text" placeholder={Language.English.InkelAdmin.ContentPrice} value={this.state.contentServicesPrice} onChange={(event)=>{this.setState({contentServicesPrice:event.target.value})}}/>


            <div className="new-organisation-submit-button" onClick={this.logic.createOrganisation}>{Language.English.Dashboard.AddButton}</div>

          </div>
        </div>
      )

    }
  }

  renderEditCoachPanel = ()=>{
    if(this.state.editCoachPanelOpen){
      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeEditCoachPanel}/>
          <div className="edit-course-form">
            <div className="inkel-admin-popup-header">
              {this.state.chosenCoachName}
            </div>

            <div className="edit-organisation-content">
              {this.renderEditCoachPricing()}
            </div>

          </div>
        </div>
      )
    }
  }

  renderEditEventPanel = ()=>{
    if(this.state.editEventPanelOpen){

      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeEditEventPanel}/>
          <div className="edit-organisation-form">
            <div className="inkel-admin-popup-header">
              {this.state.chosenCourseName+' '}


            </div>

            <div className="edit-organisation-content">

              {this.renderEditOrganisationContent()}

            </div>

          </div>
        </div>
      )

    }
  }

  renderEditOrganisationContent = ()=>{
    if(this.state.surveyPanelOpen){
      return(
        <div style={{padding:"24px"}}>

        <img style={{width:'24px', height:'24px',marginRight:'20px',display:'inline-block',verticalAlign:'middle'}} src={greyBackArrow} onClick={this.logic.closeSurvey} />
        


          <div className="hooga-surveys-header">
            <div className="hooga-surveys-button-container">
              <div className="hooga-surveys-header-text">{Language.English.CompanyAdmin.HoogaSurveysHeader}</div>

              <div className="hooga-download-button" onClick={this.logic.downloadCsvFile}>
                <img className="hooga-download-button-icon" src={downloadTriangle} />
                {Language.English.CompanyAdmin.DownloadButton}
              </div>

            </div>
          </div>

          <table className="hooga-admin-results">


          <tr className="hooga-tr">
            <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderName}</td>
            <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderEngagement}</td>
            <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderRelationship}</td>
            <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderPhysical}</td>
            <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderAchievement}</td>
            <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderMeaning}</td>
            <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderHappiness}</td>
            <td className="hooga-result-header">{Language.English.CompanyAdmin.TableHeaderOverall}</td>
          </tr>



          {
            this.state.surveyData.map((userData, index)=>{
              return(
                <tr className="hooga-tr">
                  <td className="hooga-result-data">{userData.name}</td>
                  <td style={{backgroundColor:this.logic.getHoogaDataColour(Math.round(userData.engagementMean ) )}} className="hooga-result-data">{Math.round(userData.engagementMean ) }</td>
                  <td style={{backgroundColor:this.logic.getHoogaDataColour(Math.round(userData.relationshipsMean ) )}} className="hooga-result-data">{Math.round(userData.relationshipsMean ) }</td>
                  <td style={{backgroundColor:this.logic.getHoogaDataColour(Math.round(userData.physicalMean ) )}} className="hooga-result-data">{Math.round(userData.physicalMean ) }</td>
                  <td style={{backgroundColor:this.logic.getHoogaDataColour(Math.round(userData.achievementMean ) )}} className="hooga-result-data">{Math.round(userData.achievementMean ) }</td>
                  <td style={{backgroundColor:this.logic.getHoogaDataColour(Math.round(userData.meaningMean ) )}} className="hooga-result-data">{Math.round(userData.meaningMean ) }</td>
                  <td style={{backgroundColor:this.logic.getHoogaDataColour(Math.round(userData.happinessMean ) )}} className="hooga-result-data">{Math.round(userData.happinessMean ) }</td>
                  <td style={{backgroundColor:this.logic.getHoogaDataColour(Math.round(userData.overallMean ) )}} className="hooga-result-data">{Math.round(userData.overallMean ) }</td>
                </tr>
              )
            })
          }


          </table>

        </div>
      )
    }

    return(
      <div>
        {
          this.state.organisationSurveys.map((survey,index)=>{
            return(
              <div className="inkel-admin-data-display">
                <div className="course-display-left">
                  <div className="course-display-name">Group Survey - Created at: {this.logic.convertToString(survey.creationTime)}</div>
                </div>

                <div className="course-display-right"></div>




                <div className="webinars-button" onClick={()=>{this.logic.openSurvey(survey, index)}}>
                  {Language.English.GroupSurveys.Details}
                </div>

              </div>
            )
          })
        }

        <br/><br/>

        <Toggle
          id='live-status'
          defaultChecked={this.state.chosenOrganisationIsEnabled}
          onChange={(event)=>{this.setState({chosenOrganisationIsEnabled:!this.state.chosenOrganisationIsEnabled})}}
          className="enable-toggle" />
          <label className="enabled-toggle-label" htmlFor='live-status'>{Language.English.InkelAdmin.Enabled}</label>

          <Toggle
            id='live-status'
            defaultChecked={this.state.chosenOrganisationPaidManually}
            onChange={(event)=>{this.setState({chosenOrganisationPaidManually:!this.state.chosenOrganisationPaidManually});}}
            className="enable-toggle" />
            <label className="enabled-toggle-label" htmlFor='live-status'>{Language.English.InkelAdmin.PaidManually}</label>

        <div className="edit-organisation-label">{Language.English.InkelAdmin.EditBasicUserNumbers}</div>
        <input className="edit-organisation-input" type="text"  value={this.state.chosenOrganisationBasicUserNumbers} onChange={(event)=>{this.setState({chosenOrganisationBasicUserNumbers:event.target.value})}} />

        <div className="edit-organisation-label">{Language.English.InkelAdmin.EditCoachingNumbers}</div>
        <input className="edit-organisation-input" type="text"  value={this.state.chosenOrganisationMonthlyCoachingNumbers} onChange={(event)=>{this.setState({chosenOrganisationMonthlyCoachingNumbers:event.target.value})}} />

        <div className="edit-organisation-label">{Language.English.InkelAdmin.EditMonthlyCheckinNumbers}</div>
        <input className="edit-organisation-input" type="text"  value={this.state.chosenOrganisationMonthlyCheckinNumbers} onChange={(event)=>{this.setState({chosenOrganisationMonthlyCheckinNumbers:event.target.value})}} />


        <div className="edit-organisation-label">{Language.English.InkelAdmin.EditBasicUserPrice}</div>
        <input className="edit-organisation-input" type="text"  value={this.state.chosenOrganisationBasicUserPrice} onChange={(event)=>{this.setState({chosenOrganisationBasicUserPrice:event.target.value})}} />

        <div className="edit-organisation-label">{Language.English.InkelAdmin.EditCoachingPrice}</div>
        <input className="edit-organisation-input" type="text"  value={this.state.chosenOrganisationMonthlyCoachingPrice} onChange={(event)=>{this.setState({chosenOrganisationMonthlyCoachingPrice:event.target.value})}} />

        <div className="edit-organisation-label">{Language.English.InkelAdmin.EditMonthlyCheckinPrice}</div>
        <input className="edit-organisation-input" type="text"  value={this.state.chosenOrganisationMonthlyCheckinPrice} onChange={(event)=>{this.setState({chosenOrganisationMonthlyCheckinPrice:event.target.value})}} />

        <div className="edit-organisation-label">{Language.English.InkelAdmin.EditMonthlyContentPrice}</div>
        <input className="edit-organisation-input" type="text"  value={this.state.chosenOrganisationMonthlyContentPrice} onChange={(event)=>{this.setState({chosenOrganisationMonthlyContentPrice:event.target.value})}} />
        <br/>
        <div className="edit-organisation-submit-button" onClick={this.logic.saveOrganisationDetails}>{Language.English.InkelAdmin.SubmitDetails}</div>
      </div>
    )
  }



  renderEditCoachPricing = ()=>{
    return(
      <div>
        <div className="pricing-label">
          {Language.English.InkelAdmin.PricingLabel}
        </div>
        <input type="text" placeholder={Language.English.InkelAdmin.PricingPlaceholder} className="pricing-textbox" value={this.state.chosenCoachSessionPrice} onChange={(event)=>{ this.setState({chosenCoachSessionPrice:event.target.value})}} />

        <div className="pricing-label">
          {Language.English.InkelAdmin.UserMonthlyPricingLabel}
        </div>
        <input type="text" placeholder={Language.English.InkelAdmin.UserMonthlyPricingPlaceholder} className="pricing-textbox" value={this.state.chosenCoachMonthlyPrice} onChange={(event)=>{ this.setState({chosenCoachMonthlyPrice:event.target.value})}} />

        <div className="pricing-label">
          {Language.English.InkelAdmin.MonthlyCheckinPriceLabel}
        </div>
        <input type="text" placeholder={Language.English.InkelAdmin.MonthlyCheckinPricePlaceholder} className="pricing-textbox" value={this.state.chosenCoachCheckinPrice} onChange={(event)=>{ this.setState({chosenCoachCheckinPrice:event.target.value})}} />


        <div className="pricing-save-button" onClick={this.logic.savePricingDetails}>
          {Language.English.InkelAdmin.PricingSaveButton}
        </div>
      </div>
    )
  }

  renderEditCourseContent = ()=>{
    if(this.state.editPanelLoading){ //Loading Resources
      return(
        <div className="edit-panel-loader">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
          />
        </div>
      )
    }else if(this.state.addModulePanelOpen){ //Adding New Module
      return(
        <div className="new-module-form">
        <input className="new-course-name-input" type="text" placeholder="New Module Name" value={this.state.newModuleName} onChange={(event)=>{this.setState({newModuleName:event.target.value})}}/>
        <br/>
        <div className="new-module-cancel-button" onClick={this.logic.closeAddNewModulePanel}>{Language.English.Dashboard.CancelButton}</div>
        <div className="new-module-submit-button" onClick={this.logic.addEventModule}>{Language.English.Dashboard.AddButton}</div>
        </div>
      )
    }
  }

}

export default withAlert()(CoachAdmin);
