import ApiKeys from '../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';

class DashboardLogic {

  constructor(context){
    this.baseContext = context;
  }

  loadUserDetails = async()=>{

    var projects = [];

    const currentUserId = firebase.auth().currentUser.uid;

    firebase.firestore().collection("users").doc(currentUserId).get().then(async(doc)=>{
      let name = doc.data().name;
      let type = doc.data().type;
      let accountType = doc.data().accountType;
      let profileURL = doc.data().profileURL;

      let bookingData = null;


      if(doc.data().type == 'client'){

        bookingData = await firebase.firestore().collection("bookings").where('clientId', '==' , currentUserId).limit(1).get().then((snapshot)=>{

          let newBookingData = {};

          snapshot.forEach((booking, i) => {
            newBookingData = booking.data();
            newBookingData['id'] = booking.id;
          });

          return newBookingData;
        }).catch((error)=>{
          console.log(error.message);
        })
      }

      let search = window.location.search;
      let params = new URLSearchParams(search);
      let query = params.get('q');

      if(doc.data().authority=='admin' && query!='h'){
        document.location = '/company-admin';
      }



      this.baseContext.setState({
        name: name,
        type:type,
        profileURL:profileURL,
        isLoadingUserData: false,
        accountType:accountType,
        accountStatus:{
          type:type,
          accountType:accountType,
          hasFilledSurvey:doc.data().surveyComplete,
          hasChosenCoach:doc.data().coachChosen,
          hasAssignedCoach:doc.data().coachAssigned,
          coach:doc.data().coach,
          coachConfirmed:doc.data().coachConfirmed,
          subscriptionPaid:doc.data().subscriptionPaid,
          planPaid:doc.data().planPaid,
          subscriptionId:doc.data().subscriptionId,
          planId:doc.data().planId,
          bookingData:bookingData,
          authority:doc.data().authority,
          isBasicUser:doc.data().isBasicUser || false,
          isCoachingUser:doc.data().isCoachingUser || false,
          pulseSurveyAvailable:doc.data().pulseSurveyAvailable,
          pulseSurveyResults:doc.data().pulseSurveyResults || [],
          enabled:doc.data().enabled,
          notificationTokens:doc.data().notificationTokens || [],
          bookingData:bookingData
        }
      });

    }).catch((error)=>{
      alert(error.message);
    });

    firebase.firestore().collection("users").doc(currentUserId).collection("resources").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          projects.push({name:doc.data().name});
        });

        this.baseContext.setState({
          projectsList: projects,
          isLoadingProjects: false,
        });


    }).catch((error)=>{
      console.log(error.message);
    })


  }


  validateAuth = ()=>{

    firebase.auth().onAuthStateChanged((user)=>{

      if (user && !(this.baseContext.state.loading)) {
        this.baseContext.setState({authenticating:false});
        this.loadUserDetails();
      }else{
        document.location = "/";
      }

    });

  }






}

export default DashboardLogic;
