import React from 'react';

// Styles
import './desktop.css';
import './mobile.css';

// Modules
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ApiKeys from '../../../constants/ApiKeys';
import 'firebase/firestore';
import * as firebase from 'firebase';
import { ClipLoader } from "react-spinners";
import Toggle from "react-toggle";
import DashboardHeader from "../../../components/DashboardHeader";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { withAlert } from 'react-alert';


import "react-toggle/style.css";

//Services & Local Logic
import Language from '../../../services/Language';
import Logic from './logic';

// Animations
import Fade from 'react-reveal/Fade';

//Assets
import plusIcon from '../../../assets/blue-plus-icon.png';
import greyPlusIcon from '../../../assets/grey-plus-icon.png';
import whitePlusIcon from '../../../assets/white-plus-icon.png';
import xButton from '../../../assets/x-button.png';
import editIcon from '../../../assets/icons/edit.png';
import greyBackArrow from '../../../assets/icons/grey-arrow-icon.png';
import defaultProfileImage from '../../../assets/profile-picture.png';

import 'react-big-calendar/lib/css/react-big-calendar.css';

class ClientSettings extends React.Component{

  showAlert = (text)=>{
    const alert = this.props.alert;
    alert.show(text);
  }

  constructor(props){
    super(props);

    this.state = {
      attemptingLogin:false,
      authenticating:true,
      loading:false,
      accountStatus:props.accountStatus,
      myName:props.accountStatus.name,
      userEmail:props.accountStatus.userEmail,
      myCompanyName:props.accountStatus.companyName,
      profileImage:props.accountStatus.profileURL,
      userEmail:props.accountStatus.userEmail,
      userDescription:props.accountStatus.description
    };

    this.context = props.context;
    this.mobileSideBar = props.mobileSideBar;
    this.profileImageInput = React.createRef();
    this.logic = new Logic(this);
    console.log('loads');
  }

  renderAddAvailabilityPanel = ()=>{
    if(!this.state.addAvailabilityPanelOpen) return;

    return(
      <div className="add-availability-panel">
        <img src={xButton} className="add-availability-close-button" onClick={this.logic.closeAddAvailabilityPanel} />
        <div className="add-availability-form">
          <div className="add-availability-form-header">Set your Session Availability</div>
          <div className="add-availability-timezone">Your Timezone: {this.state.timeZone}</div>
          <div className="add-availability-chosen-date">Chosen Date: {this.state.chosenDisplayDate}</div>
        </div>
      </div>
    )
  }


  render(){
    if(this.state.loading){
      return(
        <div className="coach-dashboard-loader-holder">
          <ClipLoader
            size={100}
            color={"#005c9e"}
            loading={true}
            className="coach-dashboard-loader-icon"
          />
        </div>
      );
    }else{

      const localizer = momentLocalizer(moment);

      return(
        <div className="coach-settings-home">
          <DashboardHeader context={this} mobileSideBar={this.mobileSideBar} name={Language.English.Settings.MySettings} userName={this.props.userName} profileImage={this.props.profileImage}/>
          {this.renderChangeEmailPanel()}
          {this.renderChangePasswordPanel()}
          <div className="coach-settings-container">
            <form onSubmit={this.logic.saveSettings}>
              <div className="settings-header">{Language.English.Settings.PersonalDetails}</div>

              <div className="setting-label">{Language.English.Settings.MyName}</div>
              <input type="text" className="setting-input-text" value={this.state.myName} onChange={(event)=>{this.setState({myName:event.target.value})}}/>

              <div className="setting-label">{Language.English.Settings.MyDescription}</div>
              <textarea type="text" className="coach-setting-input-textarea" value={this.state.userDescription} onChange={(event)=>{this.setState({userDescription:event.target.value})}}/>

              <br/>
              <button className="save-settings-button">{Language.English.Settings.Save}</button>

              <div className="open-change-email-button" onClick={this.logic.openChangeEmailPanel}>{Language.English.Settings.ChangeEmail}</div>
              <div className="open-change-password-button" onClick={this.logic.openChangePasswordPanel}>{Language.English.Settings.ChangePassword}</div>
            </form>
          </div>

          <div className="coach-profile-picture-container">
            <div className="settings-header">{Language.English.Settings.ProfilePicture}</div>
            <div className="settings-profile-notice">
              Note: Your profile picture will automatically update when you choose a file.
            </div>

            <div className="profile-picture-row">
              <img src={this.state.profileImage || defaultProfileImage} className="settings-profile-image" />
              <input type="file" accept="image/*" ref={this.profileImageInput} className="choose-profile-image-input" onChange={this.logic.saveProfileImage} />
            </div>

          </div>


        </div>
      );

    }

  }

  renderChangePasswordPanel = ()=>{
    if(!this.state.passwordPanelOpen) return;

    if(this.state.passwordLoading){
      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeChangePasswordPanel}/>

          <div className="edit-course-form">
            <div className="edit-panel-loader">
              <ClipLoader
                size={100}
                color={"#005c9e"}
                loading={true}
              />
            </div>
          </div>
        </div>
      )
    }

    return(
      <div className="new-course-panel">
        <img src={xButton} className="new-course-close-button" onClick={this.logic.closeChangePasswordPanel}/>

        <div className="change-coach-data-form">

          <div className="change-email-header">{Language.English.Settings.ChangePasswordHeader}</div>


          <div className="change-email-form">
            <div className="change-email-header">{Language.English.Settings.OldPassword}</div><br/>
            <input className="new-password-input" type="password" placeholder="" value={this.state.oldPassword} onChange={(event)=>{this.setState({oldPassword:event.target.value})}}/>

            <div className="change-email-header">{Language.English.Settings.NewPassword}</div><br/>
            <input className="new-password-input" type="password" placeholder="" value={this.state.updatedPassword} onChange={(event)=>{this.setState({updatedPassword:event.target.value})}}/>

            <br/>

            <div className="new-module-submit-button" onClick={this.logic.updatePassword}>{Language.English.Settings.Save}</div>

            <br/>

            <div></div>

          </div>

        </div>
      </div>
    )
  }


  renderChangeEmailPanel = ()=>{
    if(!this.state.emailPanelOpen) return;

    if(this.state.emailLoading){
      return(
        <div className="new-course-panel">
          <img src={xButton} className="new-course-close-button" onClick={this.logic.closeChangeEmailPanel}/>

          <div className="edit-course-form">
            <div className="edit-panel-loader">
              <ClipLoader
                size={100}
                color={"#005c9e"}
                loading={true}
              />
            </div>
          </div>
        </div>
      )
    }

    return(
      <div className="new-course-panel">
        <img src={xButton} className="new-course-close-button" onClick={this.logic.closeChangeEmailPanel}/>

        <div className="edit-course-form">

          <div className="change-email-header">{Language.English.Settings.ChangeEmailHeader}</div>


          <div className="change-email-form">
            <div className="change-email-header">{Language.English.Settings.MyEmail}</div><br/>
            <input className="new-course-name-input" type="text" placeholder="" value={this.state.userEmail} onChange={(event)=>{this.setState({userEmail:event.target.value})}}/>

            <div className="change-email-header">{Language.English.Settings.MyPassword}</div><br/>
            <input className="new-course-name-input" type="password" placeholder="" value={this.state.emailUpdatePassword} onChange={(event)=>{this.setState({emailUpdatePassword:event.target.value})}}/>

            <br/>

            <div className="new-module-submit-button" onClick={this.logic.updateEmail}>{Language.English.Settings.Save}</div>

            <br/>

            <div></div>

          </div>

        </div>
      </div>
    )
  }






}

export default withAlert()(ClientSettings);
